import React, { Component } from 'react';
import { Row, Col, Divider, Select, Table, Input, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { getAccountBaseList, addUser } from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import Selection from '../../../components/Selection';
import Button from '../../../components/Button';
import './index.css';

const { Option } = Select;
const { TextArea } = Input;

export default class AdminAddUser extends Component {
  state = {
    spinning: false,
    error: '',
    userName: '',
    email: '',
    pwd: '',
    conPwd: '',
    firstName: '',
    lastName: '',
    cardNum: '',
    userType: '',
    note: '',
    accounts: [],
    accountList: [],
    accSelectedValue: undefined,
  };

  accountTypes = [
    { label: 'Resident', value: 0 },
    { label: 'Employee', value: 1 },
    { label: 'Family Member', value: 2 },
    { label: 'System Admin', value: 3 },
  ];

  columns = [
    {
      title: 'Account Number',
      dataIndex: 'AccountNumber',
    },
    {
      title: 'Account Name',
      render: (_, record) => <Row>{`${record.FirstName} ${record.LastName}`}</Row>,
    },
    {
      title: 'Action',
      render: (_, record) => (
        <Row>
          <FontAwesomeIcon
            icon={faTrashCan}
            size="xl"
            color="var(--adminSecondary)"
            onClick={() => this.handleRemoveAssociatedAccount(record)}
            style={{ marginLeft: 20 }}
          />
        </Row>
      ),
    },
  ];

  getAccounts = async () => {
    try {
      this.setState({ spinning: true });
      const re = await getAccountBaseList();
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false, accounts: re.data });
    } catch (error) {
      message.error('Error obtaining accounts!', 3);
    }
  };

  handleBackBtn = () => {
    this.props.history.replace('/admin/user');
  };

  handleSaveBtn = async () => {
    const {
      userName,
      email,
      pwd,
      conPwd,
      firstName,
      lastName,
      cardNum,
      userType,
      note,
      accountList,
    } = this.state;
    if (!userName.trim()) {
      this.setState({ error: "Username mustn't be empty!" });
      return;
    }
    if (pwd !== conPwd) {
      this.setState({ error: "Passwords don't match!" });
      return;
    }
    if (!pwd.trim() && !conPwd.trim()) {
      this.setState({ error: 'Password is required!' });
      return;
    }
    const obj = {};
    obj.UserName = userName;
    obj.Password = pwd;
    obj.Email = email;
    obj.FirstName = firstName;
    obj.LastName = lastName;
    obj.Type = userType;
    obj.StoreNumber = '';
    obj.Permission = '';
    obj.CardNumber = cardNum;
    obj.EmpCode = '';
    obj.EmpNickName = '';
    obj.Inactive = false;
    obj.Note = note;
    obj.AccountList = accountList;
    obj.CurAccountInfo = null;
    obj.UserGuid = '';

    try {
      this.setState({ spinning: true });
      const re = await addUser(obj);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error(re.result);
      }
      this.setState({ spinning: false });
      message.success('User successfully created!', 3);
      this.props.history.replace('/admin/user');
    } catch (error) {
      message.error(error.message, 3);
    }
  };

  handleInputChange = (dataType, { target: { value } }) => {
    this.setState({ [dataType]: value });
  };

  handleUserTypeChange = (v, o) => {
    this.setState({ userType: v });
  };

  handleSeachClearBtn = () => {
    this.setState({ accSelectedValue: undefined });
  };

  handleSearchSelChange = (v, o) => {
    this.setState({ accSelectedValue: v });
  };

  handleAddAccBtn = () => {
    const { accounts, accSelectedValue, accountList } = this.state;
    const account = accounts.find(i => i.AccountNumber === accSelectedValue);
    if (account) {
      this.setState({ accountList: [...accountList, account], accSelectedValue: undefined });
    } else {
      message.error('Error retrieving account info!', 3);
    }
  };

  handleRemoveAssociatedAccount = account => {
    const { accountList } = this.state;
    this.setState({
      accountList: [...accountList.filter(i => i.AccountNumber !== account.AccountNumber)],
    });
  };

  componentDidMount() {
    this.getAccounts();
  }

  render() {
    const {
      spinning,
      error,
      userName,
      email,
      pwd,
      conPwd,
      firstName,
      lastName,
      cardNum,
      userType,
      note,
      accSelectedValue,
      accountList,
      accounts,
    } = this.state;

    const accountTypeArr = this.accountTypes.map(i => (
      <Option key={i.value} value={i.value}>
        {i.label}
      </Option>
    ));

    //Filter out ones already associated
    const accountsOptions = accounts
      .filter(i => accountList.every(j => j.AccountNumber !== i.AccountNumber))
      .map(i => (
        <Option key={i.AccountNumber} value={i.AccountNumber}>
          {`${i.FirstName} ${i.LastName} - ${i.AccountNumber}`}
        </Option>
      ));

    return (
      <Spin spinning={spinning}>
        <Row className="admin-add-user-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title="Add User"
              showLeftBtn={true}
              leftBtnText="Back"
              showRightBtn={true}
              rightBtnText="Save"
              onRightBtnClick={this.handleSaveBtn}
              onLeftBtnClick={this.handleBackBtn}
            />
            {/* Error Row */}
            <Row
              style={{
                padding: 15,
                height: 30,
                fontWeight: 'bold',
                color: '#8b0000',
                fontSize: 18,
                marginBottom: 20,
              }}
              justify="center"
            >
              <Col>
                <SwitchTransition>
                  <CSSTransition key={error} timeout={500} classNames="login-account-error">
                    <Row>{error}</Row>
                  </CSSTransition>
                </SwitchTransition>
              </Col>
            </Row>
            {/* Inputs Row */}
            <Row>
              <h2 style={{ color: 'var(--adminSecondary)' }}>New User Info</h2>
            </Row>
            <Row justify="center">
              <Col span={20}>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <Row>
                      <strong>Username</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={userName}
                        onChange={e => this.handleInputChange('userName', e)}
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Email</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={email}
                        onChange={e => this.handleInputChange('email', e)}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <Row>
                      <strong>Password</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        type="password"
                        size="large"
                        spellCheck={false}
                        value={pwd}
                        onChange={e => this.handleInputChange('pwd', e)}
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Confirm Password</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        type="password"
                        size="large"
                        spellCheck={false}
                        value={conPwd}
                        onChange={e => this.handleInputChange('conPwd', e)}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <Row>
                      <strong>First Name</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={firstName}
                        onChange={e => this.handleInputChange('firstName', e)}
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Last Name</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={lastName}
                        onChange={e => this.handleInputChange('lastName', e)}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <Row>
                      <strong>Card Number</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={cardNum}
                        onChange={e => this.handleInputChange('cardNum', e)}
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>User Type</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Selection
                        size="large"
                        width={250}
                        data={accountTypeArr}
                        value={userType}
                        onChange={(v, o) => this.handleUserTypeChange(v, o)}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={24}>
                    <Row>
                      <strong>Note</strong>
                    </Row>
                    <Row>
                      <TextArea
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        placeholder="Note"
                        spellCheck={false}
                        showCount
                        maxLength={100}
                        onChange={e => this.handleInputChange('note', e)}
                        value={note}
                        style={{ width: 250 }}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Search Row */}
            <Divider style={{ borderColor: 'transparent' }} />
            <Row>
              <Col span={24}>
                <Row>
                  <h2 style={{ color: 'var(--adminSecondary)' }}>
                    Associated House Account/Card Number
                  </h2>
                </Row>
                <Row style={{ marginTop: 20 }} gutter={16} align="middle">
                  <Col>
                    <Button
                      width={120}
                      height={40}
                      style={{ backgroundColor: 'var(--adminSecondary)' }}
                      textStyle={{ color: '#fff' }}
                      borderRadius={5}
                      onClick={this.handleSeachClearBtn}
                    >
                      Clear Search
                    </Button>
                  </Col>
                  <Col>
                    <Row>
                      <Selection
                        size="large"
                        width={400}
                        data={accountsOptions}
                        value={accSelectedValue}
                        showSearch={true}
                        placeholder={<span>Search Account Name</span>}
                        onChange={(v, o) => this.handleSearchSelChange(v, o)}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Button
                      width={150}
                      height={40}
                      style={{ backgroundColor: 'var(--adminPrimary)' }}
                      textStyle={{ color: 'white' }}
                      borderRadius={5}
                      onClick={this.handleAddAccBtn}
                    >
                      Add Account
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: 15 }}>
              <Col span={24}>
                <Table
                  rowKey="AccountNumber"
                  columns={this.columns}
                  dataSource={accountList}
                  pagination={false}
                  bordered
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}
