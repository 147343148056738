import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PageTransition } from '@steveeeie/react-page-transition';
import { withIdleTimer } from 'react-idle-timer';
import { Row, Layout, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import {
  clearOrderItems,
  clearScannedOrderItems,
  resetUser,
  deleteGuestName,
  resetCustomerOrderOption,
  resetLoginType,
  resetAppMode,
  deleteGuestPhone,
  deleteGuestIns,
} from '../../redux/actions';
import { getVTImageFolder } from '../../api';
import AdminNav from '../../components/AdminNav';
import Spin from '../../components/Spin';
import Dashboard from './Dashboard';
import POS from './POS';
import Profile from './Profile';
import Password from './Password';
import Statements from './Statements';
import OrderHistory from './OrderHistory';
import ApplicationSettings from './ApplicationSettings';
import KioskConfigurations from './KioskConfigurations';
import KioskMappings from './KioskMappings';
import Images from './Images';
import User from './User';
import AddUser from './AddUser';
import EditUser from './EditUser';
import OrderOptionMappings from './OrderOptionMappings';
import OrderOption from './OrderOption';
import ResMappings from './ResMappings';
import EditResMapping from './EditResMapping';
import ResSettings from './ResSettings';
import ResSetting from './ResSetting';
import Reservation from './Reservation';
import ResHistory from './ResHistory';
import ViewEditor from './ViewEditor';
import EditView from './EditView';
import './index.css';

const { Sider, Content } = Layout;

class Admin extends Component {
  state = {
    spinning: false,
    logoImageDir: '',
    backgroundImageDir: '',
  };

  init = async () => {
    this.setState({ spinning: true });
    await Promise.all([getVTImageFolder('5'), getVTImageFolder('6')])
      .then(results => {
        results.forEach(res => {
          if (!res || (res && res.result !== 'OK')) throw new Error();
        });
        this.setState({
          backgroundImageDir: results[0].data,
          logoImageDir: results[1].data,
          spinning: false,
        });
      })
      .catch(error => {
        this.setState({ spinning: false });
        message.error('Error obtaining image!', 3);
      });
  };

  getImage = (dataType, imageName) => {
    let src = '';
    const imageDir = this.state[dataType];
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      //dev mode
      if (imageName && imageDir) {
        src = `http://localhost:4000/${imageDir}/${imageName}`;
      } else {
        src = '';
      }
    } else {
      //production mode
      if (imageName && imageDir) {
        src = `${process.env.PUBLIC_URL}/${imageDir}/${imageName}`;
      } else {
        src = '';
      }
    }
    return src;
  };

  handleLogOut = () => {
    //clear cart, user
    this.props.clearOrderItems();
    this.props.clearScannedOrderItems();
    this.props.resetUser();
    this.props.deleteGuestName();
    this.props.deleteGuestPhone();
    this.props.deleteGuestIns();
    this.props.resetCustomerOrderOption();
    this.props.resetLoginType();
    this.props.resetAppMode();
    this.props.history.replace('/');
  };

  componentDidMount = () => {
    this.init();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { kioskSetting } = this.props;
    if (kioskSetting.imgSetting !== prevProps.kioskSetting.imgSetting) {
      this.init();
    }

    if (this.props.isIdle()) {
      this.props.onIdleTimerFinish();
    } else {
      this.props.reset();
    }
  };

  render() {
    const { spinning } = this.state;
    const { kioskSetting, clientStyle } = this.props;
    const {
      imgSetting: { Logo, DashboardBackground },
    } = kioskSetting;

    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      // textTransform: clientStyle.textTransform,
    };
    const descStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontTwo,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };

    return (
      <Spin spinning={spinning}>
        <Layout style={{ height: '100vh' }}>
          <Sider style={{ position: 'relative', backgroundColor: 'var(--adminPrimary)' }}>
            {/* Logo */}
            <div
              className="admin-sider-logo"
              style={{
                backgroundColor: Logo
                  ? clientStyle.storeNumber === '3932' || clientStyle.storeNumber === '5960'
                    ? 'rgba(250, 246, 232, 0.5)'
                    : 'rgba(255, 255, 255, 0.7)'
                  : '',
                borderRadius: Logo ? '4px' : '',
                boxShadow: Logo ? '0 4px 10px rgba(0, 0, 0, 0.1)' : '',
                border: Logo ? '1px solid rgba(255, 255, 255, 0.3)' : '',
              }}
            >
              <img src={this.getImage('logoImageDir', Logo)} alt={''} height={80} />
            </div>
            {/* Nav */}
            <div className="admin-nav-wrapper" style={{ height: 'calc(100vh - 180px)' }}>
              <AdminNav kioskSetting={kioskSetting} />
            </div>
            {/* Log out Button */}
            <Row
              style={{
                position: 'absolute',
                bottom: 10,
                left: '50%',
                transform: 'translate(-50%)',
              }}
              onClick={this.handleLogOut}
            >
              <FontAwesomeIcon icon={faPowerOff} size="3x" color="var(--adminSecondary)" />
            </Row>
          </Sider>
          <Content
            className="admin-content-container"
            style={{ height: '100vh', position: 'relative', ...titleStyle }}
          >
            <Route
              render={({ location }) => {
                return (
                  <PageTransition preset="pushRightPullLeft" transitionKey={location.pathname}>
                    <Switch location={location}>
                      <Route path="/admin/dashboard" component={Dashboard} />
                      <Route path="/admin/profile" component={Profile} />
                      <Route path="/admin/password" component={Password} />
                      <Route path="/admin/statements" component={Statements} />
                      <Route path="/admin/orderhistory" component={OrderHistory} />
                      <Route path="/admin/app" component={ApplicationSettings} />
                      <Route path="/admin/kiosk" component={KioskConfigurations} />
                      <Route path="/admin/kioskmappings" component={KioskMappings} />
                      <Route path="/admin/image" component={Images} />
                      <Route path="/admin/user" component={User} />
                      <Route path="/admin/adduser" component={AddUser} />
                      <Route path="/admin/edituser" component={EditUser} />
                      <Route path="/admin/orderoptionmappings" component={OrderOptionMappings} />
                      <Route path="/admin/orderoption" component={OrderOption} />
                      <Route path="/admin/resmappings" component={ResMappings} />
                      <Route path="/admin/editresmapping" component={EditResMapping} />
                      <Route path="/admin/ressettings" component={ResSettings} />
                      <Route path="/admin/ressetting" component={ResSetting} />
                      <Route path="/admin/res" component={Reservation} />
                      <Route path="/admin/reshistory" component={ResHistory} />
                      <Route path="/admin/vieweditor" component={ViewEditor} />
                      <Route path="/admin/editview" component={EditView} />
                      <Route
                        path="/admin/pos"
                        render={props => (
                          <POS
                            {...props}
                            onTimerPause={() => {
                              this.props.pause();
                            }}
                            onTimerReset={() => {
                              this.props.reset();
                            }}
                          />
                        )}
                      />
                      <Redirect to="/admin/dashboard" />
                    </Switch>
                  </PageTransition>
                );
              }}
            />
          </Content>
        </Layout>
      </Spin>
    );
  }
}

export default connect(
  state => ({ kioskSetting: state.kioskSetting, clientStyle: state.clientStyle }),
  {
    clearOrderItems,
    clearScannedOrderItems,
    resetUser,
    deleteGuestName,
    deleteGuestPhone,
    deleteGuestIns,
    resetCustomerOrderOption,
    resetLoginType,
    resetAppMode,
  },
)(withIdleTimer(Admin));
