import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from 'antd';

class OrderOptionDayParts extends Component {
  render() {
    const { dayParts, dayPartChoice, clientStyle } = this.props;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    return (
      <Row
        style={{
          margin: '10px 0',
        }}
      >
        {dayParts &&
          dayParts.map(
            (i, index) =>
              i.Description && (
                <div
                  key={index}
                  style={{
                    ...titleStyle,
                    backgroundColor: dayPartChoice === i ? clientStyle.primary : '#fff',
                    color: dayPartChoice === i ? clientStyle.colorTwo : clientStyle.colorOne,
                    margin: 5,
                    minWidth: 100,
                    padding: '10px 15px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '5px ',
                    WebkitBoxShadow: '1px -1px 8px 0px rgba(0,0,0,0.75)',
                    boxShadow: '1px -1px 8px 0px rgba(0,0,0,0.75)',
                    fontSize: 18,
                    fontWeight: 'bold',
                    transition: 'transform 0.2s ease',
                    textAlign: 'center',
                  }}
                  onClick={() => {
                    this.props.onDayPartChange(i);
                  }}
                >
                  {i.Description}
                </div>
              ),
          )}
      </Row>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(OrderOptionDayParts);
