//initCefObject is not needed
// async function initCefObject() {
//   if (typeof window.CefSharp !== 'undefined' && typeof window.cefObject === 'undefined') {
//     await window.CefSharp.BindObjectAsync('cefObject');
//   }
// }
// Close kiosk form
export const closeKioskForm = codeStr => {
  return (async function () {
    const retVal = window.cefObject.closeKioskForm(codeStr);
    return retVal;
  })();
};

// Open Station Config
export const openStationConfig = () => {
  return (async function () {
    const retVal = window.cefObject.openStationConfig();
    return retVal;
  })();
};

// Current Setting
export const getCurrentKioskSetting = () => {
  return (async function () {
    const retVal = window.cefObject.getCurrentKioskSetting();
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const appInitCompleted = () => {
  return (async function () {
    const retVal = window.cefObject.appInitCompleted();
    return retVal;
  })();
};

export const setOrderSetup = orderSetupObj => {
  const orderSetupStr = JSON.stringify(orderSetupObj);
  return (async function () {
    const retVal = window.cefObject.setOrderSetup(orderSetupStr);
    return retVal;
  })();
};

export const resetOrderSetup = () => {
  return (async function () {
    const retVal = window.cefObject.resetOrderSetup();
    return retVal;
  })();
};

//pageName: str
export function getListViewPage(pageName) {
  return (async function () {
    // await initCefObject();
    const retVal = window.cefObject.getListViewPage(pageName);
    const re = JSON.parse(retVal);
    return re;
  })();
}

//isPackageSubItem: str
export function getItemInformation(itemCode, isPackageSubItem) {
  return (async function () {
    // await initCefObject();
    const retVal = window.cefObject.getItemInformation(itemCode, isPackageSubItem);
    const re = JSON.parse(retVal);
    return re;
  })();
}

export const getPizzaPrice = checkItem => {
  return (async () => {
    const checkItemStr = JSON.stringify(checkItem);
    const retVal = window.cefObject.getPizzaItemTotalPrice(checkItemStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getOrderSummary = checkObj => {
  return (async () => {
    const checkObjStr = JSON.stringify(checkObj);
    const retVal = window.cefObject.getOrderSummary(checkObjStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const createOrder = checkObj => {
  return (async () => {
    const checkObjStr = JSON.stringify(checkObj);
    const retVal = window.cefObject.createOrder(checkObjStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getGiftCardInfo = cardNumStr => {
  return (async () => {
    const retVal = window.cefObject.getGiftCardInfo(cardNumStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getRewardPointsInfo = currAcctNoStr => {
  return (async () => {
    const retVal = window.cefObject.getRewardPointsInfo(currAcctNoStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const addPaymentToCheck = (paymentInfoObj, checkObj, otherPaymentInfoArr) => {
  return (async () => {
    const paymentInfoObjStr = JSON.stringify(paymentInfoObj);
    const checkObjStr = JSON.stringify(checkObj);
    const otherPaymentInfoStr = JSON.stringify(otherPaymentInfoArr);
    const retVal = window.cefObject.addPaymentToCheck(
      paymentInfoObjStr,
      checkObjStr,
      otherPaymentInfoStr,
    );
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const userLogin = (unameStr, pwdStr) => {
  return (async () => {
    const retVal = window.cefObject.userLogin(unameStr, pwdStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const userCardLogin = cardDataStr => {
  return (async () => {
    const retVal = window.cefObject.userCardLogin(cardDataStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getOrderOptionMappings = () => {
  return (async () => {
    const retVal = window.cefObject.getOrderOptionMappings('');
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getProfitCentres = () => {
  return (async () => {
    const retVal = window.cefObject.getProfitCentres('');
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getAccountTypes = () => {
  return (async () => {
    const retVal = window.cefObject.getAccountTypes();
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getAvailablePayments = () => {
  return (async () => {
    const retVal = window.cefObject.getAvailablePayments();
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getVenues = () => {
  return (async () => {
    const retVal = window.cefObject.getVenues();
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const saveOrderOptionMappings = orderOptionList => {
  return (async () => {
    const orderOptionListStr = JSON.stringify(orderOptionList);
    const retVal = window.cefObject.saveOrderOptionMappings(orderOptionListStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const saveOrderOptionMapping = orderOption => {
  return (async () => {
    const orderOptionStr = JSON.stringify(orderOption);
    const retVal = window.cefObject.saveOrderOptionMapping(orderOptionStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getOrderOption = acctObj => {
  return (async () => {
    let acctStr = '';
    if (acctObj) {
      acctStr = JSON.stringify(acctObj);
    }
    const retVal = window.cefObject.getOrderOption(acctStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getReservationConfigs = idStr => {
  return (async () => {
    let codeStr = '';
    if (idStr) {
      codeStr = idStr;
    }
    const retVal = window['cefObject'].getReservationConfigs(codeStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const saveReservationConfig = resObj => {
  return (async () => {
    const resStr = JSON.stringify(resObj);
    const retVal = window.cefObject.saveReservationConfig(resStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const deleteReservationConfig = codeStr => {
  return (async () => {
    const retVal = window.cefObject.deleteReservationConfig(codeStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getReservationMappings = idStr => {
  return (async () => {
    let codeStr = '';
    if (idStr) {
      codeStr = idStr;
    }
    const retVal = window.cefObject.getReservationMappings(codeStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const saveReservationMapping = resMappingObj => {
  return (async () => {
    const resMappingStr = JSON.stringify(resMappingObj);
    const retVal = window.cefObject.saveReservationMapping(resMappingStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getAccountStatement = rptParamObj => {
  return (async () => {
    const rptParamStr = JSON.stringify(rptParamObj);
    const retVal = window.cefObject.getAccountStatement(rptParamStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getAccountOrderHistory = rptParamObj => {
  return (async () => {
    const rptParamStr = JSON.stringify(rptParamObj);
    const retVal = window.cefObject.getAccountOrderHistory(rptParamStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getCheckHtml = rptParamObj => {
  return (async () => {
    const rptParamStr = JSON.stringify(rptParamObj);
    const retVal = window.cefObject.getCheckHtml(rptParamStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const updateUserPassword = (userNameStr, newPwdStr) => {
  return (async () => {
    const retVal = window.cefObject.updateUserPassword(userNameStr, newPwdStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

// Images
export const saveVTImage = imgObj => {
  const imgStr = JSON.stringify(imgObj);
  return (async () => {
    const retVal = window.cefObject.saveVTImage(imgStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const deleteVTImage = imgObj => {
  const imgStr = JSON.stringify(imgObj);
  return (async () => {
    const retVal = window.cefObject.deleteVTImage(imgStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getVTImages = typeStr => {
  return (async () => {
    const retVal = window.cefObject.getVTImages(typeStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getVTImageFolder = typeStr => {
  return (async () => {
    const retVal = window.cefObject.getVTImageFolder(typeStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getVTItemImageFolder = () => {
  return (async () => {
    const retVal = window.cefObject.getVTItemImageFolder();
    const re = JSON.parse(retVal);
    return re;
  })();
};

// Views
export const getViewPages = () => {
  return (async () => {
    const retVal = window.cefObject.getViewPages();
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const saveViewPage = viewPageObj => {
  const viewPageStr = JSON.stringify(viewPageObj);
  return (async () => {
    const retVal = window.cefObject.saveViewPage(viewPageStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const deleteViewPage = viewNameStr => {
  return (async () => {
    const retVal = window.cefObject.deleteViewPage(viewNameStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const renameViewPage = (oldViewNameStr, newViewNameStr) => {
  return (async () => {
    const retVal = window.cefObject.renameViewPage(oldViewNameStr, newViewNameStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getGroups = () => {
  return (async () => {
    const str = '';
    const retVal = window.cefObject.getGroups(str);
    const re = JSON.parse(retVal);
    return re;
  })();
};

// empty: all
// 1: regular panel
// 2: cycle panel
export const getPanelNames = typeStr => {
  let str = '';
  if (typeStr) {
    str = typeStr;
  }
  return (async () => {
    const retVal = window.cefObject.getPanelNames(str);
    const re = JSON.parse(retVal);
    return re;
  })();
};

// Users
export const getUsers = userNameStr => {
  let str = '';
  if (userNameStr) {
    str = userNameStr;
  }
  return (async () => {
    const retVal = window.cefObject.getUsers(str);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const editUser = userObj => {
  const userStr = JSON.stringify(userObj);
  return (async () => {
    const retVal = window.cefObject.editUser(userStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const addUser = userObj => {
  const userStr = JSON.stringify(userObj);
  return (async () => {
    const retVal = window.cefObject.addUser(userStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getAccountBaseList = () => {
  return (async () => {
    const retVal = window.cefObject.getAccountBaseList();
    const re = JSON.parse(retVal);
    return re;
  })();
};

//App Settings
export const getAppSetting = () => {
  return (async () => {
    const retVal = window.cefObject.getAppSetting();
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const saveAppSetting = appSettingObj => {
  const appSettingStr = JSON.stringify(appSettingObj);
  return (async () => {
    const retVal = window.cefObject.saveAppSetting(appSettingStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getKioskStationConfigurations = () => {
  return (async () => {
    const retVal = window.cefObject.getKioskStationConfigurations();
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getKioskStationConfiguration = settingIdStr => {
  return (async () => {
    const retVal = window.cefObject.getKioskStationConfiguration(settingIdStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const saveKioskStationConfiguration = configObj => {
  const configStr = JSON.stringify(configObj);
  return (async () => {
    const retVal = window.cefObject.saveKioskStationConfiguration(configStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

//Popup button data
export const getPopupButtonData = (panelNameStr, codeStr) => {
  return (async () => {
    const retVal = window.cefObject.getPopupButtonData(panelNameStr, codeStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

//Scan mode
export const getItemInformationByBarcode = barCodeStr => {
  return (async () => {
    const retVal = window.cefObject.getItemInformationByBarcode(barCodeStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

//Reservation
export const getReservationVms = () => {
  return (async () => {
    const retVal = window.cefObject.getReservationVms();
    const re = JSON.parse(retVal);
    return re;
  })();
};

/**
 *
 * @param {*} resCodeStr "code" property from vm
 * @param {*} centreCodeStr
 * @param {*} dateStr
 * @param {*} curResIdStr should be empty str when creating a new resvervation
 * @returns
 */
export const getReservationGuests = (resCodeStr, centreCodeStr, dateStr, curResIdStr) => {
  return (async () => {
    const retVal = window.cefObject.getReservationGuests(
      resCodeStr,
      centreCodeStr,
      dateStr,
      curResIdStr,
    );
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const addReservation = resObj => {
  const resStr = JSON.stringify(resObj);
  return (async () => {
    const retVal = window.cefObject.addReservation(resStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const editReservation = resObj => {
  const resStr = JSON.stringify(resObj);
  return (async () => {
    const retVal = window.cefObject.editReservation(resStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const cancelReservation = resObj => {
  const resStr = JSON.stringify(resObj);
  return (async () => {
    const retVal = window.cefObject.cancelReservation(resStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getAccountUpcomingReservations = acctStr => {
  return (async () => {
    const retVal = window.cefObject.getAccountUpcomingReservations(acctStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

export const getAccountReservationHistory = (acctStr, fromDateStr, toDateStr) => {
  return (async () => {
    const retVal = window.cefObject.getAccountReservationHistory(acctStr, fromDateStr, toDateStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

// Get all the items
export const getItemList = () => {
  return (async () => {
    const retVal = window.cefObject.getItemList();
    const re = JSON.parse(retVal);
    return re;
  })();
};

// Manually print receipt
export const printBill = orderObj => {
  const orderObjStr = JSON.stringify(orderObj);
  return (async () => {
    const retVal = window.cefObject.printBill(orderObjStr);
    const re = JSON.parse(retVal);
    return re;
  })();
};

//Refresh App

export const refreshScreen = () => {
  return (async () => {
    window.cefObject.refreshScreen();
  })();
};

//Log errors
export const errorLog = msg => {
  return (async () => {
    window.cefObject.errorLog(msg);
  })();
};
