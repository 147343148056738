import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Skeleton, Row, Col, Divider } from 'antd';
import './index.css';

class Pill extends Component {
  state = { loading: false };

  timer = () => {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 350);
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.timer();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { loading } = this.state;
    const {
      id,
      style,
      imgHeight,
      img,
      price,
      onCardClick,
      imgStr = null,
      image = null,
      imageDir,
      itemImage,
      item,
      backgroundImage = 'linear-gradient(120deg, #fdfbfb, #ebedee)',
      clientStyle,
    } = this.props;

    let src = '';
    if (image) {
      src = image;
    } else {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        //dev mode
        if (imgStr) {
          src = imgStr;
        } else {
          if (img && imageDir) {
            if (itemImage) {
              src = `http://localhost:4000/kioskimages/itemimages/${img}`;
            } else {
              src = `http://localhost:4000/${imageDir}/${img}`;
            }
          } else {
            src = '';
          }
        }
      } else {
        //production mode
        if (imgStr) {
          src = imgStr;
        } else {
          if (img && imageDir) {
            if (itemImage) {
              src = `${imageDir}/${img}`;
            } else {
              src = `${process.env.PUBLIC_URL}/${imageDir}/${img}`;
            }
          } else {
            src = '';
          }
        }
      }
    }

    return (
      <div
        id={id}
        className="vt-pill-wrap"
        onClick={onCardClick}
        style={{
          ...style,
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          // minWidth: imgHeight,
          minHeight: imgHeight,
          backgroundImage,
          boxShadow: '1px 2px 3px 0px rgba(0, 0, 0, 0.5)',
          WebkitBoxShadow: '1px 2px 3px 0px rgba(0, 0, 0, 0.5)',
          borderRadius: '10px',
        }}
      >
        <Skeleton loading={loading} active>
          <div className="vt-pill-container" style={{ width: '100%', minHeight: imgHeight }}>
            <Row wrap={false} gutter={4}>
              <Col flex={window.innerWidth >= 1080 ? '154px' : '124px'}>
                <Row align="middle" justify="center" style={{ minHeight: imgHeight }}>
                  {img ? (
                    <img height={imgHeight} src={src} alt={''} style={{ maxWidth: '100%' }} />
                  ) : (
                    ''
                  )}
                </Row>
              </Col>
              <Col flex="auto">
                <Row
                  wrap={false}
                  align="middle"
                  justify="space-between"
                  style={{
                    fontFamily: clientStyle.fontOne,
                    // fontVariationSettings: clientStyle.fontVariationSettings,
                    textTransform: clientStyle.textTransform,
                    color: clientStyle.colorOne,
                  }}
                >
                  <Col flex={5}>
                    <Row className="vt-pill-info-meta-title">{item.Description}</Row>
                  </Col>
                  <Col flex={1}>
                    <Row className="vt-pill-info-meta-price" justify="center">
                      {price ? `${price}` : ''}
                    </Row>
                  </Col>
                </Row>
                <Divider style={{ margin: 3, borderColor: clientStyle.colorOne }} />
                <Row
                  className="vt-pill-info-meta-cal"
                  style={{
                    fontFamily: clientStyle.fontOne,
                    fontVariationSettings: clientStyle.fontVariationSettings,
                    textTransform: clientStyle.textTransform,
                    color: clientStyle.colorOne,
                  }}
                >
                  {item.CalorieInfo ? item.CalorieInfo : ''}
                </Row>
                <div
                  className="vt-pill-info-meta-des"
                  style={{
                    fontFamily: clientStyle.fontTwo,
                    color: clientStyle.colorOne,
                  }}
                >
                  {item?.OnlineDescription?.split('<br/>')
                    .filter(i => i)
                    .map(j => (
                      <p key={j}>{j}</p>
                    ))}
                </div>
              </Col>
            </Row>
          </div>
        </Skeleton>
      </div>
    );
  }
}

export default connect(state => ({ clientStyle: state.clientStyle }), {})(Pill);
