import React, { Component } from 'react';
import { Row, Radio } from 'antd';

class VSFontColorPicker extends Component {
  options = [
    {
      label: 'Black',
      value: '#000000',
    },
    {
      label: 'White',
      value: '#ffffff',
    },
  ];

  handleChange = ({ target: { value } }) => {
    this.props.onFontColorChange(value);
  };

  render() {
    const { color } = this.props;
    return (
      <Row>
        <Radio.Group
          options={this.options}
          onChange={this.handleChange}
          value={color}
          optionType="button"
          buttonStyle="solid"
        />
      </Row>
    );
  }
}

export default VSFontColorPicker;
