import React, { Component } from 'react';
import { Row, Col, Divider } from 'antd';
import { connect } from 'react-redux';
import VTTextTransition from '../../components/TextTransition';

class OrderHeaderAlt extends Component {
  render() {
    const {
      order = [],
      width,
      bgColor = 'var(--primary)',
      color = 'white',
      height = 60,
      fontSize = 'large',
      transition,
      clientStyle,
    } = this.props;

    const totalItems = order.map(i => i.qty).reduce((a, b) => a + b, 0) || 0;
    const totalAmount =
      order
        .map(i => {
          let iSub = i.type === 'P' && i.packageType === '1' ? 0 : i.qty * i.price,
            mSub = 0,
            pSub = 0,
            packSub = 0;
          if (i.modifierDetails.length > 0) {
            mSub = i.modifierDetails.map(j => j.price * j.qty).reduce((a, b) => a + b, 0) * i.qty;
          }
          if (i.pizzaDetails.length > 0) {
            pSub = i.pizzaDetails.map(k => k.price * k.qty).reduce((e, f) => e + f, 0) * i.qty;
          }
          if (
            i.modifierDetails.length === 0 &&
            i.pizzaDetails.length === 0 &&
            i.packageDetails.length > 0
          ) {
            packSub =
              i.packageDetails
                .map(p => {
                  let packItemSub = parseFloat(p.price) * p.qty,
                    packPSub = 0;
                  if (p.pizzaDetails && p.pizzaDetails.length > 0) {
                    packPSub = p.pizzaDetails
                      .map(k => parseFloat(k.price) * k.qty)
                      .reduce((l, m) => l + m, 0);
                  }
                  return packItemSub + packPSub;
                })
                .reduce((u, w) => u + w, 0) * i.qty;
          }
          return iSub + mSub + pSub + packSub;
        })
        .reduce((c, d) => c + d, 0)
        .toFixed(2) || '0.00';

    const titleStyle = {
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };

    return (
      <Row
        style={{
          backgroundColor: bgColor,
          color,
          padding: '15px 0',
          width: width ? width : '100%',
          height,
          fontSize: fontSize,
          fontWeight: 'bold',
          boxShadow: '1px 2px 3px 0px rgba(0, 0, 0, 0.5)',
          ...titleStyle,
        }}
        align="middle"
      >
        <Col span={11}>
          <Row justify="center">
            {totalItems} {totalItems <= 1 ? 'Item' : 'Items'}{' '}
          </Row>
        </Col>
        <Col span={2}>
          <Divider type="vertical" style={{ borderColor: color, borderWidth: 2 }} />
        </Col>
        <Col span={11}>
          <Row justify="center" align="middle">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {transition ? <VTTextTransition text={`$${totalAmount}`} /> : `$${totalAmount}`}
            </div>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default connect(state => ({ clientStyle: state.clientStyle }), {})(OrderHeaderAlt);
