import React, { Component } from 'react';
import { Skeleton } from 'antd';

import './index.css';

export default class Card extends Component {
  state = { loading: false };

  timer = () => {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 350);
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.timer();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { loading } = this.state;
    //Default width and height are set to 200px, if no such props are passed.
    const {
      width = 200,
      height = 200,
      style,
      imgHeight,
      img,
      title,
      titleStyle,
      price,
      infoStyle,
      selectedBorder = 'var(--secondary)',
      selectedCard,
      id,
      cal,
      onCardClick,
      color = 'black',
      imageDir,
      itemImage,
      imgStr = null,
      image,
    } = this.props;
    //If no backgroundImage prop is passed, it is set to such.
    let { backgroundImage } = this.props || null;
    if (!backgroundImage) {
      backgroundImage = 'linear-gradient(120deg, #fdfbfb, #ebedee)';
    }

    let src = '';
    if (image) {
      src = image;
    } else {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        //dev mode
        if (imgStr) {
          src = imgStr;
        } else {
          if (img && imageDir) {
            if (itemImage) {
              src = `http://localhost:4000/kioskimages/itemimages/${img}`;
            } else {
              src = `http://localhost:4000/${imageDir}/${img}`;
            }
          } else {
            src = '';
          }
        }
      } else {
        //production mode
        if (imgStr) {
          src = imgStr;
        } else {
          if (img && imageDir) {
            if (itemImage) {
              src = `${imageDir}/${img}`;
            } else {
              src = `${process.env.PUBLIC_URL}/${imageDir}/${img}`;
            }
          } else {
            src = '';
          }
        }
      }
    }

    const boxShadow = `0 calc((0.3/250)*${width}px) calc((0.7/250)*${width}px) rgba(0, 0, 0, 0.126),
    0 calc((0.9/250)*${width}px) calc((1.7/250)*${width}px) rgba(0, 0, 0, 0.179),
    0 calc((1.8/250)*${width}px) calc((3.5/250)*${width}px) rgba(0, 0, 0, 0.224),
    0 calc((3.7/250)*${width}px) calc((7.3/250)*${width}px) rgba(0, 0, 0, 0.277),
    0 calc((7.5/250)*${width}px) calc((14/250)*${width}px) rgba(0, 0, 0, 0.4)`;

    return (
      <div
        id={id}
        className="vt-card-container"
        onClick={onCardClick}
        style={{
          ...style,
          display: 'flex',
          justifyContent: 'center',
          width,
          height,
          backgroundImage,
          border:
            selectedCard !== undefined && id !== undefined && selectedCard === id
              ? `3px solid ${selectedBorder}`
              : '',
          boxShadow,
          WebkitBoxShadow: boxShadow,
        }}
      >
        <Skeleton loading={loading} active>
          {image || imgStr || (img && imageDir) ? (
            <div className="vt-card-with-img">
              <div className="vt-card-img-wrap">
                <img height={imgHeight} alt={''} src={src} style={{ maxWidth: '100%' }} />
              </div>
              <div className="vt-card-with-img-meta">
                <div
                  className="vt-card-with-img-meta-title"
                  style={{
                    ...titleStyle,
                    width: '100%',
                  }}
                >
                  {title}
                </div>
                <div
                  className="vt-card-with-img-meta-info"
                  style={{ ...infoStyle, color: titleStyle.color, width }}
                >
                  <div className="vt-card-with-img-meta-info-cal">{cal ? cal : ''}</div>
                  <div className="vt-card-with-img-meta-info-price">{price ? `${price}` : ''}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="vt-card-slider-without-img">
              <div className="vt-card-meta" style={{ width, height }}>
                <div className="vt-card-meta-title">
                  <div className="vt-card-title-content" style={{ ...titleStyle }}>
                    {title}
                  </div>
                </div>
                <div
                  className="vt-card-meta-info"
                  style={{ ...infoStyle, color: titleStyle.color }}
                >
                  <div className="vt-card-meta-info-cal">{cal ? cal : ''}</div>
                  <div className="vt-card-meta-info-price" style={{ fontWeight: 500 }}>
                    {price ? `${price}` : ''}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Skeleton>
      </div>
    );
  }
}
