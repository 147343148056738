import React, { Component } from 'react';
import { connect } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { Row, Col } from 'antd';
import moment from 'moment';
import Button from '../Button';

class ResDate extends Component {
  render() {
    const { dates, dateChoice, clientStyle } = this.props;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    return (
      <Row>
        <Col span={4}></Col>
        <Col span={16}>
          <Row>
            <Col span={24}>
              <Row
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'center',
                  padding: '20px 0',
                }}
              >
                {dates.length > 0 ? (
                  dates.map(i => (
                    <Row key={nanoid()} justify="center" style={{ margin: '0 0 20px 0' }}>
                      <Button
                        width={200}
                        borderRadius={8}
                        style={{
                          ...titleStyle,
                          backgroundColor: dateChoice === i ? clientStyle.primary : '#fff',
                          color: dateChoice === i ? clientStyle.colorTwo : clientStyle.colorOne,
                        }}
                        textStyle={{ fontSize: 16 }}
                        onClick={() => {
                          this.props.onDateChange(i);
                        }}
                      >
                        {moment(i.DateShort).format('ddd, MMM D, YYYY')}
                      </Button>
                    </Row>
                  ))
                ) : (
                  <h2 style={{ ...titleStyle }}>There is no available date at the moment!</h2>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={4}></Col>
      </Row>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(ResDate);
