import React, { Component } from 'react';
import { Row } from 'antd';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Button from '../Button';
import Modal from '../Modal';

class IdleTimerModal extends Component {
  renderTimer = ({ remainingTime }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ fontSize: 40 }}>{remainingTime}</div>
      </div>
    );
  };

  onTimerFinish = () => {
    this.props.onIdleTimerFinish();
  };

  handleContinueBtn = () => {
    this.props.onContinueBtn();
  };

  render() {
    const { visible, duration = 15, clientStyle } = this.props;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };
    return (
      <Modal
        visible={visible}
        closable={false}
        maskClosable={false}
        title={null}
        footer={null}
        destroyOnClose={true}
        zIndex={9999}
        showCloseBtn={false}
      >
        {/* Title row */}
        <Row style={{ fontSize: 18, fontWeight: 'bold' }} justify="center" align="middle">
          <FontAwesomeIcon icon={faCircleExclamation} size="2x" color="#faad14" />
          <h1 style={{ ...titleStyle, marginLeft: 20 }}>Are you still there?</h1>
        </Row>
        {/* Timer row */}
        <Row justify="center" style={{ marginTop: 20 }}>
          <div
            className="timer-wrapper"
            style={{ display: 'flex', justifyContent: 'center', ...titleStyle }}
          >
            <CountdownCircleTimer
              isPlaying
              duration={duration}
              //Multiple colors do not work if passed as variables
              colors={[clientStyle.primary]}
              // colorsTime={[11, 0]}
              onComplete={this.onTimerFinish}
            >
              {this.renderTimer}
            </CountdownCircleTimer>
          </div>
        </Row>
        {/* Btn row */}
        <Row justify="center" style={{ margin: '50px 0 30px' }}>
          <Button
            width={400}
            height={70}
            style={{ ...rowStyle }}
            textStyle={{ fontSize: 18 }}
            borderRadius={5}
            onClick={this.handleContinueBtn}
          >
            Yes, I'll continue my transactions
          </Button>
        </Row>
      </Modal>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(IdleTimerModal);
