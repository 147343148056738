import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input } from 'antd';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Keyboard from 'react-simple-keyboard';
import Button from '../../components/Button';

class LoginGuest extends Component {
  state = {
    error: '',
    layout: 'default',
    input: '',
    shift: false,
    cap: false,
  };

  ref = React.createRef();

  handleGuestLoginCancel = () => {
    this.setState({ input: '', error: '' });
    this.keyboard.clearInput();
    this.props.onGuestLoginCancel();
  };

  handleOK = () => {
    const { input } = this.state;
    if (input.trim().length === 0) {
      this.setState({ error: 'Please enter your name' });
      this.ref.current.focus();
      return;
    } else {
      this.props.onGuestLoginOK(input);
      this.setState({ error: '', input: '' });
    }
  };

  keyboardOptions = {
    ...this.commonKeyboardOptions,
    layout: {
      default: [
        '1 2 3 4 5 6 7 8 9 0 - {bksp}',
        'q w e r t y u i o p [ ] \\',
        "{lock} a s d f g h j k l ; '",
        '{shift} z x c v b n m , .',
        '.com @ {space}',
      ],
      shift: [
        '! @ # $ % ^ &amp; * ( ) _ {bksp}',
        'Q W E R T Y U I O P { } |',
        '{lock} A S D F G H J K L : "',
        '{shift} Z X C V B N M &lt; &gt; ?',
        '.com @ {space}',
      ],
    },
  };

  handleInputChange = e => {
    let input;
    if (e.target.value.trim().length > 100) {
      input = e.target.value.trim().slice(0, 100);
    } else {
      input = e.target.value;
    }
    if (e.target.value.trim().length > 0) {
      this.setState({ error: '' });
      this.props.onGuestNameChange(e.target.value);
    }
    this.setState({ input });
    this.keyboard.setInput(input);
  };

  onKeyboardChange = input => {
    this.ref.current.focus();
    let val;
    if (input.trim().length > 100) {
      val = input.trim().slice(0, 100);
    } else {
      val = input;
    }
    this.setState({ input: val });
  };

  onKeyPress = button => {
    if (button === '{shift}' || button === '{lock}') this.handleShift();
  };

  onKeyReleased = button => {
    const { error } = this.state;
    if (error !== '') {
      this.setState({ error: '' });
    }
    const { shift, cap } = this.state;
    if (button === '{shift}') {
      this.setState({ shift: true });
      return;
    }
    if (button === '{lock}') {
      this.setState({ cap: !this.state.cap });
      return;
    }
    if (button !== '{shift}' && button !== '{lock}' && shift && !cap) {
      this.setState({ layout: 'default' });
    }
  };

  handleShift = () => {
    const { layout } = this.state;
    this.setState({ layout: layout === 'default' ? 'shift' : 'default' });
  };

  //Handle physical keyboard "Enter"
  handlePEnterKey = e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      this.handleOK();
    }
  };

  componentDidMount() {
    this.ref.current.focus();
  }

  componentDidUpdate(prevProps, prevState) {
    const { getFocus } = this.props;
    if (getFocus !== prevProps.getFocus) {
      this.ref.current.focus();
    }
  }

  render() {
    const { layout, input, error } = this.state;
    const { clientStyle } = this.props;
    const descStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontTwo,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };
    return (
      <div>
        <Row
          style={{ padding: 15, height: 30, fontWeight: 'bold', color: '#8b0000', fontSize: 18 }}
          justify="center"
        >
          <Col>
            <SwitchTransition>
              <CSSTransition key={error} timeout={500} classNames="login-account-error">
                <Row style={{ ...descStyle }}>{error}</Row>
              </CSSTransition>
            </SwitchTransition>
          </Col>
        </Row>
        <Row style={{ marginTop: 50 }}>
          <Col span={6}></Col>
          <Col span={12}>
            <Row justify="center">
              <Col span={24}>
                <Row>
                  <Input
                    ref={this.ref}
                    autoFocus={true}
                    size="large"
                    placeholder="Please enter your name"
                    spellCheck={false}
                    value={input}
                    onChange={this.handleInputChange}
                    onKeyUp={this.handlePEnterKey}
                    style={{ ...descStyle }}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={6}></Col>
        </Row>
        <Row justify="space-between" style={{ margin: '30px 0' }}>
          <Col span={6}></Col>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Row justify="space-between">
                  <Button
                    width={100}
                    height={50}
                    onClick={this.handleGuestLoginCancel}
                    style={{ ...rowStyle }}
                    textStyle={{ fontSize: 16 }}
                    borderRadius={5}
                  >
                    Cancel
                  </Button>
                  <Button
                    width={100}
                    height={50}
                    style={{ ...rowStyle }}
                    textStyle={{ fontSize: 16 }}
                    onClick={this.handleOK}
                    borderRadius={5}
                  >
                    OK
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={6}></Col>
        </Row>
        <Row style={{ position: 'absolute', bottom: 0, width: '100%' }}>
          <Keyboard
            baseClass={'simple-keyboard-main'}
            keyboardRef={r => (this.keyboard = r)}
            layoutName={layout}
            onChange={this.onKeyboardChange}
            onKeyPress={this.onKeyPress}
            onKeyReleased={this.onKeyReleased}
            theme={'hg-theme-default hg-layout-default btm-border-16'}
            preventMouseDownDefault={true}
            {...this.keyboardOptions}
          />
        </Row>
      </div>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(LoginGuest);
