import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Checkbox, Table, Image, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { getOrderOptionMappings, saveOrderOptionMappings, getVTImageFolder } from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import './index.css';

class AdminOrderOptionMappings extends Component {
  state = {
    spinning: false,
    data: [],
    imageDir: '',
  };

  columns = [
    {
      title: 'Index',
      dataIndex: 'Index',
      render: (text, record, index) => {
        const { data } = this.state;
        const activeArrLength = data.filter(i => i.Inactive === 0).length;
        if (record.Inactive) {
          return <></>;
        } else {
          if (index === 0) {
            return (
              <Row>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="xl"
                  color="var(--adminSecondary)"
                  onClick={() => {
                    this.handleIndexMove(record, 'Down');
                  }}
                />
              </Row>
            );
          } else if (index > 0 && index < activeArrLength - 1) {
            return (
              <>
                <Row>
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    size="xl"
                    color="var(--adminSecondary)"
                    onClick={() => {
                      this.handleIndexMove(record, 'Up');
                    }}
                  />
                </Row>
                <Row>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="xl"
                    color="var(--adminSecondary)"
                    onClick={() => {
                      this.handleIndexMove(record, 'Down');
                    }}
                  />
                </Row>
              </>
            );
          } else if (index === activeArrLength - 1) {
            return (
              <Row>
                <FontAwesomeIcon
                  icon={faChevronUp}
                  size="xl"
                  color="var(--adminSecondary)"
                  onClick={() => {
                    this.handleIndexMove(record, 'Up');
                  }}
                />
              </Row>
            );
          }
        }
      },
    },
    {
      title: 'Active',
      dataIndex: 'Inactive',
      render: (text, record) => <Checkbox checked={!record.Inactive} disabled />,
    },
    {
      title: 'Name',
      dataIndex: 'DisplayName',
    },
    /// NoMapping = 0,
    /// ProfitCentre = 1,
    ///TableNumber = 2,
    ///LabelMapping = 3
    ///Auto Charge Kiosk = 4
    {
      title: 'Mapping Type',
      dataIndex: 'MappingCode',
      render: (text, record) => {
        switch (record.Type) {
          case 0:
            return <span>No Mapping</span>;
          case 1:
            return <span>Profit Centre</span>;
          case 2:
            return <span>Table Number</span>;
          case 3:
            return <span>Label Mapping</span>;
          case 4:
            return <span>Auto Charge Kiosk</span>;
          default:
            break;
        }
      },
    },
    {
      title: 'Image',
      dataIndex: 'ImageName',
      render: (_, record) => {
        const src = this.getImages(record.ImageName);
        return (
          <Row style={{ width: 120, height: 80, border: '1px solid #e8e8e8' }}>
            <Image
              width={120}
              height={80}
              src={src}
              preview={src !== `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`}
            />
          </Row>
        );
      },
    },
    {
      title: 'Edit',
      render: (text, record) => (
        <FontAwesomeIcon
          icon={faPenToSquare}
          size="xl"
          color="var(--adminPrimary)"
          onClick={() => this.handleEditOrderOption(record)}
        />
      ),
    },
  ];

  init = async () => {
    this.setState({ spinning: true });
    await Promise.all([getOrderOptionMappings(), getVTImageFolder('10')])
      .then(values => {
        values.forEach(res => {
          if (!res || (res && res.result !== 'OK')) throw new Error();
        });
        this.setState({ spinning: false, data: values[0].data, imageDir: values[1].data });
      })
      .catch(err => {
        this.setState({ spinning: false });
        message.error('Error obtaining order options!');
      });
  };

  handleAddMappingBtn = () => {
    const { data, imageDir } = this.state;
    const newItemIndex = data.length;
    this.props.history.replace({
      pathname: '/admin/orderoption',
      state: { addOrEdit: 'Add', user: this.props.user, newItemIndex, imageDir },
    });
  };

  handleEditOrderOption = orderOption => {
    const { imageDir } = this.state;
    this.props.history.replace({
      pathname: '/admin/orderoption',
      state: { orderOption, addOrEdit: 'Edit', imageDir },
    });
  };

  handleIndexMove = async (orderOption, type) => {
    const { data } = this.state;
    const item = data.find(i => i.Code === orderOption.Code);
    const itemIndex = data.findIndex(i => i.Code === orderOption.Code);
    if (type === 'Down') {
      const nextItem = data[itemIndex + 1];
      nextItem.Index = nextItem.Index - 1;
      item.Index = item.Index + 1;
    }
    if (type === 'Up') {
      const prevItem = data[itemIndex - 1];
      prevItem.Index = prevItem.Index + 1;
      item.Index = item.Index - 1;
    }

    try {
      this.setState({ spinning: true });
      const re = await saveOrderOptionMappings(data);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false });
      message.success('New index successfully updated!', 3);
      this.init();
    } catch (error) {
      message.error('Error updating index!');
    }
  };

  getImages = ImageName => {
    const { imageDir } = this.state;
    let src = '';
    const imgFallback = `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      //dev mode
      if (ImageName) {
        src = `http://localhost:4000/${imageDir}/${ImageName}`;
      } else {
        src = imgFallback;
      }
    } else {
      //production mode
      if (ImageName) {
        src = `${process.env.PUBLIC_URL}/${imageDir}/${ImageName}`;
      } else {
        src = imgFallback;
      }
    }
    return src;
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { spinning, data } = this.state;

    return (
      <Spin spinning={spinning}>
        <Row className="admin-order-option-mappings-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title="Order Option Mapping List"
              showLeftBtn={false}
              showRightBtn={true}
              rightBtnText="Add Mapping"
              rightBtnWidth={160}
              onRightBtnClick={this.handleAddMappingBtn}
            />
            <Row style={{ marginTop: 15 }}>
              <Col span={24}>
                <Table
                  size="middle"
                  rowKey="Code"
                  columns={this.columns}
                  dataSource={data}
                  pagination={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}
export default connect(state => ({ user: state.user }), {})(AdminOrderOptionMappings);
