import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './index.css';

class ScanLoadMask extends Component {
  render() {
    const { visible, spinnerColor = 'var(--primary)' } = this.props;
    return (
      <div
        className="scan-load-mask-container"
        style={{
          display: visible ? 'flex' : 'none',
        }}
      >
        <div className="scan-load-mask-spinner-wrapper">
          <Row justify="center" className="scan-load-mask-icon">
            <Col>
              <Row
                style={{
                  fontSize: 42,
                  fontWeight: 'bold',
                }}
                justify="center"
              >
                <FontAwesomeIcon icon={faSpinner} size="xl" color={spinnerColor} spin />
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ScanLoadMask;
