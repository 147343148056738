import React, { Component } from 'react';
import { Row, Col, Input, message } from 'antd';
import { connect } from 'react-redux';
import Keyboard from 'react-simple-keyboard';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import './index.css';

const { TextArea } = Input;

class AdminProfile extends Component {
  state = {
    input: {},
    inputName: '',
    layout: 'default',
    shift: false,
    cap: false,
    spinning: false,
    visible: false,
  };

  keyboardOptions = {
    ...this.commonKeyboardOptions,
    layout: {
      default: [
        '1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        'q w e r t y u i o p [ ] \\',
        "{lock} a s d f g h j k l ; '",
        '{shift} z x c v b n m , . /',
        '.com @ {space}',
      ],
      shift: [
        '! @ # $ % ^ &amp; * ( ) _ + {bksp}',
        'Q W E R T Y U I O P { } |',
        '{lock} A S D F G H J K L : "',
        '{shift} Z X C V B N M &lt; &gt; ?',
        '.com @ {space}',
      ],
    },
  };

  init = () => {
    const { input } = this.state;
    const { user } = this.props;
    input.email = user.Email;
    input.firstname = user.FirstName;
    input.lastname = user.LastName;
    input.note = user.Note;
    this.setState({ input });
  };

  onKeyboardChange = vInput => {
    const { input } = this.state;
    for (const key in input) {
      if (Object.hasOwnProperty.call(vInput, key)) {
        input[key] = vInput[key];
      }
    }
    this.setState({ input });
  };

  onKeyPress = button => {
    if (button === '{shift}' || button === '{lock}') this.handleShift();
  };

  onKeyReleased = button => {
    const { error } = this.state;
    if (error !== '') {
      this.setState({ error: '' });
    }
    const { shift, cap } = this.state;
    if (button === '{shift}') {
      this.setState({ shift: true });
      return;
    }
    if (button === '{lock}') {
      this.setState({ cap: !this.state.cap });
      return;
    }
    if (button !== '{shift}' && button !== '{lock}' && shift && !cap) {
      this.setState({ layout: 'default' });
    }
  };

  handleShift = () => {
    const { layout } = this.state;
    this.setState({ layout: layout === 'default' ? 'shift' : 'default' });
  };

  handleEmailInputOnFocus = () => {
    if (!this.state.visible) {
      this.setState({ visible: true });
    }
    this.setState({ inputName: 'email' });
    this.keyboard && this.keyboard.setInput(this.state.input.email, 'email');
  };

  handleFirstNameInputOnFocus = () => {
    if (!this.state.visible) {
      this.setState({ visible: true });
    }
    this.setState({ inputName: 'firstname' });
    this.keyboard && this.keyboard.setInput(this.state.input.firstname, 'firstname');
  };

  handleLastNameInputOnFocus = () => {
    if (!this.state.visible) {
      this.setState({ visible: true });
    }
    this.setState({ inputName: 'lastname' });
    this.keyboard && this.keyboard.setInput(this.state.input.lastname, 'lastname');
  };

  handleNoteInputOnFocus = () => {
    if (!this.state.visible) {
      this.setState({ visible: true });
    }
    this.setState({ inputName: 'note' });
    this.keyboard && this.keyboard.setInput(this.state.input.note, 'note');
  };

  handleEmailInputChange = e => {
    const { input } = this.state;
    input.email = e.target.value;
    this.setState({ input });
    this.keyboard && this.keyboard.setInput(e.target.value, 'email');
  };

  handleFirstNameInputChange = e => {
    const { input } = this.state;
    input.firstname = e.target.value;
    this.setState({ input });
    this.keyboard && this.keyboard.setInput(e.target.value, 'firstname');
  };

  handleLastNameInputChange = e => {
    const { input } = this.state;
    input.lastname = e.target.value;
    this.setState({ input });
    this.keyboard && this.keyboard.setInput(e.target.value, 'lastname');
  };

  handleNoteInputChange = e => {
    const { input } = this.state;
    input.note = e.target.value;
    this.setState({ input });
    this.keyboard && this.keyboard.setInput(e.target.value, 'note');
  };

  handleSaveBtn = () => {
    this.setState({ spinning: true }, () => {
      setTimeout(() => {
        this.setState({ spinning: false }, () => {
          message.success('Profile successfully updated!');
        });
      }, 1000);
    });
  };

  handleClickOutside = e => {
    if (this.state.visible) {
      if (!(e.target.nodeName === 'INPUT') && !e.target.classList.contains('hg-button')) {
        this.setState({ visible: false });
      }
    }
  };

  componentDidMount() {
    this.init();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const { input, layout, inputName, spinning, visible } = this.state;
    return (
      <Spin spinning={spinning}>
        <Row className="admin-profile-wrapper">
          <Col span={24}>
            <AdminHeader
              title="Your Profile"
              showBack={false}
              showRightBtn={true}
              onRightBtnClick={this.handleSaveBtn}
              rightBtnWidth={120}
              rightBtnText="Save"
            />
            {/* Inputs */}
            <Row justify="center" style={{ margin: '30px 0 30px' }}>
              <Col span={24}>
                <Row align="middle" style={{ marginBottom: 20 }}>
                  <Col span={10}>
                    <Row
                      justify="end"
                      style={{ padding: '0 20px', fontSize: 18, fontWeight: 'bold' }}
                    >
                      Email
                    </Row>
                  </Col>
                  <Col span={14}>
                    <Row style={{ width: 300 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={input.email}
                        onFocus={this.handleEmailInputOnFocus}
                        onChange={this.handleEmailInputChange}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row align="middle" style={{ marginBottom: 20 }}>
                  <Col span={10}>
                    <Row
                      justify="end"
                      style={{ padding: '0 20px', fontSize: 18, fontWeight: 'bold' }}
                    >
                      First Name
                    </Row>
                  </Col>
                  <Col span={14}>
                    <Row style={{ width: 300 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={input.firstname}
                        onFocus={this.handleFirstNameInputOnFocus}
                        onChange={this.handleFirstNameInputChange}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row align="middle" style={{ marginBottom: 20 }}>
                  <Col span={10}>
                    <Row
                      justify="end"
                      style={{ padding: '0 20px', fontSize: 18, fontWeight: 'bold' }}
                    >
                      Last Name
                    </Row>
                  </Col>
                  <Col span={14}>
                    <Row style={{ width: 300 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={input.lastname}
                        onFocus={this.handleLastNameInputOnFocus}
                        onChange={this.handleLastNameInputChange}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row align="middle" style={{ marginBottom: 20 }}>
                  <Col span={10}>
                    <Row
                      justify="end"
                      style={{ padding: '0 20px', fontSize: 18, fontWeight: 'bold' }}
                    >
                      Note
                    </Row>
                  </Col>
                  <Col span={14}>
                    <Row style={{ width: 300 }}>
                      <TextArea
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        placeholder="Note"
                        spellCheck={false}
                        showCount
                        maxLength={100}
                        onFocus={this.handleNoteInputOnFocus}
                        onChange={this.handleNoteInputChange}
                        value={input.note}
                        style={{ width: 300 }}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Keyboard */}
            {visible && (
              <Row justify="center">
                <Keyboard
                  baseClass={'simple-keyboard-main'}
                  keyboardRef={r => (this.keyboard = r)}
                  layoutName={layout}
                  onChangeAll={this.onKeyboardChange}
                  onKeyPress={this.onKeyPress}
                  onKeyReleased={this.onKeyReleased}
                  inputName={inputName}
                  theme={'hg-theme-default hg-layout-default'}
                  preventMouseDownDefault={true}
                  {...this.keyboardOptions}
                />
              </Row>
            )}
          </Col>
        </Row>
      </Spin>
    );
  }
}
export default connect(state => ({ user: state.user }), {})(AdminProfile);
