import React from 'react';
import { Spin } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function VTSpin({
  spinning,
  spinnerColor = 'var(--adminSecondary)',
  style,
  children,
}) {
  const antIcon = (
    <FontAwesomeIcon
      icon={faSpinner}
      //   size="7x"
      color={spinnerColor}
      spin
      style={{ fontSize: 35 }}
    />
  );
  return (
    <Spin spinning={spinning} indicator={antIcon} style={style}>
      {children}
    </Spin>
  );
}
