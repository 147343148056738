import React, { Component } from 'react';
import { connect } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { Row, Col } from 'antd';

class PackageSelectedItemsRow extends Component {
  render() {
    const { item, packageType, popup, clientStyle } = this.props;
    const { modifierDetails, pizzaDetails, packageDetails } = item;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };
    return (
      <Row style={{ ...titleStyle }}>
        <Col span={24}>
          <Row
            style={{
              ...rowStyle,
              fontSize: 18,
              marginBottom: 3,
              padding: '8px 15px',
            }}
            align="middle"
          >
            <Col span={3}>
              <Row justify="start">
                {packageType !== '1' ? '' : `$${parseFloat(item.price).toFixed(2)}`}
              </Row>
            </Col>
            <Col span={3}>
              <Row justify="center">{item.qty}</Row>
            </Col>
            <Col span={18}>
              <Row justify="end">{item.description ? item.description : 'Description N/A'}</Row>
            </Col>
          </Row>
          <Row justify="end">
            <Col span={24}>
              {pizzaDetails.length > 0 &&
                pizzaDetails.map(p => (
                  <Row
                    key={nanoid()}
                    align="middle"
                    style={{
                      ...rowStyle,
                      filter: 'brightness(1.2) opacity(0.9)',
                      fontSize: 18,
                      marginBottom: 3,
                      padding: '8px 15px',
                    }}
                  >
                    <Col span={3}>
                      <Row justify="start">
                        {packageType !== '1' ? '' : `$${parseFloat(p.price).toFixed(2)}`}
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row justify="center">{p.qty}</Row>
                    </Col>
                    <Col span={18}>
                      <Row justify="end">{p.description ? p.description : 'Description N/A'}</Row>
                    </Col>
                  </Row>
                ))}
              {modifierDetails.length > 0 &&
                modifierDetails.map(i => (
                  <Row
                    key={nanoid()}
                    align="middle"
                    style={{
                      ...rowStyle,
                      filter: 'brightness(1.2) opacity(0.9)',
                      fontSize: 18,
                      marginBottom: 3,
                      padding: '8px 15px',
                    }}
                  >
                    <Col span={3}>
                      <Row justify="start">
                        {packageType !== '1' ? '' : `$${parseFloat(i.price).toFixed(2)}`}
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row justify="center">{i.qty}</Row>
                    </Col>
                    <Col span={18}>
                      <Row justify="end">{i.description ? i.description : 'Description N/A'}</Row>
                    </Col>
                  </Row>
                ))}
              {packageDetails && packageDetails.length > 0 && popup
                ? packageDetails.map(i => (
                    <Row
                      key={nanoid()}
                      align="middle"
                      style={{
                        ...rowStyle,
                        filter: 'brightness(1.2) opacity(0.9)',
                        fontSize: 18,
                        marginBottom: 3,
                        padding: '8px 15px',
                      }}
                    >
                      <Col span={3}>
                        <Row justify="start">
                          {packageType !== '1' ? '' : `$${parseFloat(i.price).toFixed(2)}`}
                        </Row>
                      </Col>
                      <Col span={3}>
                        <Row justify="center">{i.qty}</Row>
                      </Col>
                      <Col span={18}>
                        <Row justify="end">{i.description ? i.description : 'Description N/A'}</Row>
                      </Col>
                    </Row>
                  ))
                : null}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(PackageSelectedItemsRow);
