export const timeRangeTimeInterval = [
    { label: '10 minutes', value: 10 },
    { label: '15 minutes', value: 15 },
    { label: '20 minutes', value: 20 },
    { label: '30 minutes', value: 30 },
    { label: '45 minutes', value: 45 },
    { label: '60 minutes', value: 60 },
  ];

  export const weekDayOptions = [
    {
      label: 'Monday',
      value: 'Monday',
    },
    {
      label: 'Tuesday',
      value: 'Tuesday',
    },
    {
      label: 'Wednesday',
      value: 'Wednesday',
    },
    {
      label: 'Thursday',
      value: 'Thursday',
    },
    {
      label: 'Friday',
      value: 'Friday',
    },
    {
      label: 'Saturday',
      value: 'Saturday',
    },
    {
      label: 'Sunday',
      value: 'Sunday',
    },
  ];
