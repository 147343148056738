import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import Button from '../Button';

class ResGuests extends Component {
  render() {
    const { guestsNum, tables, clientStyle } = this.props;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    return (
      <Row>
        <Col span={24}>
          {/* Numbers Row */}
          <Row
            style={{
              padding: '0 30px',
            }}
          >
            {tables.map((i, index) => (
              <Button
                key={index}
                width={80}
                height={80}
                borderRadius={'50%'}
                style={{
                  ...titleStyle,
                  backgroundColor: guestsNum === i.NumberOfGuest ? clientStyle.primary : '#fff',
                  color:
                    guestsNum === i.NumberOfGuest ? clientStyle.colorTwo : clientStyle.colorOne,
                  margin: 20,
                }}
                textStyle={{ fontSize: 22, fontWeight: 'bold' }}
                onClick={() => {
                  this.props.onGuestsNumChange(i);
                }}
              >
                {i.NumberOfGuest}
              </Button>
            ))}
          </Row>
        </Col>
      </Row>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(ResGuests);
