import React, { Component } from 'react';
import { Row, Col, Checkbox, Input, Select, message, Divider, DatePicker, TimePicker } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { weekDayOptions } from '../../../config/options';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { getProfitCentres, saveReservationConfig } from '../../../api';
import EditableTable from '../../../components/EditableTable';
import AdminHeader from '../../../components/AdminHeader';
import Selection from '../../../components/Selection';
import Spin from '../../../components/Spin';
import './index.css';

const { RangePicker } = DatePicker;
const { Option } = Select;

export default class AdminResSetting extends Component {
  state = {
    error: '',
    spinning: false,
    profitCentersList: [],
    code: null,
    description: '',
    centreCodeList: [],
    storeNumber: '',
    fromDate: null,
    toDate: null,
    status: null,
    type: null,
    startDays: null,
    endDays: null,
    reservationTimeZones: [],
    appliedTo: '',
    weekCheckAll: false,
  };

  settingTypes = [
    {
      label: 'Recurring',
      value: 0,
    },
    {
      label: 'Date Range',
      value: 5,
    },
    {
      label: 'One Day',
      value: 10,
    },
  ];
  timeRangeTimeInterval = [
    { label: '15 minutes', value: 15 },
    { label: '30 minutes', value: 30 },
    { label: '45 minutes', value: 45 },
    { label: '60 minutes', value: 60 },
  ];

  checkedAllStr = 'Monday,Tuesday,Wednesday,Thursday,Friday,Saturday,Sunday';

  init = async () => {
    const { addOrEdit } = this.props.location.state;
    const { resSetting } = this.props.location.state || {};

    try {
      this.setState({ spinning: true });
      const re = await getProfitCentres();
      if (re && re.result === 'OK') {
        this.setState({ profitCentersList: re.data, spinning: false });
      } else if (re && re.result !== 'OK') {
        throw new Error('Error obtaining profit center list!');
      }
    } catch (error) {
      this.setState({ spinning: false });
      message.error('Error obtaining profit center list!');
    }

    const weekDaysArr = [];
    for (const key in resSetting) {
      const weekArr = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ];
      if (weekArr.indexOf(key) >= 0 && resSetting[key] === 1) {
        const index = weekArr.findIndex(i => i === key.toString());
        weekDaysArr.push(weekArr[index]);
      }
    }
    const appliedTo = weekDaysArr.join(',') || '';
    if (appliedTo === this.checkedAllStr) {
      this.setState({ weekCheckAll: true });
    }

    if (addOrEdit === 'Edit') {
      const { ReservationTimeZones } = resSetting;
      let arr = [];
      if (ReservationTimeZones.length > 0) {
        arr = ReservationTimeZones.map(i => {
          i.key = nanoid();
          return i;
        });
      }
      this.setState({
        code: resSetting.Code,
        description: resSetting.Description,
        centreCodeList: resSetting.CentreCodeList,
        storeNumber: resSetting.StoreNumber,
        fromDate: resSetting.FromDate,
        toDate: resSetting.ToDate,
        status: resSetting.Status,
        type: resSetting.Type,
        startDays: resSetting.StartDays,
        endDays: resSetting.EndDays,
        reservationTimeZones: arr,
        appliedTo: appliedTo,
      });
    }
    if (addOrEdit === 'Add') {
      this.setState({
        code: null,
        description: '',
        centreCodeList: [],
        storeNumber: '',
        fromDate: null,
        toDate: null,
        status: 1,
        type: null,
        startDays: null,
        endDays: null,
        reservationTimeZones: [
          {
            key: nanoid(),
            Description: '',
            FromTime: '',
            ToTime: '',
            BlockInterval: 15,
            MaxQtyAccepted: '',
            MaxQtyPerRes: '',
            MinQtyPerRes: '',
            MaxResAccepted: '',
          },
        ],
        appliedTo: '',
      });
    }
  };

  handleBackBtn = () => {
    this.props.history.replace('/admin/ressettings');
  };

  handleSaveBtn = async () => {
    const {
      code,
      description,
      centreCodeList,
      storeNumber,
      fromDate,
      toDate,
      status,
      type,
      startDays,
      endDays,
      reservationTimeZones,
      appliedTo,
    } = this.state;
    const weekDayArr = appliedTo.split(',');
    const obj = {};
    obj.Code = code;
    obj.Description = description;
    obj.CentreCodeList = centreCodeList;
    obj.StoreNumber = storeNumber;
    obj.FromDate = fromDate;
    obj.ToDate = toDate;
    obj.Status = status;
    obj.Type = type;
    obj.StartDays = startDays;
    obj.EndDays = endDays;
    obj.ReservationTimeZones = reservationTimeZones;
    obj.Monday = weekDayArr.indexOf('Monday') >= 0 ? 1 : 0;
    obj.Tuesday = weekDayArr.indexOf('Tuesday') >= 0 ? 1 : 0;
    obj.Wednesday = weekDayArr.indexOf('Wednesday') >= 0 ? 1 : 0;
    obj.Thursday = weekDayArr.indexOf('Thursday') >= 0 ? 1 : 0;
    obj.Friday = weekDayArr.indexOf('Friday') >= 0 ? 1 : 0;
    obj.Saturday = weekDayArr.indexOf('Saturday') >= 0 ? 1 : 0;
    obj.Sunday = weekDayArr.indexOf('Sunday') >= 0 ? 1 : 0;
    try {
      this.setState({ spinning: true });
      const re = await saveReservationConfig(obj);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false });
      message.success('Reservation setting successfully saved!');
      this.props.history.replace('/admin/ressettings');
    } catch (error) {
      message.error('Error saving reservation setting!');
    }
  };

  handleSelectChange = (v, _, dataType) => {
    const { error } = this.state;
    error && this.setState({ error: '' });
    this.setState({ [dataType]: v });
  };

  handleInputChange = (dataType, { target: { value } }) => {
    const { error } = this.state;
    error && this.setState({ error: '' });
    this.setState({ [dataType]: value });
  };

  handleDateRangeChange = (_, dateStrArr) => {
    this.setState({ fromDate: dateStrArr[0], toDate: dateStrArr[1] });
  };

  handleDateChange = (_, dateStr) => {
    this.setState({ fromDate: dateStr });
  };

  handleSingleCheckbox = ({ target: { checked } }, dataType) => {
    this.setState({ [dataType]: checked ? 0 : 1 });
  };

  handleAppliedToVenuesChange = arr => {
    this.setState({ centreCodeList: arr });
  };

  handleWeekCheckAll = ({ target: { checked } }) => {
    checked && this.setState({ appliedTo: this.checkedAllStr, weekCheckAll: true });
    !checked && this.setState({ appliedTo: '', weekCheckAll: false });
  };

  handleAppliedToChange = arr => {
    const str = arr.join(',');
    str === this.checkedAllStr && this.setState({ appliedTo: str, weekCheckAll: true });
    str !== this.checkedAllStr && this.setState({ appliedTo: str, weekCheckAll: false });
  };

  handleAddTimeRangeRow = () => {
    const { reservationTimeZones } = this.state;
    const newRow = {
      key: nanoid(),
      Description: '',
      FromTime: '',
      ToTime: '',
      BlockInterval: 15,
      MaxQtyAccepted: '',
      MaxQtyPerRes: '',
      MinQtyPerRes: '',
      MaxResAccepted: '',
    };
    this.setState({ reservationTimeZones: [...reservationTimeZones, newRow] });
  };

  handleDeleteTimeRangeRow = row => {
    const { reservationTimeZones } = this.state;
    this.setState({ reservationTimeZones: reservationTimeZones.filter(i => i.key !== row.key) });
  };

  getUpdatedTimeRangeListData = updatedData => {
    this.setState({ reservationTimeZones: updatedData });
  };

  handleStartEndTargetTimeChange = (_, timeStr, item, dataType) => {
    const { reservationTimeZones } = this.state;
    let arr, obj;
    arr = JSON.parse(JSON.stringify(reservationTimeZones));
    obj = arr.find(i => i.key === item.key);
    obj[dataType] = timeStr;
    this.setState({ reservationTimeZones: arr });
  };

  handleTimeRangeTimeIntervalChange = (v, _, item) => {
    const { reservationTimeZones } = this.state;
    let arr, obj;
    arr = JSON.parse(JSON.stringify(reservationTimeZones));
    obj = arr.find(i => i.key === item.key);
    obj.BlockInterval = v;
    this.setState({ reservationTimeZones: arr });
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const {
      error,
      spinning,
      profitCentersList,
      description,
      centreCodeList,
      fromDate,
      toDate,
      status,
      type,
      startDays,
      endDays,
      reservationTimeZones,
      appliedTo,
      weekCheckAll,
    } = this.state;
    const { addOrEdit } = this.props.location.state;

    const timeRangeTimeIntervalOptions = this.timeRangeTimeInterval.map(i => (
      <Option key={i.value} value={i.value}>
        {i.label}
      </Option>
    ));

    const settingTypeOptions = this.settingTypes.map(i => (
      <Option key={i.value} value={i.value}>
        {i.label}
      </Option>
    ));

    const venues = profitCentersList.map(i => ({
      label: i.Description,
      value: i.CentreCode,
    }));

    const columns = [
      {
        title: () => (
          <Row justify="center">
            <FontAwesomeIcon
              icon={faCirclePlus}
              size="xl"
              color="var(--adminPrimary)"
              onClick={this.handleAddTimeRangeRow}
            />
          </Row>
        ),
        render: (text, record) => (
          <Row justify="center">
            <FontAwesomeIcon
              icon={faCircleMinus}
              size="xl"
              color="var(--adminSecondary)"
              onClick={() => this.handleDeleteTimeRangeRow(record)}
            />
          </Row>
        ),
      },
      {
        title: 'Day Part Name',
        dataIndex: 'Description',
        editable: true,
      },
      {
        title: 'Start Time',
        dataIndex: 'FromTime',
        render: (text, record) => (
          <Row style={{ minWidth: 80 }}>
            <TimePicker
              showNow={false}
              format="HH:mm"
              value={record.FromTime ? moment(record.FromTime, 'HH:mm') : null}
              onChange={(time, timeString) => {
                this.handleStartEndTargetTimeChange(time, timeString, record, 'FromTime');
              }}
            />
          </Row>
        ),
      },
      {
        title: 'End Time',
        dataIndex: 'ToTime',
        render: (text, record) => (
          <Row style={{ minWidth: 80 }}>
            <TimePicker
              showNow={false}
              format="HH:mm"
              value={record.ToTime ? moment(record.ToTime, 'HH:mm') : null}
              onChange={(time, timeString) => {
                this.handleStartEndTargetTimeChange(time, timeString, record, 'ToTime');
              }}
            />
          </Row>
        ),
      },
      {
        title: 'Max Guests / Res.',
        dataIndex: 'MaxQtyPerRes',
        editable: true,
      },
      {
        title: 'Min Guests / Res.',
        dataIndex: 'MinQtyPerRes',
        editable: true,
      },
      {
        title: 'Max Guests / Timeslot',
        dataIndex: 'MaxQtyAccepted',
        editable: true,
      },
      {
        title: 'Max Res. / Timeslot',
        dataIndex: 'MaxResAccepted',
        editable: true,
      },
      {
        title: 'Time Interval',
        dataIndex: 'BlockInterval',
        render: (text, record) => (
          <Selection
            width={100}
            data={timeRangeTimeIntervalOptions}
            value={record.BlockInterval}
            onChange={(v, o) => this.handleTimeRangeTimeIntervalChange(v, o, record)}
          />
        ),
      },
    ];

    return (
      <Spin spinning={spinning}>
        <Row className="admin-res-setting-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title={
                addOrEdit === 'Edit' ? 'Edit Reservation Setting' : 'Add New Reservation Setting'
              }
              showLeftBtn={true}
              leftBtnText="Back"
              showRightBtn={true}
              rightBtnText="Save"
              onLeftBtnClick={this.handleBackBtn}
              onRightBtnClick={this.handleSaveBtn}
            />
            {/* Error Row */}
            <Row
              style={{
                padding: 15,
                height: 30,
                fontWeight: 'bold',
                color: '#8b0000',
                fontSize: 18,
                marginBottom: 20,
              }}
              justify="center"
            >
              <Col>
                <SwitchTransition>
                  <CSSTransition key={error} timeout={500} classNames="login-account-error">
                    <Row>{error}</Row>
                  </CSSTransition>
                </SwitchTransition>
              </Col>
            </Row>
            {/* Content Row */}
            <Row justify="center">
              <Col span={20}>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <Row>
                      <strong>Setting Name</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={description}
                        onChange={e => this.handleInputChange('description', e)}
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Setting Type</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Selection
                        width={250}
                        size="large"
                        data={settingTypeOptions}
                        value={type}
                        onChange={(v, o) => this.handleSelectChange(v, o, 'type')}
                      />
                    </Row>
                  </Col>
                </Row>
                {type === 5 ? (
                  <Row style={{ marginTop: 20 }}>
                    <Col span={12}>
                      <Row>
                        <strong>Date Range</strong>
                      </Row>
                      <RangePicker
                        size="large"
                        value={
                          fromDate &&
                          fromDate !== '0001-01-01T00:00:00' &&
                          toDate &&
                          toDate !== '0001-01-01T00:00:00'
                            ? [moment(fromDate), moment(toDate)]
                            : [null, null]
                        }
                        onChange={(dateObj, dateStr) => {
                          this.handleDateRangeChange(dateObj, dateStr);
                        }}
                      />
                    </Col>
                  </Row>
                ) : type === 10 ? (
                  <Row style={{ marginTop: 20 }}>
                    <Col span={12}>
                      <Row>
                        <strong>Date</strong>
                      </Row>
                      <Row>
                        <DatePicker
                          size="large"
                          value={
                            fromDate && fromDate !== '0001-01-01T00:00:00' ? moment(fromDate) : null
                          }
                          style={{ width: 250 }}
                          onChange={(dateObj, dateStr) => {
                            this.handleDateChange(dateObj, dateStr);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <Row>
                      <strong>Start Booking</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={startDays}
                        onChange={e => this.handleInputChange('startDays', e)}
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Stop Booking</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={endDays}
                        onChange={e => this.handleInputChange('endDays', e)}
                      />
                    </Row>
                  </Col>
                </Row>
                {/* Single Check Box */}
                <Row style={{ marginTop: 20 }}>
                  <Col span={20}>
                    <Row>
                      <Col span={8}>
                        <Row gutter={4}>
                          <Col>
                            <strong>Active</strong>
                          </Col>
                          <Col>
                            <Checkbox
                              checked={!status}
                              onChange={e => {
                                this.handleSingleCheckbox(e, 'status');
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* Venues Row */}
                <Row style={{ marginTop: 20 }}>
                  <Col span={20}>
                    <Row>
                      <Col span={8}>
                        <Row>
                          <strong>Apply To Venues</strong>
                        </Row>
                      </Col>
                      <Col span={16}>
                        <Row>
                          <Checkbox.Group
                            options={venues}
                            value={centreCodeList}
                            onChange={checkedValue => {
                              this.handleAppliedToVenuesChange(checkedValue);
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* Week Row */}
                <Row style={{ marginTop: 20 }}>
                  <Col span={20}>
                    <Row>
                      <Col span={8}>
                        <Row>
                          <strong>Apply To</strong>
                        </Row>
                      </Col>
                      <Col span={16}>
                        <Row>
                          <Checkbox onChange={this.handleWeekCheckAll} checked={weekCheckAll}>
                            All
                          </Checkbox>
                          <Checkbox.Group
                            options={weekDayOptions}
                            value={appliedTo.split(',')}
                            onChange={checkedValue => {
                              this.handleAppliedToChange(checkedValue);
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider style={{ borderColor: 'transparent' }} />
            {/* Editable Table */}
            <Row>
              <Col span={24}>
                <EditableTable
                  size="middle"
                  rowKey="key"
                  tableColumns={columns}
                  tableDataSource={reservationTimeZones}
                  pagination={false}
                  getUpdatedData={updatedData => this.getUpdatedTimeRangeListData(updatedData)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}
