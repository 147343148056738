import React, { Component } from 'react';
import { Row, Radio } from 'antd';
import fontOptions from '../../utils/fontOptions';

class VSFontPicker extends Component {
  handleChange = ({ target: { value } }) => {
    this.props.onFontChange(value);
  };

  render() {
    const { font } = this.props;
    return (
      <Row>
        <Radio.Group
          options={fontOptions}
          onChange={this.handleChange}
          value={font}
          optionType="button"
          buttonStyle="solid"
          style={{ marginBottom: 10 }}
        />
      </Row>
    );
  }
}

export default VSFontPicker;
