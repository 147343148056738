import React, { Component } from 'react';
import { Row, Col, DatePicker, Select, Divider, Table, message } from 'antd';
import { connect } from 'react-redux';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import moment from 'moment';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/free-solid-svg-icons';
import { receiveCurrAccount } from '../../../redux/actions';
import { getAccountOrderHistory, getCheckHtml } from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import Selection from '../../../components/Selection';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import './index.css';

const { RangePicker } = DatePicker;
const { Option } = Select;

//Try remarkablemark html-react-parser to parse HTML string to react dom elements

class AdminOrderHistory extends Component {
  state = {
    error: '',
    spinning: false,
    pageSize: 5,
    dateRange: [],
    list: [],
    detailStr: '',
    visible: false,
  };

  columns = [
    {
      title: 'Date',
      dataIndex: 'Date',
    },
    {
      title: 'Time',
      dataIndex: 'Time',
    },
    {
      title: 'Order #',
      dataIndex: 'CheckNumber',
    },
    {
      title: 'Venue',
      dataIndex: 'ProfitCentreDescription',
    },
    {
      title: 'Guest #',
      dataIndex: 'GuestNo',
      key: 'guestNum',
    },
    {
      title: 'View Details',
      render: (_, record) => (
        <Row>
          <FontAwesomeIcon
            icon={faFileImage}
            size="2x"
            color="var(--adminPrimary)"
            onClick={() => this.handleViewDetail(record)}
          />
        </Row>
      ),
    },
  ];

  init = () => {
    const dateRange = this.getDateRange();
    this.setState({ dateRange }, () => this.handleGetHistory());
  };

  //Back 30 days from today
  getDateRange = () => {
    const startDate = moment().subtract(29, 'd');
    const endDate = moment();
    const range = [startDate, endDate];
    return range;
  };

  handleAccountChange = v => {
    const { user } = this.props;
    const { AccountList } = user || [];
    const acc = AccountList.find(i => i.AccountNumber === v) || {};
    this.props.receiveCurrAccount(acc);
    this.setState({ error: '' });
  };

  handleDateRangeChange = dateObjArr => {
    this.setState({ dateRange: dateObjArr, error: '' });
  };

  handleTablePageSizeChange = (_, size) => {
    this.setState({ pageSize: size });
  };

  handleGetHistory = async () => {
    const { user } = this.props;
    if (!user.CurAccountInfo) {
      this.setState({ error: 'There is no account selected!' });
      return;
    }
    const { dateRange } = this.state;
    if (!dateRange || !dateRange[0] || !dateRange[1]) {
      this.setState({ error: 'Please select a date range!' });
      return;
    }

    const obj = {};
    obj.FromDate = dateRange[0].format('YYYY-MM-DD');
    obj.ToDate = dateRange[1].format('YYYY-MM-DD');
    obj.AccountNumber = user?.CurAccountInfo?.AccountNumber ?? '';

    try {
      this.setState({ spinning: true });
      const re = await getAccountOrderHistory(obj);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({
        spinning: false,
        list: re.data,
        error: re.data.length === 0 ? 'No record in selected date range!' : '',
      });
    } catch (error) {
      message.error('Error obtaining statement!');
    }
  };

  handleViewDetail = async row => {
    const obj = {};
    obj.FromDate = row.Date;
    obj.AccountNumber = row.AccountNumber;
    obj.CheckNumber = row.CheckNumber;
    obj.GuestNumber = row.GuestNo;
    try {
      this.setState({ spinning: true });
      const re = await getCheckHtml(obj);
      if (re && re.result === 'OK') {
        this.setState({ spinning: false, detailStr: re.data }, () => {
          this.setState({ visible: true });
        });
      }
      if (re && re.result !== 'OK') {
        this.setState({ spinning: false });
        throw new Error();
      }
    } catch (error) {
      message.error('Error obtaining statement!');
    }
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { error, spinning, pageSize, dateRange, list, visible, detailStr } = this.state;
    const { user } = this.props;
    const { AccountList } = user || [];
    const { CurAccountInfo } = user || {};

    const accountsOptions =
      (AccountList &&
        AccountList.map(i => (
          <Option key={i.AccountNumber} value={i.AccountNumber}>
            {`${i.FirstName} ${i.LastName} - ${i.AccountNumber}`}
          </Option>
        ))) ||
      [];

    return (
      <Spin spinning={spinning}>
        <Row className="admin-order-history-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader title="Order History" showBack={false} showRightBtn={false} />
            {/* Error row */}
            <Row
              style={{
                padding: 15,
                height: 30,
                fontWeight: 'bold',
                color: '#8b0000',
                fontSize: 18,
                marginBottom: 20,
              }}
              justify="center"
            >
              <Col>
                <SwitchTransition>
                  <CSSTransition key={error} timeout={500} classNames="login-account-error">
                    <Row>{error}</Row>
                  </CSSTransition>
                </SwitchTransition>
              </Col>
            </Row>
            {/* Account Row */}
            <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
              <Col span={8}>
                <Row justify="end" style={{ paddingRight: 20 }}>
                  <h3>Account</h3>
                </Row>
              </Col>
              <Col span={10}>
                <Row>
                  <Selection
                    width={310}
                    size="large"
                    value={CurAccountInfo ? CurAccountInfo.AccountNumber : null}
                    data={accountsOptions}
                    onChange={value => {
                      this.handleAccountChange(value);
                    }}
                  />
                </Row>
              </Col>
              <Col span={6}></Col>
            </Row>
            {/* Dates Selector Row */}
            <Row justify="center" align="middle">
              <Col span={8}>
                <Row justify="end" style={{ paddingRight: 20 }}>
                  <h3>Select Date Range</h3>
                </Row>
              </Col>
              <Col span={10}>
                <Row>
                  <RangePicker
                    size="large"
                    value={dateRange && dateRange[0] && dateRange[1] ? dateRange : null}
                    onChange={(dateObj, dateStr) => {
                      this.handleDateRangeChange(dateObj, dateStr);
                    }}
                  />
                </Row>
              </Col>
              <Col span={6}>
                <Row>
                  <Button
                    width={180}
                    height={50}
                    style={{ backgroundColor: 'var(--adminPrimary)', marginLeft: 5 }}
                    textStyle={{ color: 'white', fontSize: 18 }}
                    borderRadius={5}
                    onClick={this.handleGetHistory}
                  >
                    Get History
                  </Button>
                </Row>
              </Col>
            </Row>
            <Divider style={{ borderColor: 'transparent' }} />

            {/* Table  */}
            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <Table
                  rowKey={record => `${record.Time} ${record.CheckNumber}`}
                  dataSource={list}
                  columns={this.columns}
                  // loading={loading}
                  pagination={{
                    pageSizeOptions: ['5', '10'],
                    pageSize: pageSize,
                    showSizeChanger: true,
                    position: ['topCenter'],
                    itemRender: (_, type, originalElement) => {
                      if (type === 'prev') {
                        return <a>Previous</a>;
                      }

                      if (type === 'next') {
                        return <a>Next</a>;
                      }

                      return originalElement;
                    },
                    onShowSizeChange: (current, size) => {
                      this.handleTablePageSizeChange(current, size);
                    },
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          width={550}
          visible={visible}
          maskClosable={true}
          closable={false}
          title={null}
          footer={null}
          destroyOnClose={true}
          onCancel={() => this.setState({ visible: false })}
          showCloseBtn={false}
        >
          <Row>
            <Col span={24}>
              <Row justify="end">
                <Button
                  width={100}
                  height={50}
                  style={{ backgroundColor: 'var(--adminPrimary)' }}
                  textStyle={{ color: 'white', fontSize: 18 }}
                  borderRadius={5}
                  onClick={() => this.setState({ visible: false })}
                >
                  OK
                </Button>
              </Row>
              <Row justify="center" style={{ height: 550 }} className="admin-order-history-detail">
                {parse(detailStr)}
              </Row>
            </Col>
          </Row>
        </Modal>
      </Spin>
    );
  }
}

export default connect(state => ({ user: state.user }), { receiveCurrAccount })(AdminOrderHistory);
