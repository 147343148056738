import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, DatePicker, Select, Divider, Table, message } from 'antd';
import moment from 'moment';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { getAccountReservationHistory } from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import Button from '../../../components/Button';
import './index.css';

const { RangePicker } = DatePicker;

class ResHistory extends Component {
  state = {
    error: '',
    spinning: false,
    dateRange: [],
    pageSize: 5,
    data: [],
  };

  columns = [
    {
      title: 'Date',
      dataIndex: 'ArrivalDate',
      render: (_, record) => <span>{moment(record.ArrivalDate).format('ddd, MMM D, YYYY')}</span>,
      sorter: (a, b) => new Date(a.ArrivalDate) - new Date(b.ArrivalDate),
    },
    {
      title: 'Time',
      dataIndex: 'ArrivalTime',
      sorter: (a, b) =>
        new Date('1970/01/01 ' + a.ArrivalTime) - new Date('1970/01/01 ' + b.ArrivalTime),
    },
    {
      title: 'Venue',
      dataIndex: 'ReservedLocation',
      sorter: (a, b) => a.ReservedLocation.localeCompare(b.ReservedLocation),
    },
    {
      title: 'Total Guests',
      dataIndex: 'GuestNumber',
    },
    {
      title: 'Status',
      dataIndex: 'StatusDescription',
      filters: [
        {
          text: 'New',
          value: 'New',
        },
        {
          text: 'Cancelled',
          value: 'Cancelled',
        },
        {
          text: 'Processed',
          value: 'Processed',
        },
        {
          text: 'Expired',
          value: 'Expired',
        },
      ],
      onFilter: (value, record) => record.StatusDescription.indexOf(value) === 0,
    },
  ];

  handleMakeNewRes = () => {
    this.props.history.replace({
      pathname: '/reservation',
      state: { editOrAdd: 'Add' },
    });
  };

  handleBackToMyRes = () => {
    this.props.history.replace('/admin/res');
  };

  handleDateRangeChange = (_, dateStrArr) => {
    this.setState({ error: '', dateRange: dateStrArr });
  };

  handleGetHistoryBtn = async () => {
    const { dateRange } = this.state;
    if (!dateRange || !dateRange[0] || !dateRange[1]) {
      this.setState({ error: 'No valid date range selected!' });
      return;
    }
    const {
      user: {
        CurAccountInfo: { AccountNumber },
      },
    } = this.props;
    try {
      this.setState({ spinning: true });
      const re = await getAccountReservationHistory(AccountNumber, dateRange[0], dateRange[1]);
      if (!re || (re && re.result !== 'OK')) throw new Error();
      if (re.data.length === 0) {
        this.setState({ data: [], spinning: false, error: 'No record in date range selected!' });
      } else {
        this.setState({ data: re.data, spinning: false });
      }
    } catch (error) {
      this.setState({ spinning: false });
      message.error('Error obtaining reservation history');
    }
  };

  handleTablePageSizeChange = (_, size) => {
    this.setState({ pageSize: size });
  };

  render() {
    const { error, spinning, pageSize, data } = this.state;
    return (
      <Spin spinning={spinning}>
        <Row className="admin-res-history-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title="Reservation History"
              showLeftBtn={true}
              showRightBtn={true}
              onRightBtnClick={this.handleMakeNewRes}
              onLeftBtnClick={this.handleBackToMyRes}
              leftBtnWidth={180}
              rightBtnWidth={215}
              leftBtnText="My Reservations"
              rightBtnText="Make A Reservation"
            />
            {/* Error row */}
            <Row
              style={{
                padding: 15,
                height: 30,
                fontWeight: 'bold',
                color: '#8b0000',
                fontSize: 18,
                marginBottom: 20,
              }}
              justify="center"
            >
              <Col>
                <SwitchTransition>
                  <CSSTransition key={error} timeout={500} classNames="login-account-error">
                    <Row>{error}</Row>
                  </CSSTransition>
                </SwitchTransition>
              </Col>
            </Row>
            {/* Dates Selector Row */}
            <Row justify="center" align="middle">
              <Col span={10}>
                <Row justify="end" style={{ paddingRight: 20 }}>
                  <h3>Select Date Range</h3>
                </Row>
              </Col>
              <Col span={10}>
                <Row>
                  <RangePicker
                    size="large"
                    onChange={(dateObj, dateStr) => {
                      this.handleDateRangeChange(dateObj, dateStr);
                    }}
                  />
                </Row>
              </Col>
              <Col span={4}>
                <Row>
                  <Button
                    width={120}
                    height={40}
                    style={{ backgroundColor: 'var(--adminPrimary)' }}
                    textStyle={{ color: 'white' }}
                    borderRadius={5}
                    onClick={this.handleGetHistoryBtn}
                  >
                    Get History
                  </Button>
                </Row>
              </Col>
            </Row>
            <Divider style={{ borderColor: 'transparent' }} />
            {/* Table */}
            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <Table
                  rowKey="ResGuid"
                  dataSource={data}
                  columns={this.columns}
                  // loading={loading}
                  pagination={false}
                  // pagination={{
                  //   pageSizeOptions: ['5', '10'],
                  //   pageSize: pageSize,
                  //   showSizeChanger: true,
                  //   position: ['topCenter'],
                  //   itemRender: (_, type, originalElement) => {
                  //     if (type === 'prev') {
                  //       return <a>Previous</a>;
                  //     }

                  //     if (type === 'next') {
                  //       return <a>Next</a>;
                  //     }

                  //     return originalElement;
                  //   },
                  //   onShowSizeChange: (current, size) => {
                  //     this.handleTablePageSizeChange(current, size);
                  //   },
                  // }}
                  // showSorterTooltip={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}
export default connect(state => ({ user: state.user }))(ResHistory);
