import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Divider,
  Select,
  Checkbox,
  InputNumber,
  TimePicker,
  Input,
  Radio,
  DatePicker,
  message,
} from 'antd';
import moment from 'moment';
import { nanoid } from '@reduxjs/toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import {
  getAppSetting,
  saveKioskStationConfiguration,
  saveAppSetting,
  getCurrentKioskSetting,
  getViewPages,
  getOrderOptionMappings,
  getItemList,
} from '../../../api';
import { receiveAppSetting } from '../../../redux/actions';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import Selection from '../../../components/Selection';
import Button from '../../../components/Button';
import './index.css';

const { Option } = Select;

class ApplicationSettings extends Component {
  state = {
    spinning: false,
    showTitle: false,
    itemList: [],
    orderOptions: [],
    viewPage: '',
    data: {},
    loginOptionSelected: 0,
    disableOrderingChecked: 0,
    enableGuestOrderChecked: 0,
    orderOptionMappingSelected: 0,
    enableOrderOptionsChecked: 0,
    futureOrderSelected: 0,
    enableResChecked: 0,
    receiptPrintingSelected: 0,
    collectCustomerNameChecked: 0,
    giftCardSelected: 0,
    enablePayAtCounter: 0,
    enableAcctPayment: 0,
    enableCreditCardPayment: 0,
    disabledAccountStatement: 0,
    disabledOrderHistory: 0,
    enableLoyaltyPayment: 0,
    configName: '',
    useCustomSetting: 0,
    kioskModeSelected: 0,
    orderOptionsSelected: [],
    viewPageSelected: '',
    collectGuestPhoneNumber: 0,
    autoChargeItemSelected: '',
    orderCompletionCountdown: '',
    storeHoursInfo: {},
  };

  defaultOrCustomOptions = [
    {
      key: 0,
      value: 0,
      label: 'Use Default Setting',
    },
    {
      key: 1,
      value: 1,
      label: 'Use Custom Setting',
    },
  ];

  loginOption = [
    {
      key: 'all',
      value: 0,
      label: 'All',
    },
    {
      key: 'guest',
      value: 1,
      label: 'Guest Order only',
    },
    {
      key: 'account',
      value: 2,
      label: 'Account Login Only',
    },
    {
      key: 'tap',
      value: 3,
      label: 'Tap Login Only',
    },
    {
      key: 'skip-login',
      value: 4,
      label: 'Skip Login',
    },
  ];

  orderOptionGrouping = [
    {
      value: 0,
      label: ' ',
    },
    {
      value: 1,
      label: 'Venue',
    },
  ];

  futureOrder = [
    {
      key: 0,
      value: 0,
      label: 'Disable Future Order',
    },
    {
      key: 1,
      value: 1,
      label: 'Enable Future Order for Residents only',
    },
    {
      key: 2,
      value: 2,
      label: 'Enable Future Order for All',
    },
  ];

  receiptPrinting = [
    {
      key: 0,
      value: 0,
      label: 'Print Manually',
    },
    {
      key: 1,
      value: 1,
      label: 'Auto-Print',
    },
    {
      key: 2,
      value: 2,
      label: 'Disable Printing',
    },
  ];

  kioskMode = [
    { key: 0, value: 0, label: 'Ordering Only' },
    { key: 1, value: 1, label: 'Scan Only' },
    { key: 2, value: 2, label: 'Ordering & Scan' },
  ];

  giftCard = [
    {
      value: 0,
      label: 'None',
    },
    {
      value: 1,
      label: 'Visual Touch',
    },
    {
      value: 2,
      label: 'Givex',
    },
  ];

  weekdays = [
    {
      key: 'Sunday',
      Description: 'Sun',
    },
    {
      key: 'Monday',
      Description: 'Mon',
    },
    {
      key: 'Tuesday',
      Description: 'Tue',
    },
    {
      key: 'Wednesday',
      Description: 'Wed',
    },
    {
      key: 'Thursday',
      Description: 'Thu',
    },
    {
      key: 'Friday',
      Description: 'Fri',
    },
    {
      key: 'Saturday',
      Description: 'Sat',
    },
  ];

  openCloseOptions = [
    {
      label: 'Open',
      value: 0,
    },
    {
      label: 'Closed',
      value: 1,
    },
  ];

  init = async () => {
    const { item } = this.props.location.state || {};
    if (item) {
      if (item.UseCustomSetting === 1) {
        if (item.Setting.ScanItemMode === 4) {
          await this.getAllItems();
        }
        item.Setting.StoreHourInfo?.SpecialDays?.length > 0 &&
          (item.Setting.StoreHourInfo.SpecialDays = item.Setting.StoreHourInfo?.SpecialDays?.map(
            i => {
              i.key = nanoid();
              return i;
            },
          ));
        this.setState({
          showTitle: true,
          configName: item.SettingName,
          useCustomSetting: item.UseCustomSetting,
          loginOptionSelected: item.Setting.KioskLoginMode || 0,
          disableOrderingChecked: item.Setting.DisabledOnlineOrdering || 0,
          enableGuestOrderChecked: item.Setting.AllowGuestOrder || 0,
          orderOptionMappingSelected: item.Setting.OrderOptionGrouping || 0,
          enableOrderOptionsChecked: item.Setting.EnableOrderOptionMapping || 0,
          futureOrderSelected: item.Setting.EnableFutureOrder || 0,
          enableResChecked: item.Setting.EnableReservation || 0,
          receiptPrintingSelected: item.Setting.AutoPrintReceipt || 0,
          collectCustomerNameChecked: item.Setting.GuestNameRequired || 0,
          enablePayAtCounter: item.Setting.EnablePayAtCounter || 0,
          enableAcctPayment: item.Setting.EnableHousePayment || 0,
          enableCreditCardPayment: item.Setting.EnableCreditCardPayment || 0,
          giftCardSelected: item.Setting.EnableGiftCard || 0,
          disabledAccountStatement: item.Setting.DisabledAccountStatement || 0,
          disabledOrderHistory: item.Setting.DisabledOrderHistory || 0,
          enableLoyaltyPayment: item.Setting.EnableLoyaltyPayment || 0,
          kioskModeSelected: item.Setting.ScanItemMode || 0,
          orderOptionsSelected: item.Setting.OrderOptions || [],
          viewPageSelected: item.Setting.ViewPage || '',
          collectGuestPhoneNumber: item.Setting.CollectGuestPhoneNumber || 0,
          autoChargeItemSelected: item.Setting.AutoChargeItemCode || '',
          orderCompletionCountdown: item.Setting.OrderCompletedCountDown || '',
          storeHoursInfo: item.Setting.StoreHourInfo || {},
        });
      } else {
        this.setState({
          showTitle: true,
          configName: item.SettingName,
          useCustomSetting: item.UseCustomSetting,
        });
      }
    } else {
      try {
        this.setState({ spinning: true });
        const re = await getAppSetting();
        console.log(re);
        if (!re || (re && re.result !== 'OK')) throw new Error();
        re.data.AppSetting.StoreHourInfo?.SpecialDays?.length > 0 &&
          (re.data.AppSetting.StoreHourInfo.SpecialDays =
            re.data.AppSetting.StoreHourInfo?.SpecialDays?.map(i => {
              i.key = nanoid();
              return i;
            }));
        this.setState({
          spinning: false,
          showTitle: false,
          configName: null,
          data: re.data,
          useCustomSetting: 0,
          loginOptionSelected: re.data.AppSetting.KioskLoginMode || 0,
          disableOrderingChecked: re.data.AppSetting.DisabledOnlineOrdering || 0,
          enableGuestOrderChecked: re.data.AppSetting.AllowGuestOrder || 0,
          orderOptionMappingSelected: re.data.AppSetting.OrderOptionGrouping || 0,
          enableOrderOptionsChecked: re.data.AppSetting.EnableOrderOptionMapping || 0,
          futureOrderSelected: re.data.AppSetting.EnableFutureOrder || 0,
          enableResChecked: re.data.AppSetting.EnableReservation || 0,
          receiptPrintingSelected: re.data.AppSetting.AutoPrintReceipt || 0,
          collectCustomerNameChecked: re.data.AppSetting.GuestNameRequired || 0,
          enablePayAtCounter: re.data.AppSetting.EnablePayAtCounter || 0,
          enableAcctPayment: re.data.AppSetting.EnableHousePayment || 0,
          enableCreditCardPayment: re.data.AppSetting.EnableCreditCardPayment || 0,
          giftCardSelected: re.data.AppSetting.EnableGiftCard || 0,
          disabledAccountStatement: re.data.AppSetting.DisabledAccountStatement || 0,
          disabledOrderHistory: re.data.AppSetting.DisabledOrderHistory || 0,
          enableLoyaltyPayment: re.data.AppSetting.EnableLoyaltyPayment || 0,
          kioskModeSelected: re.data.AppSetting.ScanItemMode || 0,
          orderOptionsSelected: re.data.AppSetting.OrderOptions || [],
          viewPageSelected: re.data.AppSetting.ViewPage || '',
          collectGuestPhoneNumber: re.data.AppSetting.CollectGuestPhoneNumber || 0,
          autoChargeItemSelected: re.data.AppSetting.AutoChargeItemCode || '',
          orderCompletionCountdown: re.data.AppSetting.OrderCompletedCountDown || '',
          storeHoursInfo: re.data.AppSetting.StoreHourInfo || {},
        });
      } catch (error) {
        this.setState({ spinning: false });
        message.error('Error obtaining kiosk settings!', 3);
      }
    }
  };

  handleSaveBtn = async () => {
    const {
      data,
      useCustomSetting,
      loginOptionSelected,
      disableOrderingChecked,
      enableGuestOrderChecked,
      orderOptionMappingSelected,
      enableOrderOptionsChecked,
      futureOrderSelected,
      enableResChecked,
      receiptPrintingSelected,
      collectCustomerNameChecked,
      giftCardSelected,
      enablePayAtCounter,
      enableAcctPayment,
      enableCreditCardPayment,
      disabledAccountStatement,
      disabledOrderHistory,
      enableLoyaltyPayment,
      kioskModeSelected,
      orderOptionsSelected,
      viewPageSelected,
      collectGuestPhoneNumber,
      autoChargeItemSelected,
      orderCompletionCountdown,
      storeHoursInfo,
    } = this.state;
    const { item } = this.props.location.state || {};
    storeHoursInfo.SpecialDays = storeHoursInfo.SpecialDays?.filter(i => i.Date);
    try {
      this.setState({ spinning: true });
      if (item) {
        if (useCustomSetting === 1) {
          item.UseCustomSetting = 1;
          item.Setting = item.Setting ? item.Setting : {};
          item.Setting.KioskLoginMode = loginOptionSelected;
          item.Setting.DisabledOnlineOrdering = disableOrderingChecked;
          item.Setting.AllowGuestOrder = enableGuestOrderChecked;
          item.Setting.OrderOptionGrouping = orderOptionMappingSelected;
          item.Setting.EnableOrderOptionMapping = enableOrderOptionsChecked;
          item.Setting.EnableFutureOrder = futureOrderSelected;
          item.Setting.EnableReservation = enableResChecked;
          item.Setting.AutoPrintReceipt = receiptPrintingSelected;
          item.Setting.GuestNameRequired = collectCustomerNameChecked;
          item.Setting.EnablePayAtCounter = enablePayAtCounter;
          item.Setting.EnableHousePayment = enableAcctPayment;
          item.Setting.EnableCreditCardPayment = enableCreditCardPayment;
          item.Setting.EnableGiftCard = giftCardSelected;
          item.Setting.DisabledAccountStatement = disabledAccountStatement;
          item.Setting.DisabledOrderHistory = disabledOrderHistory;
          item.Setting.EnableLoyaltyPayment = enableLoyaltyPayment;
          item.Setting.ScanItemMode = kioskModeSelected;
          item.Setting.OrderOptions = orderOptionsSelected;
          item.Setting.ViewPage = viewPageSelected;
          item.Setting.CollectGuestPhoneNumber = collectGuestPhoneNumber;
          item.Setting.AutoChargeItemCode = autoChargeItemSelected;
          item.Setting.OrderCompletedCountDown = orderCompletionCountdown;
          item.Setting.StoreHourInfo = storeHoursInfo;
        } else {
          item.UseCustomSetting = 0;
          item.Setting = null;
        }
        const re = await saveKioskStationConfiguration(item);
        if (!re || (re && re.result !== 'OK')) throw new Error();
      } else {
        data.AppSetting.KioskLoginMode = loginOptionSelected;
        data.AppSetting.DisabledOnlineOrdering = disableOrderingChecked;
        data.AppSetting.AllowGuestOrder = enableGuestOrderChecked;
        data.AppSetting.OrderOptionGrouping = orderOptionMappingSelected;
        data.AppSetting.EnableOrderOptionMapping = enableOrderOptionsChecked;
        data.AppSetting.EnableFutureOrder = futureOrderSelected;
        data.AppSetting.EnableReservation = enableResChecked;
        data.AppSetting.AutoPrintReceipt = receiptPrintingSelected;
        data.AppSetting.GuestNameRequired = collectCustomerNameChecked;
        data.AppSetting.EnablePayAtCounter = enablePayAtCounter;
        data.AppSetting.EnableHousePayment = enableAcctPayment;
        data.AppSetting.EnableCreditCardPayment = enableCreditCardPayment;
        data.AppSetting.EnableGiftCard = giftCardSelected;
        data.AppSetting.DisabledAccountStatement = disabledAccountStatement;
        data.AppSetting.DisabledOrderHistory = disabledOrderHistory;
        data.AppSetting.EnableLoyaltyPayment = enableLoyaltyPayment;
        data.AppSetting.ScanItemMode = kioskModeSelected;
        data.AppSetting.OrderOptions = orderOptionsSelected;
        data.AppSetting.ViewPage = viewPageSelected;
        data.AppSetting.CollectGuestPhoneNumber = collectGuestPhoneNumber;
        data.AppSetting.AutoChargeItemCode = autoChargeItemSelected;
        data.AppSetting.OrderCompletedCountDown = orderCompletionCountdown;
        data.AppSetting.StoreHourInfo = storeHoursInfo;
        const re = await saveAppSetting(data);
        if (!re || (re && re.result !== 'OK')) throw new Error();
      }

      const res = await getCurrentKioskSetting();
      if (!res || (res && res.result !== 'OK')) throw new Error();
      const obj = {};
      obj.appSetting = res.appSetting ? res.appSetting : {};
      obj.imgSetting = res.imgSetting ? res.imgSetting : {};
      obj.stationName = res.stationName ? res.stationName : '';
      this.props.receiveAppSetting(obj);
      this.setState({ spinning: false });
      message.success('Config successfully saved!', 3);
      this.props.history.replace('/admin/kiosk');
    } catch (error) {
      this.setState({ spinning: false });
      message.error('Error saving or updating Config', 3);
    }
  };

  handleBackBtn = () => {
    this.props.history.replace('/admin/kiosk');
  };

  getAllItems = async () => {
    try {
      this.setState({ spinning: true });
      const re = await getItemList();
      if (!re || (re && typeof re.result === 'string' && re.result.toUpperCase() !== 'OK'))
        throw new Error(re.result);
      this.setState({ itemList: re.data, spinning: false });
    } catch (error) {
      message.error('Error obtaining items!');
      // message.error(error.message);
    }
  };

  handleSelectionChange = async (v, _, dataType) => {
    const { kioskModeSelected, autoChargeItemSelected } = this.state;
    if (dataType === 'loginOptionSelected' && v !== 4 && kioskModeSelected === 4) {
      this.setState({ kioskModeSelected: 0, autoChargeItemSelected: '' });
    }
    if (dataType === 'kioskModeSelected' && v === 4) {
      await this.getAllItems();
    }
    if (dataType === 'kioskModeSelected' && v !== 4 && autoChargeItemSelected) {
      this.setState({ autoChargeItemSelected: '' });
    }
    this.setState({ [dataType]: v });
  };

  handleInputChange = (value, dataType) => {
    this.setState({ [dataType]: value });
  };

  handleCheckBoxChange = ({ target: { checked } }, dataType) => {
    this.setState({ [dataType]: checked ? 1 : 0 });
    if (dataType === 'enableOrderOptionsChecked' && !checked) {
      this.setState({ orderOptionsSelected: [] });
    }
  };

  handleCheckGroupChange = checkedValues => {
    this.setState({ orderOptionsSelected: checkedValues });
  };

  getOptions = async () => {
    try {
      this.setState({ spinning: true });
      await Promise.all([getViewPages(), getOrderOptionMappings()]).then(results => {
        results.forEach(res => {
          if (!res || (res && res.result !== 'OK')) throw new Error();
        });
        this.setState({
          orderOptions: results[1].data,
          viewPage: results[0].data,
          spinning: false,
        });
      });
    } catch (error) {
      this.setState({ spinning: false });
    }
  };

  handleStoreHoursChange = (times, timeString, day) => {
    const { storeHoursInfo } = this.state;
    if (
      storeHoursInfo.hasOwnProperty(day) &&
      storeHoursInfo[day] !== undefined &&
      storeHoursInfo[day] !== null
    ) {
      storeHoursInfo[day] = {
        ...storeHoursInfo[day],
        OpenTime: timeString[0],
        CloseTime: timeString[1],
      };
    } else {
      storeHoursInfo[day] = {
        Status: 0,
        OpenTime: timeString[0],
        CloseTime: timeString[1],
        Date: '',
        Message: '',
      };
    }
    this.setState({ storeHoursInfo });
  };

  handleStoreHoursNoteChange = ({ target: { value } }, day) => {
    const { storeHoursInfo } = this.state;
    if (storeHoursInfo.hasOwnProperty(day)) {
      storeHoursInfo[day] = {
        ...storeHoursInfo[day],
        Message: value,
      };
    } else {
      storeHoursInfo[day] = {
        Status: 0,
        OpenTime: '',
        CloseTime: '',
        Date: '',
        Message: value,
      };
    }
    this.setState({ storeHoursInfo });
  };

  handleStoreHoursOpenCloseChange = ({ target: { value } }, day) => {
    const { storeHoursInfo } = this.state;
    if (storeHoursInfo.hasOwnProperty(day)) {
      storeHoursInfo[day] = {
        ...storeHoursInfo[day],
        Status: value,
      };
    } else {
      storeHoursInfo[day] = {
        Status: value,
        OpenTime: '',
        CloseTime: '',
        Date: '',
        Message: '',
      };
    }
    this.setState({ storeHoursInfo });
  };

  handleAddSpecialDay = () => {
    const { storeHoursInfo } = this.state;
    const day = {};
    day.key = nanoid();
    day.Status = 0;
    day.OpenTime = '';
    day.CloseTime = '';
    day.Date = '';
    day.Message = '';
    if (
      storeHoursInfo.hasOwnProperty('SpecialDays') &&
      storeHoursInfo['SpecialDays'] !== undefined &&
      storeHoursInfo['SpecialDays'] !== null
    ) {
      storeHoursInfo['SpecialDays'] = [...storeHoursInfo['SpecialDays'], day];
    } else {
      storeHoursInfo['SpecialDays'] = [day];
    }
    this.setState({ storeHoursInfo });
  };

  handleDeleteSpecialDay = key => {
    const { storeHoursInfo } = this.state;
    const others = storeHoursInfo.SpecialDays.filter(i => i.key !== key);
    storeHoursInfo.SpecialDays = [...others];
    this.setState({ storeHoursInfo });
  };

  handleSpecialDayDateChange = (date, dateString, key) => {
    const { storeHoursInfo } = this.state;
    const day = storeHoursInfo.SpecialDays.find(i => i.key === key);
    day.Date = dateString;
    this.setState({ storeHoursInfo });
  };

  handleSpecialDayHoursChange = (times, timeString, key) => {
    const { storeHoursInfo } = this.state;
    const day = storeHoursInfo.SpecialDays.find(i => i.key === key);
    day.OpenTime = timeString[0];
    day.CloseTime = timeString[1];
    this.setState({ storeHoursInfo });
  };

  handleSpecialDayNoteChange = ({ target: { value } }, key) => {
    const { storeHoursInfo } = this.state;
    const day = storeHoursInfo.SpecialDays.find(i => i.key === key);
    day.Message = value;
    this.setState({ storeHoursInfo });
  };

  handleSpecialDayOpenCloseChange = ({ target: { value } }, key) => {
    const { storeHoursInfo } = this.state;
    const day = storeHoursInfo.SpecialDays.find(i => i.key === key);
    day.Status = value;
    this.setState({ storeHoursInfo });
  };

  componentDidMount() {
    this.getOptions();
    this.init();
  }

  render() {
    const {
      spinning,
      itemList,
      showTitle,
      orderOptions,
      viewPage,
      loginOptionSelected,
      disableOrderingChecked,
      enableGuestOrderChecked,
      orderOptionMappingSelected,
      enableOrderOptionsChecked,
      futureOrderSelected,
      enableResChecked,
      receiptPrintingSelected,
      collectCustomerNameChecked,
      enablePayAtCounter,
      enableAcctPayment,
      enableCreditCardPayment,
      giftCardSelected,
      disabledAccountStatement,
      disabledOrderHistory,
      enableLoyaltyPayment,
      configName,
      useCustomSetting,
      kioskModeSelected,
      orderOptionsSelected,
      viewPageSelected,
      collectGuestPhoneNumber,
      autoChargeItemSelected,
      orderCompletionCountdown,
      storeHoursInfo,
    } = this.state;
    const { item } = this.props.location.state || {};

    const defaultOrCustomOptions = this.defaultOrCustomOptions.map(i => (
      <Option key={i.key} value={i.value}>
        {i.label}
      </Option>
    ));

    const loginOptions = this.loginOption.map(i => (
      <Option key={i.key} value={i.value}>
        {i.label}
      </Option>
    ));

    const orderOptionGrouping = this.orderOptionGrouping.map(i => (
      <Option key={i.value} value={i.value}>
        {i.label}
      </Option>
    ));

    const futureOrder = this.futureOrder.map(i => (
      <Option key={i.key} value={i.value}>
        {i.label}
      </Option>
    ));

    const receiptPrinting = this.receiptPrinting.map(i => (
      <Option key={i.key} value={i.value}>
        {i.label}
      </Option>
    ));

    let kioskMode = this.kioskMode.map(i => (
      <Option key={i.key} value={i.value}>
        {i.label}
      </Option>
    ));

    kioskMode =
      loginOptionSelected === 3
        ? [
            ...kioskMode,
            <Option key={4} value={4}>
              Auto-Charge
            </Option>,
          ]
        : kioskMode;

    const giftCardOptions = this.giftCard.map(i => (
      <Option key={i.value} value={i.value}>
        {i.label}
      </Option>
    ));

    const viewPageOptions =
      viewPage &&
      viewPage.length > 0 &&
      viewPage.map(i => (
        <Option key={i.ViewName} value={i.ViewName}>
          {i.ViewName}
        </Option>
      ));

    const orderOptionsOptions =
      kioskModeSelected === 4
        ? orderOptions
            ?.filter(o => o.Type === 4)
            .map(i => {
              return { label: i.DisplayName, value: i.Code };
            })
        : orderOptions
            ?.filter(o => o.Type !== 4)
            .map(i => {
              return { label: i.DisplayName, value: i.Code };
            });

    const itemsOptions = [<Option key="emptyString" value=""></Option>].concat(
      itemList.map(i => (
        <Option
          key={i.itemCode + i.description}
          value={i.itemCode}
        >{`${i.itemCode}--${i.description}`}</Option>
      )),
    );

    return (
      <Spin spinning={spinning}>
        <Row className="admin-application-settings-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title="Kiosk Station Configuration"
              showLeftBtn={true}
              leftBtnText="Back"
              onLeftBtnClick={this.handleBackBtn}
              showRightBtn={true}
              rightBtnText="Save"
              onRightBtnClick={this.handleSaveBtn}
            />
            {showTitle ? (
              <>
                <Row style={{ marginTop: 30 }}>
                  <Col span={24}>
                    <Row align="middle">
                      <h2 style={{ color: 'var(--adminSecondary)' }}>Configuration Name </h2>{' '}
                      <h2>{configName}</h2>
                    </Row>
                  </Col>
                </Row>
                <Row align="middle" style={{ marginTop: 30 }}>
                  <Col span={24}>
                    <Row align="middle">
                      <h2 style={{ marginRight: 20, color: 'var(--adminSecondary)' }}>
                        Setting Type
                      </h2>
                      <Selection
                        width={200}
                        data={defaultOrCustomOptions}
                        value={useCustomSetting}
                        onChange={(value, option) => {
                          this.handleSelectionChange(value, option, 'useCustomSetting');
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              </>
            ) : null}

            {!item || useCustomSetting ? (
              <>
                {/* Basic Setting */}
                {/* Title Row */}
                <Row style={{ marginTop: 15 }}>
                  <h2 style={{ color: 'var(--adminSecondary)' }}>Basics</h2>
                </Row>
                {/* Content Row */}
                <Row style={{ marginTop: 15 }}>
                  <Col span={24}>
                    {/* Login Option */}
                    <Row align="middle">
                      <Col span={3}>
                        <strong>Login Option</strong>
                      </Col>
                      <Col span={9}>
                        <Selection
                          width={280}
                          data={loginOptions}
                          value={loginOptionSelected}
                          onChange={(value, option) => {
                            this.handleSelectionChange(value, option, 'loginOptionSelected');
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <Row>
                          {' '}
                          <Checkbox
                            checked={disableOrderingChecked}
                            onChange={e => this.handleCheckBoxChange(e, 'disableOrderingChecked')}
                          >
                            Disable Ordering
                          </Checkbox>
                          <Checkbox
                            checked={enableGuestOrderChecked}
                            onChange={e => this.handleCheckBoxChange(e, 'enableGuestOrderChecked')}
                          >
                            Enable Guest Order
                          </Checkbox>
                        </Row>
                      </Col>
                    </Row>
                    {/* Order Option Mapping */}
                    <Row align="middle" style={{ marginTop: 15 }}>
                      <Col span={3}>
                        <strong>Order Option Grouping</strong>
                      </Col>
                      <Col span={9}>
                        <Selection
                          width={280}
                          data={orderOptionGrouping}
                          value={orderOptionMappingSelected}
                          onChange={(value, option) => {
                            this.handleSelectionChange(value, option, 'orderOptionMappingSelected');
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <Row>
                          <Checkbox
                            checked={enableResChecked}
                            onChange={e => this.handleCheckBoxChange(e, 'enableResChecked')}
                          >
                            Enable Reservation
                          </Checkbox>
                          <Checkbox
                            checked={enableOrderOptionsChecked}
                            onChange={e =>
                              this.handleCheckBoxChange(e, 'enableOrderOptionsChecked')
                            }
                          >
                            Enable Order Options
                          </Checkbox>
                        </Row>
                      </Col>
                    </Row>
                    {/* Order Option View Page */}
                    <Row align="middle" style={{ marginTop: 15 }}>
                      <Col span={3}>
                        <strong>View Page</strong>
                      </Col>
                      <Col span={9}>
                        <Selection
                          width={280}
                          data={viewPageOptions}
                          value={viewPageSelected}
                          onChange={(value, option) => {
                            this.handleSelectionChange(value, option, 'viewPageSelected');
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <Row>
                          {enableOrderOptionsChecked && orderOptions.length > 0 ? (
                            <Checkbox.Group
                              options={orderOptionsOptions}
                              value={orderOptionsSelected}
                              onChange={values => {
                                this.handleCheckGroupChange(values);
                              }}
                            />
                          ) : null}
                        </Row>
                      </Col>
                    </Row>
                    {/* Future Order / Reservation */}
                    <Row align="middle" style={{ marginTop: 15 }}>
                      <Col span={3}>
                        <strong>Future Order</strong>
                      </Col>
                      <Col span={9}>
                        <Selection
                          width={280}
                          data={futureOrder}
                          value={futureOrderSelected}
                          onChange={(value, option) => {
                            this.handleSelectionChange(value, option, 'futureOrderSelected');
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <Row>
                          <Checkbox
                            checked={collectCustomerNameChecked}
                            onChange={e =>
                              this.handleCheckBoxChange(e, 'collectCustomerNameChecked')
                            }
                          >
                            Collect Customer Name
                          </Checkbox>
                        </Row>
                      </Col>
                    </Row>
                    {/* Receipt Printing */}
                    <Row align="middle" style={{ marginTop: 15 }}>
                      <Col span={3}>
                        <strong>Receipt Printing</strong>
                      </Col>
                      <Col span={9}>
                        <Selection
                          width={280}
                          data={receiptPrinting}
                          value={receiptPrintingSelected}
                          onChange={(value, option) => {
                            this.handleSelectionChange(value, option, 'receiptPrintingSelected');
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <Row>
                          <Checkbox
                            checked={disabledOrderHistory}
                            onChange={e => this.handleCheckBoxChange(e, 'disabledOrderHistory')}
                          >
                            Disable Order History
                          </Checkbox>
                          <Checkbox
                            checked={disabledAccountStatement}
                            onChange={e => this.handleCheckBoxChange(e, 'disabledAccountStatement')}
                          >
                            Disable Account Statement
                          </Checkbox>
                        </Row>
                      </Col>
                    </Row>
                    {/* Order Completion Countdown */}
                    <Row align="middle" style={{ marginTop: 15 }}>
                      <Col span={3}>
                        <strong>Order Complete Coundown</strong>
                      </Col>
                      <Col span={9}>
                        <InputNumber
                          style={{ width: 280 }}
                          value={orderCompletionCountdown}
                          onChange={v => this.handleInputChange(v, 'orderCompletionCountdown')}
                        />
                      </Col>
                      <Col span={12}>
                        <Checkbox
                          checked={collectGuestPhoneNumber}
                          onChange={e => this.handleCheckBoxChange(e, 'collectGuestPhoneNumber')}
                        >
                          Collect Customer Phone Number
                        </Checkbox>
                      </Col>
                    </Row>
                    {/* Scan Mode */}
                    <Row align="middle" style={{ marginTop: 15 }}>
                      <Col span={3}>
                        <strong>Kiosk Mode</strong>
                      </Col>
                      <Col span={9}>
                        <Selection
                          width={280}
                          data={kioskMode}
                          value={kioskModeSelected}
                          onChange={(value, option) => {
                            this.handleSelectionChange(value, option, 'kioskModeSelected');
                          }}
                        />
                      </Col>
                      {/* Auto Charge Item Selection */}
                      {kioskModeSelected === 4 ? (
                        <>
                          <Col span={3}>
                            <strong>Auto Charge Item</strong>
                          </Col>
                          <Col span={9}>
                            <Selection
                              width={280}
                              showSearch={true}
                              allowClear={true}
                              data={itemsOptions}
                              value={autoChargeItemSelected}
                              onChange={(value, option) => {
                                this.handleSelectionChange(value, option, 'autoChargeItemSelected');
                              }}
                            />
                          </Col>
                        </>
                      ) : null}
                    </Row>
                  </Col>
                </Row>
                <Divider />
                {/* Open/Close Times */}
                {/* Title Row */}
                <Row style={{ marginTop: 15 }}>
                  <h2 style={{ color: 'var(--adminSecondary)' }}>Open/Close Times</h2>
                </Row>
                {/* Content Row */}
                <Row>
                  <Col span={24}>
                    <Row justify="center">
                      <Col span={8}></Col>
                      <Col span={8}>
                        <Row justify="center">
                          <h3 style={{ fontSize: 20, color: 'var(--adminPrimary)' }}>Every Week</h3>
                        </Row>
                      </Col>
                      <Col span={8}></Col>
                    </Row>
                    <Divider style={{ borderColor: 'transparent' }} />
                    {this.weekdays.map(i => {
                      let times = [];
                      if (storeHoursInfo[i.key]?.OpenTime && storeHoursInfo[i.key]?.CloseTime) {
                        times = [
                          moment(storeHoursInfo[i.key]?.OpenTime, 'HH:mm'),
                          moment(storeHoursInfo[i.key]?.CloseTime, 'HH:mm'),
                        ];
                      }
                      return (
                        <Row
                          key={i.key}
                          gutter={8}
                          style={{
                            marginBottom: 8,
                            padding: '5px 0',
                          }}
                        >
                          <Col span={1}>
                            <strong>{i.Description}</strong>
                          </Col>
                          <Col span={5}>
                            <TimePicker.RangePicker
                              allowClear
                              value={times}
                              // value={[
                              //   moment(storeHoursInfo[i.key]?.OpenTime, 'HH:mm').format('HHmm'),
                              //   moment(storeHoursInfo[i.key]?.CloseTime, 'HH:mm'),
                              // ]}
                              format="HH:mm"
                              style={{ width: '100%' }}
                              onChange={(times, timeString) =>
                                this.handleStoreHoursChange(times, timeString, i.key)
                              }
                            />
                          </Col>
                          <Col span={12}>
                            <Input.TextArea
                              autoSize={true}
                              placeholder="Note - Max 100 Characters"
                              maxLength={100}
                              value={
                                storeHoursInfo[i.key]?.Message ? storeHoursInfo[i.key]?.Message : ''
                              }
                              onChange={e => this.handleStoreHoursNoteChange(e, i.key)}
                            />
                          </Col>
                          <Col span={6}>
                            <Radio.Group
                              options={this.openCloseOptions}
                              value={
                                typeof storeHoursInfo[i.key]?.Status === 'number'
                                  ? storeHoursInfo[i.key]?.Status
                                  : 0
                              }
                              optionType="button"
                              buttonStyle="solid"
                              onChange={e => this.handleStoreHoursOpenCloseChange(e, i.key)}
                            />
                          </Col>
                        </Row>
                      );
                    })}
                    <Divider style={{ borderColor: 'transparent' }} />
                    <Row justify="center" align="middle">
                      <Col span={8}></Col>
                      <Col span={8}>
                        <Row justify="center">
                          <h3 style={{ fontSize: 20, color: 'var(--adminPrimary)' }}>
                            Special Days
                          </h3>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Button
                          width={90}
                          height={50}
                          style={{ backgroundColor: 'var(--adminPrimary)', marginLeft: 15 }}
                          textStyle={{ color: 'white', fontSize: 16 }}
                          borderRadius={5}
                          onClick={this.handleAddSpecialDay}
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                    <Divider style={{ borderColor: 'transparent' }} />
                    {storeHoursInfo.SpecialDays?.length > 0
                      ? storeHoursInfo.SpecialDays?.map(i => {
                          let times = [];
                          if (i.OpenTime && i.CloseTime) {
                            times = [moment(i.OpenTime, 'HH:mm'), moment(i.CloseTime, 'HH:mm')];
                          }
                          return (
                            <Row
                              key={i.key}
                              gutter={8}
                              style={{
                                marginBottom: 8,
                                padding: '5px 0',
                              }}
                            >
                              <Col span={1}>
                                <Row justify="center">
                                  <FontAwesomeIcon
                                    icon={faTrashCan}
                                    size="2x"
                                    color="var(--adminSecondary)"
                                    onClick={() => this.handleDeleteSpecialDay(i.key)}
                                  />
                                </Row>
                              </Col>
                              <Col span={4}>
                                <DatePicker
                                  allowClear={false}
                                  style={{ width: '100%' }}
                                  value={!i.Date ? null : moment(i.Date)}
                                  onChange={(date, dateString) =>
                                    this.handleSpecialDayDateChange(date, dateString, i.key)
                                  }
                                />
                              </Col>
                              <Col span={5}>
                                <TimePicker.RangePicker
                                  allowClear
                                  value={times}
                                  format="HH:mm"
                                  style={{ width: '100%' }}
                                  onChange={(times, timeString) =>
                                    this.handleSpecialDayHoursChange(times, timeString, i.key)
                                  }
                                />
                              </Col>
                              <Col span={9}>
                                <Input.TextArea
                                  autoSize={true}
                                  placeholder="Note - Max 100 Characters"
                                  maxLength={100}
                                  value={i.Message ? i.Message : ''}
                                  onChange={e => this.handleSpecialDayNoteChange(e, i.key)}
                                />
                              </Col>
                              <Col span={5}>
                                <Radio.Group
                                  options={this.openCloseOptions}
                                  value={typeof i.Status === 'number' ? i.Status : 0}
                                  optionType="button"
                                  buttonStyle="solid"
                                  onChange={e => this.handleSpecialDayOpenCloseChange(e, i.key)}
                                />
                              </Col>
                            </Row>
                          );
                        })
                      : null}
                  </Col>
                </Row>
                <Divider />
                {/* Payment Setting */}
                {/* Title Row */}
                <Row>
                  <h2 style={{ color: 'var(--adminSecondary)' }}>Payment</h2>
                </Row>
                {/* Payment Settings slection Row */}
                <Row style={{ marginTop: 15 }}>
                  <Col span={24}>
                    {/* Payment Integration */}
                    <Row align="middle" style={{ marginTop: 15 }}>
                      <Col span={3}>
                        <strong>Gift Card</strong>
                      </Col>
                      <Col span={9}>
                        <Selection
                          width={280}
                          data={giftCardOptions}
                          value={giftCardSelected}
                          onChange={(value, option) => {
                            this.handleSelectionChange(value, option, 'giftCardSelected');
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* Payment Setting Checkboxes Row */}
                <Row align="middle" style={{ marginTop: 15 }}>
                  <Col span={24}>
                    <Row>
                      <Checkbox
                        checked={enablePayAtCounter}
                        onChange={e => this.handleCheckBoxChange(e, 'enablePayAtCounter')}
                      >
                        Enable Pay At Counter
                      </Checkbox>
                      <Checkbox
                        checked={enableAcctPayment}
                        onChange={e => this.handleCheckBoxChange(e, 'enableAcctPayment')}
                      >
                        Enable Account Payment
                      </Checkbox>
                      <Checkbox
                        checked={enableCreditCardPayment}
                        onChange={e => this.handleCheckBoxChange(e, 'enableCreditCardPayment')}
                      >
                        Enable Credit Card Payment
                      </Checkbox>
                      <Checkbox
                        checked={enableLoyaltyPayment}
                        onChange={e => this.handleCheckBoxChange(e, 'enableLoyaltyPayment')}
                      >
                        Enable Loyalty Payment
                      </Checkbox>
                    </Row>
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>
        </Row>
      </Spin>
    );
  }
}
export default connect(state => ({}), { receiveAppSetting })(ApplicationSettings);
