import React, { Component } from 'react';
import { getVTItemImageFolder } from '../../api';
import MenuGrouper from '../MenuGrouper';

import './index.css';

class MenuPicker extends Component {
  state = {
    tags: [],
    imageDir: '',
  };

  ref = React.createRef();

  init = () => {
    const { data } = this.props;
    this.setState({ tags: data });
  };

  getImageFolder = async () => {
    try {
      const re = await getVTItemImageFolder();
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ imageDir: '' });
      }
      this.setState({ imageDir: re.data });
    } catch (error) {}
  };

  handleCardPillClick = (e, item) => {
    const cart = document.getElementById('menu-picker-corner-control-shopping-cart') || null;
    const speed = 500,
      curveDelay = 300,
      position = 'fixed';
    let btnY =
        position === 'fixed'
          ? e.currentTarget.getBoundingClientRect().top
          : e.currentTarget.offsetTop,
      btnX =
        position === 'fixed'
          ? e.currentTarget.getBoundingClientRect().left
          : e.currentTarget.offsetLeft,
      flyingBtn = e.currentTarget.cloneNode(true);

    flyingBtn.classList.add('flyingBtn');
    flyingBtn.style.position = position;
    flyingBtn.style.top = `${btnY}px`;
    flyingBtn.style.left = `${btnX}px`;
    flyingBtn.style.opacity = '1';
    flyingBtn.style.transition = `all ${speed / 1000}s ease, top ${
      (speed + curveDelay) / 1000
    }s ease, left ${speed / 1000}s ease, transform ${speed / 1000}s ease-out ${
      (speed - 100) / 1000
    }s`;

    const obj = {};
    obj.flyingBtn = flyingBtn;
    obj.speed = speed;
    obj.curveDelay = curveDelay;
    obj.btnY = btnY;
    obj.btnX = btnX;
    obj.cart = cart;
    this.props.onCardPillClick(item, obj);
  };

  componentDidMount() {
    this.init();
    this.getImageFolder();
  }

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props;
    if (data !== prevProps.data) {
      this.init();
      this.ref.current.scrollTo(0, 0);
    }
  }

  render() {
    const { tags, imageDir } = this.state;
    const {
      height = null,
      cardOrPill = 0,
      cardHeight = 200,
      cardWidth = 200,
      cardImgHeight = 120,
      pillImgHeight = window.innerWidth >= 1080 ? 150 : 120,
      tagBarCss,
      itemCss,
    } = this.props;
    return (
      <div
        id="menu-picker-container"
        ref={this.ref}
        className="menu-picker-container"
        style={{
          height: height ? height : '100%',
        }}
      >
        {tags && tags.length > 1 ? (
          <>
            {tags.map(i => (
              <MenuGrouper
                key={i.TagCode}
                cardOrPill={cardOrPill}
                tag={i}
                tagBarCss={tagBarCss}
                itemCss={itemCss}
                cardWidth={cardWidth}
                cardHeight={cardHeight}
                imageDir={imageDir}
                itemImage={true}
                cardImgHeight={cardImgHeight}
                pillImgHeight={pillImgHeight}
                bar={true}
                // onCardPillClick={onCardPillClick}
                onCardPillClick={(e, item) => {
                  this.handleCardPillClick(e, item);
                }}
              />
            ))}
          </>
        ) : tags && tags.length === 1 ? (
          <MenuGrouper
            key={tags[0].TagCode}
            cardOrPill={cardOrPill}
            tag={tags[0]}
            tagBarCss={tagBarCss}
            itemCss={itemCss}
            cardWidth={cardWidth}
            cardHeight={cardHeight}
            imageDir={imageDir}
            itemImage={true}
            cardImgHeight={cardImgHeight}
            pillImgHeight={pillImgHeight}
            bar={false}
            // onCardPillClick={onCardPillClick}
            onCardPillClick={(item, id) => {
              this.handleCardPillClick(item, id);
            }}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}
export default MenuPicker;
