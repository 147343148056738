import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyCheckDollar,
  faCashRegister,
  faCreditCard,
  faUser,
  faGift,
  faAward,
  faPowerOff,
  faPlus,
  faTrashCan,
  faCalendarDays,
  faHouse,
  faFileInvoiceDollar,
  faIdCardClip,
  faIdCard,
  faCircleUser,
  faArrowLeft,
  faMagnifyingGlass,
  faCircleInfo,
  faCircleExclamation,
  faPenToSquare,
  faKeyboard,
  faBookOpen,
  faListOl,
  faMessage,
} from '@fortawesome/free-solid-svg-icons';

import './index.css';

class Button extends Component {
  state = { flip: false };

  timer = () => {
    setTimeout(() => {
      this.setState({ flip: false });
    }, 900);
  };

  handleIconAnimation = () => {
    const { flip } = this.state;
    if (flip) {
      clearTimeout(this.timer);
      this.timer();
    }
    if (!flip) {
      this.setState({ flip: true });
      this.timer();
    }
  };

  handleBtnClick = e => {
    const { icon } = this.props;
    if (icon) {
      this.handleIconAnimation();
    }
    this.props.onClick(e);
  };

  componentDidMount() {
    this.handleIconAnimation();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { flip } = this.state;
    const {
      style,
      minWidth,
      minHeight,
      width = 100,
      height = 60,
      children,
      icon,
      textStyle,
      iconSize,
      borderRadius,
      border = null,
      clientStyle,
    } = this.props;

    let btnIcon;
    switch (icon) {
      case 'check':
        btnIcon = faMoneyCheckDollar;
        break;
      case 'register':
        btnIcon = faCashRegister;
        break;
      case 'credit':
        btnIcon = faCreditCard;
        break;
      case 'account':
        btnIcon = faUser;
        break;
      case 'gift':
        btnIcon = faGift;
        break;
      case 'reward':
        btnIcon = faAward;
        break;
      case 'logout':
        btnIcon = faPowerOff;
        break;
      case 'plus':
        btnIcon = faPlus;
        break;
      case 'trash':
        btnIcon = faTrashCan;
        break;
      case 'home':
        btnIcon = faHouse;
        break;
      case 'calender':
        btnIcon = faCalendarDays;
        break;
      case 'split':
        btnIcon = faFileInvoiceDollar;
        break;
      case 'guest':
        btnIcon = faIdCardClip;
        break;
      case 'user':
        btnIcon = faCircleUser;
        break;
      case 'card':
        btnIcon = faIdCard;
        break;
      case 'back':
        btnIcon = faArrowLeft;
        break;
      case 'search':
        btnIcon = faMagnifyingGlass;
        break;
      case 'info':
        btnIcon = faCircleInfo;
        break;
      case 'warn':
        btnIcon = faCircleExclamation;
        break;
      case 'edit':
        btnIcon = faPenToSquare;
        break;
      case 'keyboard':
        btnIcon = faKeyboard;
        break;
      case 'menu':
        btnIcon = faBookOpen;
        break;
      case 'listOl':
        btnIcon = faListOl;
        break;
      case 'message':
        btnIcon = faMessage;
        break;

      default:
        break;
    }

    const titleStyle = {
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    return (
      <button
        className="vt-button-container"
        onClick={e => this.handleBtnClick(e)}
        style={{
          ...style,
          minWidth,
          minHeight,
          width,
          height,
          borderRadius,
          border,
        }}
      >
        {icon ? (
          <div className="vt-button-icon" style={{ width: `calc(0.33*${width}px)` }}>
            {icon && <FontAwesomeIcon icon={btnIcon} size={iconSize} flip={flip} />}
          </div>
        ) : (
          <></>
        )}
        <div
          className="vt-button-text"
          style={{
            ...titleStyle,
            ...textStyle,
            width: icon ? `calc(0.67*${width}px)` : '100%',
            textAlign: icon ? 'left' : '',
          }}
        >
          <span className="vt-button-text-span">{children}</span>
        </div>
      </button>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(Button);
