import React, { Component } from 'react';
import { Row, Col, Table, message } from 'antd';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { getAccountUpcomingReservations, cancelReservation } from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import './index.css';

class AdminReservation extends Component {
  state = {
    spinning: false,
    list: [],
  };

  columns = [
    {
      title: 'Cancel',
      render: (_, record) => (
        <FontAwesomeIcon
          icon={faTrashCan}
          size="2xl"
          color="var(--adminSecondary)"
          onClick={() => this.handleDelete(record)}
        />
      ),
    },
    {
      title: 'Date',
      dataIndex: 'ArrivalDate',
      render: (_, record) => <span>{moment(record.ArrivalDate).format('ddd, MMM D, YYYY')}</span>,
    },
    {
      title: 'Time',
      dataIndex: 'ArrivalTime',
    },
    {
      title: 'Venue',
      dataIndex: 'ReservedLocation',
    },
    {
      title: 'Total Guests',
      dataIndex: 'GuestNumber',
    },
    {
      title: 'Edit',
      render: (_, record) => (
        <FontAwesomeIcon
          icon={faPenToSquare}
          size="2xl"
          color="var(--adminPrimary)"
          onClick={() => this.handleEdit(record)}
        />
      ),
    },
  ];

  init = async () => {
    const {
      user: {
        CurAccountInfo: { AccountNumber },
      },
    } = this.props;
    try {
      const re = await getAccountUpcomingReservations(AccountNumber);
      if (!re || (re && re.result !== 'OK')) throw new Error();
      this.setState({ list: re.data });
    } catch (error) {
      message.error('Error obtaining upcoming reservations', 3);
    }
  };

  handleEdit = row => {
    this.props.history.replace({
      pathname: '/reservation',
      state: { editOrAdd: 'Edit', reservation: row },
    });
  };

  handleViewHistory = () => {
    this.props.history.replace('/admin/reshistory');
  };

  //Navigate to reservation page
  //Pass state to indicate add or edit
  handleMakeNewReservation = () => {
    this.props.history.replace({
      pathname: '/reservation',
      state: { editOrAdd: 'Add' },
    });
  };

  //Handle the deletion of a seleted row
  handleDelete = async row => {
    try {
      const re = await cancelReservation(row);
      if (!re || (re && re.result !== 'OK')) throw new Error();
      // this.setState({ list: re.data });
      this.init();
      message.success('Reservation successfully cancelled');
    } catch (error) {
      message.error('Error obtaining upcoming reservations', 3);
    }
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { spinning, list } = this.state;
    return (
      <Spin spinning={spinning}>
        <Row className="admin-reservation-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title="My Reservations"
              showLeftBtn={true}
              showRightBtn={true}
              leftBtnText="View History"
              rightBtnText="Make A Reservation"
              leftBtnWidth={150}
              rightBtnWidth={220}
              onRightBtnClick={this.handleMakeNewReservation}
              onLeftBtnClick={this.handleViewHistory}
            />
            <Row style={{ marginTop: 25 }}>
              <Col span={24}>
                <Table
                  size="middle"
                  rowKey="ResGuid"
                  columns={this.columns}
                  dataSource={list}
                  pagination={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}
export default connect(state => ({ user: state.user }))(AdminReservation);
