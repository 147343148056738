import React, { Component } from 'react';
import { connect } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { Row, Col } from 'antd';
import Card from '../Card';

class ResVenue extends Component {
  handleCardClick = item => {
    this.props.onVenueSeletionChange(item);
  };
  render() {
    const { vms, imageDir, venueChoice, clientStyle } = this.props;
    const width = 200;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    return (
      <Col span={24}>
        <Row justify="center">
          {vms.length > 0 &&
            vms.map(i => (
              <div key={nanoid()} style={{ margin: 15 }}>
                <Card
                  titleStyle={{
                    fontSize: width > 149 ? `${width / 200}rem` : '0.75rem',
                    ...titleStyle,
                  }}
                  imageDir={imageDir}
                  img={i.ImageName}
                  imgHeight={120}
                  title={i.DisplayName}
                  width={width}
                  height={width}
                  selectedCard={venueChoice}
                  selectedBorder={clientStyle.secondary}
                  id={i}
                  item={i}
                  onCardClick={() => this.handleCardClick(i)}
                />
              </div>
            ))}
        </Row>
      </Col>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(ResVenue);
