import React, { Component } from 'react';
import { Row, Col, Input, Divider, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import Keyboard from 'react-simple-keyboard';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { closeKioskForm, openStationConfig } from '../../../api';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import './index.css';

class POS extends Component {
  state = {
    error: '',
    modalVisible: false,
    input: '',
  };

  refInput = React.createRef();

  keyboardOptions = {
    ...this.commonKeyboardOptions,
    layout: {
      default: ['1 2 3', '4 5 6', '7 8 9', '{bksp} 0 {enter}'],
    },
    buttonTheme: [
      {
        class: 'hg-primary',
        buttons: '{enter}',
      },
    ],
    display: {
      '{bksp}': 'Del',
      '{enter}': 'OK',
    },
  };

  onKeyboardChange = input => {
    if (input.trim().length > 0 && this.state.error) {
      this.setState({ error: '' });
    }
    this.setState({ input });
  };

  onKeyborardPress = button => {
    if (button === '{enter}') {
      this.handleCodeModalOK();
    }
  };

  handleCodeModalOpen = () => {
    this.setState({ modalVisible: true });
  };

  handleCodeModalCancel = () => {
    this.setState({ modalVisible: false, input: '', error: '' });
  };

  handleCodeInputChange = ({ target: { value: input } }) => {
    this.setState({ input });
    if (input.trim().length > 0 && this.state.error) {
      this.setState({ error: '' });
    }
    this.keyboard && this.keyboard.setInput(input);
  };

  handleCodeModalOK = async () => {
    const { input } = this.state;
    const re = await closeKioskForm(input);
    if (!re || (re && re !== 'OK')) {
      this.setState({ error: re, input: '' });
      this.keyboard && this.keyboard.setInput('');
      return;
    }
    this.keyboard && this.keyboard.setInput('');
    this.setState({ input: '', modalVisible: false });
  };

  handlePEnterKey = e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      this.handleCodeModalOK();
    }
  };

  handleGoToStationConfig = async () => {
    try {
      this.props.onTimerPause();
      const re = await openStationConfig();
      if (!re || re !== 'OK') throw new Error(re);
      this.props.onTimerReset();
    } catch (error) {
      message.error('Error opening station config.', 3);
      this.props.onTimerReset();
    }
  };

  render() {
    const { modalVisible, input, error } = this.state;

    return (
      <Row className="admin-POS-wrapper">
        <div className="admin-POS-btns">
          <Button
            width={200}
            height={60}
            style={{ backgroundColor: 'var(--adminSecondary)' }}
            textStyle={{ fontSize: 18, color: 'white' }}
            borderRadius={5}
            onClick={this.handleCodeModalOpen}
          >
            Shut Down App
          </Button>
          <Divider style={{ borderColor: 'transparent' }} />
          <Button
            width={200}
            height={60}
            style={{ backgroundColor: 'var(--adminPrimary)' }}
            textStyle={{ fontSize: 18, color: 'white' }}
            borderRadius={5}
            onClick={this.handleGoToStationConfig}
          >
            Station Config.
          </Button>
        </div>

        {/* Shut down code Modal */}
        <Modal
          visible={modalVisible}
          size="large"
          maskClosable={true}
          closable={false}
          title={null}
          footer={null}
          destroyOnClose={true}
          onCancel={this.handleCodeModalCancel}
          showCloseBtn={false}
        >
          <div style={{ margin: '20px 0 30px', position: 'relative' }}>
            <div className="menu-cancel-modal-close-btn">
              <div className="menu-cancel-modal-close-icon" onClick={this.handleCodeModalCancel}>
                <FontAwesomeIcon icon={faX} size="2x" style={{ color: '#666' }} />
              </div>
            </div>
            {/* Input Row */}
            <Row justify="center">
              <Input.Password
                ref={this.refNew}
                autoFocus={true}
                spellCheck={false}
                style={{ width: 300 }}
                value={input}
                onChange={this.handleCodeInputChange}
                onKeyUp={this.handlePEnterKey}
                visibilityToggle={false}
              />
            </Row>
            <Divider style={{ borderColor: 'transparent', margin: 10 }} />
            {/* Error Row */}
            <Row
              style={{
                height: 30,
                fontWeight: 'bold',
                color: '#8b0000',
                fontSize: 18,
                marginBottom: 20,
              }}
              justify="center"
            >
              <Col>
                <SwitchTransition>
                  <CSSTransition key={error} timeout={500} classNames="login-account-error">
                    <Row>{error}</Row>
                  </CSSTransition>
                </SwitchTransition>
              </Col>
            </Row>
            <Divider style={{ borderColor: 'transparent', margin: 3 }} />
            {/* Keyboard Row */}
            <Row justify="center">
              <Col>
                <Row style={{ width: 300 }}>
                  <Keyboard
                    baseClass={'simple-keyboard-main'}
                    keyboardRef={r => (this.keyboard = r)}
                    layoutName={'default'}
                    onChange={this.onKeyboardChange}
                    onKeyPress={this.onKeyborardPress}
                    theme={'hg-theme-default hg-layout-default'}
                    preventMouseDownDefault={true}
                    {...this.keyboardOptions}
                  />
                </Row>
              </Col>
            </Row>
          </div>
        </Modal>
      </Row>
    );
  }
}

export default POS;
