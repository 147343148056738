import React, { Component } from 'react';
import { Row, Col, Divider, Image, Checkbox, message } from 'antd';
import { nanoid } from '@reduxjs/toolkit';
import { saveVTImage, deleteVTImage, getVTImages, getVTImageFolder } from '../../api';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import AdminHeader from '../AdminHeader';
import Spin from '../Spin';
import ImageUploader from '../ImageUploader';
import Button from '../Button';
import './index.css';

/**
None = 0,
ViewButtonImage = 1,
ViewBackgroundImage = 2,
SliderImage = 3,
ProfitCentreImage = 4,
DashboardBackgroundImage = 5,
CompanyLogoImage = 6,
KioskOrderSetupBackgroundImage = 7,
KiosWelcomeBackgroundImage = 8,
KioskReservationBackgroundImage = 9,
OrderOptionMappingImage = 10,
ReservationMappingImage = 11,
KioskVideo = 12,
KioskCustomizedImage = 13,
VisualItem = 14,
EventImage = 15,
ItemTabImage = 16,
ListViewPopUpImage = 17
 */

export default class AdminImageManager extends Component {
  state = {
    spinning: false,
    error: '',
    data: [],
    imageDir: '',
    selectedSliderImages: [],
  };

  init = async () => {
    const { type, sliderImages } = this.props;
    if (type) {
      this.setState({ spinning: true });
      await Promise.all([getVTImages(type), getVTImageFolder(type)])
        .then(results => {
          results.forEach(res => {
            if (!res || (res && res.result !== 'OK')) throw new Error();
          });
          const data = results[0].data.map(i => {
            const obj = {};
            obj.ImageName = i;
            obj.key = nanoid();
            return obj;
          });
          let selectedSliderImages = [];
          if (type === '8') {
            data.forEach(i => {
              sliderImages.indexOf(i.ImageName) > -1 && selectedSliderImages.push(i.ImageName);
            });
          } else {
            selectedSliderImages = [...sliderImages];
          }
          this.setState({ spinning: false, data, imageDir: results[1].data, selectedSliderImages });
        })
        .catch(error => {
          this.setState({ spinning: false });
          message.error('Error obtaining images!', 3);
        });
    }
  };

  handleBackBtn = () => {
    const { selectedSliderImages } = this.state;
    this.props.onSliderImageClick(selectedSliderImages);
    this.props.onCloseImgDrawer();
  };

  sortType = type => {
    switch (type) {
      case '8':
        return 'SliderImage';
      case '5':
        return 'DashboardBackgroundImage';
      case '6':
        return 'CompanyLogoImage';
      case '7':
        return 'KioskOrderSetupBackgroundImage';
      case '9':
        return 'KioskReservationBackgroundImage';
      default:
        return '';
    }
  };

  getImage = ImageUrl => {
    const { imageDir } = this.state;
    const imgFallback = `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`;
    let src = '';
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      //dev mode
      if (ImageUrl) {
        src = `http://localhost:4000/${imageDir}/${ImageUrl}`;
      } else {
        src = imgFallback;
      }
    } else {
      //production mode
      if (ImageUrl) {
        src = `${process.env.PUBLIC_URL}/${imageDir}/${ImageUrl}`;
      } else {
        src = imgFallback;
      }
    }
    return src;
  };

  getTitle = type => {
    switch (type) {
      case '8':
        return 'Welcome Page';
      case '5':
        return 'Dashboard Background';
      case '6':
        return 'Company/Store Logo';
      case '7':
        return 'Order Setup Background';
      case '9':
        return 'Reservation Background';
      default:
        return 'Kiosk Image Manager';
    }
  };

  handleUploadImg = async base64 => {
    const { type } = this.props;
    const obj = {};
    obj.Type = type;
    obj.ImageString = base64;
    try {
      this.setState({ spinning: true });
      const re = await saveVTImage(obj);
      if (!re || (re && re.result !== 'OK')) throw new Error();
      this.setState({ spinning: false });
    } catch (error) {
      this.setState({ spinning: false });
      message.error('Error uploading image!', 3);
    }
    this.init();
  };

  handleImageClick = item => {
    const { type } = this.props;
    if (type === '8') {
      const { selectedSliderImages } = this.state;
      selectedSliderImages.indexOf(item.ImageName) > -1 &&
        this.setState({
          selectedSliderImages: selectedSliderImages.filter(i => i !== item.ImageName),
        });
      selectedSliderImages.indexOf(item.ImageName) < 0 &&
        this.setState({ selectedSliderImages: [...selectedSliderImages, item.ImageName] });
    } else {
      this.props.onImageClick(type, item);
    }
  };

  handleImageRemove = async (e, item) => {
    e.stopPropagation();
    const { type } = this.props;
    const exObj = {};
    exObj.Code = item.ImageName;
    exObj.Type = type;
    try {
      this.setState({ spinning: true });
      const re = await deleteVTImage(exObj);
      if (!re || (re && re.result !== 'OK')) throw new Error();
      this.setState({ spinning: false });
      message.success('Image successfully deleted!', 3);
    } catch (error) {
      this.setState({ spinning: false });
      message.error('Error deleting image!', 3);
    }
    this.init();
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { type } = this.props;
    if (type !== prevProps.type) {
      this.init();
    }
  }

  render() {
    const { spinning, error, data, selectedSliderImages } = this.state;

    //Use item to check if it is default or custom setting
    const { type, currImage } = this.props || '';

    const title = this.getTitle(type);

    return (
      <Spin spinning={spinning}>
        <Row className="admin-image-manager-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title={`${title} Images`}
              showLeftBtn={true}
              leftBtnText="Back"
              onLeftBtnClick={this.handleBackBtn}
              showRightBtn={false}
              // rightBtnText="Save"
              // onRightBtnClick={this.handleSaveBtn}
            />
            {/* Error Row */}
            <Row
              style={{
                padding: 15,
                height: 30,
                fontWeight: 'bold',
                color: '#8b0000',
                fontSize: 18,
                marginBottom: 20,
              }}
              justify="center"
            >
              <Col>
                <SwitchTransition>
                  <CSSTransition key={error} timeout={500} classNames="login-account-error">
                    <Row>{error}</Row>
                  </CSSTransition>
                </SwitchTransition>
              </Col>
            </Row>
            {/* ImageLoader Row */}
            <Row justify="center">
              <ImageUploader
                onImgUploadClick={imgFile => {
                  this.handleUploadImg(imgFile);
                }}
              />
            </Row>
            <Divider style={{ borderColor: 'transparent' }} />
            {/* Content Row */}
            <Row>
              {data &&
                data.map((i, index) => (
                  <div
                    key={i.key}
                    className="admin-image-manager-img-wrapper"
                    onClick={() => this.handleImageClick(i)}
                  >
                    <Image
                      width={300}
                      height={200}
                      src={this.getImage(i.ImageName)}
                      preview={false}
                    />
                    <div className="admin-image-manager-img-checkbox-wrapper">
                      <Checkbox
                        // onChange={e => this.handleSelectedChange(e, i)}
                        checked={
                          type === '8'
                            ? selectedSliderImages.indexOf(i.ImageName) > -1
                            : currImage === i.ImageName
                        }
                      />
                    </div>
                    {type === '8' ? (
                      selectedSliderImages.indexOf(i.ImageName) < 0 ? (
                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                          <Button
                            width={75}
                            height={35}
                            style={{ backgroundColor: 'var(--adminSecondary)' }}
                            textStyle={{ color: '#fff' }}
                            borderRadius={3}
                            onClick={e => this.handleImageRemove(e, i)}
                          >
                            Remove
                          </Button>
                        </div>
                      ) : null
                    ) : currImage !== i.ImageName ? (
                      <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        <Button
                          width={75}
                          height={35}
                          style={{ backgroundColor: 'var(--adminSecondary)' }}
                          textStyle={{ color: '#fff' }}
                          borderRadius={5}
                          onClick={e => this.handleImageRemove(e, i)}
                        >
                          Remove
                        </Button>
                      </div>
                    ) : null}
                  </div>
                ))}
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}
