import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { updateOrder, delOrderItem } from '../../redux/actions';
import OrderRow from '../OrderRow';
import './index.css';

class OrderItemsPaneAlt extends Component {
  state = { seed: false };
  ref = React.createRef();

  handleRemoveOrderRow = key => {
    this.props.delOrderItem(key);
  };

  componentDidMount() {
    this.setState({ seed: !this.state.seed });
    this.ref.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { seed } = this.state;
    const { order } = this.props;
    if (order.length > prevProps.order.length || seed !== prevState.seed) {
      this.ref.current?.lastElementChild?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }

  render() {
    const { order } = this.props;
    return (
      <Row
        justify="end"
        className="order-list-pane"
        style={{ height: window.innerHeight - 85, overflow: 'scroll' }}
      >
        <Col span={22}>
          <ul ref={this.ref}>
            <TransitionGroup enter={true} exit={true} appear={true}>
              {order.map(i => {
                return (
                  <CSSTransition key={i.key} timeout={500} classNames="order-list-item">
                    <li style={{ width: '100%' }}>
                      <OrderRow
                        key={i.key}
                        height={50}
                        item={i}
                        editable={true}
                        editablePlus={true}
                        onDelete={() => this.handleRemoveOrderRow(i.key)}
                        onOrderItemChange={data => {
                          this.props.updateOrder(data);
                        }}
                      />
                    </li>
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </ul>
        </Col>
      </Row>
    );
  }
}

export default connect(state => ({ order: state.order }), {
  updateOrder,
  delOrderItem,
})(OrderItemsPaneAlt);
