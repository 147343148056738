import { Form, Input, Table, message } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.css';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      for (const key in values) {
        values[key] = values[key].toString().trim();
      }
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      message.warning('Field not saved properly. Please check it again!');
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        // rules={[
        //   {
        //     pattern: _pattern,
        //     message: _message,
        //   },
        // ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} spellCheck={false} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

//props: tableDataSource, tableColumns, size, rowKey, rowSelection, pagination
const EditableTable = props => {
  const [dataSource, setDataSource] = useState(props.tableDataSource);
  const handleSave = row => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
    props.getUpdatedData(newData);
  };

  useEffect(() => {
    setDataSource(props.tableDataSource);
  }, [props.tableDataSource]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = props.tableColumns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <Table
      {...props}
      components={components}
      size={props.size}
      rowKey={props.rowKey}
      rowSelection={props.rowSelection}
      scroll={props.scroll}
      bordered
      pagination={props.pagination}
      dataSource={dataSource}
      columns={columns}
    />
  );
};

export default EditableTable;
