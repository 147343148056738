import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import TextTransition, { presets } from 'react-text-transition';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp, faX, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import MenuPizzaToppingsGrouper from '../MenuPizzaToppingsGrouper';
import Button from '../Button';
import ScanLoadMask from '../ScanLoadMask';
import Modal from '../Modal';

import './index.css';

class MenuPizzaModal extends Component {
  state = { item: {}, pizzaDetails: [], showArrow: false, flag: true };

  refModalLeftList = React.createRef();

  init = () => {
    const { item, sizeCode } = this.props || {};
    // const itemObj = JSON.parse(JSON.stringify(item));
    if (item) {
      const { ForcedToppingList, SizeItemInfoList } = item;
      let GroupValueList;
      if (SizeItemInfoList && sizeCode) {
        const size = SizeItemInfoList.find(j => j.SizeCode === sizeCode);
        GroupValueList = size.GroupValueList;
      }

      if (ForcedToppingList && ForcedToppingList.length > 0) {
        const arr = [];
        ForcedToppingList.forEach(i => {
          let mod;
          if (GroupValueList) {
            mod = GroupValueList.find(k => k.Code === i.GroupCode);
          }
          const pizzaDetail = {};
          pizzaDetail.code = i.Code;
          pizzaDetail.description = i.Description;
          pizzaDetail.price = (mod && mod.Price) || 0;
          pizzaDetail.qty = i.Qty;
          pizzaDetail.error = i.Error;
          pizzaDetail.errorMessage = '';
          pizzaDetail.dependencyCargo = {};
          pizzaDetail.index = 1;
          pizzaDetail.isUsed = 0;
          pizzaDetail.itemGroup = '';
          pizzaDetail.isPizzaItem = false;
          pizzaDetail.itemPoints = (mod && mod.Qty.toString()) || '';
          pizzaDetail.itemModified = false;
          arr.push(pizzaDetail);
        });
        this.setState({ pizzaDetails: arr }, () => {
          this.props.addPizzaDetails(this.state.pizzaDetails, 0);
        });
      }
      this.setState({ item: item, flag: true });
    }
  };

  handlePizzaModalCancel = () => {
    const { showArrow } = this.state;
    if (showArrow) {
      this.setState({ showArrow: false });
    }
    this.setState({ pizzaDetails: [], item: {}, flag: true });
    this.props.onPizzaModalCancel();
  };

  handlePizzaModalRedo = () => {
    const { showArrow } = this.state;
    if (showArrow) {
      this.setState({ showArrow: false });
    }
    this.setState({ pizzaDetails: [], flag: true });
    this.props.onPizzaModalRedo();
  };

  arrowTimer = () => {
    setTimeout(() => {
      this.setState({ showArrow: false });
    }, 2000);
  };

  checkArrow = () => {
    const { pizzaDetails, flag } = this.state;
    if (pizzaDetails.length > 10 && flag === true) {
      this.setState({ showArrow: true, flag: false }, () => {
        this.arrowTimer();
      });
    }
  };

  //Item is one topping
  //Index is which part of the pizza enum
  /// Whole = 1,
  /// FirstHalf = 2,
  /// SecondHalf = 3,
  /// FirstQuarter = 4,
  /// SecondQuarter = 5,
  /// ThirdQuarter = 6,
  /// FourthQuarter = 7
  handleUpdatePizzaTopping = (item, index) => {
    const { item: mainItem, pizzaDetails } = this.state;
    const { sizeCode } = this.props;
    const { SizeItemInfoList } = mainItem;
    const size = SizeItemInfoList.find(i => i.SizeCode === sizeCode);
    const { GroupValueList } = size;
    const mod = GroupValueList.find(i => i.Code === item.GroupCode);
    if (item.Qty > 0) {
      const isNew = pizzaDetails.filter(i => i.code === item.Code).length === 0;
      if (isNew) {
        const pizzaDetail = {};
        pizzaDetail.code = item.Code;
        pizzaDetail.description = item.Description;
        pizzaDetail.price = mod.Price;
        pizzaDetail.qty = item.Qty;
        pizzaDetail.error = item.Error;
        pizzaDetail.errorMessage = '';
        pizzaDetail.dependencyCargo = {};
        pizzaDetail.index = index;
        pizzaDetail.isUsed = 0;
        pizzaDetail.itemGroup = '';
        pizzaDetail.isPizzaItem = false;
        pizzaDetail.itemPoints = mod.Qty.toString();
        pizzaDetail.itemModified = false;
        this.setState({ pizzaDetails: [...pizzaDetails, pizzaDetail] }, () => {
          this.props.addPizzaDetails(this.state.pizzaDetails, 0);
          this.checkArrow();
        });
      }
      if (!isNew) {
        const pizzi = pizzaDetails.find(i => i.code === item.Code) || {};
        pizzi.qty = item.Qty;
        pizzi.index = index;
        this.setState({ pizzaDetails }, () => {
          this.props.addPizzaDetails(this.state.pizzaDetails, 0);
          this.checkArrow();
        });
      }
    }
    if (item.Qty === 0) {
      const otherPizzis = pizzaDetails.filter(i => i.code !== item.Code);
      this.setState({ pizzaDetails: otherPizzis }, () => {
        this.props.addPizzaDetails(this.state.pizzaDetails, 0);
        this.checkArrow();
      });
    }
  };

  // handlePizzaSelectedListScroll = e => {
  //   if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
  //     this.setState({ showArrow: false });
  //   } else {
  //     this.setState({ showArrow: true });
  //   }
  // };

  handleOKBtn = () => {
    const { pizzaDetails } = this.state;
    this.props.addPizzaDetails(pizzaDetails, 1);
  };

  componentDidMount() {
    const { item } = this.props;
    if (item && item.Type === 'I') {
      this.init();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { item, sizeCode } = this.props;
    if (item && item.Type === 'I' && (item !== prevProps.item || sizeCode !== prevProps.sizeCode)) {
      this.init();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.arrowTimer);
  }

  render() {
    const { pizzaDetails, item, showArrow } = this.state;
    const {
      visible,
      checkItem,
      memoColor = 'white',
      pizzaTotalPrice,
      pizzaModalSpinning,
      clientStyle,
    } = this.props;
    const { ForcedToppingList, SizeItemInfoList, ToppingItemList, SizeItemNotPizza } = item || [];
    const { sizeCode } = checkItem;
    const size = (SizeItemInfoList && SizeItemInfoList.find(i => i.SizeCode === sizeCode)) || {};
    const { GroupValueList } = size;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };

    return (
      <Modal
        width={700}
        visible={visible}
        maskClosable={false}
        closable={false}
        title={null}
        footer={null}
        zIndex={1001}
        destroyOnClose={true}
        style={{ left: 100 }}
        onCloseClick={this.props.onPizzaModalCancel}
        showCloseBtn={true}
      >
        <Row style={{ position: 'relative' }}>
          {/* <ScanLoadMask visible={pizzaModalSpinning} /> */}
          <div className="menu-pizza-item-modal-redo-btn">
            <div
              className="menu-pizza-item-modal-redo-icon"
              onClick={this.handlePizzaModalRedo}
              style={{ backgroundColor: clientStyle.primary }}
            >
              <FontAwesomeIcon
                icon={faRotateLeft}
                size="2x"
                style={{ color: clientStyle.colorTwo }}
              />
            </div>
          </div>
          <Col span={24}>
            <Row className="menu-pizza-item-modal-left">
              <div className="menu-pizza-item-modal-left-content">
                <Row style={{ borderRadius: 5 }}>
                  <Col span={24}>
                    <Row
                      justify="center"
                      align="middle"
                      className="menu-pizza-item-modal-left-content-list-title"
                      style={{
                        ...rowStyle,
                        padding: '5px 0',
                      }}
                    >
                      <Col>
                        <Row>Summary</Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div
                  className="menu-pizza-item-modal-left-content-list"
                  onScroll={this.handlePizzaSelectedListScroll}
                  style={{ backgroundColor: clientStyle.bgColor }}
                >
                  <Row>
                    <Col>
                      <Row
                        style={{ ...titleStyle, fontWeight: 'bold', padding: 5, marginBottom: 5 }}
                      >
                        {checkItem.description}
                      </Row>
                    </Col>
                  </Row>
                  <Row ref={this.refModalLeftList}>
                    <Col span={24}>
                      <TransitionGroup enter={true} exit={true} appear={true}>
                        {pizzaDetails && pizzaDetails.length > 0 ? (
                          pizzaDetails.map(i => (
                            <CSSTransition
                              key={i.code}
                              timeout={500}
                              classNames="menu-pizza-selector-selected-item"
                            >
                              <Row
                                key={i.code}
                                align="middle"
                                style={{ ...titleStyle, padding: '2px 5px 2px 10px' }}
                                className="menu-pizza-selector-selected-item"
                              >
                                <Col span={21}>
                                  <Row>{i.description || ''}</Row>
                                </Col>
                                <Col span={3}>
                                  <Row justify="center">{i.qty || ''}</Row>
                                </Col>
                              </Row>
                            </CSSTransition>
                          ))
                        ) : (
                          <></>
                        )}
                      </TransitionGroup>
                    </Col>
                  </Row>
                  <Row
                    className="menu-pizza-item-modal-left-content-swipe-up"
                    style={{ display: showArrow ? 'block' : 'none' }}
                  >
                    <FontAwesomeIcon
                      icon={faAngleDoubleUp}
                      fontSize={50}
                      bounce
                      color={clientStyle.colorOne}
                    />
                  </Row>
                </div>
              </div>
            </Row>
            <Row
              justify="space-between"
              align="middle"
              className="menu-item-pizza-modal-sticky-header"
              style={{ backgroundColor: clientStyle.bgColor }}
            >
              <Col span={24}>
                <Row align="middle" style={{ ...titleStyle }}>
                  <Col span={5}>
                    <Row justify="center">
                      <TextTransition
                        inline={true}
                        delay={200}
                        springConfig={presets.default}
                        style={{ fontSize: 18, fontWeight: 'bold' }}
                      >
                        {`$${pizzaTotalPrice.toFixed(2)}`}
                      </TextTransition>
                    </Row>
                  </Col>
                  <Col span={14}>
                    <Row justify="center">
                      <div style={{ fontWeight: 'bold', fontSize: 18 }}>
                        {checkItem.description || ''}
                      </div>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <Row justify="end">
                      <Button
                        width={120}
                        height={50}
                        style={{
                          fontSize: 18,
                          ...rowStyle,
                        }}
                        borderRadius={5}
                        onClick={() => {
                          this.handleOKBtn();
                        }}
                      >
                        OK
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {ForcedToppingList && ForcedToppingList.length > 0 && (
                  <MenuPizzaToppingsGrouper
                    key="ForcedToppingList"
                    data={ForcedToppingList}
                    isPizza={SizeItemNotPizza === 0}
                    title={
                      ForcedToppingList && ForcedToppingList.length > 1
                        ? 'INCLUDED TOPPINGS'
                        : 'INCLUDED TOPPING'
                    }
                    priceInfo=""
                    updatePizzaTopping={(item, index) => {
                      this.handleUpdatePizzaTopping(item, index);
                    }}
                  />
                )}
                {GroupValueList &&
                  GroupValueList.length > 0 &&
                  GroupValueList.map(i => (
                    <MenuPizzaToppingsGrouper
                      key={i.Code}
                      color="black"
                      data={ToppingItemList && ToppingItemList.filter(j => j.GroupCode === i.Code)}
                      isPizza={SizeItemNotPizza === 0}
                      title={i.Description}
                      priceInfo={i.Price}
                      updatePizzaTopping={(item, index) => {
                        this.handleUpdatePizzaTopping(item, index);
                      }}
                    />
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(MenuPizzaModal);
