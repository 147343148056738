import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartSimple,
  faLock,
  faCalendarCheck,
  faClockRotateLeft,
  faGear,
  faTableList,
} from '@fortawesome/free-solid-svg-icons';

const adminMenu = [
  {
    title: 'Dashboard',
    key: '/admin/dashboard',
    icon: <FontAwesomeIcon icon={faChartSimple} style={{ marginRight: 8 }} />,
    isPublic: true,
  },
  {
    title: 'Password',
    key: '/admin/password',
    icon: <FontAwesomeIcon icon={faLock} style={{ marginRight: 8 }} />,
    isPublic: true,
  },
  {
    title: 'My Reservations',
    key: '/admin/res',
    icon: <FontAwesomeIcon icon={faCalendarCheck} style={{ marginRight: 8 }} />,
    isPublic: true,
  },
  {
    title: 'Statements',
    key: '/admin/statements',
    icon: <FontAwesomeIcon icon={faTableList} style={{ marginRight: 8 }} />,
    isPublic: true,
  },
  {
    title: 'Order History',
    key: '/admin/orderhistory',
    icon: <FontAwesomeIcon icon={faClockRotateLeft} style={{ marginRight: 8 }} />,
    isPublic: true,
  },
  {
    title: 'Settings',
    key: '/settings',
    icon: <FontAwesomeIcon icon={faGear} style={{ marginRight: 8 }} />,
    isPublic: true,
    children: [
      {
        title: 'POS',
        key: '/admin/pos',
        isPublic: true,
      },
      {
        title: 'Kiosk Configurations',
        key: '/admin/kiosk',
        isPublic: true,
      },
      {
        title: 'Kiosk Config. Mappings',
        key: '/admin/kioskmappings',
        isPublic: true,
      },
      {
        title: 'View Editor',
        key: '/admin/vieweditor',
        isPublic: true,
      },
      {
        title: 'Users',
        key: '/admin/user',
        isPublic: true,
      },
      {
        title: 'Order Option Mappings',
        key: '/admin/orderoptionmappings',
        isPublic: true,
      },
      {
        title: 'Reservation Mappings',
        key: '/admin/resmappings',
        isPublic: true,
      },
      {
        title: 'Reservation Settings',
        key: '/admin/ressettings',
        isPublic: true,
      },
    ],
  },
];

export default adminMenu;
