import React, { Component } from 'react';
import { connect } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { Row, Col, Divider, Input, message } from 'antd';
import Keyboard from 'react-simple-keyboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleExclamation,
  faBookOpen,
  faX,
  faCartShopping,
} from '@fortawesome/free-solid-svg-icons';
import {
  getVTImageFolder,
  getItemInformationByBarcode,
  getOrderSummary,
  setOrderSetup,
} from '../../api';
import {
  updateScannedOrder,
  deleteScannedOrderItem,
  clearScannedOrderItems,
  clearOrderItems,
  resetUser,
  deleteGuestName,
  resetCustomerOrderOption,
  resetLoginType,
  resetAppMode,
  receiveScannedOrder,
  deleteGuestPhone,
  deleteGuestIns,
} from '../../redux/actions';
import { withIdleTimer } from 'react-idle-timer';
import ScanStartMask from '../../components/ScanStartMask';
import ScanLoadMask from '../../components/ScanLoadMask';
import ScanSearch from '../../components/ScanSearch';
import ScanLeftList from '../../components/ScanLeftList';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import ErrorModal from '../../components/ErrorModal';
import './index.css';

class Scan extends Component {
  state = {
    spinning: false,
    startMaskVisible: true,
    loadMaskVisible: false,
    logoImageDir: '',
    rowEditable: true,
    rowEditPlus: false,
    employeeLoginModalVisible: false,
    showEmployeeLogin: false,
    employeeLoggedIn: false,
    checkOutFailureModalVisible: false,
    checkOutErrorMsg: '',
    showKeyInCode: false,
    input: '',
    layout: 'default',
    scanSearchVisible: false,
    getFocus: false,
    cancelConfirmModalVisible: false,
  };

  ref = React.createRef();
  keyInCodeInput = React.createRef();
  loginRef = React.createRef();

  keyboardOptions = {
    ...this.commonKeyboardOptions,
    layout: {
      default: ['1 2 3', '4 5 6', '7 8 9', '{shift} 0 {bksp}'],
      shift: ['Q W E R T', 'Y U I O P', 'A S D F G', 'H J K L M', 'Z X C V N', '{shift} B {bksp}'],
    },
  };

  init = async () => {
    const { customerOrderOption, guestName } = this.props;
    const { AccountNumber } = this.props.user?.CurAccountInfo ?? '';
    const obj = {};
    obj.AccountNumber = AccountNumber ?? '';
    obj.ProfitCentreCode = customerOrderOption?.orderOption?.CentreCode ?? '';
    obj.RequestedDate = customerOrderOption?.date?.DateShort ?? '';
    obj.RequestedTime = customerOrderOption?.timeslot?.Time ?? '';
    obj.GuestName = !guestName
      ? ''
      : guestName && guestName.length > 15
      ? guestName.substring(0, 15)
      : guestName;
    this.setState({ spinning: true });
    await Promise.all([getVTImageFolder('6'), setOrderSetup(obj)])
      .then(results => {
        if (results[0].result !== 'OK') throw new Error(results[0].result);
        if (results[1] !== 'OK') throw new Error(results[1]);
        this.setState({
          logoImageDir: results[0].data,
          spinning: false,
        });
      })
      .catch(error => {
        this.setState({ spinning: false });
        message.error('Error obtaining settings!', 3);
      });
  };

  handleStartBtn = () => {
    this.setState({ startMaskVisible: false }, () => {
      this.ref.current.focus();
    });
  };

  handleEnterKeyDown = e => {
    if (e.key === 'Enter') {
      if (e.target.value.trim() === '') return;
      this.getScannedItem(e.target.value.trim());
      e.target.value = '';
    }
  };

  handleLoginEnterKeyDown = e => {
    if (e.key === 'Enter') {
      if (e.target.value.trim() === '') return;
      e.target.value = '';
      setTimeout(() => {
        this.setState({
          employeeLoginModalVisible: false,
          employeeLoggedIn: true,
          showEmployeeLogin: false,
        });
        this.ref.current.focus();
        this.handleLoadMask();
      }, 2000);
    }
  };

  handleLoadMask = () => {
    const { loadMaskVisible } = this.state;
    if (loadMaskVisible) {
      clearTimeout(this.loadMaskTimer);
      this.loadMaskTimer();
    }
    if (!loadMaskVisible) {
      this.setState({ loadMaskVisible: true });
      this.loadMaskTimer();
    }
  };

  loadMaskTimer = () => {
    setTimeout(() => {
      this.setState({ loadMaskVisible: false });
    }, 1000);
  };

  getScannedItem = async str => {
    try {
      const re = await getItemInformationByBarcode(str);
      if (!re || (re && re.result !== 'OK')) throw new Error();
      const { data } = re;
      const checkItem = {};
      checkItem.key = nanoid();
      checkItem.code = data.Code;
      checkItem.description = data.Description;
      checkItem.price = data.Price;
      checkItem.qty = 1;
      checkItem.type = data.Type;
      checkItem.sizeCode = '';
      checkItem.modifierDetails = [];
      checkItem.packageDetails = [];
      checkItem.packageType = data.PackageType.toString();
      checkItem.pizzaDetails = [];
      this.props.updateScannedOrder(checkItem);
    } catch (error) {
      message.error('Invalid barcode or barcode not found', 3);
    }
  };

  handleSearchBtn = () => {
    this.setState({ scanSearchVisible: true, getFocus: !this.state.getFocus });
  };

  handleKeyInCodeBtn = () => {
    this.setState({ showKeyInCode: true });
  };

  handleHelpBtn = () => {
    const { employeeLoggedIn } = this.state;
    if (employeeLoggedIn) return;
    this.setState({ employeeLoginModalVisible: true }, () => {
      this.props.pause();
    });
  };

  handleCheckoutBtn = async () => {
    const { employeeLoggedIn } = this.state;
    if (employeeLoggedIn) return;
    const {
      order,
      kioskSetting: {
        appSetting: { ScanItemMode },
      },
    } = this.props;
    if (ScanItemMode !== 1) {
      this.props.receiveScannedOrder(order);
      this.props.clearScannedOrderItems();
      this.props.history.replace('/menu');
      return;
    }
    if (!order || order.length === 0) return;
    if (order && order.length > 0) {
      const checkObj = {};
      checkObj.CheckNumber = -1;
      checkObj.Date = '';
      checkObj.EmpCode = '';
      checkObj.Shift = 1;
      checkObj.Time = '';
      checkObj.TableNum = '';
      checkObj.CustomerInfo = {};
      checkObj.Items = order;
      checkObj.Adjustments = [];
      checkObj.PaymentInfos = [];
      checkObj.OrderSummary = {};
      checkObj.Error = null;
      checkObj.ErrorText = '';

      const re = await getOrderSummary(checkObj);
      if (re && re.Error === true && re.ErrorText) {
        this.setState({ checkOutErrorMsg: re.ErrorText, checkOutFailureModalVisible: true });
      } else if (re && re.Error === false && re.ErrorText === '') {
        this.props.history.push({
          pathname: '/order',
          state: { checkSum: re, isScanned: true },
        });
      }
    }
  };

  handleCheckOutFailModalOK = () => {
    this.setState({ checkOutFailureModalVisible: false });
  };

  handleEmployeeLoginModalClose = () => {
    this.setState({ employeeLoginModalVisible: false, showEmployeeLogin: false }, () => {
      this.ref.current.focus();
    });

    this.props.resume();
  };

  handleEmployeeLoginBtn = () => {
    this.setState({ showEmployeeLogin: true });
  };

  handleEditBtn = () => {
    this.setState({ rowEditable: true });
  };

  handleExitBtn = () => {
    this.setState({ rowEditable: false, employeeLoggedIn: false });
    this.props.resume();
    this.handleLoadMask();
  };

  handleKeyInCodeClose = () => {
    this.setState({ input: '', showKeyInCode: false }, () => {
      this.ref.current.focus();
    });
  };

  handleKeyInCodeInputChange = ({ target: { value } }) => {
    this.setState({ input: value });
    this.keyboard && this.keyboard.setInput(value);
  };

  onKeyInCodeKeyboardChange = input => {
    this.setState({ input });
  };

  handleKeyInCodeClear = () => {
    this.setState({ input: '' });
    this.keyboard && this.keyboard.setInput('');
  };

  handleKeyInCodeOK = () => {
    const { input } = this.state;
    if (input.trim() === '') return;
    this.getScannedItem(input.trim());
    this.setState({ input: '' });
    this.keyboard && this.keyboard.setInput('');
  };

  onKeyInCodeKeyPress = button => {
    if (button === '{shift}') {
      const { layout } = this.state;
      this.setState({ layout: layout === 'default' ? 'shift' : 'default' });
    }
  };

  onKeyInCodeKeyReleased = button => {};

  handleKeyInCodeEnterKeyDown = e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      this.handleKeyInCodeOK();
    }
  };

  handleSearchClose = () => {
    this.setState({ scanSearchVisible: false }, () => {
      this.ref.current.focus();
    });
  };

  handleSearchSelection = item => {
    const checkItem = {};
    checkItem.key = nanoid();
    checkItem.code = item.id;
    checkItem.description = item.description;
    checkItem.price = item.price;
    checkItem.qty = 1;
    checkItem.type = item.Type || null;
    checkItem.sizeCode = '';
    checkItem.modifierDetails = [];
    checkItem.packageDetails = [];
    checkItem.packageType = item.PackageType?.toString() || '';
    checkItem.pizzaDetails = [];
    this.props.updateScannedOrder(checkItem);
  };

  onCancelBtnClick = () => {
    this.setState({ cancelConfirmModalVisible: true });
  };

  handleScanCancelModalCancel = () => {
    this.setState({ cancelConfirmModalVisible: false });
  };

  handleScanClearCart = () => {
    this.setState({ cancelConfirmModalVisible: false });
    this.props.clearScannedOrderItems();
  };

  // handleScanGoToDashboard = () => {
  //   this.setState({ cancelConfirmModalVisible: false });
  //   this.props.clearScannedOrderItems();
  //   this.props.history.replace('/admin/dashboard');
  // };

  handleMenuGoToOrderOption = () => {
    this.setState({ cancelConfirmModalVisible: false });
    this.props.clearScannedOrderItems();
    this.props.history.replace('/orderOptions');
  };

  handleScanGoToMenu = () => {
    this.setState({ cancelConfirmModalVisible: false });
    this.props.clearScannedOrderItems();
    this.props.history.replace('/menu');
  };

  handleScanLogOut = () => {
    this.setState({ cancelConfirmModalVisible: false });
    this.props.clearOrderItems();
    this.props.clearScannedOrderItems();
    this.props.resetUser();
    this.props.deleteGuestName();
    this.props.deleteGuestPhone();
    this.props.deleteGuestIns();
    this.props.resetCustomerOrderOption();
    this.props.resetLoginType();
    this.props.resetAppMode();
    this.props.history.push('/');
  };

  componentDidMount = () => {
    this.init();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isIdle()) {
      this.onIdleTimerFinish();
    } else {
      this.props.reset();
    }
  }

  render() {
    const {
      startMaskVisible,
      loadMaskVisible,
      logoImageDir,
      rowEditable,
      rowEditPlus,
      employeeLoginModalVisible,
      showEmployeeLogin,
      employeeLoggedIn,
      checkOutFailureModalVisible,
      checkOutErrorMsg,
      showKeyInCode,
      input,
      layout,
      scanSearchVisible,
      getFocus,
      cancelConfirmModalVisible,
    } = this.state;
    const { kioskSetting, order, user, clientStyle } = this.props;
    const {
      imgSetting: { Logo },
      appSetting: { ScanItemMode, EnableOrderOptionMapping },
    } = kioskSetting;

    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };

    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {/* <ScanStartMask visible={startMaskVisible} onStartBtn={this.handleStartBtn} /> */}
        {/* {<ScanLoadMask visible={loadMaskVisible} />} */}
        <ScanSearch
          visible={scanSearchVisible}
          onScanSearchClose={this.handleSearchClose}
          getFocus={getFocus}
          onSelectItem={item => {
            this.handleSearchSelection(item);
          }}
        />
        <Row style={{ position: 'relative' }}>
          <Col span={window.innerWidth >= 1200 ? 12 : 24}>
            <div className="scan-instruction-row">
              <h2 style={{ ...titleStyle, fontSize: 40 }}>Please Scan Your Items</h2>
            </div>
            <Row
              justify="center"
              style={{ width: '100%', height: '100vh', background: clientStyle.bgColor }}
            >
              <Col span={23}>
                <ScanLeftList
                  logoImageDir={logoImageDir}
                  logoImageName={Logo}
                  rowEditable={rowEditable}
                  rowEditPlus={rowEditPlus}
                  onCompleteScanningBtn={this.handleCheckoutBtn}
                />
              </Col>
            </Row>
          </Col>
          {window.innerWidth >= 1200 ? (
            <Col span={12}>
              <div
                style={{
                  position: 'relative',
                  backgroundColor: clientStyle.bgColor,
                  height: '100vh',
                }}
              >
                {/* Instructions row */}
                <Row justify="center" style={{ height: '20vh' }}>
                  <Col span={24}>
                    <Row align="middle" style={{ padding: 5 }}>
                      <Col span={3}></Col>
                      <Col span={18}></Col>
                      <Col span={3}>
                        <Row justify="center">
                          <div
                            className="scan-page-close-btn"
                            style={{ ...rowStyle }}
                            onClick={this.onCancelBtnClick}
                            role="button"
                          >
                            <FontAwesomeIcon icon={faX} size="3x" />
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* Function Buttons Row */}
                <Row style={{ padding: '0 5px', marginTop: 10, height: '25vh' }}>
                  {/* <Button
                  width={200}
                  height={70}
                  style={{ backgroundColor: 'var(--primary)', margin: '0 10px 10px 10px' }}
                  textStyle={{ fontSize: 18, color: 'white' }}
                  borderRadius={5}
                  icon="search"
                  iconSize="2xl"
                  onClick={this.handleSearchBtn}
                >
                  Look Up Item
                </Button> */}
                  {/* <Button
                  width={200}
                  height={70}
                  style={{ backgroundColor: 'var(--primary)', margin: '0 10px 10px 10px' }}
                  borderRadius={5}
                  textStyle={{ fontSize: 18, color: 'white' }}
                  icon="keyboard"
                  iconSize="2xl"
                  onClick={this.handleKeyInCodeBtn}
                >
                  Key In Code
                </Button> */}
                  {/* <Button
                  width={200}
                  height={70}
                  style={{ backgroundColor: 'var(--adminSecondary)', margin: '0 10px 10px 10px' }}
                  borderRadius={5}
                  textStyle={{ fontSize: 18, color: 'white' }}
                  icon="info"
                  iconSize="2xl"
                  onClick={this.handleHelpBtn}
                >
                  Need Help
                </Button> */}
                </Row>
                {/* Employee Only Buttons Row */}
                <Row style={{ padding: '0 5px', marginTop: 10, height: '25vh' }}>
                  {/* {employeeLoggedIn ? (
                  <>
                    <Button
                      width={200}
                      height={70}
                      style={{ backgroundColor: 'var(--adminPrimary)', margin: '0 10px 10px 10px' }}
                      textStyle={{ fontSize: 18, color: 'white' }}
                      borderRadius={5}
                      icon="edit"
                      iconSize="2xl"
                      onClick={this.handleEditBtn}
                    >
                      Edit Items
                    </Button>
                    <Button
                      width={200}
                      height={70}
                      style={{ backgroundColor: 'var(--adminPrimary)', margin: '0 10px 10px 10px' }}
                      textStyle={{ fontSize: 18, color: 'white' }}
                      borderRadius={5}
                      icon="warn"
                      iconSize="2xl"
                      onClick={this.handleExitBtn}
                    >
                      Save & Exit
                    </Button>
                  </>
                ) : null} */}
                </Row>
                {/* Checkout Button Row */}
                <Row
                  style={{
                    height: '25vh',
                    padding: '0 15px',
                    marginTop: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    width={500}
                    height={150}
                    style={{ ...rowStyle }}
                    textStyle={{ fontSize: 28 }}
                    borderRadius={5}
                    icon="check"
                    iconSize="4x"
                    onClick={this.handleCheckoutBtn}
                  >
                    {ScanItemMode === 1 ? 'Proceed to Checkout' : 'Add Scanned to Cart'}
                  </Button>
                </Row>
                {showKeyInCode ? (
                  <div className="scan-right-key-in-code-wrapper">
                    <Row style={{ position: 'relative', height: '100vh' }}>
                      <Col span={24}>
                        {/* Close Btn row */}
                        <Row justify="end">
                          <Button
                            width={120}
                            height={50}
                            style={{ margin: 5 }}
                            textStyle={{ fontSize: 18 }}
                            borderRadius={5}
                            onClick={this.handleKeyInCodeClose}
                          >
                            Close
                          </Button>
                        </Row>
                        {/* Input row */}
                        <Divider style={{ borderColor: 'transparent' }} />
                        <Row justify="center">
                          <Input
                            ref={this.keyInCodeInput}
                            autoFocus={true}
                            size="large"
                            placeholder="Enter Barcode"
                            spellCheck={false}
                            value={input}
                            onChange={this.handleKeyInCodeInputChange}
                            onKeyUp={this.handleKeyInCodeEnterKeyDown}
                            onBlur={() => this.keyInCodeInput.current.focus()}
                            disabled={!showKeyInCode}
                            style={{ width: 450 }}
                          />
                        </Row>
                        <Divider style={{ borderColor: 'transparent' }} />
                        {/* Btns row */}
                        <Row style={{ padding: 20 }} justify="space-between">
                          <Button
                            width={120}
                            height={50}
                            textStyle={{ fontSize: 18 }}
                            borderRadius={5}
                            onClick={this.handleKeyInCodeClear}
                          >
                            Clear
                          </Button>
                          <Button
                            width={120}
                            height={50}
                            style={{ backgroundColor: 'var(--primary)' }}
                            textStyle={{ fontSize: 18, color: 'white' }}
                            borderRadius={5}
                            onClick={this.handleKeyInCodeOK}
                          >
                            OK
                          </Button>
                        </Row>
                        <Row
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                          }}
                        >
                          <Keyboard
                            baseClass={'simple-keyboard-main'}
                            keyboardRef={r => (this.keyboard = r)}
                            layoutName={layout}
                            onChange={this.onKeyInCodeKeyboardChange}
                            onKeyPress={this.onKeyInCodeKeyPress}
                            onKeyReleased={this.onKeyInCodeKeyReleased}
                            theme={'hg-theme-default hg-layout-default btm-border-16'}
                            preventMouseDownDefault={true}
                            {...this.keyboardOptions}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </div>
                ) : null}
              </div>
            </Col>
          ) : null}
        </Row>
        {/* Page Control button @screen < 1200 */}
        {window.innerWidth >= 1200 ? null : (
          <div className="scan-page-control-buttons-wrap">
            <div className="scan-page-control-container">
              <div
                className="scan-page-control-button"
                style={{ ...rowStyle }}
                onClick={this.onCancelBtnClick}
                role="button"
              >
                <FontAwesomeIcon icon={faX} size="3x" />
              </div>
              {/* <div
                className="scan-page-control-button"
                style={{ ...rowStyle }}
                onClick={this.handleCheckoutBtn}
                role="button"
              >
                <FontAwesomeIcon icon={faBookOpen} size="3x" />
              </div> */}
            </div>
          </div>
        )}

        {/* Page hidden input to take scan code */}
        <input
          ref={this.ref}
          className="scan-barcode-input"
          autoFocus={true}
          onKeyDown={e => {
            this.handleEnterKeyDown(e);
          }}
          onBlur={() => this.ref.current.focus()}
          disabled={
            showKeyInCode === true || scanSearchVisible === true || showEmployeeLogin === true
          }
        />
        {/* Employee Login Modal */}
        {/* <Modal
          visible={employeeLoginModalVisible}
          width={650}
          maskClosable={true}
          closable={false}
          title={null}
          footer={null}
          destroyOnClose={true}
          onCancel={this.handleEmployeeLoginModalClose}
          onCloseClick={this.handleEmployeeLoginModalClose}
          showCloseBtn={true}
        >
          <div style={{ position: 'relative' }}>
            <Row>
              <Col span={24}>
                <Row justify="center">
                  <h1>Your help is on the way...</h1>
                </Row>
                <Row
                  style={{ width: '100%', margin: '10px 0', height: 300 }}
                  justify="center"
                  align="middle"
                >
                  <Button
                    width={300}
                    height={80}
                    icon="account"
                    iconSize="2xl"
                    style={{ backgroundColor: 'var(--primary)' }}
                    textStyle={{ fontSize: 22, color: 'white' }}
                    borderRadius={5}
                    onClick={this.handleEmployeeLoginBtn}
                  >
                    Employee Login
                  </Button>
                </Row>
              </Col>
            </Row>
            {showEmployeeLogin ? (
              <div className="scan-employee-login-modal-login-wrapper">
                <div className="scan-employee-login-modal-login-content">
                  <input
                    ref={this.loginRef}
                    className="scan-barcode-input"
                    autoFocus={true}
                    onKeyDown={e => {
                      this.handleLoginEnterKeyDown(e);
                    }}
                    onBlur={() => this.loginRef.current.focus()}
                    disabled={showKeyInCode === true || scanSearchVisible === true}
                  />
                  <Row justify="center">
                    <h1>Please tap or swipe your card to login</h1>
                  </Row>
                  <Row style={{ margin: '50px 0' }} justify="center">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/login/tapcard.png`}
                      alt={''}
                      height={200}
                    />
                  </Row>
                </div>
              </div>
            ) : null}
          </div>
        </Modal> */}
        {/* Check out failure modal */}
        <ErrorModal
          visible={checkOutFailureModalVisible}
          title="Order cannot be processed"
          msg={checkOutErrorMsg}
          onOK={this.handleCheckOutFailModalOK}
        />
        {/* Cancel Confirm Modal */}
        <Modal
          visible={cancelConfirmModalVisible}
          size="large"
          maskClosable={true}
          closable={false}
          title={null}
          footer={null}
          destroyOnClose={true}
          onCancel={this.handleScanCancelModalCancel}
          onCloseClick={this.handleScanCancelModalCancel}
          showCloseBtn={true}
        >
          <div style={{ margin: '20px 0 30px', position: 'relative' }}>
            <Row
              justify="center"
              align="middle"
              style={{ margin: '0 0 50px', fontSize: 18, fontWeight: 'bold' }}
            >
              <FontAwesomeIcon icon={faCircleExclamation} size="xl" color="#faad14" />
              <h3 style={{ marginLeft: 20, ...titleStyle }}>Please Select An Option</h3>
            </Row>
            {order.length > 0 ? (
              <Row justify="center" style={{ marginBottom: 20 }}>
                <Button
                  width={260}
                  height={60}
                  style={{ ...rowStyle }}
                  textStyle={{ fontSize: 18 }}
                  borderRadius={5}
                  icon="trash"
                  iconSize="2xl"
                  onClick={this.handleScanClearCart}
                >
                  Clear Scanned
                </Button>
              </Row>
            ) : null}
            {ScanItemMode !== 1 ? (
              <Row justify="center" style={{ marginBottom: 20 }}>
                <Button
                  width={260}
                  height={60}
                  style={{ ...rowStyle }}
                  textStyle={{ fontSize: 18 }}
                  borderRadius={5}
                  icon="menu"
                  iconSize="2xl"
                  onClick={this.handleScanGoToMenu}
                >
                  Back To Menu
                </Button>
              </Row>
            ) : null}
            {EnableOrderOptionMapping ? (
              <Row justify="center" style={{ marginBottom: 20 }}>
                <Button
                  width={260}
                  height={60}
                  style={{ ...rowStyle }}
                  textStyle={{ fontSize: 18 }}
                  borderRadius={5}
                  icon="calender"
                  iconSize="2xl"
                  onClick={this.handleMenuGoToOrderOption}
                >
                  Order Options
                </Button>
              </Row>
            ) : null}
            <Divider style={{ borderColor: 'transparent' }} />
            <Row justify="center">
              <Button
                width={260}
                height={60}
                style={{ ...rowStyle }}
                textStyle={{ fontSize: 18 }}
                borderRadius={5}
                icon="logout"
                iconSize="2xl"
                onClick={this.handleScanLogOut}
              >
                Cancel Order
              </Button>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(
  state => ({
    kioskSetting: state.kioskSetting,
    order: state.scannedOrder,
    user: state.user,
    guestName: state.guestName,
    customerOrderOption: state.customerOrderOption,
    clientStyle: state.clientStyle,
  }),
  {
    updateScannedOrder,
    clearScannedOrderItems,
    deleteScannedOrderItem,
    clearOrderItems,
    resetUser,
    deleteGuestName,
    resetCustomerOrderOption,
    resetLoginType,
    resetAppMode,
    receiveScannedOrder,
    deleteGuestPhone,
    deleteGuestIns,
  },
)(withIdleTimer(Scan));
