import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import OrderOptionDayParts from '../OrderOptionDayParts';
import OrderOptionTimeSlots from '../OrderOptionTimeSlots';

class ResTime extends Component {
  render() {
    const { dayPartChoice, timeSlotChoice, dayParts } = this.props;
    return (
      <Row style={{ padding: '5px 30px 20px' }}>
        <Col span={24}>
          <OrderOptionDayParts
            dayParts={dayParts}
            dayPartChoice={dayPartChoice}
            onDayPartChange={key => {
              this.props.onDayPartChange(key);
            }}
          />
          <OrderOptionTimeSlots
            timeSlotChoice={timeSlotChoice}
            slots={
              dayPartChoice && dayPartChoice.Times && dayPartChoice.Times.length > 0
                ? dayPartChoice.Times
                : []
            }
            onTimeSlotChange={key => this.props.onTimeSlotChange(key)}
          />
        </Col>
      </Row>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(ResTime);
