import React, { Component } from 'react';
import { Row, Col } from 'antd';
import './index.css';

export default class ScanStartMask extends Component {
  handleStartBtn = () => {
    this.props.onStartBtn();
  };
  render() {
    const { visible } = this.props;

    return (
      <div
        className="scan-start-mask-container"
        style={{
          display: visible ? 'flex' : 'none',
        }}
      >
        <div
          className="scan-start-mask-start-btn-wrapper"
          style={{
            background: 'var(--primary)',
          }}
          onClick={this.handleStartBtn}
        >
          <Row justify="center">
            <Col>
              <Row
                style={{
                  fontSize: 42,
                  fontWeight: 'bold',
                  color: 'white',
                }}
              >
                <i>Start</i>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
