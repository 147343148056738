import React, { Component } from 'react';
import { Row, Col, Input } from 'antd';
import { CompactPicker } from 'react-color';

class VSBackgroundColorPicker extends Component {
  state = {
    displayColorPicker: false,
    isColorOneFocused: false,
    color: '#D0021B',
  };

  ref = React.createRef();

  handleColorPalletChange = (color, event) => {
    this.setState({ color: color.hex });
  };

  handleColorOneFocus = ({ target }) => {
    target.select();
    this.setState({ displayColorPicker: true, isColorOneFocused: true });
  };

  handleChange = (color, event) => {
    this.setState({ color: color.hex });
    const { hex } = color;
    const { isColorOneFocused } = this.state;
    const { direction, colorStop1, colorStop2 } = this.props;
    const obj = {};
    if (isColorOneFocused) {
      obj.colorStop1 = hex;
      obj.colorStop2 = colorStop2.trim() ? colorStop2.trim() : hex;
      obj.direction = direction;
    } else {
      obj.colorStop1 = colorStop1;
      obj.colorStop2 = hex;
      obj.direction = direction;
    }
    this.props.onColorChange(obj);
    this.setState({ displayColorPicker: false });
  };

  handeColorOneInputChange = ({ target: { value } }) => {
    const { direction, colorStop2 } = this.props;
    const obj = {};
    obj.colorStop1 = value.trim();
    obj.colorStop2 = colorStop2;
    obj.direction = direction.trim() ? direction : '0';
    this.props.onColorChange(obj);
  };

  handleColorTwoFocus = ({ target }) => {
    target.select();
    this.setState({ displayColorPicker: true, isColorOneFocused: false });
  };

  handeColorDirectionChange = ({ target: { value } }) => {
    const { colorStop1, colorStop2 } = this.props;
    const obj = {};
    obj.colorStop1 = colorStop1;
    obj.colorStop2 = colorStop2;
    obj.direction = value.trim();
    this.props.onColorChange(obj);
  };

  handeColorTwoInputChange = ({ target: { value } }) => {
    const { direction, colorStop1 } = this.props;
    const obj = {};
    obj.colorStop1 = colorStop1;
    obj.colorStop2 = value.trim();
    obj.direction = direction.trim() ? direction : '0';
    this.props.onColorChange(obj);
  };

  handleClickOutside = e => {
    if (this.state.displayColorPicker) {
      if (
        !(e.target.nodeName === 'INPUT' && !e.target.id) &&
        !(this.ref && this.ref.current.contains(e.target))
      ) {
        this.setState({ displayColorPicker: false });
      }
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    //singleColor boolean
    const { direction, colorStop1, colorStop2, singleColor = false } = this.props;
    return (
      <Row gutter={16}>
        <Col>
          <Row style={{ width: 200, margin: '0 10px 10px 0' }}>
            <Input
              value={colorStop1}
              spellCheck={false}
              addonBefore={singleColor ? 'Color' : 'Color One'}
              onFocus={this.handleColorOneFocus}
              onChange={this.handeColorOneInputChange}
            />
          </Row>
        </Col>
        {!singleColor ? (
          <>
            <Col>
              <Row style={{ width: 200, margin: '0 10px 10px 0' }}>
                <Input
                  value={colorStop2}
                  spellCheck={false}
                  addonBefore="Color Two"
                  onFocus={this.handleColorTwoFocus}
                  onChange={this.handeColorTwoInputChange}
                />
              </Row>
            </Col>
            <Col>
              <Row style={{ width: 200, margin: '0 10px 10px 0' }}>
                <Input
                  id="vs-bgc-picker-direction"
                  value={direction}
                  spellCheck={false}
                  addonBefore="Angle"
                  addonAfter="degrees"
                  onChange={this.handeColorDirectionChange}
                  onFocus={e => e.target.select()}
                />
              </Row>
            </Col>
          </>
        ) : null}

        {this.state.displayColorPicker ? (
          <div ref={this.ref}>
            <div onClick={this.handleClose} />
            <CompactPicker
              color={this.state.color}
              disableAlpha={true}
              onChange={this.handleColorPalletChange}
              onChangeComplete={this.handleChange}
            />
          </div>
        ) : null}
      </Row>
    );
  }
}

export default VSBackgroundColorPicker;
