import React, { Component } from 'react';
import { Row, Col, Table, Checkbox, Image, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { getReservationMappings, getVTImageFolder } from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import './index.css';

export default class ResMappings extends Component {
  state = {
    spinning: false,
    data: [],
    imageDir: '',
  };

  init = async () => {
    this.setState({ spinning: true });
    await Promise.all([getReservationMappings(), getVTImageFolder('11')])
      .then(values => {
        values.forEach(res => {
          if (!res || (res && res.result !== 'OK')) throw new Error();
        });
        this.setState({
          spinning: false,
          data: values[0].data,
          imageDir: values[1].data,
        });
      })
      .catch(err => {
        this.setState({ spinning: false });
        message.error('Error obtaining order options!');
      });
  };

  handleEditResMapping = item => {
    const { imageDir } = this.state;
    this.props.history.replace({
      pathname: '/admin/editresmapping',
      state: { item, imageDir },
    });
  };

  getImage = ImageName => {
    const { imageDir } = this.state;
    let src = '';
    const imgFallback = `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      //dev mode
      if (ImageName) {
        src = `http://localhost:4000/${imageDir}/${ImageName}`;
      } else {
        src = imgFallback;
      }
    } else {
      //production mode
      if (ImageName) {
        src = `${process.env.PUBLIC_URL}/${imageDir}/${ImageName}`;
      } else {
        src = imgFallback;
      }
    }
    return src;
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { spinning, data } = this.state;
    const columns = [
      {
        title: 'Active',
        dataIndex: 'Status',
        render: (_, record) => <Checkbox checked={!record.Status} disabled />,
      },
      {
        title: 'Venue',
        dataIndex: 'Description',
      },
      {
        title: 'Display Name',
        dataIndex: 'DisplayName',
      },
      {
        title: 'Image',
        dataIndex: 'ImageName',
        render: (_, record) => {
          const src = this.getImage(record.ImageName);
          return (
            <Row style={{ width: 120, height: 80, border: '1px solid #e8e8e8' }}>
              <Image
                width={120}
                height={80}
                src={src}
                preview={src !== `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`}
              />
            </Row>
          );
        },
      },
      {
        title: 'Edit',
        render: (text, record) => (
          <Row justify="center">
            <FontAwesomeIcon
              icon={faPenToSquare}
              size="xl"
              color="var(--adminPrimary)"
              onClick={() => this.handleEditResMapping(record)}
            />
          </Row>
        ),
      },
    ];

    return (
      <Spin spinning={spinning}>
        <Row className="admin-res-mappings-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title="Reservation Mapping List"
              showLeftBtn={false}
              showRightBtn={false}
            />
            <Row style={{ marginTop: 15 }}>
              <Col span={24}>
                <Table
                  size="middle"
                  rowKey="Code"
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}
