import React, { Component } from 'react';
import { Row, Col, Checkbox, Table, message } from 'antd';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { getReservationConfigs, deleteReservationConfig } from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import './index.css';

class AdminResSettings extends Component {
  state = {
    spinning: false,
    data: [],
  };

  columns = [
    {
      title: 'Delete',
      render: (text, record) => (
        <FontAwesomeIcon
          icon={faTrashCan}
          size="xl"
          color="var(--adminSecondary)"
          onClick={() => this.handleDelete(record)}
        />
      ),
    },
    {
      title: 'Active',
      dataIndex: 'Status',
      render: (text, record) => <Checkbox checked={!record.Status} disabled />,
    },
    {
      title: 'Setting Name',
      dataIndex: 'Description',
    },
    {
      title: 'Edit',
      render: (text, record) => (
        <FontAwesomeIcon
          icon={faPenToSquare}
          size="xl"
          color="var(--adminPrimary)"
          onClick={() => this.handleEdit(record)}
        />
      ),
    },
  ];

  data = [
    {
      key: 1,
      Description: 'Dining Room',
      SettingType: 1,
      DateRange: ['2022-11-09', '2022-12-03'],
      Date: '',
      StartBooking: 4,
      StopBooking: 7,
      Inactive: 0,
      ApplyToVenues: '',
      ApplyTo: 'Monday,Tuesday,Wednesday',
      TimeRangeList: [],
    },
    {
      key: 2,
      Description: 'House Keeping',
      SettingType: 1,
      DateRange: [],
      Date: '2022-11-16',
      StartBooking: 5,
      StopBooking: 6,
      Inactive: 0,
      ApplyToVenues: '',
      ApplyTo: 'Monday,Tuesday,Wednesday',
      TimeRangeList: [],
    },
    {
      key: 3,
      Description: 'Services',
      SettingType: 1,
      DateRange: [],
      Date: '',
      StartBooking: 2,
      StopBooking: 1,
      Inactive: 0,
      ApplyToVenues: '',
      ApplyTo: 'Monday,Tuesday,Wednesday',
      TimeRangeList: [],
    },
  ];

  init = async () => {
    try {
      this.setState({ spinning: true });
      const re = await getReservationConfigs();
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false, data: re.data });
    } catch (error) {
      message.error('Error obtaining reservation setting list!');
    }
  };

  handleAddSettingBtn = () => {
    this.props.history.replace({
      pathname: '/admin/ressetting',
      state: { addOrEdit: 'Add' },
    });
  };

  handleEdit = resSetting => {
    this.props.history.replace({
      pathname: '/admin/ressetting',
      state: { resSetting, addOrEdit: 'Edit' },
    });
  };

  handleDelete = async resSetting => {
    const { Code } = resSetting;
    try {
      this.setState({ spinning: true });
      const re = await deleteReservationConfig(Code);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false });
      message.success('Reservation setting list successfully updated');
      this.init();
    } catch (error) {
      message.error('Error saving changes to server!');
    }
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { spinning, data } = this.state;
    const sortedData = [...data.filter(i => i.Status === 1), ...data.filter(i => i.Status === 0)];

    return (
      <Spin spinning={spinning}>
        <Row className="admin-res-settings-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title="Reservation Setting List"
              showLeftBtn={false}
              showRightBtn={true}
              rightBtnText="Add Setting"
              rightBtnWidth={160}
              onRightBtnClick={this.handleAddSettingBtn}
            />
            <Row style={{ marginTop: 15 }}>
              <Col span={24}>
                <Table
                  size="middle"
                  rowKey="Code"
                  columns={this.columns}
                  dataSource={sortedData}
                  pagination={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}
export default connect(state => ({ user: state.user }), {})(AdminResSettings);
