import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Table, Input, Tooltip, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { getUsers } from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import Button from '../../../components/Button';
import './index.css';

const { Search } = Input;

class AdminUser extends Component {
  state = {
    spinning: false,
    users: [],
    searchText: '',
    searchResult: [],
  };

  init = async () => {
    try {
      this.setState({ spinning: true });
      const re = await getUsers();
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false, users: re.data, searchText: '' }, () => {
        this.getSearchResults();
      });
    } catch (error) {
      message.error('Error obtaining users!', 3);
    }
  };

  getSearchResults = () => {
    const { users, searchText } = this.state;
    if (!searchText) {
      this.setState({ searchResult: users });
      return;
    }
    const data = users.filter(
      i => i.UserName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1,
    );
    this.setState({ searchResult: data });
  };

  handleAddUserBtn = () => {
    this.props.history.push('/admin/adduser');
  };

  handleEditUserClick = user => {
    this.props.history.push({
      pathname: '/admin/edituser',
      state: { userName: user.UserName },
    });
  };

  handleSearchBoxChange = ({ target: { value } }) => {
    this.setState({ searchText: value });
  };

  handleSearch = () => {
    this.getSearchResults();
  };

  handleClearSearch = () => {
    this.setState({ searchText: '' }, () => {
      this.getSearchResults();
    });
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { spinning, searchText, searchResult } = this.state;
    const screen = window.innerWidth;

    const columns = [
      {
        title: 'User Name',
        dataIndex: 'UserName',
        onCell: () => {
          return {
            style: {
              whiteSpace: 'nowrap',
              maxWidth: screen <= 1024 ? '200px' : '',
            },
          };
        },
        render: text => {
          if (screen <= 1024) {
            return (
              <Tooltip title={text}>
                <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{text}</div>
              </Tooltip>
            );
          } else {
            return <div>{text}</div>;
          }
        },
      },
      {
        title: 'Email',
        dataIndex: 'Email',
        onCell: () => {
          return {
            style: {
              whiteSpace: 'nowrap',
              maxWidth: screen <= 1024 ? '200px' : '',
            },
          };
        },
        render: text => (
          <Tooltip title={text}>
            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{text}</div>
          </Tooltip>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'Inactive',
        render: (_, record) => <Row>{record.Inactive === false ? 'Active' : 'Inactive'}</Row>,
        filters: [
          {
            text: 'Active',
            value: false,
          },
          {
            text: 'Inactive',
            value: true,
          },
        ],
        onFilter: (value, record) => record.Inactive === value,
      },
      {
        title: 'Type',
        dataIndex: 'Type',
        render: (_, record) => (
          <Row>
            {record.Type === 0
              ? 'Resident'
              : record.Type === 1
              ? 'Employee'
              : record.Type === 2
              ? 'Family Member'
              : record.Type === 3
              ? 'System Admin'
              : ''}
          </Row>
        ),
        filters: [
          {
            text: 'Resident',
            value: 0,
          },
          {
            text: 'Employee',
            value: 1,
          },
          {
            text: 'Family Member',
            value: 2,
          },
          {
            text: 'System Admin',
            value: 3,
          },
        ],
        onFilter: (value, record) => record.Type === value,
      },
      {
        title: 'Edit',
        render: (_, record) => (
          <Row>
            <FontAwesomeIcon
              icon={faPenToSquare}
              size="xl"
              color="var(--adminPrimary)"
              onClick={() => this.handleEditUserClick(record)}
            />
          </Row>
        ),
      },
    ];

    return (
      <Spin spinning={spinning}>
        <Row className="admin-user-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title="User Management"
              showLeftBtn={false}
              showRightBtn={true}
              rightBtnText="Add User"
              onRightBtnClick={this.handleAddUserBtn}
            />
            <Row style={{ marginTop: 15 }}>
              <Col>
                <Search
                  size="large"
                  placeholder="Search Account"
                  onSearch={this.handleSearch}
                  value={searchText}
                  onChange={this.handleSearchBoxChange}
                  enterButton
                  style={{ width: 350 }}
                />
              </Col>
              <Col>
                <Button
                  width={140}
                  height={40}
                  style={{ backgroundColor: 'var(--adminSecondary)', marginLeft: 30 }}
                  textStyle={{ color: 'white', fontSize: 16 }}
                  borderRadius={5}
                  onClick={this.handleClearSearch}
                >
                  Clear Search
                </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col span={24}>
                <Table
                  rowKey="UserGuid"
                  columns={columns}
                  dataSource={searchResult}
                  pagination={false}
                  bordered
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}
export default connect(state => ({ user: state.user }), {})(AdminUser);
