import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router';
import Button from '../Button';

class AdminHeader extends Component {
  handleBtnClick = () => {
    return false;
  };
  render() {
    const {
      title,
      showLeftBtn = false,
      showRightBtn = true,
      leftBtnWidth = 120,
      rightBtnWidth = 120,
      leftBtnText,
      rightBtnText,
    } = this.props;
    return (
      <Row style={{ marginBottom: 15, height: 50 }}>
        <Col span={24}>
          <Row align="middle">
            {/* Navigation all go back to admin dashboard */}
            <Col span={6}>
              {showLeftBtn && (
                <Row>
                  {/* <Link to="/admin/dashboard" style={{ color: '#fff' }}> */}
                  <Button
                    width={leftBtnWidth}
                    height={50}
                    style={{ backgroundColor: 'var(--adminSecondary)' }}
                    textStyle={{ fontSize: 18, color: '#fff' }}
                    borderRadius={5}
                    onClick={() => {
                      this.props.onLeftBtnClick();
                    }}
                  >
                    {leftBtnText}
                  </Button>
                  {/* </Link> */}
                </Row>
              )}
            </Col>
            {/* Page title */}
            <Col span={12}>
              <Row justify="center" style={{ fontSize: 20, fontWeight: 'bold' }}>
                <div>{title}</div>
              </Row>
            </Col>
            <Col span={6}>
              {showRightBtn && (
                <Row justify="end">
                  <Button
                    width={rightBtnWidth}
                    height={50}
                    style={{ backgroundColor: 'var(--adminPrimary)' }}
                    textStyle={{ fontSize: 18, color: '#fff' }}
                    borderRadius={5}
                    onClick={() => {
                      this.props.onRightBtnClick();
                    }}
                  >
                    {rightBtnText}
                  </Button>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
export default withRouter(AdminHeader);
