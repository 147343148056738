import React, { Component } from 'react';
import { Row, Col, Divider, Select, Table, Input, Radio, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { getAccountBaseList, editUser, updateUserPassword, getUsers } from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import Selection from '../../../components/Selection';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import './index.css';

const { Option } = Select;
const { TextArea } = Input;

export default class AdminEditUser extends Component {
  state = {
    spinning: false,
    user: {},
    accounts: [],
    accSelectedValue: undefined,
    visible: false,
    newPassword: '',
    confirmNewPassword: '',
    modalError: '',
  };

  accountTypes = [
    { label: 'Resident', value: 0 },
    { label: 'Employee', value: 1 },
    { label: 'Family Member', value: 2 },
    { label: 'System Admin', value: 3 },
  ];

  userStatusOptions = [
    { label: 'Active', value: false },
    { label: 'Inactive', value: true },
  ];

  columns = [
    {
      title: 'Account Number',
      dataIndex: 'AccountNumber',
    },
    {
      title: 'Account Name',
      render: (_, record) => <Row>{`${record.FirstName} ${record.LastName}`}</Row>,
    },
    {
      title: 'Action',
      render: (_, record) => (
        <Row>
          <FontAwesomeIcon
            icon={faTrashCan}
            size="xl"
            color="var(--adminSecondary)"
            onClick={() => this.handleRemoveAssociatedAccount(record)}
            style={{ marginLeft: 20 }}
          />
        </Row>
      ),
    },
  ];

  init = async () => {
    const { userName } = this.props.location.state;
    if (userName) {
      try {
        this.setState({ spinning: true });
        const re = await getUsers(userName);
        if (!re || (re && re.result !== 'OK')) {
          this.setState({ spinning: false });
          throw new Error();
        }
        this.setState({ spinning: false, user: re.data[0] });
      } catch (error) {
        message.error('Error obtaining user info!', 3);
      }
    }
  };

  getAccounts = async () => {
    try {
      this.setState({ spinning: true });
      const re = await getAccountBaseList();
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false, accounts: re.data });
    } catch (error) {
      message.error('Error obtaining accounts!', 3);
    }
  };

  handleBackBtn = () => {
    this.props.history.replace('/admin/user');
  };

  handleSaveBtn = async () => {
    const { user } = this.state;
    try {
      this.setState({ spinning: true });
      const re = await editUser(user);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false });
      message.success('User info successfully updated!', 3);
      this.props.history.replace('/admin/user');
    } catch (error) {
      message.error('Error saving user info!', 3);
    }
  };

  handleInputChange = (dataType, { target: { value } }) => {
    const { user } = this.state;
    user[dataType] = value;
    this.setState({ user });
  };

  handleUserTypeChange = (v, o) => {
    const { user } = this.state;
    user.Type = v;
    this.setState({ user });
  };

  handleUserStatusChange = ({ target: { value } }) => {
    const { user } = this.state;
    user.Inactive = value;
    this.setState({ user });
  };

  handleSeachClearBtn = () => {
    this.setState({ accSelectedValue: undefined });
  };

  handleSearchSelChange = (v, o) => {
    this.setState({ accSelectedValue: v });
  };

  handleAddAccBtn = () => {
    const { accounts, accSelectedValue, user } = this.state;
    if (!accSelectedValue) {
      return;
    }
    const account = accounts.find(i => i.AccountNumber === accSelectedValue);
    if (account) {
      user.AccountList = [...user.AccountList, account];
      this.setState({ user, accSelectedValue: undefined });
    } else {
      message.error('Error retrieving account info!', 3);
    }
  };

  handleRemoveAssociatedAccount = account => {
    const { user } = this.state;
    user.AccountList = [...user.AccountList.filter(i => i.AccountNumber !== account.AccountNumber)];
    this.setState({ user });
  };

  handleChangePwdBtn = () => {
    this.setState({ visible: true });
  };

  handleNewPwdChange = ({ target: { value } }) => {
    this.setState({ newPassword: value, modalError: '' });
  };

  handleConformNewPwdChange = ({ target: { value } }) => {
    this.setState({ confirmNewPassword: value, modalError: '' });
  };

  handleModalCancel = () => {
    this.setState({ newPassword: '', confirmNewPassword: '', visible: false, modalError: '' });
  };

  handleModalOKBtn = async () => {
    const { newPassword, confirmNewPassword, user } = this.state;
    if (newPassword !== confirmNewPassword) {
      this.setState({ modalError: 'Passwords do not match!' });
      return;
    }
    if (!newPassword.trim() && !confirmNewPassword.trim()) {
      this.setState({ modalError: 'Password is required!' });
      return;
    }
    try {
      this.setState({ spinning: true });
      const re = await updateUserPassword(user.UserName, newPassword);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({
        spinning: false,
        newPassword: '',
        confirmNewPassword: '',
        visible: false,
        modalError: '',
      });
      message.success('User password successfully updated!', 3);
    } catch (error) {
      message.error('Error updating user password!', 3);
    }
  };

  componentDidMount() {
    this.init();
    this.getAccounts();
  }

  render() {
    const {
      spinning,
      user,
      accounts,
      accSelectedValue,
      visible,
      newPassword,
      confirmNewPassword,
      modalError,
    } = this.state;
    const accountTypeArr = this.accountTypes.map(i => (
      <Option key={i.value} value={i.value}>
        {i.label}
      </Option>
    ));

    //Filter out ones already associated
    const accountsOptions = accounts
      .filter(i => user.AccountList.every(j => j.AccountNumber !== i.AccountNumber))
      .map(i => (
        <Option key={i.AccountNumber} value={i.AccountNumber}>
          {`${i.FirstName} ${i.LastName} - ${i.AccountNumber}`}
        </Option>
      ));

    return (
      <Spin spinning={spinning}>
        <Row className="admin-edit-user-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title="Edit User Info"
              showLeftBtn={true}
              leftBtnText="Back"
              showRightBtn={true}
              rightBtnText="Save"
              onRightBtnClick={this.handleSaveBtn}
              onLeftBtnClick={this.handleBackBtn}
            />
            {/* Inputs Row */}
            <Row>
              <h2 style={{ color: 'var(--adminSecondary)' }}>User Info</h2>
            </Row>
            <Row justify="center">
              <Col span={20}>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <Row>
                      <strong>Username</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        value={user.UserName}
                        disabled={true}
                        spellCheck={false}
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Email</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={user.Email}
                        onChange={e => this.handleInputChange('Email', e)}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <Row>
                      <strong>First Name</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={user.FirstName}
                        onChange={e => this.handleInputChange('FirstName', e)}
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Last Name</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={user.LastName}
                        onChange={e => this.handleInputChange('LastName', e)}
                      />
                    </Row>
                  </Col>
                </Row>
                {user.Type === 9 ? null : (
                  <Row style={{ marginTop: 20 }}>
                    <Col span={12}>
                      <Row>
                        <strong>User Type</strong>
                      </Row>
                      <Row style={{ width: 250 }}>
                        <Selection
                          size="large"
                          width={250}
                          data={accountTypeArr}
                          value={user.Type}
                          onChange={(v, o) => this.handleUserTypeChange(v, o)}
                        />
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <strong>User Status</strong>
                      </Row>
                      <Row style={{ width: 250 }}>
                        <Radio.Group
                          options={this.userStatusOptions}
                          onChange={e => {
                            this.handleUserStatusChange(e);
                          }}
                          value={user.Inactive}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row style={{ marginTop: 20 }} align="middle">
                  <Col span={12}>
                    <Row>
                      <strong>Note</strong>
                    </Row>
                    <Row>
                      <TextArea
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        placeholder="Note"
                        spellCheck={false}
                        showCount
                        maxLength={100}
                        onChange={e => this.handleInputChange('Note', e)}
                        value={user.Note}
                        style={{ width: 250 }}
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Button
                        width={150}
                        height={40}
                        style={{ backgroundColor: 'var(--adminPrimary)' }}
                        textStyle={{ color: 'white' }}
                        borderRadius={5}
                        onClick={this.handleChangePwdBtn}
                      >
                        Change Password
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Search Row */}
            <Divider style={{ borderColor: 'transparent' }} />
            <Row>
              <Col span={24}>
                <Row>
                  <h2 style={{ color: 'var(--adminSecondary)' }}>
                    Associated House Account/Card Number
                  </h2>
                </Row>
                <Row style={{ marginTop: 20 }} gutter={16} align="middle">
                  <Col>
                    <Button
                      width={80}
                      height={40}
                      style={{ backgroundColor: 'var(--adminSecondary)' }}
                      textStyle={{ color: '#fff' }}
                      borderRadius={5}
                      onClick={this.handleSeachClearBtn}
                    >
                      Clear
                    </Button>
                  </Col>
                  <Col>
                    <Row>
                      <Selection
                        size="large"
                        width={400}
                        showSearch={true}
                        data={accountsOptions}
                        value={accSelectedValue}
                        placeholder={<span>Search Account Name</span>}
                        onChange={(v, o) => this.handleSearchSelChange(v, o)}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Button
                      width={150}
                      height={40}
                      style={{ backgroundColor: 'var(--adminPrimary)' }}
                      textStyle={{ color: 'white' }}
                      borderRadius={5}
                      onClick={this.handleAddAccBtn}
                    >
                      Add Account
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: 15 }}>
              <Col span={24}>
                <Table
                  rowKey="AccountNumber"
                  columns={this.columns}
                  dataSource={user.AccountList}
                  pagination={false}
                  bordered
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Password Modal */}
        <Modal
          visible={visible}
          width={620}
          title={null}
          footer={null}
          closable={false}
          destroyOnClose={true}
          onCancel={this.handleModalCancel}
          showCloseBtn={false}
        >
          <Row justify="center">
            <h2>Change Password</h2>
          </Row>
          {/* Error Row */}
          <Row
            style={{
              padding: 15,
              height: 30,
              fontWeight: 'bold',
              color: '#8b0000',
              fontSize: 18,
              marginBottom: 20,
            }}
            justify="center"
          >
            <Col>
              <SwitchTransition>
                <CSSTransition key={modalError} timeout={500} classNames="login-account-error">
                  <Row>{modalError}</Row>
                </CSSTransition>
              </SwitchTransition>
            </Col>
          </Row>
          <Divider style={{ borderColor: 'transparent' }} />
          <Row align="middle" style={{ marginBottom: 20 }}>
            <Col span={10}>
              <Row justify="end" style={{ padding: '0 20px', fontSize: 18, fontWeight: 'bold' }}>
                Username
              </Row>
            </Col>
            <Col span={14}>
              <Row style={{ width: 250 }}>
                <Input size="large" value={user.UserName} disabled={true} spellCheck={false} />
              </Row>
            </Col>
          </Row>
          <Row align="middle" style={{ marginBottom: 20 }}>
            <Col span={10}>
              <Row justify="end" style={{ padding: '0 20px', fontSize: 18, fontWeight: 'bold' }}>
                New Password
              </Row>
            </Col>
            <Col span={14}>
              <Row style={{ width: 250 }}>
                <Input.Password
                  size="large"
                  spellCheck={false}
                  value={newPassword}
                  onChange={this.handleNewPwdChange}
                  visibilityToggle={false}
                />
              </Row>
            </Col>
          </Row>
          <Row align="middle" style={{ marginBottom: 20 }}>
            <Col span={10}>
              <Row justify="end" style={{ padding: '0 20px', fontSize: 18, fontWeight: 'bold' }}>
                Confirm New Password
              </Row>
            </Col>
            <Col span={14}>
              <Row style={{ width: 250 }}>
                <Input.Password
                  size="large"
                  spellCheck={false}
                  value={confirmNewPassword}
                  onChange={this.handleConformNewPwdChange}
                  visibilityToggle={false}
                />
              </Row>
            </Col>
          </Row>
          <Divider style={{ borderColor: 'transparent' }} />
          <Row style={{ padding: '0, 30px' }} justify="space-between">
            <Button
              width={120}
              style={{ backgroundColor: 'var(--adminPrimary)' }}
              textStyle={{ fontSize: 18, color: '#fff' }}
              borderRadius={5}
              onClick={this.handleModalCancel}
            >
              Cancel
            </Button>
            <Button
              width={120}
              style={{ backgroundColor: 'var(--adminPrimary)' }}
              textStyle={{ fontSize: 18, color: '#fff' }}
              borderRadius={5}
              onClick={this.handleModalOKBtn}
            >
              OK
            </Button>
          </Row>
        </Modal>
      </Spin>
    );
  }
}
