import React, { Component } from 'react';
import { Row, Col, Divider, Input, List, message } from 'antd';
import Keyboard from 'react-simple-keyboard';
import Button from '../Button';
import Spin from '../Spin';
import './index.css';

export default class ScanSearch extends Component {
  state = {
    spinning: false,
    input: '',
    filteredRes: [],
  };

  data = [
    { id: '01', description: 'Pizza', price: 9.99 },
    { id: '02', description: 'Orange Juice', price: 2.59 },
    { id: '03', description: 'Coconut Water', price: 2.49 },
    { id: '04', description: 'Almond Juice', price: 4.99 },
    { id: '05', description: 'Soybean Juice', price: 3.99 },
    { id: '06', description: 'Water Melon Juice', price: 2.99 },
    { id: '07', description: 'Turkey Sanwich', price: 8.99 },
    { id: '08', description: 'Beef Sanwich', price: 11.99 },
    { id: '09', description: 'Bottled Water', price: 1.0 },
  ];

  searchInput = React.createRef();

  keyboardOptions = {
    ...this.commonKeyboardOptions,
    layout: {
      default: [
        '1 2 3 4 5 6 7 8 9 0',
        'q w e r t y u i o p',
        'a s d f g h j k l',
        'z x c v b n m {bksp}',
        '{space}',
      ],
    },
  };
  handleScanSearchCloseBtn = () => {
    this.setState({ input: '', filteredRes: [] });
    this.props.onScanSearchClose();
  };

  onKeyInCodeKeyboardChange = input => {
    this.setState({ input });
  };

  handlesearchInputChange = ({ target: { value } }) => {
    this.setState({ input: value });
    this.keyboard && this.keyboard.setInput(value);
  };

  handleKeyInCodeEnterKeyDown = e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      this.handleKeyInCodeOK();
    }
  };

  onKeyInCodeKeyPress = button => {};
  onKeyInCodeKeyReleased = button => {};

  handleKeyInCodeClear = () => {
    this.setState({ input: '' });
    this.keyboard && this.keyboard.setInput('');
  };

  handleKeyInCodeOK = () => {
    const { input } = this.state;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (input.trim() === '') {
        this.setState({ filteredRes: [] });
      } else {
        const keywords = input.trim().toLowerCase();
        this.setState({
          filteredRes: this.data.filter(i => i.description.toLowerCase().indexOf(keywords) > -1),
        });
      }
    }, 1000);
  };

  handleItemClick = item => {
    this.handleScanSearchCloseBtn();
    this.props.onSelectItem(item);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.input !== this.state.input) {
      this.handleKeyInCodeOK();
    }
    if (prevProps.getFocus !== this.props.getFocus) {
      this.searchInput.current.focus();
    }
  }

  componentDidMount() {
    this.searchInput.current.focus();
  }

  render() {
    const { spinning, input, filteredRes } = this.state;
    const { visible } = this.props;
    return (
      <div className="scan-search-wrapper" style={{ display: visible ? 'block' : 'none' }}>
        <div className="scan-search-container">
          <Row>
            {/* Left Side */}
            <Col span={10}>
              <Spin spinning={spinning} spinnerColor="var(--primary)">
                <Row
                  style={{ backgroundColor: '#fff', height: '100vh' }}
                  className="scan-search-list-pane"
                >
                  <Col span={24}>
                    <List
                      size="large"
                      header={null}
                      footer={null}
                      dataSource={filteredRes}
                      // grid={{ column: 1 }}
                      renderItem={item => (
                        <List.Item
                          key={item.id}
                          onClick={() => this.handleItemClick(item)}
                          className="scan-search-list-item"
                        >
                          <Row style={{ width: '100%' }}>
                            <Col span={20}>
                              <span style={{ fontWeight: 'bold' }}>{item.description}</span>
                            </Col>
                            <Col span={4}>{`$${item.price.toFixed(2)}`}</Col>
                          </Row>
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              </Spin>
            </Col>
            {/* Right Side */}
            <Col span={14}>
              <Row justify="end">
                <Button
                  width={120}
                  height={50}
                  style={{ margin: 5 }}
                  textStyle={{ fontSize: 18 }}
                  borderRadius={5}
                  onClick={this.handleScanSearchCloseBtn}
                >
                  Close
                </Button>
              </Row>
              {/* Input row */}
              <Divider style={{ borderColor: 'transparent' }} />
              <Row justify="center">
                <Input
                  ref={this.searchInput}
                  autoFocus={true}
                  size="large"
                  placeholder="Key Words"
                  spellCheck={false}
                  value={input}
                  onChange={this.handlesearchInputChange}
                  onKeyUp={this.handleKeyInCodeEnterKeyDown}
                  onBlur={() => this.searchInput.current.focus()}
                  style={{ width: 450 }}
                />
              </Row>
              <Divider style={{ borderColor: 'transparent' }} />
              {/* Btns row */}
              <Row style={{ padding: 20 }} justify="center">
                <Button
                  width={120}
                  height={50}
                  textStyle={{ fontSize: 18 }}
                  borderRadius={5}
                  onClick={this.handleKeyInCodeClear}
                >
                  Clear
                </Button>
                {/* <Button
                  width={100}
                  height={50}
                  style={{ backgroundColor: 'var(--primary)' }}
                  textStyle={{ fontSize: 18, color: 'white' }}
                  onClick={this.handleKeyInCodeOK}
                >
                  OK
                </Button> */}
              </Row>
              <Row
                style={{
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                }}
              >
                <Keyboard
                  baseClass={'simple-keyboard-main'}
                  keyboardRef={r => (this.keyboard = r)}
                  layoutName="default"
                  onChange={this.onKeyInCodeKeyboardChange}
                  onKeyPress={this.onKeyInCodeKeyPress}
                  onKeyReleased={this.onKeyInCodeKeyReleased}
                  theme={'hg-theme-default hg-layout-default'}
                  preventMouseDownDefault={true}
                  {...this.keyboardOptions}
                />
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
