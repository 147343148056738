import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Row, Col } from 'antd';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import Button from '../Button';
import Modal from '../Modal';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import './index.css';

const { TextArea } = Input;

class OrderInstructionModal extends Component {
  state = {
    layout: 'default',
    input: '',
    shift: false,
    cap: false,
    error: '',
  };

  refInstructionInput = React.createRef();

  setLayout = () => {
    const { insModalFor } = this.props;
    if (insModalFor === 'GUESTPHONE') {
      this.setState({ layout: 'number' });
    } else {
      this.setState({ layout: 'default' });
    }
  };

  handleInputOnFocus = e => {
    const temp_value = e.target.value;
    e.target.value = '';
    e.target.value = temp_value;
    this.setState({ input: e.target.value });
  };

  handleInputOnBlur = () => {
    this.refInstructionInput.current.focus();
  };

  handleInputOnChange = e => {
    const { insModalFor } = this.props;
    let input;
    if (insModalFor === 'GUESTNAME') {
      if (e.target.value.trim().length > 15) {
        input = e.target.value.trim().slice(0, 15);
      } else {
        input = e.target.value;
      }
    } else if (insModalFor === 'GUESTPHONE') {
      input = e.target.value.replace(/\D/g, '').substring(0, 10);
    } else {
      if (e.target.value.trim().length > 100) {
        input = e.target.value.trim().slice(0, 100);
      } else {
        input = e.target.value;
      }
    }
    this.setState({ input, error: '' });
    this.keyboard.setInput(input);
  };

  handleInsModalCancel = () => {
    this.handleClearInput();
    this.props.onInsModalCancel();
  };

  keyboardOptions = {
    ...this.commonKeyboardOptions,
    layout: {
      default: [
        '1 2 3 4 5 6 7 8 9 0 - {bksp}',
        'q w e r t y u i o p [ ] \\',
        "{lock} a s d f g h j k l ; '",
        '{shift} z x c v b n m , .',
        '.com @ {space}',
      ],
      shift: [
        '! @ # $ % ^ &amp; * ( ) _ {bksp}',
        'Q W E R T Y U I O P { } |',
        '{lock} A S D F G H J K L : "',
        '{shift} Z X C V B N M &lt; &gt; ?',
        '.com @ {space}',
      ],
      number: ['1 2 3', '4 5 6', '7 8 9', '0 {bksp}'],
    },
  };

  onKeyboardChange = input => {
    const { insModalFor } = this.props;
    if (insModalFor === 'GUESTPHONE') {
      input = input.replace(/\D/g, '').substring(0, 10);
    }
    this.setState({ input, error: '' });
    this.keyboard.setInput(input);
  };

  onKeyPress = button => {
    if (button === '{shift}' || button === '{lock}') this.handleShift();
  };

  onKeyReleased = button => {
    const { shift, cap } = this.state;
    if (button === '{shift}') {
      this.setState({ shift: true });
      return;
    }
    if (button === '{lock}') {
      this.setState({ cap: !this.state.cap });
      return;
    }
    if (button !== '{shift}' && button !== '{lock}' && shift && !cap) {
      if (this.props.insModalFor === 'GUESTPHONE') {
        return;
      } else {
        this.setState({ layout: 'default' });
      }
    }
  };

  handleShift = () => {
    const { layout } = this.state;
    this.setState({ layout: layout === 'default' ? 'shift' : 'default' });
  };

  handleInputEnterKey = e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      this.handleOK();
    }
  };

  handleClearInput = () => {
    this.setState({ input: '' });
    this.keyboard.clearInput();
  };

  handleCancelInput = () => {
    this.props.onTextChange('', this.props.insModalFor);
    this.setState({ input: '' });
    this.keyboard.clearInput();
  };

  handleOK = () => {
    const { input } = this.state;
    const { insModalFor } = this.props;
    if (insModalFor === 'GUESTPHONE') {
      const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      if (input && !re.test(input)) {
        this.setState({ error: 'Please enter a valid phone number' });
        return;
      }
    }
    this.props.onTextChange(input, insModalFor);
    this.setState({ input: '' });
    this.keyboard.clearInput();
  };

  componentDidMount() {
    const { input } = this.state;
    this.keyboard && this.keyboard.setInput(input);
    this.setLayout();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { guestName, guestPhone, guestIns, content } = this.props;
    if (nextProps.insModalFor === 'INSTRUCTIONS') {
      this.setState({ input: content ? content : guestIns });
    }
    if (nextProps.insModalFor === 'GUESTNAME') {
      this.setState({ input: guestName });
    }
    if (nextProps.insModalFor === 'GUESTPHONE') {
      this.setState({ input: guestPhone });
    }
    this.keyboard && this.keyboard.setInput(this.state.input);
  }

  componentDidUpdate(prevProps, prevState) {
    const { insModalFor } = this.props;
    if (insModalFor !== prevProps.insModalFor) {
      this.setLayout();
    }
    const { input } = this.state;
    this.keyboard && this.keyboard.setInput(input);
  }

  render() {
    const { layout, input, error } = this.state;
    const { visible, insModalFor, clientStyle } = this.props;
    const title =
      insModalFor === 'GUESTNAME'
        ? 'Please Provide A Name For The Order'
        : insModalFor === 'GUESTPHONE'
        ? clientStyle.storeNumber === '5960'
          ? 'Please Provide A Cellphone Number So We Can Send You A Text When Your Order Is Ready'
          : 'Please Provide A Cellphone Number'
        : 'Special Instructions';
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const descStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontTwo,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };

    return (
      <Modal
        width={insModalFor === 'GUESTPHONE' ? 450 : 950}
        visible={visible}
        maskClosable={false}
        closable={false}
        title={null}
        footer={null}
        destroyOnClose={true}
        onCancel={this.handleInsModalCancel}
        showCloseBtn={false}
      >
        <Row
          justify="center"
          align="middle"
          style={{ margin: '0 0 10px', fontSize: 18, fontWeight: 'bold' }}
        >
          <h3 style={{ ...titleStyle, marginLeft: 20, textAlign: 'center' }}>{title}</h3>
        </Row>
        <Row
          style={{ padding: 15, height: 30, fontWeight: 'bold', color: '#8b0000', fontSize: 18 }}
          justify="center"
        >
          <Col>
            <SwitchTransition>
              <CSSTransition key={error} timeout={500} classNames="login-account-error">
                <Row style={{ ...descStyle }}>{error}</Row>
              </CSSTransition>
            </SwitchTransition>
          </Col>
        </Row>
        {insModalFor === 'GUESTNAME' && (
          <Row justify="center">
            <Input
              ref={this.refInstructionInput}
              autoFocus={true}
              placeholder="Guest Name"
              spellCheck={false}
              value={input}
              maxLength={15}
              style={{ margin: '20px 0 30px', width: 500, fontWeight: 'bolder', ...descStyle }}
              onFocus={this.handleInputOnFocus}
              onBlur={this.handleInputOnBlur}
              onChange={this.handleInputOnChange}
            />
          </Row>
        )}
        {insModalFor === 'GUESTPHONE' && (
          <>
            <Row justify="center">
              <input
                ref={this.refInstructionInput}
                className="login-card-input"
                autoFocus={true}
                type="tel"
                value={input}
                onFocus={this.handleInputOnFocus}
                onBlur={this.handleInputOnBlur}
                onChange={this.handleInputOnChange}
              />
            </Row>
            <Row justify="center">
              <Col>
                <Row
                  justify="center"
                  style={{
                    margin: '20px 0 30px',
                    width: 300,
                    borderBottom: `2px solid ${clientStyle.primary}`,
                    fontSize: 20,
                    fontWeight: 'bolder',
                    ...descStyle,
                  }}
                >
                  <span className="order-instruction-modal-phone-number-cell">(</span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(0, 1)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(1, 2)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(2, 3)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">)</span>
                  {/* <span className="order-instruction-modal-phone-number-cell"> </span> */}
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(3, 4)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(4, 5)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(5, 6)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">-</span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(6, 7)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(7, 8)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(8, 9)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(9, 10)}
                  </span>
                </Row>
              </Col>
            </Row>
          </>
        )}
        {insModalFor === 'INSTRUCTIONS' && (
          <TextArea
            ref={this.refInstructionInput}
            showCount
            autoFocus={true}
            autoSize={{ minRows: 3, maxRows: 3 }}
            placeholder=" Special Instructions"
            spellCheck={false}
            maxLength={100}
            value={input}
            style={{ margin: '20px 0 30px', fontWeight: 'bolder', ...descStyle }}
            onFocus={this.handleInputOnFocus}
            onBlur={this.handleInputOnBlur}
            onChange={this.handleInputOnChange}
          />
        )}

        <Row justify="space-between" style={{ margin: '0 10px 30px' }}>
          <Button
            height={50}
            onClick={this.handleCancelInput}
            style={{ ...rowStyle }}
            borderRadius={5}
          >
            Cancel
          </Button>
          <Button height={50} style={{ ...rowStyle }} borderRadius={5} onClick={this.handleOK}>
            OK
          </Button>
        </Row>

        <Keyboard
          keyboardRef={r => (this.keyboard = r)}
          layoutName={layout}
          onChange={this.onKeyboardChange}
          onKeyPress={this.onKeyPress}
          onKeyReleased={this.onKeyReleased}
          theme={'hg-theme-default hg-layout-default'}
          preventMouseDownDefault={true}
          {...this.keyboardOptions}
        />
      </Modal>
    );
  }
}
export default connect(
  state => ({
    clientStyle: state.clientStyle,
    guestName: state.guestName,
    guestPhone: state.guestPhone,
    guestIns: state.guestIns,
  }),
  {},
)(OrderInstructionModal);
