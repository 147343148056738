import React, { Component } from 'react';
import { Row, Col, Divider, Table, Input, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { getKioskStationConfigurations, saveKioskStationConfiguration } from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import './index.css';

class KioskConfigurations extends Component {
  state = {
    spinning: false,
    data: [],
    visible: false,
    error: '',
    newConfigName: '',
    selectedConfig: null,
    editConfigNameModalVisible: false,
    editedConfigName: '',
  };

  columns = [
    {
      title: 'Configuration Name',
      dataIndex: 'SettingName',
      render: (text, record) => (
        <Row>
          <div>{text}</div>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="xl"
            color="var(--adminPrimary)"
            onClick={() => this.handleEditConfigNameClick(record)}
            style={{ marginLeft: 20 }}
          />
        </Row>
      ),
    },
    {
      title: 'Station',
      render: (text, record) => (
        <Row align="middle">
          <span>{parseInt(record.UseCustomSetting) ? 'Custom' : 'Default'}</span>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="xl"
            color="var(--adminPrimary)"
            onClick={() => this.handleEditKiosk(record)}
            style={{ marginLeft: 20 }}
          />
        </Row>
      ),
    },
    {
      title: 'Images',
      render: (text, record) => (
        <Row align="middle">
          <span>{parseInt(record.UseCustomImage) ? 'Custom' : 'Default'}</span>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="xl"
            color="var(--adminPrimary)"
            onClick={() => this.handleEditImage(record)}
            style={{ marginLeft: 20 }}
          />
        </Row>
      ),
    },
  ];

  init = async () => {
    try {
      this.setState({ spinning: true });
      const re = await getKioskStationConfigurations();
      if (!re || (re && re.result !== 'OK')) throw new Error();
      this.setState({ spinning: false, data: re.data || [] });
    } catch (error) {
      this.setState({ spinning: false });
      message.error('Error obtaining kiosk configurations!', 3);
    }
  };

  handleEditConfigNameClick = record => {
    this.setState({
      selectedConfig: record,
      editedConfigName: record.SettingName,
      editConfigNameModalVisible: true,
    });
  };

  handleEditConfigNameChange = ({ target: { value } }) => {
    this.setState({ editedConfigName: value });
    if (this.state.error !== '') {
      this.setState({ error: '' });
    }
  };

  handleEditConfigNameModalCancel = () => {
    this.setState({
      editConfigNameModalVisible: false,
      editedConfigName: '',
      selectedConfig: null,
      error: '',
    });
  };

  handleEditConfigNameModalOK = () => {
    const { selectedConfig, editedConfigName, data } = this.state;
    const others = data.filter(i => i.SettingName !== selectedConfig.SettingName);
    if (
      others.filter(i => i.SettingName.toLowerCase() === editedConfigName.trim().toLowerCase())
        .length > 0
    ) {
      this.setState({ error: 'Name already exits' });
      return;
    }
    this.setState({
      editConfigNameModalVisible: false,
      editedConfigName: '',
      selectedConfig: null,
    });
    selectedConfig.SettingName = editedConfigName.trim();
    this.saveConfiguration(selectedConfig);
  };

  handleEditKiosk = record => {
    this.props.history.replace({
      pathname: '/admin/app',
      state: { item: record },
    });
  };

  handleEditImage = record => {
    this.props.history.replace({
      pathname: '/admin/image',
      state: { item: record },
    });
  };

  handleEditDefaultBtns = type => {
    if (type === 'station') {
      this.props.history.replace('/admin/app');
    }
    if (type === 'image') {
      this.props.history.replace('/admin/image');
    }
  };

  handleAddConfiguration = () => {
    this.setState({ visible: true });
  };

  handleNewConfigNameChange = ({ target: { value } }) => {
    this.setState({ newConfigName: value });
    if (this.state.error !== '') {
      this.setState({ error: '' });
    }
  };

  handleModalCancel = () => {
    this.setState({ newConfigName: '', visible: false, error: '' });
  };

  handleModalOK = () => {
    const { newConfigName, data } = this.state;
    if (
      data.filter(i => i.SettingName.toLowerCase() === newConfigName.trim().toLowerCase()).length >
      0
    ) {
      this.setState({ error: 'Name already exits' });
      return;
    }
    this.setState({ newConfigName: '', visible: false });
    const obj = {};
    obj.SettingId = '';
    obj.SettingName = newConfigName.trim();
    obj.UseCustomSetting = 0;
    obj.Setting = null;
    obj.UseCustomImage = 0;
    obj.ImageConfiguration = null;
    this.saveConfiguration(obj);
  };

  saveConfiguration = async config => {
    try {
      this.setState({ spinning: true });
      const re = await saveKioskStationConfiguration(config);
      if (!re || (re && re.result !== 'OK')) throw new Error();
      this.setState({ spinning: false });
      this.init();
    } catch (error) {
      this.setState({ spinning: false });
      message.error('Error saving kiosk configuration!', 3);
    }
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const {
      spinning,
      data,
      visible,
      error,
      newConfigName,
      editedConfigName,
      editConfigNameModalVisible,
    } = this.state;
    return (
      <Spin spinning={spinning}>
        <Row className="admin-kiosk-configurations-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title="Kiosk Station Configurations"
              showBack={false}
              showRightBtn={false}
            />
            {/* Btns row */}
            <Row justify="space-between">
              <Col>
                <Row>
                  <Button
                    width={250}
                    height={50}
                    style={{ backgroundColor: 'var(--adminPrimary)', marginRight: 30 }}
                    textStyle={{ fontSize: 16, color: '#fff' }}
                    borderRadius={5}
                    onClick={() => {
                      this.handleEditDefaultBtns('station');
                    }}
                  >
                    Edit Default Station Config.
                  </Button>
                  <Button
                    width={250}
                    height={50}
                    style={{ backgroundColor: 'var(--adminPrimary)' }}
                    textStyle={{ fontSize: 16, color: '#fff' }}
                    borderRadius={5}
                    onClick={() => {
                      this.handleEditDefaultBtns('image');
                    }}
                  >
                    Edit Default Images Config.
                  </Button>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Button
                    width={200}
                    height={50}
                    style={{ backgroundColor: 'var(--adminPrimary)' }}
                    textStyle={{ fontSize: 16, color: '#fff' }}
                    borderRadius={5}
                    onClick={this.handleAddConfiguration}
                  >
                    Add Configuration
                  </Button>
                </Row>
              </Col>
            </Row>
            <Divider style={{ borderColor: 'transparent' }} />
            <Table
              rowKey="SettingId"
              columns={this.columns}
              dataSource={data}
              pagination={false}
              bordered
            />
          </Col>
        </Row>
        {/* Add a config. modal */}
        <Modal
          visible={visible}
          width={520}
          title={null}
          footer={null}
          closable={false}
          destroyOnClose={true}
          onCancel={this.handleModalCancel}
          showCloseBtn={false}
        >
          {/* Title Row */}
          <Row justify="center">
            <h2>Enter Configuration Name</h2>
          </Row>
          {/* Error Row */}
          <Row
            style={{
              padding: 15,
              height: 30,
              fontWeight: 'bold',
              color: '#8b0000',
              fontSize: 16,
              marginBottom: 20,
            }}
            justify="center"
          >
            <Col>
              <SwitchTransition>
                <CSSTransition key={error} timeout={500} classNames="login-account-error">
                  <Row>{error}</Row>
                </CSSTransition>
              </SwitchTransition>
            </Col>
          </Row>
          <Divider style={{ borderColor: 'transparent' }} />
          {/* Input Row */}
          <Row justify="center">
            <Input
              size="large"
              spellCheck={false}
              value={newConfigName}
              onChange={this.handleNewConfigNameChange}
              style={{ width: 250 }}
            />
          </Row>
          <Divider style={{ borderColor: 'transparent' }} />
          {/* Btns Row */}
          <Row style={{ padding: '0 30px' }} justify="space-between">
            <Button
              width={120}
              style={{ backgroundColor: 'var(--adminPrimary)' }}
              textStyle={{ fontSize: 18, color: 'white' }}
              borderRadius={5}
              onClick={this.handleModalCancel}
            >
              Cancel
            </Button>
            <Button
              width={120}
              style={{ backgroundColor: 'var(--adminPrimary)' }}
              textStyle={{ fontSize: 18, color: 'white' }}
              borderRadius={5}
              onClick={this.handleModalOK}
            >
              OK
            </Button>
          </Row>
        </Modal>
        {/* Edit config. name modal */}
        <Modal
          visible={editConfigNameModalVisible}
          width={520}
          title={null}
          footer={null}
          closable={false}
          destroyOnClose={true}
          onCancel={this.handleEditConfigNameModalCancel}
          showCloseBtn={false}
        >
          {/* Title Row */}
          <Row justify="center">
            <h2>Edit Configuration Name</h2>
          </Row>
          {/* Error Row */}
          <Row
            style={{
              padding: 15,
              height: 30,
              fontWeight: 'bold',
              color: '#8b0000',
              fontSize: 16,
              marginBottom: 20,
            }}
            justify="center"
          >
            <Col>
              <SwitchTransition>
                <CSSTransition key={error} timeout={500} classNames="login-account-error">
                  <Row>{error}</Row>
                </CSSTransition>
              </SwitchTransition>
            </Col>
          </Row>
          <Divider style={{ borderColor: 'transparent' }} />
          {/* Input Row */}
          <Row justify="center">
            <Input
              size="large"
              spellCheck={false}
              value={editedConfigName}
              onChange={this.handleEditConfigNameChange}
              style={{ width: 250 }}
            />
          </Row>
          <Divider style={{ borderColor: 'transparent' }} />
          {/* Btns Row */}
          <Row style={{ padding: '0 30px' }} justify="space-between">
            <Button
              width={120}
              style={{ backgroundColor: 'var(--adminPrimary)' }}
              textStyle={{ fontSize: 18, color: 'white' }}
              borderRadius={5}
              onClick={this.handleEditConfigNameModalCancel}
            >
              Cancel
            </Button>
            <Button
              width={120}
              style={{ backgroundColor: 'var(--adminPrimary)' }}
              textStyle={{ fontSize: 18, color: 'white' }}
              borderRadius={5}
              onClick={this.handleEditConfigNameModalOK}
            >
              OK
            </Button>
          </Row>
        </Modal>
      </Spin>
    );
  }
}

export default KioskConfigurations;
