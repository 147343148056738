import React, { Component } from 'react';
import { Row, Col, Divider } from 'antd';
import Button from '../Button';

class OrderSplitBillModalSplitByChoice extends Component {
  handleSplitByChoiceBtn = type => {
    this.props.onSplitByChoiceChange(type);
  };
  render() {
    const { splitByChoice } = this.props;
    return (
      <Col span={24}>
        <Divider style={{ borderColor: 'transparent' }} />
        <Row justify="center">
          <h3>Split by: </h3>
        </Row>
        <Divider style={{ borderColor: 'transparent' }} />
        <Row style={{ padding: '0 50px' }} justify="space-around">
          <Button
            width={90}
            height={90}
            borderRadius="50%"
            style={{
              backgroundColor: splitByChoice === 0 ? 'var(--primary)' : '',
            }}
            textStyle={{ color: splitByChoice === 0 ? 'white' : '', fontSize: 18 }}
            onClick={() => this.handleSplitByChoiceBtn(0)}
          >
            Amount
          </Button>
          <Button
            width={90}
            height={90}
            borderRadius="50%"
            style={{
              backgroundColor: splitByChoice === 1 ? 'var(--primary)' : '',
            }}
            textStyle={{ color: splitByChoice === 1 ? 'white' : '', fontSize: 18 }}
            onClick={() => this.handleSplitByChoiceBtn(1)}
          >
            Items
          </Button>
        </Row>
        <Divider style={{ borderColor: 'transparent' }} />
      </Col>
    );
  }
}

export default OrderSplitBillModalSplitByChoice;
