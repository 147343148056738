import React, { Component } from 'react';
import { Row, Col, Input, Divider, Table, Popconfirm, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare, faFileImage } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import {
  getViewPages,
  saveViewPage,
  deleteViewPage,
  renameViewPage,
  getVTImages,
} from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import './index.css';

export default class AdminViewEditor extends Component {
  state = {
    spinning: false,
    views: [],
    pageSize: 5,
    visible: false,
    viewName: '',
    editViewName: false,
    viewSelected: {},
    modalError: '',
  };

  columns = [
    {
      title: 'Delete',
      render: (_, record) => (
        <Popconfirm title="Sure to delete this view?" onConfirm={() => this.handleDelete(record)}>
          <FontAwesomeIcon icon={faTrashCan} size="xl" color="var(--adminSecondary)" />
        </Popconfirm>
      ),
    },
    {
      title: 'View Name',
      dataIndex: 'ViewName',
      render: (text, record) => (
        <Row>
          <span>{text}</span>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="xl"
            color="var(--adminPrimary)"
            style={{ marginLeft: 15 }}
            onClick={() => this.handleEditViewName(record)}
          />
        </Row>
      ),
    },
    {
      title: 'Use as Startup',
      dataIndex: 'IsStartUp',
      render: (_, record) => <Row>{record.IsStartUp === true ? 'YES' : ''}</Row>,
    },
    {
      title: 'Edit',
      render: (_, record) => (
        <FontAwesomeIcon
          icon={faPenToSquare}
          size="xl"
          color="var(--adminPrimary)"
          onClick={() => this.handleEdit(record)}
        />
      ),
    },
    {
      title: 'Preview',
      render: (_, record) => (
        <Row>
          <FontAwesomeIcon
            icon={faFileImage}
            size="2x"
            color="var(--adminPrimary)"
            onClick={() => this.handlePreviewBtn(record)}
          />
        </Row>
      ),
    },
  ];

  init = async () => {
    try {
      this.setState({ spinning: true });
      const re = await getViewPages();
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false, views: re.data });
    } catch (error) {
      message.error('Error obtaining views!', 3);
    }
  };

  handleLoadImages = async () => {
    try {
      this.setState({ spinning: true });
      const re = await getVTImages('16');
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false });
      message.success('Images successfully loaded!', 3);
    } catch (error) {
      message.error('Error loading images!', 3);
    }
  };

  handleAddBtn = () => {
    this.setState({ visible: true });
  };

  handleTablePageSizeChange = (_, size) => {
    this.setState({ pageSize: size });
  };

  handleInputChange = ({ target: { value } }) => {
    this.setState({ viewName: value, modalError: '' });
  };

  handleModalCancel = () => {
    this.setState({ viewName: '', visible: false, modalError: '' });
  };

  createOrUpdateViewName = async () => {
    const { viewName, editViewName, viewSelected } = this.state;
    if (editViewName) {
      const oldViewName = viewSelected.ViewName;
      try {
        this.setState({ spinning: true });
        const re = await renameViewPage(oldViewName, viewName);
        if (!re || (re && re.result !== 'OK')) {
          this.setState({ spinning: false });
          throw new Error(re.result);
        }
        this.setState({ spinning: false, visible: false, viewName: '', editViewName: false });
        message.success('View name successfully updated!');
        this.init();
      } catch (error) {
        message.error('Error updating view name!', 3);
      }
    } else {
      const obj = {};
      obj.ViewName = viewName;
      try {
        this.setState({ spinning: true });
        const re = await saveViewPage(obj);
        if (!re || (re && re.result !== 'OK')) {
          this.setState({ spinning: false });
          throw new Error();
        }
        this.setState({ spinning: false, visible: false, viewName: '' });
        message.success('View successfully created!');
        this.init();
      } catch (error) {
        message.error('Error saving view!', 3);
      }
    }
  };

  handleOKBtn = () => {
    const { viewName, views } = this.state;
    if (viewName.trim() === '') {
      this.setState({ visible: false, viewName: '' });
      return;
    }
    if (views.find(i => i.ViewName.toLowerCase() === viewName.toLowerCase())) {
      this.setState({ modalError: 'View name already exists!' });
      return;
    }
    this.createOrUpdateViewName();
  };

  handleEdit = row => {
    this.props.history.replace({
      pathname: '/admin/editview',
      state: { viewName: row.ViewName },
    });
  };

  handleEditViewName = row => {
    this.setState({ viewName: row.ViewName, editViewName: true, viewSelected: row }, () => {
      this.setState({ visible: true });
    });
  };

  handleDelete = async row => {
    const { ViewName } = row;
    if (ViewName) {
      try {
        this.setState({ spinning: true });
        const re = await deleteViewPage(ViewName);
        if (!re || (re && re.result !== 'OK')) {
          this.setState({ spinning: false });
          throw new Error();
        }
        this.setState({ spinning: false });
        message.success('View successfully deleted!');
        this.init();
      } catch (error) {
        message.error('Error deleting view!', 3);
      }
    }
  };

  handlePreviewBtn = row => {
    this.props.history.replace({
      pathname: '/preview',
      state: { viewName: row.ViewName },
    });
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { spinning, views, pageSize, visible, viewName, modalError } = this.state;

    return (
      <Spin spinning={spinning}>
        <Row className="admin-view-editor-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title="View Editor"
              showLeftBtn={false}
              showRightBtn={true}
              rightBtnText="Add View"
              onRightBtnClick={this.handleAddBtn}
            />
            <Row style={{ marginTop: 15 }}>
              <Button
                width={130}
                height={50}
                style={{ backgroundColor: 'var(--adminPrimary)' }}
                textStyle={{ color: 'white', fontSize: 16 }}
                borderRadius={5}
                onClick={this.handleLoadImages}
              >
                Load Images
              </Button>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col span={24}>
                <Table
                  rowKey="Id"
                  columns={this.columns}
                  dataSource={views}
                  // pagination={{
                  //   pageSizeOptions: ['5', '10'],
                  //   pageSize: pageSize,
                  //   showSizeChanger: true,
                  //   position: ['topCenter'],
                  //   itemRender: (_, type, originalElement) => {
                  //     if (type === 'prev') {
                  //       return <a>Previous</a>;
                  //     }

                  //     if (type === 'next') {
                  //       return <a>Next</a>;
                  //     }
                  //     return originalElement;
                  //   },
                  //   onShowSizeChange: (current, size) => {
                  //     this.handleTablePageSizeChange(current, size);
                  //   },
                  // }}
                  pagination={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          visible={visible}
          width={520}
          title={null}
          footer={null}
          closable={false}
          destroyOnClose={true}
          onCancel={this.handleModalCancel}
          showCloseBtn={false}
        >
          <Row justify="center">
            <h2>Enter New View Name</h2>
          </Row>
          {/* Error Row */}
          <Row
            style={{
              padding: 15,
              height: 30,
              fontWeight: 'bold',
              color: '#8b0000',
              fontSize: 18,
              marginBottom: 20,
            }}
            justify="center"
          >
            <Col>
              <SwitchTransition>
                <CSSTransition key={modalError} timeout={500} classNames="login-account-error">
                  <Row>{modalError}</Row>
                </CSSTransition>
              </SwitchTransition>
            </Col>
          </Row>
          <Divider style={{ borderColor: 'transparent' }} />
          <Row justify="center">
            <Input
              size="large"
              spellCheck={false}
              value={viewName}
              onChange={e => this.handleInputChange(e)}
              style={{ width: 250 }}
            />
          </Row>
          <Divider style={{ borderColor: 'transparent' }} />
          <Row style={{ padding: '0, 30px' }} justify="space-between">
            <Button
              width={120}
              style={{ backgroundColor: 'var(--adminPrimary)' }}
              textStyle={{ fontSize: 18, color: 'white' }}
              borderRadius={5}
              onClick={this.handleModalCancel}
            >
              Cancel
            </Button>
            <Button
              width={120}
              style={{ backgroundColor: 'var(--adminPrimary)' }}
              textStyle={{ fontSize: 18, color: 'white' }}
              borderRadius={5}
              onClick={this.handleOKBtn}
            >
              OK
            </Button>
          </Row>
        </Modal>
      </Spin>
    );
  }
}
