import React, { Component } from 'react';
import { Row, Col, DatePicker, Select, message, Divider } from 'antd';
import { connect } from 'react-redux';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import moment from 'moment';
import parse from 'html-react-parser';
import { receiveCurrAccount } from '../../../redux/actions';
import { getAccountStatement } from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import Selection from '../../../components/Selection';
import Button from '../../../components/Button';

import './index.css';

const { RangePicker } = DatePicker;
const { Option } = Select;

//Try remarkablemark html-react-parser to parse HTML string to react dom elements

class AdminStatements extends Component {
  state = {
    spinning: false,
    error: '',
    dateRange: [],
    statement: '',
  };

  init = () => {
    const dateRange = this.getDateRange();
    this.setState({ dateRange });
  };

  //From 1st day of month to today
  getDateRange = () => {
    const startDate = moment().startOf('month');
    const endDate = moment();
    const range = [startDate, endDate];
    return range;
  };

  handleAccountChange = v => {
    const { user } = this.props;
    const { AccountList } = user || [];
    const acc = AccountList.find(i => i.AccountNumber === v) || {};
    this.props.receiveCurrAccount(acc);
    this.setState({ error: '' });
  };

  handleDateRangeChange = dateObjArr => {
    this.setState({ dateRange: dateObjArr, error: '' });
  };

  handleGetStatement = async () => {
    const { user } = this.props;
    if (!user.CurAccountInfo) {
      this.setState({ error: 'There is no account selected!' });
      return;
    }
    const { dateRange } = this.state;
    if (!dateRange || !dateRange[0] || !dateRange[1]) {
      this.setState({ error: 'Please select a date range!' });
      return;
    }

    const obj = {};
    obj.FromDate = dateRange[0].format('YYYY-MM-DD');
    obj.ToDate = dateRange[1].format('YYYY-MM-DD');
    obj.AccountNumber = user.CurAccountInfo.AccountNumber;

    try {
      this.setState({ spinning: true });
      const re = await getAccountStatement(obj);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({
        spinning: false,
        statement: re.data,
        error: re.data.length === 0 ? 'No record in selected date range!' : '',
      });
    } catch (error) {
      message.error('Error obtaining statement!');
    }
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { error, spinning, dateRange, statement } = this.state;
    const { user } = this.props;
    const { AccountList } = user || [];
    const { CurAccountInfo } = user || {};

    const accountsOptions =
      (AccountList &&
        AccountList.map(i => (
          <Option key={i.AccountNumber} value={i.AccountNumber}>
            {`${i.FirstName} ${i.LastName} - ${i.AccountNumber}`}
          </Option>
        ))) ||
      [];

    return (
      <Spin spinning={spinning}>
        <Row className="admin-statements-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader title="Your Statements" showLeftBtn={false} showRightBtn={false} />
            {/* Error row */}
            <Row
              style={{
                padding: 15,
                height: 30,
                fontWeight: 'bold',
                color: '#8b0000',
                fontSize: 18,
                marginBottom: 20,
              }}
              justify="center"
            >
              <Col>
                <SwitchTransition>
                  <CSSTransition key={error} timeout={500} classNames="login-account-error">
                    <Row>{error}</Row>
                  </CSSTransition>
                </SwitchTransition>
              </Col>
            </Row>
            {/* Account Row */}
            {1 === 1 && (
              <Row justify="center" align="middle" style={{ marginBottom: 20 }}>
                <Col span={8}>
                  <Row justify="end" style={{ paddingRight: 20 }}>
                    <h3>Account</h3>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row>
                    <Selection
                      width={310}
                      size="large"
                      value={CurAccountInfo ? CurAccountInfo.AccountNumber : null}
                      data={accountsOptions}
                      onChange={value => {
                        this.handleAccountChange(value);
                      }}
                    />
                  </Row>
                </Col>
                <Col span={6}></Col>
              </Row>
            )}
            {/* Dates Selector Row */}
            <Row justify="center" align="middle">
              <Col span={8}>
                <Row justify="end" style={{ paddingRight: 20 }}>
                  <h3>Select Date Range</h3>
                </Row>
              </Col>
              <Col span={10}>
                <Row>
                  <RangePicker
                    size="large"
                    value={dateRange && dateRange[0] && dateRange[1] ? dateRange : null}
                    onChange={(dateObjArr, dateStrArr) => {
                      this.handleDateRangeChange(dateObjArr, dateStrArr);
                    }}
                  />
                </Row>
              </Col>
              <Col span={6}>
                <Row>
                  <Button
                    width={180}
                    height={50}
                    style={{ backgroundColor: 'var(--adminPrimary)', marginLeft: 5 }}
                    textStyle={{ color: 'white', fontSize: 18 }}
                    borderRadius={5}
                    onClick={this.handleGetStatement}
                  >
                    Get Statement
                  </Button>
                </Row>
              </Col>
            </Row>
            {/* Statement Row */}
            <Divider style={{ borderColor: 'transparent' }} />
            <Row>
              <Col span={24}>{parse(statement)}</Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default connect(state => ({ user: state.user }), { receiveCurrAccount })(AdminStatements);
