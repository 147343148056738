import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal';

class LoadingModal extends Component {
  render() {
    const { visible, msg = null, clientStyle } = this.props;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    return (
      <Modal
        visible={visible}
        width={700}
        maskClosable={false}
        closable={false}
        title={null}
        footer={null}
        destroyOnClose={true}
        showCloseBtn={false}
      >
        <Row style={{ fontSize: 18, fontWeight: 'bold' }} justify="center" align="middle">
          <FontAwesomeIcon icon={faSpinner} size="xl" color={clientStyle.primary} spin />
          <h3 style={{ marginLeft: 20, ...titleStyle }}>{msg}</h3>
        </Row>
      </Modal>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(LoadingModal);
