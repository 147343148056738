import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import TextTransition, { presets } from 'react-text-transition';
import VTTextTransition from '../TextTransition';
import { CSSTransition, TransitionGroup, SwitchTransition } from 'react-transition-group';
import { getItemInformation, getPizzaPrice } from '../../api';
import MenuItemModsSelector from '../../components/MenuItemModsSelector';
import MenuItemSizeSelector from '../MenuItemSizeSelector';
import MenuPizzaModal from '../../components/MenuPizzaModal';
import Button from '../Button';
import PackageSelectedItemsRow from '../../components/PackageSelectedItemsRow';
import { toCamel } from '../../utils/camelCase';

import './index.css';

class PackageItemSelector extends Component {
  state = {
    animated: false,
    combos: [],
    comboSelected: {},
    packageDetails: [],
    itemSelected: {},
    sizeModalVisible: false,
    modsModalVisible: false,
    pizzaModalVisible: false,
    itemSize: '',
    pizzaTotalPrice: 0,
    packageList: [],
  };

  init = () => {
    const { packageItemInfos } = this.props || [];
    if (packageItemInfos && packageItemInfos.length > 0) {
      const combosArr = JSON.parse(JSON.stringify(packageItemInfos));
      this.setState({
        combos: combosArr,
        comboSelected: combosArr[0],
        packageDetails: [],
      });
    } else {
      this.setState({
        combos: [],
        comboSelected: {},
        packageDetails: [],
        itemSelected: {},
        itemSize: '',
        packageList: [],
      });
    }
  };

  handlePackageItemCancel = () => {
    this.props.onPackageItemCancel();
    this.setState({
      combos: [],
      comboSelected: {},
      packageDetails: [],
      itemSelected: {},
      itemSize: '',
      packageList: [],
    });
  };

  handlePrevBtn = index => {
    const { combos } = this.state;
    if (index === 0) return;
    let nextIndex;
    if (index > 0) {
      nextIndex = index - 1;
      this.setState({ comboSelected: combos[nextIndex] });
    }
  };

  handleNextBtn = index => {
    const { combos, comboSelected } = this.state;
    const combo = combos.find(i => i.Guid === comboSelected.Guid) || {};
    const min = combo.MinQty;
    const sum = combo.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
    if (sum < min) {
      this.handleInstructionAnimation();
      return;
    }
    if (sum >= min) {
      if (index === combos.length - 1) return;
      let nextIndex;
      if (index < combos.length - 1) {
        nextIndex = index + 1;
        this.setState({ comboSelected: combos[nextIndex] });
      }
    }
  };

  handleInstructionAnimation = () => {
    const { animated } = this.state;
    if (animated) {
      clearTimeout(this.animationTimer);
      this.animationTimer();
    }
    if (!animated) {
      this.setState({ animated: true });
      this.animationTimer();
    }
  };

  animationTimer = () => {
    setTimeout(() => {
      this.setState({ animated: false });
    }, 900);
  };

  prunePrice = i => {
    let price = '';
    const { packageType } = this.props;
    if (packageType !== '1') {
      price = '';
    }
    if (packageType === '1') {
      if (i.Price === 0 && i.SizeItemInfoList.length === 0) {
        price = '$0.00';
      } else if ((i.Price === 0 || i.Price !== 0) && i.SizeItemInfoList.length === 0) {
        price = `$${i.Price.toFixed(2)}`;
      } else if ((i.Price === 0 || i.Price !== 0) && i.SizeItemInfoList.length > 0) {
        const arr = i.SizeItemInfoList.map(j => j.BasePrice);
        const max = Math.max(...arr);
        const min = Math.min(...arr);
        if (min === max) {
          price = `$${max.toFixed(2)}`;
        }
        if (min !== max) {
          price = `$${min.toFixed(2)}-${max.toFixed(2)}`;
        }
      }
    }
    return price;
  };

  handleMinusOne = item => {
    const { combos, comboSelected, packageDetails } = this.state;
    const combo = combos.find(i => i.Guid === comboSelected.Guid);
    const { ItemList } = combo;
    const comboItem = ItemList.find(i => i.Code === item.Code);
    const combi = packageDetails.find(i => i.code === item.Code);
    const otherCombis = packageDetails.filter(i => i.code !== item.Code);
    if (combi) {
      if (combi.qty > 1) {
        combi.qty = combi.qty - 1;
        comboItem.Qty = comboItem.Qty - 1;
        this.setState({ combos, packageDetails }, () => {
          this.flattenPackageDetails();
        });
      } else if (combi.qty === 1) {
        combi.qty = 0;
        comboItem.Qty = 0;
        this.setState({ combos, packageDetails: otherCombis }, () => {
          this.flattenPackageDetails();
        });
      }
    } else {
      return;
    }
  };

  handlePlusClick = async item => {
    const { packageType } = this.props;
    const re = await getItemInformation(item.Code, packageType);
    let selectedItem = {};
    if (re.result === 'OK' && re.data) {
      selectedItem = re.data;
    }
    this.setState({ itemSelected: selectedItem });

    const { packageDetails, combos, comboSelected } = this.state;
    const isExist = packageDetails.filter(i => i.code === item.Code).length > 0;
    const cCombi = packageDetails.find(i => i.code === item.Code);
    const combo = combos.find(i => i.Guid === comboSelected.Guid) || {};
    const max = combo.MaxQty;
    let maxed = false;
    const sum = combo.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);

    if (max > 0 && sum >= max) {
      maxed = true;
      this.handleInstructionAnimation();
      return;
    }

    const combi = {};
    if (!maxed && !cCombi) {
      combi.code = selectedItem.Code;
      combi.description = selectedItem.Description;
      if (packageType !== '1') {
        combi.price = '0.00';
      }
      if (packageType === '1') {
        if (selectedItem.SizeItemInfoList.length > 0) {
          combi.price = selectedItem.SizeItemInfoList[0].BasePrice.toFixed(2);
        }
        if (selectedItem.SizeItemInfoList.length === 0) {
          combi.price = selectedItem.Price.toFixed(2);
        }
      }
      combi.qty = 0;
      combi.error = selectedItem.Error;
      combi.errorMessage = '';
      combi.dependencyCargo = {};
      combi.type = selectedItem.Type;
      combi.sizeCode = '';
      combi.modifierDetails = [];
      combi.pizzaDetails = [];
      combi.matrixDetails = [];
      this.setState({ packageDetails: [...packageDetails, combi], itemSelected: selectedItem });
    }

    const { SizeItemInfoList, ModifierInfos } = selectedItem;
    //Item has sizes, qty not maxed, not in packageDetails, open size selector
    if (
      SizeItemInfoList &&
      SizeItemInfoList.length > 0 &&
      !maxed &&
      (!cCombi || (cCombi && cCombi.qty === 0))
    ) {
      this.setState({ sizeModalVisible: true });
    }

    //Item has sizes, qty not maxed, already in packageDetails, go to add one;
    if (SizeItemInfoList && SizeItemInfoList.length > 0 && !maxed && cCombi && cCombi.qty > 0) {
      this.setState({ itemSelected: selectedItem }, () => {
        this.handleAddOne();
      });
    }

    //Item has no sizes, but has mods, qty not maxed, not in packageDetails, open mods selector
    if (
      (!SizeItemInfoList || SizeItemInfoList.length === 0) &&
      ModifierInfos &&
      ModifierInfos.length > 0 &&
      !maxed &&
      !isExist
    ) {
      this.setState(
        { packageDetails: [...packageDetails, combi], itemSelected: selectedItem },
        () => {
          this.setState({ modsModalVisible: true });
        },
      );
    }

    //Item has no sizes, but has mods, qty not maxed, already in packageDetails, go to add one
    if (
      (!SizeItemInfoList || SizeItemInfoList.length === 0) &&
      ModifierInfos &&
      ModifierInfos.length > 0 &&
      !maxed &&
      isExist
    ) {
      this.setState({ itemSelected: selectedItem }, () => {
        this.handleAddOne();
      });
    }

    //Item has no sizes nor mods, qty not maxed, go to add one;
    if (
      (!SizeItemInfoList || SizeItemInfoList.length === 0) &&
      (!ModifierInfos || ModifierInfos.length === 0) &&
      !maxed
    ) {
      this.setState({ itemSelected: selectedItem }, () => {
        this.handleAddOne();
      });
    }
  };

  handlePizzaModalCancel = () => {
    const { packageDetails, itemSelected } = this.state;
    const otherCombis = packageDetails.filter(i => i.code !== itemSelected.Code);
    this.setState({ pizzaModalVisible: false, itemSize: '', packageDetails: otherCombis });
  };

  handlePizzaModalRedo = () => {
    const { packageDetails, itemSelected } = this.state;
    const otherCombis = packageDetails.filter(i => i.code !== itemSelected.Code);
    this.setState({ pizzaModalVisible: false, itemSize: '', packageDetails: otherCombis }, () => {
      this.handlePlusClick(itemSelected);
    });
  };

  handleAddOne = () => {
    const { combos, comboSelected, packageDetails, itemSelected } = this.state;
    const combo = combos.find(i => i.Guid === comboSelected.Guid) || {};
    const { ItemList } = combo;
    const comboItem = ItemList.find(i => i.Code === itemSelected.Code);
    const max = combo.MaxQty;
    const min = combo.MinQty;
    const combi = packageDetails.find(i => i.code === itemSelected.Code);
    if (itemSelected.subMods) {
      combi.modifierDetails = itemSelected.subMods;
    }

    if ((max === 0 && min === 0) || (max === 0 && min > 0)) {
      combi.qty = combi.qty + 1;
      comboItem.Qty = comboItem.Qty + 1;
      this.setState({ combos, packageDetails, itemSize: '' }, () => {
        this.flattenPackageDetails();
      });
    } else if (max > 0) {
      const sum = combo.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
      if (sum < max) {
        combi.qty = combi.qty + 1;
        comboItem.Qty = comboItem.Qty + 1;
        this.setState({ combos, packageDetails, itemSize: '' }, () => {
          if (!itemSelected.subMods || itemSelected.subMods.length === 0) {
            this.checkAutoNext();
          }
          this.flattenPackageDetails();
        });
      } else {
        return;
      }
    }
  };

  checkAutoNext = () => {
    const { combos, comboSelected } = this.state;
    const combo = combos.find(i => i.Guid === comboSelected.Guid) || {};
    const max = combo.MaxQty;
    const sum = combo.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
    const comboIndex = combos.findIndex(i => i.Guid === comboSelected.Guid);
    if (sum === max) {
      this.handleNextBtn(comboIndex);
    }
  };

  handleItemSizeModalOK = info => {
    const { itemSelected, packageDetails } = this.state;
    const combi = packageDetails.find(i => i.code === itemSelected.Code) || {};
    combi.description = info.description;
    combi.sizeCode = info.sizeCode;
    combi.price = info.price;
    itemSelected.size = info.size;
    this.setState(
      {
        packageDetails,
        itemSize: info.sizeCode,
        itemSelected,
      },
      () => {
        const { ModifierInfos, Type } = itemSelected;
        if (Type === 'I') {
          this.setState({ sizeModalVisible: false, pizzaModalVisible: true });
        }
        if (Type !== 'I' && ModifierInfos && ModifierInfos.length > 0) {
          this.setState({ sizeModalVisible: false, modsModalVisible: true });
        }

        if (Type !== 'I' && (!ModifierInfos || ModifierInfos.length === 0)) {
          this.setState({ sizeModalVisible: false });
          this.handleAddOne();
        }
      },
    );
  };

  handleItemSizeModalCancel = combi => {
    const { packageDetails } = this.state;
    const otherCombis = packageDetails.filter(i => i.code !== combi.code);
    this.setState({ sizeModalVisible: false, itemSize: '', packageDetails: otherCombis });
  };

  handleModsModalRedo = () => {
    const { packageDetails, itemSelected } = this.state;
    const otherCombis = packageDetails.filter(i => i.code !== itemSelected.Code);
    this.setState({ modsModalVisible: false, itemSize: '', packageDetails: otherCombis }, () => {
      this.handlePlusClick(itemSelected);
    });
  };

  handleModsModalCancel = () => {
    const { packageDetails, itemSelected } = this.state;
    const otherCombis = packageDetails.filter(i => i.code !== itemSelected.Code);
    this.setState({ modsModalVisible: false, itemSize: '', packageDetails: otherCombis });
  };

  handleAddMods = mods => {
    const { packageDetails, itemSelected } = this.state;
    const combi = packageDetails.find(i => i.code === itemSelected.Code) || {};
    combi.modifierDetails = mods;
    this.setState({ modsModalVisible: false, packageDetails }, () => {
      this.handleAddOne();
    });
  };

  flattenPackageItemMods = (modi, tier) => {
    const arr = [];
    if (modi.modifierDetails && modi.modifierDetails.length > 0) {
      modi.modifierDetails.forEach(i => {
        switch (tier) {
          case 0:
            i.description = `${i.description}`;
            break;
          case 1:
            i.description = `=>${i.description}`;
            break;
          case 2:
            i.description = `==>${i.description}`;
            break;
          case 3:
            i.description = `===>${i.description}`;
            break;
          case 4:
            i.description = `====>${i.description}`;
            break;
          case 5:
            i.description = `=====>${i.description}`;
            break;
          default:
            break;
        }
        arr.push(i);
        if (i.modifierDetails && i.modifierDetails.length > 0) {
          const nextTier = tier + 1;
          const subArr = this.flattenPackageItemMods(i, nextTier);
          if (subArr.length > 0) {
            subArr.forEach(j => {
              switch (nextTier) {
                case 1:
                  j.description = `=>${j.description.slice(2)}`;
                  break;
                case 2:
                  j.description = `==>${j.description.slice(3)}`;
                  break;
                case 3:
                  j.description = `===>${j.description.slice(4)}`;
                  break;
                case 4:
                  j.description = `====>${j.description.slice(5)}`;
                  break;
                case 5:
                  j.description = `=====>${j.description.slice(6)}`;
                  break;
                default:
                  break;
              }
              arr.push(j);
            });
          }
        }
      });
    }
    return arr;
  };

  flattenPackageDetails = () => {
    const { packageDetails } = this.state;
    let newArr = JSON.parse(JSON.stringify(packageDetails));
    newArr = newArr.map(i => {
      if (i.modifierDetails.length > 0) {
        i.modifierDetails = this.flattenPackageItemMods(i, 0);
      }
      return i;
    });
    this.setState({ packageList: newArr });
  };

  handleOKBtn = () => {
    const { combos, comboSelected, packageDetails } = this.state;
    const combo = combos.find(i => i.Guid === comboSelected.Guid) || {};
    const min = combo.MinQty;
    const sum = combo.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
    if (sum < min) {
      this.handleInstructionAnimation();
      return;
    }
    this.props.onSelectionChange(packageDetails);
  };

  //isOK: 1 - "OK" button pressed; 0 - only update price
  handleAddPizzaDetails = (pizzaDetails, isOK) => {
    const { itemSelected, packageDetails } = this.state;
    const combi = packageDetails.find(i => i.code === itemSelected.Code) || {};
    combi.pizzaDetails = pizzaDetails;
    this.setState({ packageDetails }, () => {
      if (isOK === 1) {
        this.getPizzaItemPrice();
        if (itemSelected.ModifierInfos.length > 0) {
          this.setState({ pizzaModalVisible: false, modsModalVisible: true });
        }
      }
      if (isOK === 0) {
        this.getPizzaItemPrice();
      }
    });
  };

  getPizzaItemPrice = async () => {
    const { packageType } = this.props;
    const { packageDetails, itemSelected } = this.state;
    const combi = packageDetails.find(i => i.code === itemSelected.Code) || {};
    const checkItem = {};
    checkItem.code = combi.code;
    checkItem.description = combi.description;
    checkItem.price = combi.price;
    checkItem.qty = 1;
    checkItem.error = combi.error;
    checkItem.errorMessage = combi.errorMessage;
    checkItem.dependencyCargo = combi.dependencyCargo;
    checkItem.type = combi.type;
    checkItem.sizeCode = combi.sizeCode;
    checkItem.modifierDetails = combi.modifierDetails;
    checkItem.packageDetails = combi.packageDetails || [];
    checkItem.pizzaDetails = combi.pizzaDetails || [];
    checkItem.matrixDetails = combi.matrixDetails;
    checkItem.interStoreNumber = '';
    checkItem.guestNumber = combi.guestNumber || -1;
    checkItem.packageType = packageType;

    const re = await getPizzaPrice(checkItem);
    if (re.result === 'OK') {
      const arr = toCamel(re.pizzaDetails);
      combi.pizzaDetails = arr;
      this.setState({ pizzaTotalPrice: re.price, packageDetails });
    }
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { packageItemInfos } = this.props;
    if (packageItemInfos !== prevProps.packageItemInfos) {
      this.init();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.animationTimer);
  }

  render() {
    const {
      animated,
      combos,
      comboSelected,
      sizeModalVisible,
      modsModalVisible,
      pizzaModalVisible,
      packageDetails,
      itemSelected,
      packageList,
      pizzaTotalPrice,
    } = this.state;
    const { packageType, packagePrice, clientStyle } = this.props;
    const combo = combos.find(i => i.Guid === comboSelected.Guid) || {};
    const { ItemList, SortingBy } = combo || [];
    let list = [];
    if (SortingBy === 0) {
      const o = ItemList.reduce((groups, item) => {
        const group = groups[item.TagCode] || [];
        group.push(item);
        groups[item.TagCode] = group;
        return groups;
      }, {});
      for (const key in o) {
        if (Object.hasOwnProperty.call(o, key)) {
          list.push({
            tagCode: key,
            tagItems: o[key],
            tagDisplayName: o[key][0].TagDisplayName || '',
          });
        }
      }
      list = list.sort(
        (a, b) => parseInt(a.tagItems[0]?.TagIndex) - parseInt(b.tagItems[0]?.TagIndex),
      );
    } else {
      list = [{ tagCode: 'NotSorted', tagItems: ItemList, tagDisplayName: '' }];
    }
    //combi: selected item in combo
    const combi = packageDetails.find(i => i.code === itemSelected.Code) || {};
    const comboIndex = combos.findIndex(i => i.Guid === comboSelected.Guid);
    let la = true,
      ra = true;
    if (comboIndex === 0) {
      la = false;
    }
    if (combos.length === 1 || (combos.length > 1 && comboIndex === combos.length - 1)) {
      ra = false;
    }

    const max = combo.MaxQty;
    const min = combo.MinQty;
    let sum = 0,
      grayOut = false;
    if (ItemList && ItemList.length > 0) {
      sum = ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
    }
    if (max > 0) {
      grayOut = sum === max;
    }

    let ins = '';
    if (min === 0 && max === 0) {
      ins = '';
    }
    if (min > 0 && max === 0) {
      ins = `Select minimum ${min}`;
    }
    if (min === 0 && max > 0) {
      ins = `Select maximum ${max}`;
    }
    if (min > 0 && max > 0) {
      ins = `Select minimum ${min}, maximum ${max}`;
    }

    const packageSelectedTotal =
      (
        packageList
          .map(i => {
            let iSub = i.qty * parseFloat(i.price),
              mSub = 0,
              pSub = 0;
            if (i.modifierDetails.length > 0) {
              mSub =
                i.modifierDetails.map(j => parseFloat(j.price) * j.qty).reduce((a, b) => a + b, 0) *
                i.qty;
            }
            if (i.pizzaDetails.length > 0) {
              pSub =
                i.pizzaDetails.map(k => parseFloat(k.price) * k.qty).reduce((e, f) => e + f, 0) *
                i.qty;
            }
            return iSub + mSub + pSub;
          })
          .reduce((c, d) => c + d, 0) + parseFloat(packagePrice)
      ).toFixed(2) || '0.00';

    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };

    return (
      <>
        <div>
          <Row>
            <Col span={10}>
              <Row style={{ padding: '0 8px' }}>
                <Col span={24}>
                  <Row justify="space-between" align="bottom">
                    <Col span={12}>
                      <Button
                        width={120}
                        height={50}
                        style={{
                          fontSize: 18,
                          color: clientStyle.colorTwo,
                          backgroundColor: clientStyle.primary,
                        }}
                        borderRadius={5}
                        onClick={this.handlePackageItemCancel}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Row justify="space-between" align="bottom">
                        <Col>
                          <Row>
                            <TextTransition
                              style={{ ...titleStyle, fontSize: 18, fontWeight: 'bold' }}
                            >{`$${packageSelectedTotal}`}</TextTransition>
                          </Row>
                        </Col>
                        <Col>
                          <Row justify="end" style={{ ...titleStyle }}>
                            <div
                              className="menu-package-item-sum-title"
                              style={{ borderBottom: `3px solid ${clientStyle.colorOne}` }}
                            >
                              Summary
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{ padding: '10px 8px 0', height: window.innerHeight - 120 }}
                className="menu-package-selector-selected-pane"
              >
                <Col span={24}>
                  <TransitionGroup enter={true} exit={true} appear={true}>
                    {packageList.map(
                      i =>
                        i.qty > 0 && (
                          <CSSTransition
                            key={i.code}
                            timeout={500}
                            classNames="menu-package-selector-selected-item"
                          >
                            <PackageSelectedItemsRow
                              key={i.code}
                              item={i}
                              packageType={packageType}
                            />
                          </CSSTransition>
                        ),
                    )}
                  </TransitionGroup>
                </Col>
              </Row>
            </Col>
            <Col span={14}>
              <Row style={{ padding: '0 8px' }} className="menu-item-package-drawer-selector">
                <Col span={24}>
                  <Row
                    justify="space-between"
                    align="middle"
                    className="menu-item-package-drawer-sticky-header"
                    style={{ backgroundColor: clientStyle.bgColor }}
                  >
                    <Col span={24}>
                      <Row align="middle">
                        <Col span={5}>
                          <Row justify="start">
                            {la ? (
                              <Button
                                width={120}
                                height={50}
                                style={{
                                  fontSize: 18,
                                  color: clientStyle.colorTwo,
                                  backgroundColor: clientStyle.primary,
                                }}
                                borderRadius={5}
                                onClick={() => {
                                  this.handlePrevBtn(comboIndex);
                                }}
                              >
                                Previous
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Row>
                        </Col>
                        <Col span={14}>
                          <Row justify="center">
                            {/* <div
                              style={{
                                ...titleStyle,
                                fontWeight: 'bold',
                                fontSize: 18,
                                animation: animated
                                  ? 'horizontal-shaking 700ms ease-out 300ms'
                                  : '',
                              }}
                            >
                              {comboSelected.CustomMessage ? comboSelected.CustomMessage : ''}
                            </div> */}
                          </Row>
                        </Col>
                        <Col span={5}>
                          <Row justify="end">
                            {ra ? (
                              <Button
                                width={120}
                                height={50}
                                style={{
                                  fontSize: 18,
                                  color: clientStyle.colorTwo,
                                  backgroundColor: clientStyle.primary,
                                }}
                                borderRadius={5}
                                onClick={() => {
                                  this.handleNextBtn(comboIndex);
                                }}
                                className="menu-item-mods-modal-next-button"
                              >
                                Next
                              </Button>
                            ) : (
                              <Button
                                width={120}
                                height={50}
                                style={{
                                  fontSize: 18,
                                  color: clientStyle.colorTwo,
                                  backgroundColor: clientStyle.primary,
                                }}
                                borderRadius={5}
                                onClick={() => {
                                  this.handleOKBtn();
                                }}
                              >
                                OK
                              </Button>
                            )}
                          </Row>
                        </Col>
                      </Row>
                      <Row justify="center" style={{ padding: '8px 5px' }}>
                        <div
                          style={{
                            ...titleStyle,
                            fontSize: 18,
                            fontWeight: 'bold',
                            animation: animated ? 'horizontal-shaking 700ms ease-out 300ms' : '',
                          }}
                        >
                          <TextTransition inline={true} delay={300} springConfig={presets.default}>
                            {comboSelected?.CustomMessage ? comboSelected?.CustomMessage : ins}
                          </TextTransition>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <SwitchTransition mode="out-in">
                    <CSSTransition
                      key={comboSelected.Guid}
                      timeout={500}
                      classNames="menu-package-items-list"
                    >
                      <div className="menu-package-items-list">
                        {list.map((t, index) => (
                          <div key={index}>
                            {t.tagCode !== 'NotSorted' && list.length > 1 ? (
                              <div
                                className="menu-item-mods-selector-list-tag-row"
                                style={{ ...titleStyle }}
                              >
                                {t.tagDisplayName ?? ''}
                              </div>
                            ) : null}
                            {t.tagItems?.map(i => (
                              <Row
                                key={i.Code}
                                justify="space-between"
                                align="middle"
                                className="menu-package-item-selector-row"
                              >
                                <Col span={24}>
                                  <Row align="middle" style={{ ...titleStyle }}>
                                    <Col span={18}>
                                      <Row
                                        justify="space-between"
                                        align="middle"
                                        style={{ marginLeft: 10 }}
                                      >
                                        <Col span={17}>
                                          <h3 style={{ padding: '0 2px', ...titleStyle }}>
                                            {i.Description ? i.Description : 'Description N/A'}
                                          </h3>
                                        </Col>
                                        <Col span={7}>
                                          <Row justify="start">
                                            <h3>{this.prunePrice(i)}</h3>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col span={2}>
                                      <Row
                                        className="menu-item-mods-selector-list-minus"
                                        justify="center"
                                        align="middle"
                                        onClick={() => {
                                          this.handleMinusOne(i);
                                        }}
                                      >
                                        <Col>
                                          <FontAwesomeIcon
                                            icon={faCircleMinus}
                                            size="3x"
                                            color={i.Qty === 0 ? '#999' : clientStyle.primary}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col span={2}>
                                      <Row justify="center" align="middle">
                                        <Col>
                                          <h2 style={{ ...titleStyle }}>
                                            <VTTextTransition text={i.Qty} />
                                          </h2>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col span={2}>
                                      <Row
                                        className="menu-item-mods-selector-list-minus"
                                        justify="center"
                                        align="middle"
                                        onClick={() => this.handlePlusClick(i)}
                                      >
                                        <Col>
                                          <FontAwesomeIcon
                                            icon={faCirclePlus}
                                            size="3x"
                                            color={grayOut ? '#999' : clientStyle.primary}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            ))}
                          </div>
                        ))}
                        {/* {ItemList &&
                          ItemList.map(i => (
                            <Row
                              key={i.Code}
                              justify="space-between"
                              align="middle"
                              className="menu-package-item-selector-row"
                            >
                              <Col span={24}>
                                <Row align="middle" style={{ ...titleStyle }}>
                                  <Col span={18}>
                                    <Row
                                      justify="space-between"
                                      align="middle"
                                      style={{ marginLeft: 10 }}
                                    >
                                      <Col span={17}>
                                        <h3 style={{ padding: '0 2px', ...titleStyle }}>
                                          {i.Description ? i.Description : 'Description N/A'}
                                        </h3>
                                      </Col>
                                      <Col span={7}>
                                        <Row justify="start">
                                          <h3>{this.prunePrice(i)}</h3>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={2}>
                                    <Row
                                      className="menu-item-mods-selector-list-minus"
                                      justify="center"
                                      align="middle"
                                      onClick={() => {
                                        this.handleMinusOne(i);
                                      }}
                                    >
                                      <Col>
                                        <FontAwesomeIcon
                                          icon={faCircleMinus}
                                          size="3x"
                                          color={i.Qty === 0 ? '#999' : clientStyle.primary}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={2}>
                                    <Row justify="center" align="middle">
                                      <Col>
                                        <h2 style={{ ...titleStyle }}>
                                          <VTTextTransition text={i.Qty} />
                                        </h2>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={2}>
                                    <Row
                                      className="menu-item-mods-selector-list-minus"
                                      justify="center"
                                      align="middle"
                                      onClick={() => this.handlePlusClick(i)}
                                    >
                                      <Col>
                                        <FontAwesomeIcon
                                          icon={faCirclePlus}
                                          size="3x"
                                          color={grayOut ? '#999' : clientStyle.primary}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          ))} */}
                      </div>
                    </CSSTransition>
                  </SwitchTransition>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {/* Item size modal */}
        <MenuItemSizeSelector
          visible={sizeModalVisible}
          onCancel={() => this.handleItemSizeModalCancel(combi)}
          checkItem={combi}
          sizes={itemSelected?.SizeItemInfoList}
          itemSelected={itemSelected}
          onOK={obj => this.handleItemSizeModalOK(obj)}
        />
        {/* Item mods modal */}
        <MenuItemModsSelector
          width={800}
          visible={modsModalVisible}
          des={combi?.description}
          itemMods={itemSelected?.ModifierInfos ?? []}
          addMods={mods => this.handleAddMods(mods)}
          onCancel={this.handleModsModalCancel}
          onRedo={this.handleModsModalRedo}
          sizes={itemSelected?.SizeItemInfoList ?? []}
        />
        {/* Pizza modal */}
        <MenuPizzaModal
          visible={pizzaModalVisible}
          item={itemSelected}
          checkItem={combi}
          pizzaTotalPrice={pizzaTotalPrice}
          sizeCode={combi.sizeCode}
          onPizzaModalCancel={this.handlePizzaModalCancel}
          onPizzaModalRedo={this.handlePizzaModalRedo}
          addPizzaDetails={(pizzaDetails, isOK) => this.handleAddPizzaDetails(pizzaDetails, isOK)}
        />
      </>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(PackageItemSelector);
