import React, { Component } from 'react';
import { Row, Col, Upload, message } from 'antd';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button';
import './index.css';

export default class ImageUploader extends Component {
  state = {
    imageSrc: '',
    fileList: [],
    base64: '',
  };

  handleRemoveThumbnail = () => {
    this.setState({ fileList: [], imageSrc: '' });
  };

  handleChooseFileBtn = () => {
    return false;
  };

  handleUploadBtn = () => {
    const { fileList, base64 } = this.state;
    if (fileList.length === 0) {
      message.error('There is no image selected!', 3);
      return;
    }
    const file = fileList[0];
    if (file) {
      const { type } = file;
      if (type && type !== 'image/jpeg' && type !== 'image/png') {
        message.error('Only JPG/PGN image types are allowed!', 3);
        return;
      } else if (type && (type === 'image/jpeg' || type === 'image/png')) {
        this.props.onImgUploadClick(base64);
        this.setState({ fileList: [], imageSrc: '', base64: '' });
      }
    }
  };

  handleReaderLoad = readerEvt => {
    let binStr = readerEvt.target.result;
    this.setState({ base64: btoa(binStr) });
  };

  render() {
    const { imageSrc, fileList } = this.state;
    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
        this.setState({ imageSrc: '' });
      },
      onChange: ({ file }) => {
        if (file) {
          const reader = new FileReader();
          reader.onload = this.handleReaderLoad;
          reader.readAsBinaryString(file);
        }
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        this.setState({ imageSrc: URL.createObjectURL(file) });
        return false;
      },
      fileList,
      showUploadList: false,
    };

    return (
      <Row justify="center">
        <SwitchTransition mode="out-in">
          <CSSTransition key={imageSrc} timeout={500} classNames="admin-image-uploader-transition">
            {imageSrc ? (
              //Thumbnail preview before upload
              <Row
                style={{
                  width: 300,
                  height: 200,
                  border: '2px solid var(--adminPrimary)',
                  borderRadius: 8,
                }}
              >
                <Col>
                  <div style={{ width: 300, height: 150, textAlign: 'center', paddingTop: 10 }}>
                    <img src={imageSrc} alt={''} height={140} />
                  </div>
                  {/* Btns */}
                  <Row style={{ marginTop: 8, padding: '0 15px' }} justify="space-between">
                    <Button
                      width={75}
                      height={35}
                      style={{ backgroundColor: 'var(--adminPrimary)' }}
                      textStyle={{ color: '#fff' }}
                      borderRadius={5}
                      onClick={this.handleRemoveThumbnail}
                    >
                      Cancel
                    </Button>
                    <Button
                      width={75}
                      height={35}
                      style={{ backgroundColor: 'var(--adminPrimary)' }}
                      textStyle={{ color: '#fff' }}
                      borderRadius={5}
                      onClick={this.handleUploadBtn}
                    >
                      Upload
                    </Button>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Upload {...props}>
                <div
                  style={{
                    width: 300,
                    height: 200,
                    border: '2px solid var(--adminPrimary)',
                    borderRadius: 8,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFolderOpen}
                    size="4x"
                    color="var(--adminSecondary)"
                    onClick={this.handleAddKioskStationSettingRow}
                  />
                  <div
                    style={{
                      marginTop: 20,
                      fontSize: 16,
                    }}
                  >
                    <Button
                      width={220}
                      height={50}
                      style={{ backgroundColor: 'var(--adminPrimary)' }}
                      textStyle={{ color: '#fff' }}
                      borderRadius={5}
                      onClick={this.handleChooseFileBtn}
                    >
                      Choose Your Own Image
                    </Button>
                  </div>
                </div>
              </Upload>
            )}
          </CSSTransition>
        </SwitchTransition>
      </Row>
    );
  }
}
