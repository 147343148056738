import {
  UPDATE_ORDER,
  DELETE_ORDER_ITEM,
  RECEIVE_PIZZA_ORDER,
  CLEAR_ORDER_ITEMS,
  RECEIVE_USER,
  RESET_USER,
  RECEIVE_ORDER_OPTION,
  RECEIVE_CURRENT_ACCOUNT,
  RECEIVE_LOGIN_TYPE,
  RESET_LOGIN_TYPE,
  RECEIVE_APP_SETTING,
  RECEIVE_GUEST_NAME,
  DELETE_GUEST_NAME,
  RECEIVE_CUSTOMER_ORDER_OPTION,
  RESET_CUSTOMER_ORDER_OPTION,
  RECEIVE_POPUP_ORDER,
  UPDATE_SCANNED_ORDER,
  DELETE_SCANNED_ORDER_ITEM,
  CLEAR_SCANNED_ORDER_ITEMS,
  RECEIVE_APP_MODE,
  RESET_APP_MODE,
  RECEIVE_SCANNED_ORDER,
  UPDATE_CLIENT_STYLE,
  RECEIVE_GUEST_PHONE,
  DELETE_GUEST_PHONE,
  RECEIVE_GUEST_INS,
  DELETE_GUEST_INS,
} from './action-types';

export const updateOrder = data => ({ type: UPDATE_ORDER, data });
export const updateScannedOrder = data => ({ type: UPDATE_SCANNED_ORDER, data });
export const deleteScannedOrderItem = data => ({ type: DELETE_SCANNED_ORDER_ITEM, data });
export const clearScannedOrderItems = () => ({ type: CLEAR_SCANNED_ORDER_ITEMS });
export const receivePizzaOrder = data => ({ type: RECEIVE_PIZZA_ORDER, data });
export const receivePopupOrder = data => ({ type: RECEIVE_POPUP_ORDER, data });
export const delOrderItem = data => ({ type: DELETE_ORDER_ITEM, data });
export const clearOrderItems = () => ({ type: CLEAR_ORDER_ITEMS });
export const receiveUser = data => ({ type: RECEIVE_USER, data });
export const resetUser = () => ({ type: RESET_USER });
export const receiveOrderOption = data => ({ type: RECEIVE_ORDER_OPTION, data });
export const receiveCurrAccount = data => ({ type: RECEIVE_CURRENT_ACCOUNT, data });
export const receiveLoginType = data => ({ type: RECEIVE_LOGIN_TYPE, data });
export const resetLoginType = () => ({ type: RESET_LOGIN_TYPE });
export const receiveAppSetting = data => ({ type: RECEIVE_APP_SETTING, data });
export const receiveGuestName = data => ({ type: RECEIVE_GUEST_NAME, data });
export const deleteGuestName = () => ({ type: DELETE_GUEST_NAME });
export const receiveCustomerOrderOption = data => ({ type: RECEIVE_CUSTOMER_ORDER_OPTION, data });
export const resetCustomerOrderOption = data => ({ type: RESET_CUSTOMER_ORDER_OPTION, data });
export const receiveAppMode = data => ({ type: RECEIVE_APP_MODE, data });
export const resetAppMode = data => ({ type: RESET_APP_MODE, data });
export const receiveScannedOrder = data => ({ type: RECEIVE_SCANNED_ORDER, data });
export const updateClientStyle = data => ({ type: UPDATE_CLIENT_STYLE, data });
export const receiveGuestPhone = data => ({ type: RECEIVE_GUEST_PHONE, data });
export const deleteGuestPhone = () => ({ type: DELETE_GUEST_PHONE });
export const receiveGuestIns = data => ({ type: RECEIVE_GUEST_INS, data });
export const deleteGuestIns = () => ({ type: DELETE_GUEST_INS });
