import React, { Component } from 'react';
import TextTransition, { presets } from 'react-text-transition';

export default class TextTransitionComponent extends Component {
  render() {
    const { text, style } = this.props;
    return (
      <TextTransition inline={false} delay={200} springConfig={presets.default} style={style}>
        {text}
      </TextTransition>
    );
  }
}
