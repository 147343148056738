import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import Card from '../Card';
import Pill from '../Pill';
import './index.css';

class MenuGrouper extends Component {
  state = { expanded: true, groupPaneMaxHeight: null };

  menuGroupRef = React.createRef();

  init = () => {
    const height = this.menuGroupRef?.current?.scrollHeight;
    this.setState({ groupPaneMaxHeight: height });
  };

  handleExpand = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  handleCardPillClick = (e, item) => {
    this.props.onCardPillClick(e, item);
  };

  prunePrice = i => {
    let price = '';
    if (i.Price === 0 && i.SizeItemInfos?.length === 0) {
      price = '';
    } else if ((i.Price === 0 || i.Price !== 0) && i.SizeItemInfos?.length === 0) {
      price = `$${i.Price.toFixed(2)}`;
    } else if ((i.Price === 0 || i.Price !== 0) && i.SizeItemInfos?.length > 0) {
      const arr = i.SizeItemInfos.map(j => j.BasePrice);
      const max = Math.max(...arr);
      const min = Math.min(...arr);
      if (min === max) {
        price = `$${max.toFixed(2)}`;
      }
      if (min !== max) {
        price = `$${min.toFixed(2)}-${max.toFixed(2)}`;
      }
    }
    return price;
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { tag } = this.props;
    if (tag !== prevProps.tag) {
      this.init();
    }
  }

  render() {
    const { expanded, groupPaneMaxHeight } = this.state;
    const {
      style,
      tag,
      cardOrPill = 0,
      height = 40,
      cardWidth,
      cardHeight,
      cardImgHeight,
      pillImgHeight,
      imageDir,
      itemImage,
      bar,
      tagBarCss,
      itemCss,
      clientStyle,
    } = this.props;
    let { paneBg } = this.props;
    if (!paneBg) {
      paneBg = 'linear-gradient(to top, #f3e7e9 0%, #e3eeff 50%, #e3eeff 100%)';
      paneBg = '';
    }

    const width = 400;
    const boxShadow = `0 calc((0.3/400)*${width}px) calc((0.7/400)*${width}px) rgba(0, 0, 0, 0.126),
    0 calc((0.4/400)*${width}px) calc((0.8/400)*${width}px) rgba(0, 0, 0, 0.179),
    0 calc((0.6/400)*${width}px) calc((1.2/400)*${width}px) rgba(0, 0, 0, 0.224),
    0 calc((0.9/400)*${width}px) calc((1.8/400)*${width}px) rgba(0, 0, 0, 0.277),
    0 calc((2/400)*${width}px) calc((4/400)*${width}px) rgba(0, 0, 0, 0.4)`;

    let TagBarBackgroundColor, TagBarFontName, TagBarColor;
    if (clientStyle.storeNumber === '5960' || clientStyle.storeNumber === '3932') {
      TagBarBackgroundColor = `linear-gradient(0deg, ${clientStyle.primary}, ${clientStyle.primary})`;
      TagBarFontName = clientStyle.fontOne;
      TagBarColor = clientStyle.colorTwo;
    } else if (tagBarCss) {
      TagBarBackgroundColor = tagBarCss.BackgroundColor
        ? tagBarCss.BackgroundColor
        : 'linear-gradient(45, #cfd9df 0%, #e2ebf0 100%)';
      TagBarFontName = tagBarCss.FontName ? tagBarCss.FontName : 'Noto-Regular';
      TagBarColor = tagBarCss.Color ? tagBarCss.Color : 'black';
    }

    let ItemBackgroundColor, ItemFontName, ItemColor;
    if (itemCss) {
      ItemBackgroundColor = itemCss.BackgroundColor
        ? itemCss.BackgroundColor
        : 'linear-gradient(120deg, #fdfbfb, #ebedee)';
      ItemFontName = itemCss.FontName ? itemCss.FontName : 'Noto-Regular';
      ItemColor = itemCss.Color ? itemCss.Color : 'black';
    } else {
      ItemBackgroundColor = 'linear-gradient(120deg, #fdfbfb, #ebedee)';
      ItemFontName = 'Noto-Regular';
      ItemColor = 'black';
    }

    return (
      <div className="menu-grouper-container">
        <div
          className="menu-group-title-bar"
          onClick={this.handleExpand}
          style={{
            ...style,
            display: bar ? 'flex' : 'none',
            height,
            backgroundImage: TagBarBackgroundColor,
            padding: `calc((5 / 400)*${width}px) calc((10 / 400)*${width}px)`,
            boxShadow,
            WebkitBoxShadow: boxShadow,
          }}
        >
          <div
            className="menu-group-title"
            style={{
              fontFamily: TagBarFontName,
              color: TagBarColor,
              fontVariationSettings: clientStyle.fontVariationSettings,
              textTransform: clientStyle.textTransform,
            }}
          >
            <span>{tag.TagDescription}</span>
          </div>
          <div className="menu-group-title-arrow">
            <FontAwesomeIcon
              icon={faAnglesUp}
              size="lg"
              color={TagBarColor}
              rotation={expanded ? 0 : 180}
            />
          </div>
        </div>
        {cardOrPill === 0 ? (
          <div
            className="menu-group-content"
            ref={this.menuGroupRef}
            style={{
              overflow: 'hidden',
              maxHeight: expanded ? groupPaneMaxHeight : 0,
              transition: 'max-height 500ms ease-in-out',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              // alignItems: 'flex-start',
              backgroundImage: paneBg,
              boxShadow: paneBg ? boxShadow : '',
              WebkitBoxShadow: paneBg ? boxShadow : '',
            }}
          >
            {tag.Items.map(i => (
              <div className="menu-group-item-card" key={i.Code}>
                <Card
                  id={`menuItemCard-${i.Code}`}
                  cardOrPill="card"
                  width={cardWidth}
                  height={cardHeight}
                  imgHeight={cardImgHeight}
                  item={i}
                  img={i.ImageUrl}
                  imageDir={imageDir}
                  itemImage={itemImage}
                  title={i.Description}
                  price={this.prunePrice(i)}
                  cal={i.CalorieInfo}
                  backgroundImage={ItemBackgroundColor}
                  titleStyle={{
                    fontSize: cardWidth > 149 ? `${cardWidth / 190}rem` : '1rem',
                    fontFamily: ItemFontName,
                    color: ItemColor,
                  }}
                  color="black"
                  onCardClick={e => this.handleCardPillClick(e, i)}
                />
              </div>
            ))}
          </div>
        ) : (
          <Row
            className="menu-group-content-pill"
            gutter={8}
            ref={this.menuGroupRef}
            style={{
              overflow: 'hidden',
              maxHeight: expanded ? groupPaneMaxHeight : 0,
              transition: 'max-height 500ms ease-in-out',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              // alignItems: 'flex-start',
              backgroundImage: paneBg,
              boxShadow: paneBg ? boxShadow : '',
              WebkitBoxShadow: paneBg ? boxShadow : '',
            }}
          >
            {tag.Items.map(i => (
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} key={i.Code}>
                <div className="menu-group-item-pill" key={i.Code}>
                  <Pill
                    id={`menuItemCard-${i.Code}`}
                    imgHeight={pillImgHeight}
                    item={i}
                    img={i.ImageUrl}
                    imageDir={imageDir}
                    itemImage={itemImage}
                    price={this.prunePrice(i)}
                    onCardClick={e => this.handleCardPillClick(e, i)}
                  />
                </div>
              </Col>
            ))}
          </Row>
        )}
      </div>
    );
  }
}

export default connect(state => ({ clientStyle: state.clientStyle }), {})(MenuGrouper);
