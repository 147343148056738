import React, { Component } from 'react';
import { Row, Col, Divider } from 'antd';
import MenuGrouper from '../MenuGrouper';
import VSBackgroundColorPicker from '../VSBackgroundColorPicker';
import VSFontColorPicker from '../VSFontColorPicker';
import VSFontPicker from '../VSFontPicker';

class VSTagBar extends Component {
  state = {
    direction: '0',
    colorStop1: '',
    colorStop2: '',
    color: '#000000',
    fontFamily: '',
  };

  tag = {
    TagDescription: 'Soft Drinks',
    Items: [],
  };

  init = () => {
    const { view } = this.props;
    //Set tag bar backgroungImage
    const css = view?.CustomizedCss.find(i => i.Type === 'TagBarCss');
    if (css) {
      const { FontName, Color, BackgroundColor } = css;
      if (!BackgroundColor) {
        this.setState({
          direction: '0',
          colorStop1: '#cfd9df',
          colorStop2: '#e2ebf0',
          color: Color,
          fontFamily: FontName,
        });
      }
      if (BackgroundColor && BackgroundColor.indexOf('(') < 0) {
        this.setState({
          direction: '0',
          colorStop1: BackgroundColor,
          colorStop2: BackgroundColor,
          color: Color,
          fontFamily: FontName,
        });
      }
      if (BackgroundColor.indexOf('(') > 0) {
        const arr = BackgroundColor.substring(
          BackgroundColor.indexOf('(') + 1,
          BackgroundColor.indexOf(')'),
        ).split(',');
        this.setState({
          direction: arr[0].split('deg')[0].trim(),
          colorStop1: arr[1].trim(),
          colorStop2: arr[2].trim(),
          color: Color,
          fontFamily: FontName,
        });
      }
    } else {
      this.setState({
        direction: '0',
        colorStop1: '#cfd9df',
        colorStop2: '#e2ebf0',
        color: '#000000',
        fontFamily: 'Segoe UI',
      });
    }
  };

  handlePickerChange = (dataType, value) => {
    if (dataType === 'colors') {
      const { direction, colorStop1, colorStop2 } = value;
      this.setState({ direction, colorStop1, colorStop2 }, () => {
        this.updateChanges();
      });
    } else {
      this.setState({ [dataType]: value }, () => {
        this.updateChanges();
      });
    }
  };

  updateChanges = () => {
    const { direction, colorStop1, colorStop2, color, fontFamily } = this.state;
    const obj = {};
    obj.direction = direction === '' ? 0 : direction;
    obj.colorStop1 = colorStop1;
    obj.colorStop2 = colorStop2;
    obj.color = color;
    obj.fontFamily = fontFamily;
    this.props.onTagBarChange(obj);
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { direction, colorStop1, colorStop2, color, fontFamily } = this.state;
    const { view } = this.props;
    const tagBarCss = view?.CustomizedCss.find(i => i.Type === 'TagBarCss');
    return (
      <Row>
        <Col span={16}>
          <Row style={{ padding: 5 }}>
            <Col span={24}>
              <Row>
                <Col>
                  <Row>
                    <h3>Background Color</h3>
                  </Row>
                  <VSBackgroundColorPicker
                    direction={direction}
                    colorStop1={colorStop1}
                    colorStop2={colorStop2}
                    onColorChange={colors => this.handlePickerChange('colors', colors)}
                  />
                </Col>
              </Row>
              <Divider style={{ borderColor: 'transparent' }} />
              <Row>
                <Col>
                  <Row>
                    <h3>Font Color</h3>
                  </Row>
                  <VSFontColorPicker
                    color={color}
                    onFontColorChange={v => {
                      this.handlePickerChange('color', v);
                    }}
                  />
                </Col>
              </Row>
              <Divider style={{ borderColor: 'transparent' }} />
              <Row>
                <Col>
                  <Row>
                    <h3>Font</h3>
                  </Row>
                  <VSFontPicker
                    font={fontFamily}
                    onFontChange={v => {
                      this.handlePickerChange('fontFamily', v);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <MenuGrouper tag={this.tag} bar={true} tagBarCss={tagBarCss} />
          </Row>
        </Col>
      </Row>
    );
  }
}

export default VSTagBar;
