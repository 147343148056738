export const UPDATE_ORDER = 'update_order';
export const DELETE_ORDER_ITEM = 'delete_order_item';
export const CLEAR_ORDER_ITEMS = 'clear_order_items';
export const RECEIVE_PIZZA_ORDER = 'receive_pizza_order';
export const RECEIVE_POPUP_ORDER = 'receive_popup_order';
export const RECEIVE_USER = 'receive_user';
export const RESET_USER = 'reset_user';
export const RECEIVE_ORDER_OPTION = 'receive_order_option';
export const RECEIVE_CURRENT_ACCOUNT = 'receive_current_account';
export const RECEIVE_LOGIN_TYPE = 'receive_login_type';
export const RESET_LOGIN_TYPE = 'reset_login_type';
export const RECEIVE_APP_SETTING = 'receive_app_setting';
export const RECEIVE_GUEST_NAME = 'receive_guest_name';
export const DELETE_GUEST_NAME = 'delete_guest_name';
export const RECEIVE_CUSTOMER_ORDER_OPTION = 'receive_customer_order_option';
export const RESET_CUSTOMER_ORDER_OPTION = 'reset_customer_order_option';
export const UPDATE_SCANNED_ORDER = 'update_scanned_order';
export const DELETE_SCANNED_ORDER_ITEM = 'delete_scanned_order_item';
export const CLEAR_SCANNED_ORDER_ITEMS = 'clear_scanned_order_items';
export const RECEIVE_APP_MODE = 'receive_app_mode';
export const RESET_APP_MODE = 'reset_app_mode';
export const RECEIVE_SCANNED_ORDER = 'receive_scanned_order';
export const UPDATE_CLIENT_STYLE = 'update_client_style;';
export const RECEIVE_GUEST_PHONE = 'receive_guest_phone';
export const DELETE_GUEST_PHONE = 'delete_guest_phone';
export const RECEIVE_GUEST_INS = 'receive_guest_instruction';
export const DELETE_GUEST_INS = 'delete_guest_instruction';
