import { combineReducers } from 'redux';
import {
  UPDATE_ORDER,
  DELETE_ORDER_ITEM,
  RECEIVE_PIZZA_ORDER,
  CLEAR_ORDER_ITEMS,
  RECEIVE_USER,
  RESET_USER,
  RECEIVE_ORDER_OPTION,
  RECEIVE_CURRENT_ACCOUNT,
  RECEIVE_LOGIN_TYPE,
  RESET_LOGIN_TYPE,
  RECEIVE_APP_SETTING,
  RECEIVE_GUEST_NAME,
  DELETE_GUEST_NAME,
  RECEIVE_CUSTOMER_ORDER_OPTION,
  RESET_CUSTOMER_ORDER_OPTION,
  RECEIVE_POPUP_ORDER,
  UPDATE_SCANNED_ORDER,
  DELETE_SCANNED_ORDER_ITEM,
  CLEAR_SCANNED_ORDER_ITEMS,
  RECEIVE_APP_MODE,
  RESET_APP_MODE,
  RECEIVE_SCANNED_ORDER,
  UPDATE_CLIENT_STYLE,
  RECEIVE_GUEST_PHONE,
  DELETE_GUEST_PHONE,
  RECEIVE_GUEST_INS,
  DELETE_GUEST_INS,
} from './action-types';

const initClientStoreNumber = '';
function clientStoreNumber(state = initClientStoreNumber, action) {
  switch (action.type) {
    case UPDATE_CLIENT_STYLE:
      return action.data;
    default:
      return state;
  }
}

const initClientStyle = {
  storeNumber: '',
  fontOne: 'Segoe UI',
  fontTwo: 'Arial',
  fontThree: 'Times',
  primary: 'var(--primary)',
  secondary: 'var(--secondary)',
  accent: '#fff',
  fontVariationSettings: 'normal',
  colorOne: '#000',
  colorTwo: '#fff',
  backdrop: 'rgba(0, 0, 0, 0.3)',
  bgColor: '#f0f2f5',
  textTransform: 'none',
};

function clientStyle(state = initClientStyle, action) {
  switch (action.type) {
    case UPDATE_CLIENT_STYLE:
      switch (action.data) {
        case '3932':
        case '5960':
          return {
            ...state,
            storeNumber: action.data,
            fontOne: 'Segoe UI',
            fontTwo: 'CaslonIonic',
            primary: '#1A402B',
            secondary: '#82BE64',
            accent: '#FAF6E8',
            colorOne: '#1A402B',
            colorTwo: '#FAF6E8',
            // fontVariationSettings: "'wght' 500, 'wdth' 600",
            fontVariationSettings: 'normal',
            backdrop: 'rgba(250, 246, 232, 0.5)',
            bgColor: '#FAF6E8',
            textTransform: 'uppercase',
          };
        default:
          return state;
      }

    default:
      return state;
  }
}

const initKioskSetting = {
  appSetting: {},
  imgSetting: {},
  stationName: '',
};
function kioskSetting(state = initKioskSetting, action) {
  switch (action.type) {
    case RECEIVE_APP_SETTING:
      return action.data;

    default:
      return state;
  }
}

const initGuestName = '';
function guestName(state = initGuestName, action) {
  switch (action.type) {
    case RECEIVE_GUEST_NAME:
      return action.data;
    case DELETE_GUEST_NAME:
      return '';
    default:
      return state;
  }
}

const initPhoneNumber = '';
function guestPhone(state = initPhoneNumber, action) {
  switch (action.type) {
    case RECEIVE_GUEST_PHONE:
      return action.data;
    case DELETE_GUEST_PHONE:
      return '';
    default:
      return state;
  }
}

const initGuestIns = '';
function guestIns(state = initGuestIns, action) {
  switch (action.type) {
    case RECEIVE_GUEST_INS:
      return action.data;
    case DELETE_GUEST_INS:
      return '';
    default:
      return state;
  }
}

const initUser = {};
function user(state = initUser, action) {
  switch (action.type) {
    case RECEIVE_USER:
      return action.data;
    case RECEIVE_CURRENT_ACCOUNT:
      return {
        ...state,
        CurAccountInfo: action.data,
      };
    case RESET_USER:
      return {};
    default:
      return state;
  }
}

const initLoginType = '';
function loginType(state = initLoginType, action) {
  switch (action.type) {
    case RECEIVE_LOGIN_TYPE:
      return action.data;
    case RESET_LOGIN_TYPE:
      return '';
    default:
      return state;
  }
}

/*
Int
0: ordering mode;
1: scan mode;
2: both
*/
const initAppMode = null;
function appMode(state = initAppMode, action) {
  switch (action.type) {
    case RECEIVE_APP_MODE:
      return action.data;
    case RESET_APP_MODE:
      return null;
    default:
      return state;
  }
}

const initOrderOption = [];
function orderOption(state = initOrderOption, action) {
  switch (action.type) {
    case RECEIVE_ORDER_OPTION:
      return action.data;
    default:
      return state;
  }
}

const initCustomerOrderOption = {};
function customerOrderOption(state = initCustomerOrderOption, action) {
  switch (action.type) {
    case RECEIVE_CUSTOMER_ORDER_OPTION:
      return action.data;
    case RESET_CUSTOMER_ORDER_OPTION:
      return {};
    default:
      return state;
  }
}

const initOrder = [];
function order(state = initOrder, action) {
  switch (action.type) {
    case UPDATE_ORDER:
      const isExisting = state.filter(i => i.key === action.data.key).length === 0;
      if (isExisting) {
        return [...state, action.data];
      } else if (isExisting === false) {
        const index = state.findIndex(i => i.key === action.data.key);
        let head = index <= 0 ? [] : state.slice(0, index);
        let tail = state.slice(index + 1);
        return [...head, action.data, ...tail];
      }
      break;
    case RECEIVE_POPUP_ORDER:
      return [...state, ...action.data];
    case RECEIVE_PIZZA_ORDER:
      return [...state, ...action.data];
    case RECEIVE_SCANNED_ORDER:
      return [...state, ...action.data];
    case DELETE_ORDER_ITEM:
      return [...state.filter(i => i.key !== action.data)];
    case CLEAR_ORDER_ITEMS:
      return [];
    default:
      return state;
  }
}

const initScannedOrder = [];
function scannedOrder(state = initScannedOrder, action) {
  switch (action.type) {
    case UPDATE_SCANNED_ORDER:
      const isExisting = state.filter(i => i.key === action.data.key).length === 0;
      if (isExisting) {
        return [...state, action.data];
      } else if (isExisting === false) {
        const index = state.findIndex(i => i.key === action.data.key);
        let head = index <= 0 ? [] : state.slice(0, index);
        let tail = state.slice(index + 1);
        return [...head, action.data, ...tail];
      }
      break;
    case DELETE_SCANNED_ORDER_ITEM:
      return [...state.filter(i => i.key !== action.data)];
    case CLEAR_SCANNED_ORDER_ITEMS:
      return [];
    default:
      return state;
  }
}

export default combineReducers({
  orderOption,
  order,
  scannedOrder,
  user,
  loginType,
  appMode,
  kioskSetting,
  guestName,
  customerOrderOption,
  clientStyle,
  clientStoreNumber,
  guestPhone,
  guestIns,
});
