import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PageTransition } from '@steveeeie/react-page-transition';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  receiveAppSetting,
  resetUser,
  clearOrderItems,
  clearScannedOrderItems,
  deleteGuestName,
  resetCustomerOrderOption,
  resetLoginType,
  resetAppMode,
  updateClientStyle,
  deleteGuestPhone,
  deleteGuestIns,
} from './redux/actions';
import { Layout, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { getCurrentKioskSetting, appInitCompleted, refreshScreen, errorLog } from './api';
import Start from './pages/Start';
import Login from './pages/Login';
import OrderOptions from './pages/OrderOptions';
import Menu from './pages/MenuPortrait';
import Order from './pages/OrderPortrait';
// import Menu from './pages/Menu';
// import Order from './pages/Order';
import Reservation from './pages/Reservation';
import Admin from './pages/Admin';
import Preview from './pages/Preview';
import Scan from './pages/Scan';
import Spin from './components/Spin';
import IdleTimerModal from './components/IdleTimerModal';

import './App.css';

const { Content } = Layout;

class App extends Component {
  state = {
    spinning: false,
    hasError: '',
    error: '',
    idleTimerModalVisible: false,
  };

  idleConfig = {
    promptTimeout: 1000 * 15,
    startOnMount: true,
    onPrompt: () => this.handleTimerPrompt(),
    onIdleTimerFinish: this.handleIdleTimerFinish,
    timeout:
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? 1000 * 60 * 60 * 24
        : 1000 * 60 * 1 * 1,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  handleTimerPrompt = () => {
    this.setState({ idleTimerModalVisible: true });
  };

  handleIdleTimerFinish = () => {
    this.setState({ idleTimerModalVisible: false });
    this.props.clearOrderItems();
    this.props.clearScannedOrderItems();
    this.props.resetUser();
    this.props.deleteGuestName();
    this.props.deleteGuestPhone();
    this.props.deleteGuestIns();
    this.props.resetCustomerOrderOption();
    this.props.resetLoginType();
    this.props.resetAppMode();
    this.props.history.replace('/');
  };

  handleContinueTransaction = () => {
    this.setState({ idleTimerModalVisible: false });
  };

  restartApp = async () => {
    this.handleIdleTimerFinish();
    window.location.reload();
  };

  componentDidCatch = async (error, info) => {
    const trunckedInfo = info?.componentStack
      ? info?.componentStack?.split('\n').slice(0, 5).join('\n')
      : '';
    const msg = `${error?.message ?? ''} ${trunckedInfo}`;
    await errorLog(msg);
    setTimeout(() => {
      this.restartApp();
    }, 3000);
  };

  componentDidMount = async () => {
    // document.body.style.setProperty('--primary', '#666');
    try {
      this.setState({ spinning: true });
      const re = await getCurrentKioskSetting();
      if (!re || (re && re.result !== 'OK')) throw new Error(re.result);
      const obj = {};
      obj.appSetting = re.appSetting ? re.appSetting : {};
      obj.imgSetting = re.imgSetting ? re.imgSetting : {};
      obj.stationName = re.stationName ? re.stationName : '';
      this.props.receiveAppSetting(obj);
      re.storeNumber && this.props.updateClientStyle(re.storeNumber);
      if (re.storeNumber && (re.storeNumber === '5960' || re.storeNumber === '3932')) {
        document.body.style.setProperty('--primary', '#1A402B');
        document.body.style.setProperty('--secondary', '#82BE64');
        document.body.style.setProperty('--adminPrimary', '#1A402B');
        document.body.style.setProperty('--adminSecondary', '#82BE64');
      }
      const res = await appInitCompleted();
      if (!res || (res && res !== 'OK')) throw new Error(res);
      if (re.appSetting.KioskLoginMode === 3 && re.appSetting.ScanItemMode === 4) {
        this.idleConfig = {};
        this.props.history.replace('/login');
      }
      this.setState({ spinning: false });
    } catch (error) {
      this.setState({ spinning: false });
      message.error('Error obtaining settings!', 3);
    }
  };

  render() {
    const { hasError, idleTimerModalVisible, spinning } = this.state;
    return (
      <Spin spinning={spinning} spinnerColor="var(--primary)">
        <Layout style={{ height: '100vh' }}>
          <Content>
            <div className="site-layout-content">
              {hasError ? (
                <div
                  style={{
                    backgroundColor: 'white',
                    height: '100%',
                  }}
                >
                  <div style={{ textAlign: 'center', paddingTop: 120 }}>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      size="6x"
                      color="#faad14"
                      style={{ marginBottom: 40 }}
                    />
                    <h1>Oops, something wasn't right!</h1>
                    <h1 style={{ marginTop: 20, color: '#888' }}>Attempting to start the app...</h1>
                  </div>
                  {/* <h2>{error}</h2> */}
                </div>
              ) : (
                <Route
                  render={({ location }) => {
                    let transitionKey;
                    if (location.pathname.lastIndexOf('/') !== 0) {
                      //No transition for nested routes
                      transitionKey = null;
                    } else {
                      transitionKey = location.pathname;
                    }
                    return (
                      <PageTransition preset="cubeToRight" transitionKey={transitionKey}>
                        <Switch location={location}>
                          <Redirect from="/" exact to="/start" />
                          <Route path="/start" component={Start} />
                          <Route
                            path="/login"
                            render={props => <Login {...props} {...this.idleConfig} />}
                          />
                          <Route
                            path="/admin"
                            render={props => <Admin {...props} {...this.idleConfig} />}
                          />
                          <Route
                            path="/orderOptions"
                            render={props => <OrderOptions {...props} {...this.idleConfig} />}
                          />
                          <Route
                            path="/menu"
                            render={props => <Menu {...props} {...this.idleConfig} />}
                          />
                          {/* <Route path="/order" component={Order} /> */}
                          <Route
                            path="/order"
                            render={props => <Order {...props} {...this.idleConfig} />}
                          />
                          <Route
                            path="/reservation"
                            render={props => <Reservation {...props} {...this.idleConfig} />}
                          />
                          <Route
                            path="/preview"
                            render={props => <Preview {...props} {...this.idleConfig} />}
                          />
                          <Route
                            path="/scan"
                            render={props => <Scan {...props} {...this.idleConfig} />}
                          />
                        </Switch>
                      </PageTransition>
                    );
                  }}
                />
              )}
            </div>
          </Content>
        </Layout>
        <IdleTimerModal
          visible={idleTimerModalVisible}
          duration={15}
          onIdleTimerFinish={this.handleIdleTimerFinish}
          onContinueBtn={this.handleContinueTransaction}
        />
      </Spin>
    );
  }
}
export default connect(state => ({ kioskSetting: state.kioskSetting }), {
  receiveAppSetting,
  resetUser,
  clearOrderItems,
  clearScannedOrderItems,
  deleteGuestName,
  deleteGuestPhone,
  deleteGuestIns,
  resetCustomerOrderOption,
  resetLoginType,
  resetAppMode,
  updateClientStyle,
})(withRouter(App));
