import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, Divider } from 'antd';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import TextTransition, { presets } from 'react-text-transition';
import VTTextTransition from '../TextTransition';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus, faX, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { nanoid } from 'nanoid';
import { getItemInformation } from '../../api';
import Button from '../Button';
import Modal from '../Modal';
import OrderInstructionModal from '../OrderInstructionModal';
import MenuItemSizeSelector from '../MenuItemSizeSelector';

import './index.css';
const { TextArea } = Input;

class MenuItemModsSelector extends Component {
  state = {
    mods: [],
    modSelected: {},
    modItemSelected: {},
    modifierDetails: [], //list of selected modifiers
    stateId: '',
    parentId: '',
    parentObjs: [],
    modSizeModalVisible: false,
    modItemSize: '',
    animated: false,
    insModalVisible: false,
    insModalContent: '',
  };

  init = () => {
    const { itemMods } = this.props;
    const modsArr = JSON.parse(JSON.stringify(itemMods));
    this.setState({
      mods: modsArr,
      modSelected: modsArr[0],
      stateId: nanoid(),
      modifierDetails: [],
    });
  };

  handlePrevBtn = index => {
    const { mods } = this.state;
    if (index === 0) return;
    let nextIndex;
    if (index > 0) {
      nextIndex = index - 1;
      this.setState({ modSelected: mods[nextIndex] });
    }
  };

  handleNextBtn = index => {
    const { mods, modSelected } = this.state;
    const mod = mods.find(i => i.Guid === modSelected.Guid) || {};
    const min = mod.MinQty;
    const sum = mod.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
    if (sum < min) {
      this.handleInstructionAnimation();
      return;
    }
    if (sum >= min) {
      if (index === mods.length - 1) return;
      let nextIndex;
      if (index < mods.length - 1) {
        nextIndex = index + 1;
        this.setState({ modSelected: mods[nextIndex] });
      }
    }
  };

  handleMinusOne = item => {
    const { mods, modSelected, modifierDetails } = this.state;
    const mod = mods.find(i => i.Guid === modSelected.Guid);
    const { ItemList } = mod;
    const modItem = ItemList.find(i => i.Code === item.Code);
    const modi = modifierDetails.find(i => i.code === item.Code);
    const otherModis = modifierDetails.filter(i => i.code !== item.Code);
    if (modi) {
      if (modi.qty > 1) {
        modi.qty = modi.qty - 1;
        modItem.Qty = modItem.Qty - 1;
        this.setState({ mods, modifierDetails });
      } else if (modi.qty === 1) {
        modi.qty = 0;
        modItem.Qty = 0;
        this.setState({ mods, modifierDetails: otherModis });
      }
    } else {
      return;
    }
  };

  handlePlusClick = async item => {
    const { packageType } = this.props || '';
    const re = await getItemInformation(item.Code, packageType);
    let selectedItem = {};
    if (re.result === 'OK' && re.data) {
      selectedItem = re.data;
    }
    this.setState({ modItemSelected: selectedItem });

    //Check if already in modifierDetails list;
    const { modifierDetails, mods, modSelected } = this.state;
    const isExist = modifierDetails.filter(i => i.code === item.Code).length > 0;
    const mModi = modifierDetails.find(i => i.code === item.Code);
    const mod = mods.find(i => i.Guid === modSelected.Guid) || {};
    const max = mod.MaxQty;
    let maxed = false;
    const sum = mod.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);

    if (max > 0 && sum >= max) {
      maxed = true;
      this.handleInstructionAnimation();
      return;
    }

    const modi = {};
    if (!maxed && !mModi) {
      modi.sizeCode = '';
      modi.modifierDetails = [];
      modi.code = selectedItem.Code;
      modi.description = selectedItem.Description;
      if (selectedItem.SizeItemInfoList.length > 0) {
        modi.price = selectedItem.SizeItemInfoList[0].BasePrice.toFixed(2);
      }
      if (selectedItem.SizeItemInfoList.length === 0) {
        modi.price = selectedItem.Price.toFixed(2);
      }
      modi.qty = 0;
      this.setState({ modifierDetails: [...modifierDetails, modi], modItemSelected: selectedItem });
    }

    const { SizeItemInfoList, ModifierInfos } = selectedItem;

    if (
      SizeItemInfoList &&
      SizeItemInfoList.length > 0 &&
      !maxed &&
      (!mModi || (mModi && mModi.qty === 0))
    ) {
      this.setState({ modSizeModalVisible: true });
    }

    if (SizeItemInfoList && SizeItemInfoList.length > 0 && !maxed && mModi && mModi.qty > 0) {
      this.setState({ modItemSelected: selectedItem }, () => {
        this.handleAddOne();
      });
    }

    if (
      (!SizeItemInfoList || SizeItemInfoList.length === 0) &&
      ModifierInfos &&
      ModifierInfos.length > 0 &&
      !maxed &&
      !isExist
    ) {
      this.setState(
        { modifierDetails: [...modifierDetails, modi], modItemSelected: selectedItem },
        () => {
          this.saveCurrentStateUpdateState(ModifierInfos);
        },
      );
    }

    if (
      (!SizeItemInfoList || SizeItemInfoList.length === 0) &&
      ModifierInfos &&
      ModifierInfos.length > 0 &&
      !maxed &&
      isExist
    ) {
      this.setState({ modItemSelected: selectedItem }, () => {
        this.handleAddOne();
      });
    }

    if (
      (!SizeItemInfoList || SizeItemInfoList.length === 0) &&
      (!ModifierInfos || ModifierInfos.length === 0) &&
      !maxed
    ) {
      this.setState({ modItemSelected: selectedItem }, () => {
        this.handleAddOne();
      });
    }
  };

  saveCurrentStateUpdateState = newMods => {
    const { modSelected, mods, modItemSelected, stateId, parentId, parentObjs, modifierDetails } =
      this.state;
    const parentStateId = stateId;
    const newStateId = nanoid();
    const obj = {};
    obj.mods = JSON.parse(JSON.stringify(mods));
    obj.modSelected = JSON.parse(JSON.stringify(modSelected));
    obj.stateId = stateId;
    obj.parentId = parentId;
    obj.modItemSelected = JSON.parse(JSON.stringify(modItemSelected));
    obj.modifierDetails = JSON.parse(JSON.stringify(modifierDetails));
    this.setState({
      mods: newMods,
      modSelected: newMods[0],
      modItemSelected: {},
      modItemSize: '',
      modifierDetails: [],
      stateId: newStateId,
      parentId: parentStateId,
      parentObjs: [...parentObjs, obj],
    });
  };

  checkAutoNext = () => {
    const { mods, modSelected } = this.state;
    const mod = mods.find(i => i.Guid === modSelected.Guid) || {};
    const max = mod.MaxQty;
    const sum = mod.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
    const modIndex = mods.findIndex(i => i.Guid === modSelected.Guid);
    if (sum === max) {
      this.handleNextBtn(modIndex);
    }
  };

  handleAddOne = () => {
    const { mods, modSelected, modItemSelected, modifierDetails } = this.state;
    const mod = mods.find(i => i.Guid === modSelected.Guid) || {};
    const { ItemList } = mod;
    const modItem = ItemList.find(i => i.Code === modItemSelected.Code);
    const max = mod.MaxQty;
    const min = mod.MinQty;
    const modi = modifierDetails.find(i => i.code === modItemSelected.Code);
    if (modItemSelected.subMods) {
      modi.modifierDetails = modItemSelected.subMods;
    }

    if ((max === 0 && min === 0) || (max === 0 && min > 0)) {
      modi.qty = modi.qty + 1;
      modItem.Qty = modItem.Qty + 1;
      this.setState({ mods, modifierDetails, modItemSize: '' });
    } else if (max > 0) {
      const sum = mod.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
      if (sum < max) {
        modi.qty = modi.qty + 1;
        modItem.Qty = modItem.Qty + 1;
        this.setState({ mods, modifierDetails, modItemSize: '' }, () => {
          if (!modItemSelected.subMods || modItemSelected.subMods.length === 0) {
            this.checkAutoNext();
          }
        });
      } else {
        return;
      }
    }
  };

  handleModItemSizeModalOK = info => {
    const { modItemSelected, modifierDetails } = this.state;
    const modi = modifierDetails.find(i => i.code === modItemSelected.Code);
    modi.description = info.description;
    modi.sizeCode = info.sizeCode;
    modi.price = info.price;
    modItemSelected.size = info.size;
    this.setState({ modifierDetails, modItemSize: info.sizeCode, modItemSelected }, () => {
      const { ModifierInfos } = modItemSelected;
      this.setState({ modSizeModalVisible: false });
      if (ModifierInfos && ModifierInfos.length > 0) {
        //save the current state as parentState then update state to show lower tier mods and items
        this.saveCurrentStateUpdateState(ModifierInfos);
      }
      if (!ModifierInfos || ModifierInfos.length === 0) {
        this.handleAddOne();
      }
    });
  };

  handleModItemSizeModalCancel = modi => {
    const { modifierDetails } = this.state;
    const otherModis = modifierDetails.filter(i => i.code !== modi.code);
    this.setState({
      modSizeModalVisible: false,
      modItemSelected: {},
      modItemSize: '',
      modifierDetails: otherModis,
    });
  };

  handleOKBtn = () => {
    const { mods, modSelected, parentId, parentObjs, modifierDetails, insModalContent } =
      this.state;

    // const mod = mods.find(i => i.modId === modSelected.modId);
    const mod = mods.find(i => i.Guid === modSelected.Guid) || {};
    const min = mod.MinQty;
    const sum = mod.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
    if (sum < min) {
      this.handleInstructionAnimation();
      return;
    }
    if (parentId) {
      const parentObj = parentObjs.find(i => i.stateId === parentId) || {};
      if (parentObj) {
        const newArr = parentObjs.filter(i => i.stateId !== parentId);
        parentObj.modItemSelected.subMods = modifierDetails;
        this.setState(
          {
            mods: parentObj.mods,
            modSelected: parentObj.modSelected,
            stateId: parentObj.stateId,
            parentId: parentObj.parentId,
            modItemSelected: parentObj.modItemSelected,
            parentObjs: newArr,
            modifierDetails: parentObj.modifierDetails,
          },
          () => {
            this.handleAddOne();
          },
        );
      }
    }

    if (!parentId) {
      if (this.props.addMods) {
        let modsDetails = [];
        if (insModalContent.trim().length > 0) {
          const obj = {};
          obj.code = 'MEMO';
          obj.description = insModalContent;
          obj.modifierDetails = [];
          obj.price = '0.00';
          obj.qty = 1;
          modsDetails = [...modifierDetails, obj];
        } else {
          modsDetails = modifierDetails;
        }
        this.props.addMods(modsDetails);
        this.setState({ insModalContent: '' });
      }
    }
  };

  flattenModifierDetails = modi => {
    const arr = [];
    if (modi.modifierDetails && modi.modifierDetails.length > 0) {
      modi.modifierDetails.forEach(i => {
        arr.push(i);
        if (i.modifierDetails && i.modifierDetails.length > 0) {
          const subArr = this.flattenModifierDetails(i);
          if (subArr.length > 0) {
            subArr.forEach(j => {
              arr.push(j);
            });
          }
        }
      });
    }
    return arr;
  };

  handleInstructionAnimation = () => {
    const { animated } = this.state;
    if (animated) {
      clearTimeout(this.animationTimer);
      this.animationTimer();
    }
    if (!animated) {
      this.setState({ animated: true });
      this.animationTimer();
    }
  };

  animationTimer = () => {
    setTimeout(() => {
      this.setState({ animated: false });
    }, 900);
  };

  prunePrice = i => {
    let price = '';
    const { packageType } = this.props;
    if (packageType !== '1') {
      price = '';
    }
    if (packageType === '1' || !packageType) {
      if (i.Price === 0 && i.SizeItemInfoList.length === 0) {
        price = '$0.00';
      } else if ((i.Price === 0 || i.Price !== 0) && i.SizeItemInfoList.length === 0) {
        price = `$${i.Price.toFixed(2)}`;
      } else if ((i.Price === 0 || i.Price !== 0) && i.SizeItemInfoList.length > 0) {
        const arr = i.SizeItemInfoList.map(j => j.BasePrice);
        const max = Math.max(...arr);
        const min = Math.min(...arr);
        if (min === max) {
          price = `$${max.toFixed(2)}`;
        }
        if (min !== max) {
          price = `$${min.toFixed(2)}-${max.toFixed(2)}`;
        }
      }
    }
    return price;
  };

  handleInstructionBoxOnFocus = () => {
    this.setState({ insModalVisible: true });
  };

  handleInsModalCancel = () => {
    this.setState({ insModalVisible: false });
  };

  handleInsTextChange = (text, type) => {
    this.setState({ insModalContent: text, insModalVisible: false });
  };

  handleCloseButton = () => {
    this.setState({ insModalContent: '' });
    this.props.onCancel();
  };

  handleRedoBtn = () => {
    this.setState({ insModalContent: '' });
    this.props.onRedo();
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { itemMods } = this.props;
    if (itemMods !== prevProps.itemMods) {
      this.init();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.animationTimer);
  }

  render() {
    const {
      modSelected,
      mods,
      modSizeModalVisible,
      modItemSelected,
      modifierDetails,
      animated,
      parentId,
      parentObjs,
      insModalVisible,
      insModalContent,
    } = this.state;
    const parent = parentObjs.find(i => i.stateId === parentId) || {};
    const { des, visible, zIndex = 1000, width = 800, sizes, clientStyle } = this.props;
    const mod = mods.find(i => i.Guid === modSelected.Guid) || {};
    const { ItemList, SortingBy } = mod || [];
    let list = [];
    if (SortingBy === 0) {
      const o = ItemList.reduce((groups, item) => {
        const group = groups[item.TagCode] || [];
        group.push(item);
        groups[item.TagCode] = group;
        return groups;
      }, {});
      for (const key in o) {
        if (Object.hasOwnProperty.call(o, key)) {
          list.push({
            tagCode: key,
            tagItems: o[key],
            tagDisplayName: o[key][0].TagDisplayName || '',
          });
        }
      }
      list = list.sort(
        (a, b) => parseInt(a.tagItems[0]?.TagIndex) - parseInt(b.tagItems[0]?.TagIndex),
      );
    } else {
      list = [{ tagCode: 'NotSorted', tagItems: ItemList, tagDisplayName: '' }];
    }
    const modi = modifierDetails.find(i => i.code === modItemSelected.Code) || {};
    const modIndex = mods.findIndex(i => i.Guid === modSelected.Guid);
    let la = true,
      ra = true;
    if (modIndex === 0) {
      la = false;
    }
    if (mods.length === 1 || (mods.length > 1 && modIndex === mods.length - 1)) {
      ra = false;
    }

    const max = mod.MaxQty;
    const min = mod.MinQty;
    let sum = 0,
      grayOut = false;
    if (ItemList && ItemList.length > 0) {
      sum = ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
    }
    if (max > 0) {
      grayOut = sum === max;
    }

    let ins = '';
    if (min === 0 && max === 0) {
      ins = '';
    }
    if (min > 0 && max === 0) {
      ins = `Select minimum ${min}`;
    }
    if (min === 0 && max > 0) {
      ins = `Select maximum ${max}`;
    }
    if (min > 0 && max > 0) {
      ins = `Select minimum ${min}, maximum ${max}`;
    }

    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };

    const descStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontTwo,
    };

    return (
      <>
        <Modal
          width={width}
          visible={visible}
          maskClosable={false}
          closable={false}
          title={null}
          footer={null}
          zIndex={zIndex}
          destroyOnClose={true}
          onCloseClick={this.props.onCancel}
          onCancel={this.props.onCancel}
        >
          <div className="menu-item-mods-modal-container">
            {sizes.length > 0 ? (
              <div className="menu-item-mods-modal-redo-btn">
                <div
                  className="menu-item-mods-modal-redo-icon"
                  onClick={this.handleRedoBtn}
                  style={{ backgroundColor: clientStyle.primary }}
                >
                  <FontAwesomeIcon
                    icon={faRotateLeft}
                    size="2x"
                    style={{ color: clientStyle.colorTwo }}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="menu-item-mods-modal-close-btn">
              <div
                className="menu-item-mods-modal-close-icon"
                onClick={this.handleCloseButton}
                style={{ backgroundColor: clientStyle.primary }}
              >
                <FontAwesomeIcon icon={faX} size="2x" style={{ color: clientStyle.colorTwo }} />
              </div>
            </div>

            {/* Sticky Header */}
            <Row
              justify="space-between"
              align="middle"
              className="menu-item-mods-modal-sticky-header"
              style={{ backgroundColor: clientStyle.bgColor }}
            >
              <Col span={24}>
                <Row justify="center" style={{ fontWeight: 'bold', fontSize: 20, ...titleStyle }}>
                  <TextTransition inline={true} delay={300} springConfig={presets.default}>
                    {des}
                  </TextTransition>
                </Row>
                {parent && parent.modItemSelected && (
                  <Row justify="center" style={{ ...titleStyle }}>
                    Ordering: {parent.modItemSelected.Description}
                  </Row>
                )}
                <Row align="middle">
                  <Col span={5}>
                    <Row justify="start">
                      {la ? (
                        <Button
                          width={150}
                          height={60}
                          style={{
                            fontSize: 18,
                            color: clientStyle.colorTwo,
                            backgroundColor: clientStyle.primary,
                          }}
                          borderRadius={5}
                          onClick={() => {
                            this.handlePrevBtn(modIndex);
                          }}
                        >
                          Previous
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </Col>
                  <Col span={14}>
                    <Row justify="center">
                      {/* <div
                        style={{
                          ...titleStyle,
                          fontSize: 18,
                          fontWeight: 'bold',
                          animation: animated ? 'horizontal-shaking 700ms ease-out 300ms' : '',
                        }}
                      >
                        <TextTransition inline={true} delay={300} springConfig={presets.default}>
                          {modSelected?.CustomerMessage
                            ? modSelected?.CustomerMessage
                            : modSelected?.CustomMessage
                            ? modSelected?.CustomMessage
                            : ins}
                        </TextTransition>
                      </div> */}
                    </Row>
                  </Col>
                  <Col span={5}>
                    <Row justify="end">
                      {ra ? (
                        <Button
                          width={150}
                          height={60}
                          style={{
                            fontSize: 18,
                            color: clientStyle.colorTwo,
                            backgroundColor: clientStyle.primary,
                          }}
                          borderRadius={5}
                          onClick={() => {
                            this.handleNextBtn(modIndex);
                          }}
                          className="menu-item-mods-modal-next-button"
                        >
                          Next
                        </Button>
                      ) : (
                        <Button
                          width={150}
                          height={60}
                          style={{
                            fontSize: 18,
                            color: clientStyle.colorTwo,
                            backgroundColor: clientStyle.primary,
                          }}
                          borderRadius={5}
                          onClick={() => {
                            this.handleOKBtn();
                          }}
                        >
                          OK
                        </Button>
                      )}
                    </Row>
                  </Col>
                </Row>
                <Row justify="center" style={{ padding: '8px 5px' }}>
                  <div
                    style={{
                      ...titleStyle,
                      fontSize: 18,
                      fontWeight: 'bold',
                      animation: animated ? 'horizontal-shaking 700ms ease-out 300ms' : '',
                    }}
                  >
                    <TextTransition inline={true} delay={300} springConfig={presets.default}>
                      {modSelected?.CustomerMessage
                        ? modSelected?.CustomerMessage
                        : modSelected?.CustomMessage
                        ? modSelected?.CustomMessage
                        : ins}
                    </TextTransition>
                  </div>
                </Row>
              </Col>
            </Row>
            {/* <Divider style={{ borderColor: 'transparent', marginBottom: 0 }} /> */}
            <div style={{ marginBottom: 10 }}>
              <SwitchTransition mode="out-in">
                <CSSTransition
                  key={modSelected?.Guid}
                  timeout={500}
                  classNames="menu-item-mods-selector-list"
                >
                  <div className="menu-item-mods-selector-list">
                    {list.map((t, index) => (
                      <div key={index}>
                        {t.tagCode !== 'NotSorted' && list.length > 1 ? (
                          <div
                            className="menu-item-mods-selector-list-tag-row"
                            style={{ ...titleStyle }}
                          >
                            {t.tagDisplayName ?? ''}
                          </div>
                        ) : null}
                        {t.tagItems?.map(i => {
                          const modItem = modifierDetails.find(j => j.code === i.Code);
                          let modis = [];
                          if (modItem && modItem.modifierDetails) {
                            modis = this.flattenModifierDetails(modItem) || [];
                          }
                          return (
                            <Row
                              key={i.Code}
                              justify="space-between"
                              align="middle"
                              className="menu-item-mods-selector-row"
                              style={{
                                ...titleStyle,
                              }}
                            >
                              <Col span={24}>
                                <Row align="middle">
                                  <Col span={18}>
                                    <Row
                                      justify="space-between"
                                      align="middle"
                                      style={{ marginLeft: 10 }}
                                    >
                                      <Col span={18}>
                                        <h2
                                          style={{ padding: '0 3px', color: clientStyle.colorOne }}
                                        >
                                          {i.Description ? i.Description : 'Description N/A'}
                                        </h2>
                                      </Col>
                                      <Col span={6}>
                                        <Row justify="start">
                                          <h2>{this.prunePrice(i)}</h2>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={2}>
                                    <div
                                      className="menu-item-mods-selector-list-minus"
                                      justify="center"
                                      align="middle"
                                      onClick={() => {
                                        this.handleMinusOne(i);
                                      }}
                                    >
                                      <Col>
                                        <FontAwesomeIcon
                                          icon={faCircleMinus}
                                          size="3x"
                                          color={i.Qty === 0 ? '#999' : clientStyle.primary}
                                        />
                                      </Col>
                                    </div>
                                  </Col>
                                  <Col span={2}>
                                    <Row justify="center" align="middle">
                                      <Col>
                                        <h2>
                                          <VTTextTransition text={i.Qty} />
                                        </h2>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={2}>
                                    <Row
                                      className="menu-item-mods-selector-list-minus"
                                      justify="center"
                                      align="middle"
                                      onClick={() => this.handlePlusClick(i)}
                                    >
                                      <Col>
                                        <FontAwesomeIcon
                                          icon={faCirclePlus}
                                          size="3x"
                                          color={grayOut ? '#999' : clientStyle.primary}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                {modis && modis.length > 0 ? (
                                  <Row>
                                    {modis.map(k => (
                                      <div key={k.code} style={{ ...titleStyle }}>
                                        {k.qty} X{' '}
                                        {k.description ? k.description : 'Description N/A'};
                                        &nbsp;&nbsp;
                                      </div>
                                    ))}
                                  </Row>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </div>
            <div className="menu-item-mods-modal-ins-btn">
              {/* <Button
                width={250}
                height={50}
                style={{
                  fontSize: 16,
                  color: clientStyle.colorTwo,
                  backgroundColor: clientStyle.primary,
                }}
                borderRadius={500}
                onClick={() => {
                  this.handleAddInstructionBtn();
                }}
              >
                <span style={{ fontSize: 18, marginRight: 8 }}>+</span> Special Instructions
              </Button> */}
              <TextArea
                ref={this.refInstructionBox}
                autoSize={{ minRows: 3, maxRows: 3 }}
                placeholder=" Special Instructions"
                spellCheck={false}
                maxLength={100}
                onFocus={this.handleInstructionBoxOnFocus}
                value={insModalContent}
                style={{ ...descStyle }}
              />
            </div>
          </div>
        </Modal>
        {/* Mod Item Size Modal */}
        <MenuItemSizeSelector
          visible={modSizeModalVisible}
          onCancel={() => this.handleModItemSizeModalCancel(modi)}
          checkItem={modi}
          sizes={modItemSelected?.SizeItemInfoList}
          itemSelected={modItemSelected}
          onOK={obj => this.handleModItemSizeModalOK(obj)}
          zIndex={1001}
        />
        <OrderInstructionModal
          visible={insModalVisible}
          insModalFor="INSTRUCTIONS"
          clearContent={true}
          content={insModalContent}
          onInsModalCancel={this.handleInsModalCancel}
          onTextChange={(text, dataType) => {
            this.handleInsTextChange(text, dataType);
          }}
        />
      </>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(MenuItemModsSelector);
