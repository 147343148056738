import React, { Component } from 'react';
import { Row, Col, Divider } from 'antd';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleExclamation,
  faCartShopping,
  faBarcode,
  faHouse,
} from '@fortawesome/free-solid-svg-icons';
import { withIdleTimer } from 'react-idle-timer';
import { nanoid } from 'nanoid';
import {
  updateOrder,
  receivePizzaOrder,
  delOrderItem,
  clearOrderItems,
  clearScannedOrderItems,
  resetUser,
  deleteGuestName,
  resetCustomerOrderOption,
  resetLoginType,
  resetAppMode,
  deleteGuestPhone,
  deleteGuestIns,
} from '../../redux/actions';
import MenuPicker from '../../components/MenuPicker';
import OrderHeaderAlt from '../../components/OrderHeaderAlt';
import Button from '../../components/Button';
import MenuItemModsSelector from '../../components/MenuItemModsSelector';
import OrderItemsPaneAlt from '../../components/OrderItemsPaneAlt';
import VTTextTransition from '../../components/TextTransition';
import PackageItemSelector from '../../components/PackageItemSelector';
import PopupItemSelector from '../../components/PopupItemSelector';
import MenuPizzaModal from '../../components/MenuPizzaModal';
import MenuItemSizeSelector from '../../components/MenuItemSizeSelector';
import Modal from '../../components/Modal';
import Drawer from '../../components/Drawer';
import ErrorModal from '../../components/ErrorModal';
import {
  setOrderSetup,
  getListViewPage,
  getItemInformation,
  getPizzaPrice,
  getOrderSummary,
  getPopupButtonData,
  getVTImageFolder,
} from '../../api';
import { toCamel } from '../../utils/camelCase';
import cartIcon from '../../assets/icons/Cart.png';
import homeIcon from '../../assets/icons/Home.png';
import './index.css';

class Menu extends Component {
  state = {
    logoImageDir: '',
    backgroundImageDir: '',
    view: {},
    tabs: [],
    tabId: null,
    orderDrawerVisible: false,
    itemSizeModalVisible: false,
    itemModsModalVisible: false,
    packageItemDrawerVisible: false,
    popupSelectorDrawerVisible: false,
    pizzaItemModalVisible: false,
    menuCancelBtnVisible: false,
    errorModalVisible: false,
    errorModalTitle: '',
    errorModalMsg: '',
    itemSelected: null,
    pizzaModalSpinning: false,
    checkItem: {
      code: '',
      description: '',
      price: '', //Need to convert to decimal at check out
      qty: 0,
      error: false,
      errorMessage: '',
      dependencyCargo: '',
      type: '',
      sizeCode: null,
      modifierDetails: [],
      pizzaDetails: [],
      packageDetails: [],
      matrixDetails: [],
      interStoreNumber: '',
      guestNumber: -1, //int,
      packageType: '',
    },
    sizeCode: '',
    pizzaTotalPrice: 0,
    sizeItemInfoList: [],
    modifierInfos: [],
    packageItemInfos: [],
    toppingItemList: [],
    popupItemInfos: [],
    selectedItemEvent: null,
    flyBtnObj: null,
  };

  refOrderPaneBtm = React.createRef();

  init = async () => {
    this.cleanCheckItem();
    const { customerOrderOption, guestName, guestPhone } = this.props;
    const { AccountNumber } = this.props.user?.CurAccountInfo ?? '';
    const { pageName } = customerOrderOption || '';
    const obj = {};
    obj.AccountNumber = AccountNumber ?? '';
    obj.ProfitCentreCode = customerOrderOption?.orderOption?.CentreCode ?? '';
    obj.RequestedDate = customerOrderOption?.date?.DateShort ?? '';
    obj.RequestedTime = customerOrderOption?.timeslot?.Time ?? '';
    obj.GuestName = !guestName
      ? ''
      : guestName && guestName.length > 15
      ? guestName.substring(0, 15)
      : guestName;
    obj.GuestPhoneNumber = guestPhone;
    try {
      await Promise.all([getVTImageFolder('6')]).then(results => {
        results.forEach(res => {
          if (!res || (res && res.result !== 'OK')) throw new Error(res.result);
          this.setState({ logoImageDir: res.data });
        });
      });
      const res = await setOrderSetup(obj);
      if (!res || res !== 'OK') throw new Error(res.result);
      const re = await getListViewPage(pageName);
      console.log(re);
      if (!re || (re && re.result !== 'OK')) throw new Error(re.result);
      this.setState({ tabs: re.data.Tabs, view: re.data, tabId: re.data.Tabs[0].Code });
    } catch (error) {
      this.setState(
        {
          errorModalTitle: 'Page is not properly displayed',
          errorModalMsg: error.message,
        },
        () => {
          this.setState({ errorModalVisible: true });
        },
      );
    }
  };

  cleanCheckItem = () => {
    this.setState({
      checkItem: {
        code: '',
        description: '',
        price: '',
        qty: 0,
        error: false,
        errorMessage: '',
        dependencyCargo: '',
        type: '',
        sizeCode: null,
        modifierDetails: [],
        pizzaDetails: [],
        packageDetails: [],
        matrixDetails: [],
        interStoreNumber: '',
        guestNumber: -1, //int,
        packageType: '',
      },
      sizeCode: '',
      pizzaTotalPrice: 0,
      sizeItemInfoList: [],
      modifierInfos: [],
      toppingItemList: [],
      itemSelected: {},
    });
  };

  handleScrollerSelection = id => {
    this.setState({ tabId: id });
  };

  handleShoppingCartClick = () => {
    this.setState({ orderDrawerVisible: true, seed: !this.state.seed });
  };

  handleScanBarCodeClick = () => {
    this.props.history.push('/scan');
  };

  handleOrderDrawerClose = () => {
    this.setState({ orderDrawerVisible: false });
  };

  handleErrorModalOK = () => {
    this.setState({ errorModalVisible: false });
  };

  handleItemClick = async (item, flyBtnObj) => {
    this.setState({ flyBtnObj });
    const { checkItem } = this.state;
    if (item.ButtonType && item.ButtonType === 2) {
      try {
        const re = await getPopupButtonData(item.PanelName, item.Code);
        if (!re || (re && re.result !== 'OK')) throw new Error(re.result);
        this.setState({ popupItemInfos: re.data }, () => {
          this.setState({ popupSelectorDrawerVisible: true });
        });
      } catch (error) {
        this.setState(
          {
            errorModalTitle: 'Item is not available',
            errorModalMsg: error.message,
          },
          () => {
            this.setState({ errorModalVisible: true });
          },
        );
      }
    } else {
      try {
        const re = await getItemInformation(item.Code, '');
        if (!re || (re && re.result !== 'OK')) throw new Error(re.result);
        const { data } = re;
        checkItem.code = data.Code;
        checkItem.description = data.Description;
        if (data.SizeItemInfoList.length > 0) {
          checkItem.price = data.SizeItemInfoList[0].BasePrice.toFixed(2);
        }
        if (data.SizeItemInfoList.length === 0) {
          checkItem.price = data.Price.toFixed(2);
        }
        checkItem.qty = 1;
        checkItem.type = data.Type;
        checkItem.sizeCode = '';
        checkItem.modifierDetails = [];
        if (data.Type === 'P' && data.PackageItemInfos.length > 0) {
          checkItem.packageDetails = [];
          checkItem.packageType = data.PackageType.toString();
        }
        if (data.Type === 'I') {
          checkItem.packageDetails = [];
          checkItem.packageType = '';
        }
        this.setState({
          checkItem,
          sizeItemInfoList: data.SizeItemInfoList,
          modifierInfos: data.ModifierInfos,
          packageItemInfos: data.PackageItemInfos,
          toppingItemList: data.ToppingItemList,
          itemSelected: data,
          sizeCode: '',
          pizzaTotalPrice: 0,
        });

        if (data.PackageItemInfos && data.PackageItemInfos.length > 0) {
          this.setState({ packageItemDrawerVisible: true });
        }

        if (data.SizeItemInfoList && data.SizeItemInfoList.length > 0) {
          this.setState({ itemSizeModalVisible: true });
        } else if (
          (!data.SizeItemInfoList || data.SizeItemInfoList.length === 0) &&
          data.ModifierInfos &&
          data.ModifierInfos.length > 0
        ) {
          this.setState({ itemModsModalVisible: true });
        }

        if (
          (!data.PackageItemInfos || data.PackageItemInfos.length === 0) &&
          (!data.SizeItemInfoList || data.SizeItemInfoList.length === 0) &&
          (!data.ModifierInfos || data.ModifierInfos.length === 0)
        ) {
          this.handleAddToOrder();
        }
      } catch (error) {
        this.setState(
          { errorModalTitle: 'Item is not available', errorModalMsg: error.message },
          () => {
            this.setState({ errorModalVisible: true });
          },
        );
      }
    }
  };

  handleItemSizeModalCancel = () => {
    this.setState({
      itemSizeModalVisible: false,
    });
    this.cleanCheckItem();
  };

  handleItemSizeModalOK = info => {
    const { modifierInfos, checkItem, itemSelected } = this.state;
    checkItem.description = info.description;
    checkItem.sizeCode = info.sizeCode;
    checkItem.price = info.price;
    this.setState({ checkItem, sizeCode: info.sizeCode }, () => {
      if (itemSelected.Type === 'I') {
        this.setState({ itemSizeModalVisible: false, pizzaItemModalVisible: true });
      } else if (modifierInfos.length > 0) {
        this.setState({ itemSizeModalVisible: false, itemModsModalVisible: true });
      } else if (modifierInfos.length === 0) {
        this.setState({ itemSizeModalVisible: false });
        this.handleAddToOrder();
      }
    });
  };

  handleModsModalCancel = () => {
    this.setState({
      itemModsModalVisible: false,
    });
    this.cleanCheckItem();
  };

  handleModsModalRedo = () => {
    const { itemSelected, flyBtnObj } = this.state;
    this.setState({ itemModsModalVisible: false, pizzaDetails: [] }, () => {
      this.handleItemClick(itemSelected, flyBtnObj);
    });
  };

  handleAddToOrder = () => {
    const { checkItem, flyBtnObj } = this.state;

    let orderObj = {};
    orderObj = JSON.parse(JSON.stringify(checkItem));
    orderObj.key = nanoid();
    orderObj.price = parseFloat(orderObj.price);

    if (orderObj.modifierDetails.length > 0) {
      orderObj.modifierDetails = orderObj.modifierDetails.map(i => {
        i.price = parseFloat(i.price);
        return i;
      });
    }
    if (orderObj.packageDetails.length > 0) {
      orderObj.packageDetails = orderObj.packageDetails.map(i => {
        i.price = parseFloat(i.price);
        return i;
      });
    }
    if (orderObj.pizzaDetails.length > 0) {
      orderObj.pizzaDetails = orderObj.pizzaDetails.map(i => {
        i.price = parseFloat(i.price);
        return i;
      });
    }

    const { flyingBtn, cart, speed } = flyBtnObj;
    // if (window.innerWidth <= 1366 && cart) {
    document.body.appendChild(flyingBtn);
    flyingBtn.style.width = flyingBtn.style.width === '100%' ? '300px' : flyingBtn.style.width;
    flyingBtn.style.height = flyingBtn.style.width === '100%' ? '50px' : flyingBtn.style.height;
    flyingBtn.style.top = `${cart.getBoundingClientRect().top + 0}px`;
    flyingBtn.style.left = `${cart.getBoundingClientRect().left + 35}px`;
    flyingBtn.style.width = '1rem';
    flyingBtn.style.height = '1rem';
    flyingBtn.style.minHeight = '1rem';
    flyingBtn.style.transform = 'scale(0)';

    this.cleanCheckItem();
    setTimeout(() => {
      flyingBtn.remove();
      this.props.updateOrder(orderObj);
    }, speed * 1.5);
    // } else {
    //   this.props.updateOrder(orderObj);
    // }
  };

  handleCheckOutBtn = async () => {
    this.setState({ orderDrawerVisible: false });
    const { order } = this.props;
    let orderArr = JSON.parse(JSON.stringify(order));
    orderArr = orderArr.map(i => {
      i.modifierDetails.length > 0 &&
        i.modifierDetails.map(j => {
          j.description = j.description.replace(/=*>/, '');
          return j;
        });
      i.pizzaDetails.length > 0 &&
        i.pizzaDetails.map(k => {
          k.description = k.description.replace(/=*>/, '');
          return k;
        });
      i.packageDetails.length > 0 &&
        i.packageDetails.map(l => {
          l.description = l.description.replace(/=*>/, '');
          l.modifierDetails &&
            l.modifierDetails.length > 0 &&
            l.modifierDetails.map(n => {
              n.description = n.description.replace(/=*>/, '');
              return n;
            });
          l.pizzaDetails &&
            l.pizzaDetails.length > 0 &&
            l.pizzaDetails.map(m => {
              m.description = m.description.replace(/=*>/, '');
              return m;
            });
          return l;
        });
      return i;
    });
    if (!order || order.length === 0) return;

    if (order && order.length > 0) {
      const checkObj = {};
      checkObj.CheckNumber = -1;
      checkObj.Date = '';
      checkObj.EmpCode = '';
      checkObj.Shift = 1;
      checkObj.Time = '';
      checkObj.TableNum = '';
      checkObj.CustomerInfo = {};
      checkObj.Items = orderArr;
      checkObj.Adjustments = [];
      checkObj.PaymentInfos = [];
      checkObj.OrderSummary = {};
      checkObj.Error = null;
      checkObj.ErrorText = '';

      const re = await getOrderSummary(checkObj);
      if (re && re.Error === true && re.ErrorText) {
        this.setState(
          {
            errorModalTitle: 'Order cannot be processed',
            errorModalMsg: re.ErrorText,
          },
          () => {
            this.setState({ errorModalVisible: true });
          },
        );
      } else if (re && re.Error === false && re.ErrorText === '') {
        this.cleanCheckItem();
        this.props.history.push({
          pathname: '/order',
          state: { checkSum: re, isScanned: false },
        });
      }
    }
  };

  flattenMods = (mods, tier) => {
    const arr = [];
    mods.forEach(i => {
      switch (tier) {
        // case 0:
        //   i.description = i.description;
        //   break;
        case 1:
          i.description = `=>${i.description}`;
          break;
        case 2:
          i.description = `==>${i.description}`;
          break;
        case 3:
          i.description = `===>${i.description}`;
          break;
        case 4:
          i.description = `====>${i.description}`;
          break;
        case 5:
          i.description = `=====>${i.description}`;
          break;
        default:
          break;
      }
      let obj = JSON.parse(JSON.stringify(i));
      obj.modifierDetails = [];
      arr.push(obj);
      if (i.modifierDetails && i.modifierDetails.length > 0) {
        const nextTier = tier + 1;
        const subArr = this.flattenMods(i.modifierDetails, nextTier);
        if (subArr.length > 0) {
          subArr.forEach(j => {
            switch (nextTier) {
              case 1:
                j.description = `=>${j.description.slice(2)}`;
                break;
              case 2:
                j.description = `==>${j.description.slice(3)}`;
                break;
              case 3:
                j.description = `===>${j.description.slice(4)}`;
                break;
              case 4:
                j.description = `====>${j.description.slice(5)}`;
                break;
              case 5:
                j.description = `=====>${j.description.slice(6)}`;
                break;
              default:
                break;
            }
            arr.push(j);
          });
        }
      }
    });
    return arr;
  };

  handleAddMods = mods => {
    this.setState({ itemModsModalVisible: false });
    const { checkItem } = this.state;
    if (mods.length > 0) {
      const modsArr = this.flattenMods(mods, 0);
      checkItem.modifierDetails = modsArr;
    } else {
      checkItem.modifierDetails = [];
    }
    this.setState({ checkItem }, () => {
      this.handleAddToOrder();
    });
  };

  handlePackageItemDrawerClose = () => {
    this.setState({ packageItemDrawerVisible: false });
  };

  handlePackageItemCancel = () => {
    this.setState({ packageItemDrawerVisible: false });
    this.cleanCheckItem();
  };

  flattenPackageItems = packageItems => {
    const arr = [];
    packageItems.forEach(i => {
      let obj = JSON.parse(JSON.stringify(i));
      obj.modifierDetails = [];
      arr.push(obj);
      // if (i.pizzaDetails.length > 0) {
      //   i.pizzaDetails.forEach(j => {
      //     j.description = `=>${j.description}`;
      //     arr.push(j);
      //   });
      // }
      if (i.modifierDetails.length > 0) {
        const subArr = this.flattenMods(i.modifierDetails, 1);
        subArr.forEach(k => {
          arr.push(k);
        });
      }
    });
    return arr;
  };

  handlePackageChange = packageItems => {
    const { checkItem } = this.state;
    packageItems = packageItems.map(i => {
      if (i.pizzaDetails.length > 0) {
        i.pizzaDetails = i.pizzaDetails.map(j => {
          j.description = `=>${j.description}`;
          return j;
        });
      }
      return i;
    });
    if (packageItems.length > 0) {
      const packageArr = this.flattenPackageItems(packageItems);
      checkItem.packageDetails = packageArr;
      if (checkItem.packageType === '1') {
        const amount = packageArr
          .map(p => {
            let packItemSub = parseFloat(p.price) * p.qty,
              packPSub = 0;
            if (p.pizzaDetails && p.pizzaDetails.length > 0) {
              packPSub = p.pizzaDetails
                .map(k => parseFloat(k.price) * k.qty)
                .reduce((l, m) => l + m, 0);
            }
            return packItemSub + packPSub;
          })
          .reduce((u, w) => u + w, 0);
        checkItem.price = amount;
      }
    } else {
      checkItem.packageDetails = [];
      checkItem.price = '0.00';
    }
    this.setState({ checkItem, packageItemDrawerVisible: false }, () => {
      this.handleAddToOrder();
    });
  };

  handlePizzaModalCancel = () => {
    this.setState({ pizzaItemModalVisible: false });
    this.cleanCheckItem();
  };

  handlePizzaModalRedo = () => {
    const { itemSelected, flyBtnObj } = this.state;
    this.setState({ pizzaItemModalVisible: false }, () => {
      this.handleItemClick(itemSelected, flyBtnObj);
    });
  };

  //isOK: 1 - "OK" button pressed; 0 - only update price
  handleAddPizzaDetails = (pizzaDetails, isOK) => {
    const { checkItem, itemSelected } = this.state;
    checkItem.pizzaDetails = pizzaDetails;
    this.setState({ checkItem }, () => {
      if (isOK === 1) {
        this.getPizzaItemPrice();
        if (itemSelected.ModifierInfos.length > 0) {
          this.setState({ pizzaItemModalVisible: false, itemModsModalVisible: true });
        }
      }
      if (isOK === 0) {
        this.getPizzaItemPrice();
      }
    });
  };

  getPizzaItemPrice = async () => {
    const { checkItem } = this.state;
    try {
      this.setState({ pizzaModalSpinning: true });
      const re = await getPizzaPrice(checkItem);
      if (!re || (re && re.result !== 'OK')) throw new Error(re.result);
      const arr = toCamel(re.pizzaDetails);
      checkItem.pizzaDetails = arr;
      // this.setState({ pizzaTotalPrice: re.price, checkItem, pizzaModalSpinning: false });
      this.setState({ pizzaTotalPrice: re.price, checkItem, pizzaModalSpinning: false });
    } catch (error) {
      this.setState(
        {
          pizzaModalSpinning: false,
          errorModalTitle: 'Pizza price not properly updated',
          errorModalMsg: error.message,
        },
        () => {
          this.setState({ errorModalVisible: true });
        },
      );
    }
  };

  handlePopupItemCancel = () => {
    this.setState({ popupSelectorDrawerVisible: false });
  };

  handlePopupChange = () => {
    this.setState({ popupSelectorDrawerVisible: false });
  };

  handleMenuPickerCancelBtnClick = () => {
    this.setState({ menuCancelBtnVisible: true });
  };

  handleMenuCancelBtnCancel = () => {
    this.setState({ menuCancelBtnVisible: false });
  };

  handleMenuClearCart = () => {
    this.setState({ menuCancelBtnVisible: false });
    this.props.clearOrderItems();
  };

  handleMenuGoToOrderOption = () => {
    this.setState({ menuCancelBtnVisible: false });
    this.props.clearOrderItems();
    this.props.history.push('/orderOptions');
  };

  handleMenuGoToDashboard = () => {
    this.setState({ menuCancelBtnVisible: false });
    this.props.clearOrderItems();
    this.props.history.push('/admin/dashboard');
  };

  handleMenuLogOut = () => {
    this.setState({ menuCancelBtnVisible: false });
    //Log user out
    this.props.clearOrderItems();
    this.props.clearScannedOrderItems();
    this.props.resetUser();
    this.props.deleteGuestName();
    this.props.deleteGuestPhone();
    this.props.deleteGuestIns();
    this.props.resetCustomerOrderOption();
    this.props.resetLoginType();
    this.props.resetAppMode();
    this.props.history.push('/');
  };

  handleNoViewGoBack = () => {
    const { UserName } = this.props.user ?? null;
    if (UserName) {
      this.props.clearOrderItems();
      this.props.history.push('/admin/dashboard');
    } else {
      this.handleMenuLogOut();
    }
  };

  getImage = (dataType, imageName) => {
    let src = '';
    const imageDir = this.state[dataType];
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      //dev mode
      if (imageName && imageDir) {
        src = `http://localhost:4000/${imageDir}/${imageName}`;
      } else {
        src = '';
      }
    } else {
      //production mode
      if (imageName && imageDir) {
        src = `${process.env.PUBLIC_URL}/${imageDir}/${imageName}`;
      } else {
        src = '';
      }
    }
    return src;
  };

  componentDidMount = () => {
    this.init();
  };

  componentWillUnmount = () => {
    clearTimeout(this.viewOrderPromptTimer);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isIdle()) {
      this.props.onIdleTimerFinish();
    } else {
      this.props.reset();
    }
  }

  render() {
    const {
      view,
      tabs,
      tabId,
      orderDrawerVisible,
      itemSizeModalVisible,
      itemModsModalVisible,
      packageItemDrawerVisible,
      pizzaItemModalVisible,
      pizzaModalSpinning,
      menuCancelBtnVisible,
      popupSelectorDrawerVisible,
      checkItem,
      itemSelected,
      sizeItemInfoList,
      modifierInfos,
      packageItemInfos,
      sizeCode,
      pizzaTotalPrice,
      popupItemInfos,
      errorModalVisible,
      errorModalTitle,
      errorModalMsg,
    } = this.state;
    const {
      order,
      user,
      kioskSetting: {
        imgSetting: { Logo },
        appSetting: { EnableOrderOptionMapping, ScanItemMode },
      },
      clientStyle,
    } = this.props;
    const totalItems = order.map(i => i.qty).reduce((a, b) => a + b, 0) || 0;

    let tab;
    if (tabId) {
      tab = tabs.find(i => i.Code === tabId) || {};
    }
    let tags = [];
    if (tab && tab.Tags.length > 0) {
      tags = tab.Tags || [];
    }

    const tagBarCss = view?.CustomizedCss?.find(i => i.Type === 'TagBarCss');
    const itemCss = view?.CustomizedCss?.find(i => i.Type === 'ItemCss');
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };

    return (
      <>
        {/* Page Content */}
        <div
          className="menu-wrap"
          style={{
            position: 'relative',
          }}
        >
          {/* Page Background */}
          <div className="menu-bgImage-wrap">
            {clientStyle.storeNumber === '3932' || clientStyle.storeNumber === '5960' ? (
              <video width="100%" height="100%" autoPlay muted loop style={{ objectFit: 'cover' }}>
                <source
                  src={`${process.env.PUBLIC_URL}/assets/videos/tin_background.webm`}
                  type="video/webm"
                />
              </video>
            ) : (
              <img
                // src={`${process.env.PUBLIC_URL}/assets/login/loginBackground.jpg`}
                src=""
                alt={''}
                style={{ height: '100vh', width: '100%', objectFit: 'cover' }}
              />
            )}
          </div>
          {/* Header */}
          <div
            className="menu-header-wrap"
            style={{
              backgroundColor:
                clientStyle.storeNumber === '3932' || clientStyle.storeNumber === '5960'
                  ? clientStyle.primary
                  : 'var(--primary)',
              borderTop:
                clientStyle.storeNumber === '3932' || clientStyle.storeNumber === '5960'
                  ? `5px solid ${clientStyle.secondary}`
                  : `5px solid var(--secondary)`,
              height: 118,
              boxShadow: '1px 2px 3px 0px rgba(0, 0, 0, 0.5)',
            }}
          >
            {/* Header Fucntion Row */}
            <Row className="menu-header-logo-row" align="middle">
              {/* Home Icon */}
              <Col span={8}>
                <Row>
                  {clientStyle.storeNumber === '3932' || clientStyle.storeNumber === '5960' ? (
                    <div onClick={this.handleMenuPickerCancelBtnClick}>
                      <img src={homeIcon} height={40} alt="" />
                    </div>
                  ) : (
                    <FontAwesomeIcon
                      icon={faHouse}
                      fontSize={40}
                      color={clientStyle.colorTwo}
                      onClick={this.handleMenuPickerCancelBtnClick}
                    />
                  )}
                </Row>
              </Col>
              <Col span={8}>
                <Row justify="center">
                  <div className="menu-logo-wrap">
                    <img src={this.getImage('logoImageDir', Logo)} alt={''} height={40} />
                  </div>
                </Row>
              </Col>
              <Col span={8}>
                <Row justify="end">
                  {ScanItemMode === 2 ? (
                    <Row align="middle" onClick={this.handleScanBarCodeClick}>
                      <span style={{ fontSize: 25, ...titleStyle, color: clientStyle.colorTwo }}>
                        Scan Item
                      </span>
                      <FontAwesomeIcon
                        icon={faBarcode}
                        fontSize={40}
                        color={clientStyle.colorTwo}
                        style={{ marginLeft: 5 }}
                      />
                    </Row>
                  ) : null}

                  <div
                    id="menu-picker-corner-control-shopping-cart"
                    className="menu-header-cart-wrap"
                    onClick={this.handleShoppingCartClick}
                  >
                    {clientStyle.storeNumber === '3932' || clientStyle.storeNumber === '5960' ? (
                      <div style={{ marginLeft: 25 }}>
                        <img src={cartIcon} height={40} alt="" />
                      </div>
                    ) : (
                      <FontAwesomeIcon
                        icon={faCartShopping}
                        fontSize={40}
                        color={clientStyle.colorTwo}
                        style={{ marginLeft: 25 }}
                      />
                    )}
                    {order.length > 0 ? (
                      <div
                        className="menu-header-cart-count"
                        style={{
                          color: clientStyle.colorTwo,
                          backgroundColor: clientStyle.secondary,
                        }}
                      >
                        <VTTextTransition text={totalItems} />
                      </div>
                    ) : null}
                  </div>
                </Row>
              </Col>
            </Row>
            <Row className="menu-header-tabs-row">
              {tabs.map(i => (
                <div
                  key={i.Code}
                  className="menu-header-tab-card"
                  style={{
                    backgroundColor: clientStyle.bgColor,
                    borderBottom: tabId === i.Code ? `8px solid ${clientStyle.secondary}` : '',
                    fontFamily: clientStyle.fontOne,
                    fontVariationSettings: clientStyle.fontVariationSettings,
                    color: clientStyle.colorOne,
                    textTransform: clientStyle.textTransform,
                    fontSize: 16,
                    boxShadow: '1px 2px 3px 0px rgba(0, 0, 0, 0.5)',
                  }}
                  onClick={() => this.handleScrollerSelection(i.Code)}
                >
                  {i.Description}
                </div>
              ))}
            </Row>
          </div>
          <div className="menu-picker-wrap" style={{ height: 'calc(100vh - 117px)' }}>
            <MenuPicker
              data={tags}
              cardOrPill={
                1
                // clientStyle.storeNumber === '5960' || clientStyle.storeNumber === '3932' ? 1 : 0
              } //0: card; 1: pill
              itemCss={itemCss ? itemCss : null}
              tagBarCss={tagBarCss ? tagBarCss : null}
              onCardPillClick={(item, obj) => this.handleItemClick(item, obj)}
              onMenuPickerCancelBtnClick={this.handleMenuPickerCancelBtnClick}
            />
          </div>
        </div>
        {/* Menu Cancel Modal */}
        <Modal
          visible={menuCancelBtnVisible}
          size="large"
          maskClosable={true}
          closable={false}
          title={null}
          footer={null}
          destroyOnClose={true}
          onCancel={this.handleMenuCancelBtnCancel}
          onCloseClick={this.handleMenuCancelBtnCancel}
          showCloseBtn={true}
        >
          <div style={{ margin: '20px 0 30px', position: 'relative' }}>
            <Row
              justify="center"
              align="middle"
              style={{ margin: '0 0 50px', fontSize: 18, fontWeight: 'bold' }}
            >
              <FontAwesomeIcon icon={faCircleExclamation} size="xl" color="#faad14" />
              <h3 style={{ ...titleStyle, marginLeft: 20 }}>Please Select An Option</h3>
            </Row>
            {order.length > 0 ? (
              <Row justify="center" style={{ marginBottom: 20 }}>
                <Button
                  width={260}
                  height={60}
                  style={{ ...rowStyle }}
                  textStyle={{ fontSize: 18 }}
                  borderRadius={5}
                  icon="trash"
                  iconSize="2xl"
                  onClick={this.handleMenuClearCart}
                >
                  Clear Cart
                </Button>
              </Row>
            ) : null}
            {EnableOrderOptionMapping ? (
              <Row justify="center" style={{ marginBottom: 20 }}>
                <Button
                  width={260}
                  height={60}
                  style={{ ...rowStyle }}
                  textStyle={{ fontSize: 18 }}
                  borderRadius={5}
                  icon="calender"
                  iconSize="2xl"
                  onClick={this.handleMenuGoToOrderOption}
                >
                  Order Options
                </Button>
              </Row>
            ) : null}
            {user && user.UserName ? (
              <Row justify="center" style={{ marginBottom: 20 }}>
                <Button
                  width={260}
                  height={60}
                  style={{ ...rowStyle }}
                  textStyle={{ fontSize: 18 }}
                  borderRadius={5}
                  icon="home"
                  iconSize="2xl"
                  onClick={this.handleMenuGoToDashboard}
                >
                  Dashboard
                </Button>
              </Row>
            ) : null}
            <Divider style={{ borderColor: 'transparent' }} />
            <Row justify="center">
              <Button
                width={260}
                height={60}
                style={{ ...rowStyle }}
                textStyle={{ fontSize: 18 }}
                borderRadius={5}
                icon="logout"
                iconSize="2xl"
                onClick={this.handleMenuLogOut}
              >
                Cancel Order
              </Button>
            </Row>
          </div>
        </Modal>
        {/* Order drawer */}
        <Drawer
          visible={orderDrawerVisible}
          size="large"
          maskClosable={true}
          closable={false}
          onClose={this.handleOrderDrawerClose}
          destroyOnClose={true}
          maskStyle={{ backgroundColor: clientStyle.backdrop }}
          bodyStyle={{ backgroundColor: clientStyle.bgColor }}
        >
          <Row align="middle">
            <Col span={2}>
              <div
                className="order-drawer-close-btn"
                onClick={this.handleOrderDrawerClose}
                style={{ color: clientStyle.colorOne }}
              >
                X
              </div>
            </Col>
            <Col span={22}>
              <Row justify="space-around" gutter={16}>
                <Col span={14}>
                  <OrderHeaderAlt
                    order={order}
                    color={clientStyle.accent}
                    bgColor={clientStyle.primary}
                    fontSize={20}
                    height={60}
                    // width={380}
                    transition={false}
                  />
                </Col>
                <Col span={10}>
                  <Button
                    width={250}
                    height={60}
                    icon="check"
                    iconSize="2xl"
                    style={{ ...rowStyle }}
                    textStyle={{ fontSize: 20 }}
                    borderRadius={5}
                    onClick={this.handleCheckOutBtn}
                  >
                    Check out
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <OrderItemsPaneAlt />
        </Drawer>
        {/* Menu item size modal */}
        <MenuItemSizeSelector
          visible={itemSizeModalVisible}
          onCancel={this.handleItemSizeModalCancel}
          checkItem={checkItem}
          sizes={sizeItemInfoList || []}
          itemSelected={itemSelected}
          onOK={obj => this.handleItemSizeModalOK(obj)}
        />
        {/* Menu item mods modal */}
        <MenuItemModsSelector
          width={800}
          visible={itemModsModalVisible}
          des={checkItem?.description}
          itemMods={modifierInfos || []}
          addMods={mods => this.handleAddMods(mods)}
          onCancel={this.handleModsModalCancel}
          onRedo={this.handleModsModalRedo}
          sizes={sizeItemInfoList || []}
        />
        {/* Menu package item drawer */}
        <Drawer
          width="100%"
          visible={packageItemDrawerVisible}
          destroyOnClose={true}
          maskClosable={false}
          closable={false}
          drawerStyle={{ position: 'relative' }}
          maskStyle={{ backgroundColor: clientStyle.backdrop }}
          bodyStyle={{ backgroundColor: clientStyle.bgColor }}
        >
          <Row>
            <Col span={24}>
              <PackageItemSelector
                onPackageItemCancel={this.handlePackageItemCancel}
                packageItemInfos={packageItemInfos}
                packageType={checkItem.packageType}
                packagePrice={checkItem.packageType !== '1' ? itemSelected?.Price : 0}
                onSelectionChange={packageItems => {
                  this.handlePackageChange(packageItems);
                }}
              />
            </Col>
          </Row>
        </Drawer>
        {/* Menu Popup Seletor drawer */}
        <Drawer
          width="100%"
          visible={popupSelectorDrawerVisible}
          destroyOnClose={true}
          maskClosable={false}
          closable={false}
          drawerStyle={{ position: 'relative' }}
          maskStyle={{ backgroundColor: clientStyle.backdrop }}
          bodyStyle={{ backgroundColor: clientStyle.bgColor }}
        >
          <Row>
            <Col span={24}>
              <PopupItemSelector
                onPopupItemCancel={this.handlePopupItemCancel}
                popupItemInfos={popupItemInfos}
                onSelectionChange={() => {
                  this.handlePopupChange();
                }}
              />
            </Col>
          </Row>
        </Drawer>
        {/* Failure Modal */}
        <ErrorModal
          visible={errorModalVisible}
          title={errorModalTitle}
          msg={errorModalMsg}
          onOK={this.handleErrorModalOK}
        />
        <MenuPizzaModal
          visible={pizzaItemModalVisible}
          item={itemSelected}
          checkItem={checkItem}
          pizzaTotalPrice={pizzaTotalPrice}
          sizeCode={sizeCode}
          pizzaModalSpinning={pizzaModalSpinning}
          onPizzaModalCancel={this.handlePizzaModalCancel}
          onPizzaModalRedo={this.handlePizzaModalRedo}
          addPizzaDetails={(pizzaDetails, isOK) => this.handleAddPizzaDetails(pizzaDetails, isOK)}
        />
      </>
    );
  }
}
export default connect(
  state => ({
    order: state.order,
    user: state.user,
    kioskSetting: state.kioskSetting,
    customerOrderOption: state.customerOrderOption,
    guestName: state.guestName,
    guestPhone: state.guestPhone,
    clientStyle: state.clientStyle,
  }),
  {
    updateOrder,
    receivePizzaOrder,
    delOrderItem,
    clearOrderItems,
    clearScannedOrderItems,
    resetUser,
    deleteGuestName,
    resetCustomerOrderOption,
    resetLoginType,
    resetAppMode,
    deleteGuestPhone,
    deleteGuestIns,
  },
)(withIdleTimer(Menu));
