import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faAnglesUp, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import OrderSubRow from '../OrderSubRow';

import './index.css';

class OrderRow extends Component {
  state = { expanded: false, item: {}, modsPaneMaxHeight: 0 };

  refSubRowsPane = React.createRef();
  ref = React.createRef();

  init = () => {
    const { item } = this.props;
    const obj = JSON.parse(JSON.stringify(item));
    this.setState({ item: obj }, () => {
      this.setSubRowPanesHeight();
    });
  };

  setSubRowPanesHeight = () => {
    let height;
    if (this.refSubRowsPane.current) {
      height = this.refSubRowsPane.current.scrollHeight;
    }

    this.setState({ modsPaneMaxHeight: height });
  };

  handleDecrement = () => {
    const { item } = this.state;
    item.qty = item.qty - 1 <= 0 ? 1 : item.qty - 1;
    this.setState({ item });
    this.props.onOrderItemChange(item);
  };

  handleIncrement = () => {
    const { editablePlus } = this.props;
    if (editablePlus) {
      const { item } = this.state;
      item.qty = item.qty + 1;
      this.setState({ item });
      this.props.onOrderItemChange(item);
    } else {
      return;
    }
  };

  handleDelete = () => {
    this.props.onDelete();
  };

  handleExpand = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  handleRemoveModRow = id => {
    const { item } = this.state;
    const { modifierDetails } = item;
    const newItems = modifierDetails.filter(i => i.code !== id);
    item.modifierDetails = newItems;
    this.setState({ item });
    this.props.onOrderItemChange(item);
  };

  componentDidMount() {
    this.init();
    this.refSubRowsPane.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { item } = this.props;
    if (item !== prevProps.item) {
      this.init();
    }
  }

  render() {
    const { expanded, item, modsPaneMaxHeight } = this.state;
    const {
      style,
      width = 495,
      height = 50,
      boxShadow = true,
      editable = true,
      editablePlus = true,
      showQty = true,
      clientStyle,
    } = this.props;

    let data = [];
    if (item && item.type === 'P' && item.packageDetails && item.packageDetails.length > 0) {
      let packageArr = [];
      item.packageDetails.forEach(i => {
        packageArr.push(i);
        if (i.type === 'I' && i.pizzaDetails.length > 0) {
          i.pizzaDetails.forEach(j => {
            packageArr.push(j);
          });
        }
      });

      data = packageArr || [];
    } else if (item && item.type === 'I') {
      data = [...item.pizzaDetails, ...item.modifierDetails];
    } else if (
      item &&
      item.type !== 'P' &&
      item.modifierDetails &&
      item.modifierDetails.length > 0
    ) {
      data = item.modifierDetails || [];
    }

    const titleStyle = {
      color: clientStyle.colorTwo,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };

    return (
      <div className="vt-order-row-wrapper" style={{ width: '100%' }}>
        <div
          className="vt-order-row-container"
          style={{
            ...style,
            minHeight: height,
            padding: `calc((5 / 800)*${width}px) calc((10 / 800)*${width}px)`,
            backgroundColor: clientStyle.primary,
            boxShadow: boxShadow
              ? `0 calc((0.3/400)*${width}px) calc((0.7/400)*${width}px) rgba(0, 0, 0, 0.126),
                      0 calc((0.4/400)*${width}px) calc((0.8/400)*${width}px) rgba(0, 0, 0, 0.179),
                      0 calc((0.6/400)*${width}px) calc((1.2/400)*${width}px) rgba(0, 0, 0, 0.224),
                      0 calc((0.9/400)*${width}px) calc((1.8/400)*${width}px) rgba(0, 0, 0, 0.277),
                      0 calc((2/400)*${width}px) calc((4/400)*${width}px) rgba(0, 0, 0, 0.4)`
              : '',
            WebkitBoxShadow: boxShadow
              ? `0 calc((0.3/400)*${width}px) calc((0.7/400)*${width}px) rgba(0, 0, 0, 0.126),
                            0 calc((0.4/400)*${width}px) calc((0.8/400)*${width}px) rgba(0, 0, 0, 0.179),
                            0 calc((0.6/400)*${width}px) calc((1.2/400)*${width}px) rgba(0, 0, 0, 0.224),
                            0 calc((0.9/400)*${width}px) calc((1.8/400)*${width}px) rgba(0, 0, 0, 0.277),
                            0 calc((2/400)*${width}px) calc((4/400)*${width}px) rgba(0, 0, 0, 0.4)`
              : '',
          }}
        >
          {editable ? (
            <div className="vt-order-row-edit" style={{ ...titleStyle }}>
              {item.qty === 1 ? (
                <div className="vt-order-row-delete" onClick={this.handleDelete}>
                  <FontAwesomeIcon icon={faTrashCan} size="2x" />
                </div>
              ) : (
                <div className="vt-order-row-minus" onClick={this.handleDecrement}>
                  <FontAwesomeIcon icon={faMinus} size="2x" />
                </div>
              )}

              <div className="vt-order-row-plus" onClick={this.handleIncrement}>
                {editablePlus && <FontAwesomeIcon icon={faPlus} size="2x" />}
              </div>
            </div>
          ) : (
            <div className="vt-order-row-edit" style={{ ...titleStyle }}></div>
          )}
          <div className="vt-order-row-main" style={{ ...titleStyle }} onClick={this.handleExpand}>
            <div className="vt-order-row-main-title">
              <span>{item.description}</span>
            </div>
            <div className="vt-order-row-main-price">
              <span>
                {item && item.packageType && item.packageType === '1'
                  ? ''
                  : `$${parseFloat(item.price).toFixed(2)}`}
              </span>
            </div>
            <div className="vt-order-row-expand">
              {data.length > 0 ? (
                <FontAwesomeIcon icon={faAnglesUp} size="lg" rotation={expanded ? 0 : 180} />
              ) : (
                <></>
              )}
            </div>
          </div>
          {showQty ? (
            <div
              className="vt-order-row-badge"
              style={{ ...titleStyle, background: clientStyle.secondary, width: 55 }}
            >
              {item.qty}
            </div>
          ) : null}
        </div>
        <div
          ref={this.refSubRowsPane}
          style={{
            overflow: 'hidden',
            maxHeight: expanded ? modsPaneMaxHeight : 0,
            transition: 'max-height 500ms ease-in-out',
          }}
        >
          <CSSTransition in={expanded} timeout={500} classNames="vt-order-sub-rows-container">
            <div className="vt-order-sub-rows-container">
              <TransitionGroup exit={true}>
                {data.length > 0 &&
                  data.map(i => (
                    <CSSTransition key={i.code} timeout={500} classNames="vt-order-row-mod-item">
                      <OrderSubRow
                        key={i.code}
                        item={i}
                        pBgColor={clientStyle.primary}
                        onDelete={() => this.handleRemoveModRow(i.code)}
                      />
                    </CSSTransition>
                  ))}
              </TransitionGroup>
            </div>
          </CSSTransition>
        </div>
      </div>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(OrderRow);
