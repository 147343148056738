import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { nanoid } from '@reduxjs/toolkit';
import EditableTable from '../EditableTable';
import ResInputModal from '../ResInputModal';

const { TextArea } = Input;

class ResSubmit extends Component {
  state = {
    dataField: {
      guest: null,
      type: '',
    },
    modalVisible: false,
    modalInputValue: '',
    modalKeyboardLayout: 'default',
  };

  handleAddGuest = () => {
    const { resGuests, guestsNum } = this.props;
    const newList = [...resGuests.guestList];
    if (newList.length + 1 >= guestsNum) return;
    const newGuest = {
      key: nanoid(),
      AccountNo: '',
      FirstName: '',
      LastName: '',
    };
    resGuests.guestList = [...newList, newGuest];
    this.props.onResGuestsChange(resGuests);
  };

  handleDeleteGuestRow = row => {
    const { resGuests } = this.props;
    resGuests.guestList = [...resGuests.guestList.filter(i => i.key !== row.key)];
    this.props.onResGuestsChange(resGuests);
  };

  handleInputFocus = dataType => {
    const { resGuests } = this.props;
    this.setState({ modalInputValue: resGuests[dataType] }, () => {
      const { dataField } = this.state;
      dataField.guest = null;
      dataField.type = dataType;
      this.setState({
        dataField,
        modalVisible: true,
        modalKeyboardLayout: dataType === 'phoneNumber' ? 'number' : 'default',
      });
    });
  };

  handleGuestListInputFocus = (_, dataType, row) => {
    this.setState({ modalInputValue: row[dataType], modalKeyboardLayout: 'default' }, () => {
      const { dataField } = this.state;
      dataField.guest = row;
      dataField.type = dataType;
      this.setState({ dataField, modalVisible: true });
    });
  };

  handleInputModalChange = text => {
    const { dataField } = this.state;
    const { resGuests } = this.props;
    const { type } = dataField;
    if (!dataField.guest) {
      resGuests[type] = text;
      this.props.onResGuestsChange(resGuests);
      this.setState({ modalVisible: false });
    }
    if (dataField.guest) {
      const newList = [...resGuests.guestList];
      const guest = newList.find(i => i.key === dataField.guest.key);
      if (!guest) return;
      guest[type] = text;
      resGuests.guestList = newList;
      this.props.onResGuestsChange(resGuests);
      this.setState({ modalVisible: false });
    }
  };

  render() {
    const { dataField, modalVisible, modalInputValue, modalKeyboardLayout } = this.state;
    const { resGuests, guestsNum, clientStyle } = this.props;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const descStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontTwo,
    };
    const columns = [
      {
        title: () => (
          <Row justify="center">
            <FontAwesomeIcon
              icon={faCirclePlus}
              size="3x"
              color={clientStyle.primary}
              onClick={this.handleAddGuest}
            />
          </Row>
        ),
        render: (text, record) => (
          <Row justify="center">
            <FontAwesomeIcon
              icon={faCircleMinus}
              size="3x"
              color={clientStyle.secondary}
              onClick={() => this.handleDeleteGuestRow(record)}
            />
          </Row>
        ),
      },
      {
        title: () => (
          <Row style={{ ...titleStyle }} justify="center">
            First Name
          </Row>
        ),
        dataIndex: 'firstName',
        render: (_, record) => (
          <Row style={{ width: 250 }}>
            <Input
              size="large"
              value={record.FirstName}
              placeholder="First Name"
              spellCheck={false}
              onFocus={e => this.handleGuestListInputFocus(e, 'FirstName', record)}
              style={{ ...titleStyle }}
            />
          </Row>
        ),
      },
      {
        title: () => (
          <Row style={{ ...titleStyle }} justify="center">
            Last Name
          </Row>
        ),
        dataIndex: 'lastName',
        render: (_, record) => (
          <Row style={{ width: 250 }}>
            <Input
              size="large"
              value={record.LastName}
              placeholder="Last Name"
              spellCheck={false}
              onFocus={e => this.handleGuestListInputFocus(e, 'LastName', record)}
              style={{ ...titleStyle }}
            />
          </Row>
        ),
      },
    ];
    return (
      <>
        <Row>
          <Col span={24}>
            <Row justify="center" style={{ ...titleStyle }}>
              <Col span={20}>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <Row>
                      <strong>First Name</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={resGuests.firstName}
                        onFocus={() => this.handleInputFocus('firstName')}
                        style={{ ...descStyle }}
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Last Name</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={resGuests.lastName}
                        onFocus={() => this.handleInputFocus('lastName')}
                        style={{ ...descStyle }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <Row>
                      <strong>Phone Number</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={resGuests.phoneNumber}
                        onFocus={() => this.handleInputFocus('phoneNumber')}
                        style={{ ...descStyle }}
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Email</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={resGuests.email}
                        onFocus={() => this.handleInputFocus('email')}
                        style={{ ...descStyle }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={24}>
                    <Row>
                      <strong>Special Instruction (Optional)</strong>
                    </Row>
                    <Row>
                      <TextArea
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        placeholder="Instruction"
                        spellCheck={false}
                        showCount
                        maxLength={100}
                        onFocus={() => this.handleInputFocus('instruction')}
                        value={resGuests.instruction}
                        style={{ width: 250, ...descStyle }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={24}>
                    <Row>
                      <strong>
                        Would you like to let us know who will be with you? (Optional) You can add
                        up to {parseInt(guestsNum - 1)} guest(s).
                      </strong>
                    </Row>
                    {/* Party list row */}
                    <Row style={{ marginBottom: 30 }}>
                      <Col span={24}>
                        <EditableTable
                          locale={{ emptyText: <span></span> }}
                          tableColumns={columns}
                          tableDataSource={resGuests.guestList}
                          pagination={false}
                          rowKey="key"
                          rowClassName={() => 'vt-res-guests-table-row'}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <ResInputModal
          visible={modalVisible}
          dataField={dataField}
          onTextChange={text => {
            this.handleInputModalChange(text);
          }}
          value={modalInputValue}
          keyboardLayout={modalKeyboardLayout}
        />
      </>
    );
  }
}

export default connect(state => ({ clientStyle: state.clientStyle }), {})(ResSubmit);
