import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from 'antd';

class OrderOptionTimeSlots extends Component {
  render() {
    const { timeSlotChoice, slots, clientStyle } = this.props;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    return (
      <Row
        className="order-option-dayparts-time-slots"
        style={{
          margin: '10px 0',
          padding: '5px 0px 35px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {slots.map((i, index) => (
          <div
            key={index}
            style={{
              ...titleStyle,
              width: 120,
              padding: 10,
              backgroundColor: timeSlotChoice === i ? clientStyle.primary : '#fff',
              borderRadius: 5,
              boxShow: '1px 1px 0px rgba(0,0,0,0.75)',
              WebkitBoxShadow: '1px 1px 5px 0px rgba(0,0,0,0.75)',
              margin: 8,
              textAlign: 'center',
              color: timeSlotChoice === i ? clientStyle.colorTwo : clientStyle.colorOne,
              fontWeight: timeSlotChoice === i ? 'bold' : '',
              transform: timeSlotChoice === i ? 'translateY(1px)' : '',
              transition: 'transform 0.2s ease',
            }}
            onClick={() => {
              this.props.onTimeSlotChange(i);
            }}
          >
            {i.TimeDescription}
          </div>
        ))}
      </Row>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(OrderOptionTimeSlots);
