import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import Card from '../Card';

class OrderOptionVenue extends Component {
  handleCardClick = item => {
    this.props.onVenueClick(item);
  };

  render() {
    const { orderOption, venue, clientStyle } = this.props;
    const width = 200;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    return (
      <Col span={24} style={{ marginTop: 30 }} className="order-option-step-two-right-side">
        <Row justify="center" style={{ marginBottom: 30 }}>
          <div style={{ ...titleStyle, fontSize: 26, fontWeight: 800 }}>SELECT A VENUE</div>
        </Row>
        <Row justify="center">
          {orderOption.map(i => (
            <div key={i.key} style={{ margin: 15 }}>
              <Card
                titleStyle={{
                  fontSize: width > 149 ? `${width / 200}rem` : '0.75rem',
                  ...titleStyle,
                }}
                imgStr={i.ImageString ? `data:image/png; base64, ${i.ImageString}` : ''}
                imgHeight={120}
                title={i.VenueDescription}
                width={width}
                height={width}
                selectedCard={venue}
                selectedBorder={clientStyle.secondary}
                id={i}
                item={i}
                onCardClick={() => this.handleCardClick(i)}
              />
            </div>
          ))}
        </Row>
      </Col>
    );
  }
}

export default connect(state => ({ clientStyle: state.clientStyle }), {})(OrderOptionVenue);
