import React, { Component } from 'react';
import { Row, Col, Radio } from 'antd';
import { connect } from 'react-redux';
import TextTransition from '../TextTransition';
import Button from '../Button';
import Modal from '../Modal';
import './index.css';

export class SizeModal extends Component {
  state = {
    sizeAnimation: false,
    checkItem: null,
    description: '',
    price: '',
    sizeCode: null,
    size: null,
  };

  init = () => {
    const { checkItem } = this.props;
    if (checkItem) {
      const { description, price, sizeCode } = checkItem;
      this.setState({ description, price, sizeCode });
    }
  };

  handleSizeChange = ({ target: { value } }) => {
    const { sizes, itemSelected } = this.props;
    const size = sizes.find(i => i.SizeCode === value);
    const description = `${size.SizeDescription}${' '}${itemSelected.Description}`;
    const sizeCode = value;
    const price = size.BasePrice.toFixed(2);
    this.setState({ description, price, sizeCode, size });
  };

  handleItemSizeModalOK = () => {
    const { description, price, sizeCode, size } = this.state;
    const { onOK } = this.props;
    if (sizeCode === null || sizeCode === undefined || sizeCode === '') {
      this.handleSizeAnimation();
      return;
    }
    const obj = { description, price, sizeCode, size };
    this.setState({ description: '', price: '', sizeCode: null, size: null });
    onOK(obj);
  };

  handleSizeAnimation = () => {
    const { sizeAnimation } = this.state;
    if (sizeAnimation) {
      clearTimeout(this.sizeAnimationTimer);
      this.sizeAnimationTimer();
    }
    if (!sizeAnimation) {
      this.setState({ sizeAnimation: true });
      this.sizeAnimationTimer();
    }
  };

  sizeAnimationTimer = () => {
    setTimeout(() => {
      this.setState({ sizeAnimation: false });
    }, 900);
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { itemSelected } = this.props;
    if (itemSelected !== prevProps.itemSelected) {
      this.init();
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.sizeAnimationTimer);
  };

  render() {
    const { sizeAnimation, description, price, sizeCode } = this.state;
    const { visible, onCancel, sizes, zIndex = 1000, clientStyle } = this.props;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    return (
      <Modal
        visible={visible}
        size="large"
        maskClosable={false}
        closable={false}
        title={null}
        footer={null}
        destroyOnClose={true}
        zIndex={zIndex}
        onCloseClick={onCancel}
        showCloseBtn={true}
      >
        <div className="menu-item-size-modal-wrap">
          <div
            className="menu-item-size-modal-header"
            style={{ backgroundColor: clientStyle.bgColor }}
          >
            <Row
              className="menu-item-size-modal-title"
              justify="center"
              style={{
                animation: sizeAnimation ? 'horizontal-shaking 700ms ease-out 300ms' : '',
                ...titleStyle,
              }}
            >
              Select Size
            </Row>
            <Row
              align="middle"
              className="menu-item-size-modal-item-info"
              style={{ fontSize: 18, ...titleStyle }}
            >
              <Col span={20}>
                <Row>{description}</Row>
              </Col>
              <Col span={4}>
                <Row justify="center">
                  <TextTransition text={`$${price}`} style={{ fontSize: '18px' }} />
                </Row>
              </Col>
            </Row>
          </div>
          <div className="menu-item-size-modal-list">
            <Radio.Group value={sizeCode} onChange={e => this.handleSizeChange(e)}>
              {sizes &&
                sizes.map(i => (
                  <Radio
                    key={i.SizeCode}
                    value={i.SizeCode}
                    className="menu-item-size-modal-radio-btn"
                    style={{
                      background: i.SizeCode === sizeCode ? clientStyle.primary : '',
                    }}
                  >
                    <Row
                      key={i.SizeCode}
                      align="middle"
                      style={{
                        fontSize: '18px',
                        ...titleStyle,
                        color: i.SizeCode === sizeCode ? clientStyle.accent : clientStyle.colorOne,
                      }}
                    >
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col>
                            <Row style={{ fontWeight: 'bold', width: 350 }}>
                              {i.SizeDescription}
                            </Row>
                          </Col>
                          <Col>
                            <Row justify="end">{`$${parseFloat(i.BasePrice).toFixed(2)}`}</Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Radio>
                ))}
            </Radio.Group>
          </div>
          <div className="menu-item-size-modal-footer">
            <Row justify="center" style={{ margin: '5px 10px' }}>
              <Button
                width={250}
                height={60}
                style={{ color: clientStyle.colorTwo, backgroundColor: clientStyle.primary }}
                textStyle={{ fontSize: 18 }}
                borderRadius={5}
                onClick={this.handleItemSizeModalOK}
              >
                OK
              </Button>
            </Row>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(state => ({ clientStyle: state.clientStyle }), {})(SizeModal);
