import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import moment from 'moment';
import Button from '../Button';
import OrderOptionDayParts from '../OrderOptionDayParts';
import OrderOptionTimeSlots from '../OrderOptionTimeSlots';

class OrderOptionStepTwo extends Component {
  state = {
    rightSide: 2,
    pickerOptionGroups: {},
    pickerValueGroups: {},
    showScheduleContent: false,
  };

  handlePickerChange = (name, value) => {
    this.setState(({ pickerValueGroups }) => ({
      pickerValueGroups: {
        ...pickerValueGroups,
        [name]: value,
      },
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    const { scheduleOptionSelected } = this.props;
    if (scheduleOptionSelected !== prevProps.scheduleOptionSelected) {
      this.setState({ showScheduleContent: scheduleOptionSelected === '2' });
    }
  }

  render() {
    const { showScheduleContent } = this.state;
    const {
      selectedOption,
      scheduleOption,
      scheduleOptionSelected,
      dateChoice,
      dayPartChoice,
      timeSlotChoice,
      clientStyle,
    } = this.props;
    const screen = window.innerWidth;
    const { OrderDates } = selectedOption;
    const { DayParts } = dateChoice;

    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };

    return OrderDates.length === 0 && !scheduleOption ? (
      <Col span={24}>
        <Row style={{ marginTop: 200 }} justify="center">
          <h1 style={{ ...titleStyle }}>There is no available date found. </h1>
        </Row>
      </Col>
    ) : (
      <Col span={24}>
        <Row>
          <Col span={6}>
            <Row
              className="order-options-step-two-left-scrollable"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: screen === 1080 ? 1200 : screen === 1920 ? 950 : 720,
                padding: '20px 0',
                animation:
                  !scheduleOption || showScheduleContent
                    ? 'showDates 400ms ease 400ms forwards'
                    : '',
              }}
            >
              <Col span={24}>
                {OrderDates.length > 0 &&
                  OrderDates.map(i => (
                    <Row key={i.key} justify="center" style={{ margin: '0 0 20px 0' }}>
                      <Button
                        key={i.key}
                        width={200}
                        borderRadius={8}
                        style={{
                          backgroundColor: dateChoice === i ? clientStyle.primary : '#fff',
                          color: dateChoice === i ? clientStyle.colorTwo : clientStyle.colorOne,
                        }}
                        textStyle={{ fontSize: 16 }}
                        onClick={() => {
                          this.props.onDateChange(i);
                        }}
                      >
                        {moment(i.DateShort).format('ddd, MMM D, YYYY')}
                      </Button>
                    </Row>
                  ))}
              </Col>
            </Row>
          </Col>
          {/* Right Side
          0. Today ASAP / Schedule
          1. Day parts without time slots;
          2. Day parts with time slots;
          3. Open to close every 15 mins apart;
          4. "Today ASAP" & Schedule date and time;
          */}
          <Col span={18}>
            <div
              className="order-option-step-two-right-side"
              style={{ height: screen === 1080 ? 1200 : screen === 1920 ? 950 : 720 }}
            >
              {scheduleOption && (
                <Row
                  // justify="center"
                  className="order-option-schedule-option-container"
                  style={{
                    margin: '5px 0 10px',
                    animation:
                      scheduleOptionSelected === '2'
                        ? 'scheduleOptionMoveUp 400ms ease-out forwards'
                        : '',
                  }}
                >
                  <div
                    key="1"
                    style={{
                      ...titleStyle,
                      backgroundColor:
                        scheduleOptionSelected === '1' ? clientStyle.primary : '#fff',
                      color:
                        scheduleOptionSelected === '1'
                          ? clientStyle.colorTwo
                          : clientStyle.colorOne,
                      margin: '5px 5px 5px 150px',
                      minWidth: 100,
                      padding: '10px 15px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '5px ',
                      WebkitBoxShadow: '1px -1px 8px 0px rgba(0,0,0,0.75)',
                      boxShadow: '1px -1px 8px 0px rgba(0,0,0,0.75)',
                      fontSize: 18,
                      fontWeight: 'bold',
                      transition: 'transform 0.2s ease',
                      textAlign: 'center',
                    }}
                    onClick={() => {
                      this.props.onScheduleOptionChange('1');
                    }}
                  >
                    Today ASAP
                  </div>
                  {OrderDates.length === 0 ? null : (
                    <div
                      key="2"
                      style={{
                        ...titleStyle,
                        backgroundColor:
                          scheduleOptionSelected === '2' ? clientStyle.primary : '#fff',
                        color:
                          scheduleOptionSelected === '2'
                            ? clientStyle.colorTwo
                            : clientStyle.colorOne,
                        margin: 5,
                        minWidth: 100,
                        padding: '10px 15px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '5px ',
                        WebkitBoxShadow: '1px -1px 8px 0px rgba(0,0,0,0.75)',
                        boxShadow: '1px -1px 8px 0px rgba(0,0,0,0.75)',
                        fontSize: 18,
                        fontWeight: 'bold',
                        transition: 'transform 0.2s ease',
                        textAlign: 'center',
                      }}
                      onClick={() => {
                        this.props.onScheduleOptionChange('2');
                      }}
                    >
                      Schedule Date/Time
                    </div>
                  )}
                </Row>
              )}
              {/* Schedule Content */}
              <Row
                className="order-option-step-two-schedule-content"
                style={{
                  animation:
                    !scheduleOption || showScheduleContent
                      ? 'showScheduleContent 400ms ease 400ms forwards'
                      : '',
                }}
              >
                {
                  <Row>
                    <Col span={24}>
                      <OrderOptionDayParts
                        dayParts={DayParts}
                        dayPartChoice={dayPartChoice}
                        onDayPartChange={key => {
                          this.props.onDayPartChange(key);
                        }}
                      />
                      <OrderOptionTimeSlots
                        timeSlotChoice={timeSlotChoice}
                        slots={
                          dayPartChoice && dayPartChoice.Times && dayPartChoice.Times.length > 0
                            ? dayPartChoice.Times
                            : []
                        }
                        onTimeSlotChange={key => this.props.onTimeSlotChange(key)}
                      />
                    </Col>
                  </Row>
                }
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default connect(state => ({ clientStyle: state.clientStyle }), {})(OrderOptionStepTwo);
