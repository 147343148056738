import React, { Component } from 'react';
import { connect } from 'react-redux';

import './index.css';

class OrderSubRow extends Component {
  render() {
    //pBgColor: parent row background color
    const { style, width = 450, height = 50, item, pBgColor, clientStyle } = this.props;
    const titleStyle = {
      color: clientStyle.colorTwo,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    return (
      <div className="vt-order-sub-row-wrapper">
        <div
          className="vt-order-sub-row-content"
          style={{
            ...titleStyle,
            ...style,
            width: '100%',
            minHeight: height,
            padding: `calc((5 / 400)*${width}px) calc((10 / 400)*${width}px)`,
            backgroundColor: pBgColor,
            filter: 'brightness(1.2) opacity(0.9)',
            boxShadow: `0 calc((0.3/400)*${width}px) calc((0.7/400)*${width}px) rgba(0, 0, 0, 0.126),
                      0 calc((0.4/400)*${width}px) calc((0.8/400)*${width}px) rgba(0, 0, 0, 0.179),
                      0 calc((0.6/400)*${width}px) calc((1.2/400)*${width}px) rgba(0, 0, 0, 0.224),
                      0 calc((0.9/400)*${width}px) calc((1.8/400)*${width}px) rgba(0, 0, 0, 0.277),
                      0 calc((2/400)*${width}px) calc((4/400)*${width}px) rgba(0, 0, 0, 0.4)`,
            WebkitBoxShadow: `0 calc((0.3/400)*${width}px) calc((0.7/400)*${width}px) rgba(0, 0, 0, 0.126),
                            0 calc((0.4/400)*${width}px) calc((0.8/400)*${width}px) rgba(0, 0, 0, 0.179),
                            0 calc((0.6/400)*${width}px) calc((1.2/400)*${width}px) rgba(0, 0, 0, 0.224),
                            0 calc((0.9/400)*${width}px) calc((1.8/400)*${width}px) rgba(0, 0, 0, 0.277),
                            0 calc((2/400)*${width}px) calc((4/400)*${width}px) rgba(0, 0, 0, 0.4)`,
          }}
        >
          <div className="vt-order-sub-row-qty">{item.qty}</div>
          <div className="vt-order-sub-row-title">
            <span>{item.description ? item.description : 'Description N/A'}</span>
          </div>
          <div className="vt-order-sub-row-price">${parseFloat(item.price).toFixed(2)}</div>
        </div>
      </div>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(OrderSubRow);
