import React, { Component } from 'react';
import { Row, Col, Divider, Input, Checkbox, Image, message } from 'antd';
import { saveReservationMapping, saveVTImage, deleteVTImage } from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import ImageUploader from '../../../components/ImageUploader';
import Spin from '../../../components/Spin';
import Button from '../../../components/Button';
import './index.css';

const { TextArea } = Input;

export default class AdminEditResMapping extends Component {
  state = {
    spinning: false,
    description: '',
    displayName: '',
    status: null,
    phoneRequired: 0,
    note: '',
    imageName: '',
  };

  init = () => {
    const { item } = this.props.location.state;
    this.setState({
      description: item.Description,
      displayName: item.DisplayName,
      status: item.Status,
      phoneRequired: 0,
      note: item.Note,
      imageName: item.ImageName,
    });
  };

  handleBackBtn = () => {
    this.props.history.replace('/admin/resmappings');
  };

  handleSaveBtn = async () => {
    const { description, displayName, status, note, imageName } = this.state;
    const { item } = this.props.location.state;

    const obj = {};
    obj.Code = item.Code;
    obj.CentreCode = item.CentreCode;
    obj.Description = description;
    obj.DisplayName = displayName;
    obj.Status = status;
    obj.ImageName = imageName;
    obj.StoreNumber = item.StoreNumber;
    obj.Note = note;
    obj.OtherInfo = item.OtherInfo;
    try {
      this.setState({ spinning: true });
      const re = await saveReservationMapping(obj);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false });
      message.success('Reservation mapping succussfully updated!');
      this.props.history.replace('/admin/resmappings');
    } catch (error) {
      message.error('Error saving reservation mapping changes!');
    }
  };

  handleInputChange = (dataType, { target: { value } }) => {
    this.setState({ [dataType]: value });
  };

  handleSingleCheckbox = ({ target: { checked } }, dataType) => {
    this.setState({ [dataType]: checked ? 0 : 1 });
  };

  uploadImg = async base64 => {
    //type: 11
    const obj = {};
    obj.Type = 11;
    obj.ImageString = base64;
    try {
      this.setState({ spinning: true });
      const re = await saveVTImage(obj);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ imageName: re.imageName, spinning: false });
      message.success('Image successfully uploaded!', 3);
    } catch (error) {
      message.error('Error uploading image!', 3);
    }
  };

  handleUploadImg = async base64 => {
    const type = 11;
    const { imageName } = this.state;
    if (imageName) {
      //Delete existing then upload
      const exObj = {};
      exObj.Code = imageName;
      exObj.Type = type;

      try {
        this.setState({ spinning: true });
        const re = await deleteVTImage(exObj);
        if (!re || (re && re.result !== 'OK')) {
          this.setState({ spinning: false });
          throw new Error();
        }
        this.uploadImg(base64);
      } catch (error) {
        message.error('Error uploading image!', 3);
      }
    } else {
      this.uploadImg(base64);
    }
  };

  handleCurrImageRemove = () => {
    this.setState({ imageName: '' });
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { spinning, description, displayName, status, phoneRequired, note, imageName } =
      this.state;
    const { imageDir } = this.props.location.state;
    let src = '';
    const imgFallback = `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      //dev mode
      if (imageName) {
        src = `http://localhost:4000/${imageDir}/${imageName}`;
      } else {
        src = imgFallback;
      }
    } else {
      //production mode
      if (imageName) {
        src = `${process.env.PUBLIC_URL}/${imageDir}/${imageName}`;
      } else {
        src = imgFallback;
      }
    }

    return (
      <Spin spinning={spinning}>
        <Row className="admin-edit-res-mapping-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title="Edit Reservation Mapping"
              showLeftBtn={true}
              leftBtnText="Back"
              showRightBtn={true}
              rightBtnText="Save"
              onRightBtnClick={this.handleSaveBtn}
              onLeftBtnClick={this.handleBackBtn}
            />
            {/* Inputs Row */}
            <Row justify="center">
              <Col span={20}>
                <Row style={{ marginTop: 15 }}>
                  <Col span={12}>
                    <Row>
                      <strong>Description</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input size="large" value={description} disabled={true} spellCheck={false} />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Display Name</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={displayName}
                        onChange={e => this.handleInputChange('displayName', e)}
                      />
                    </Row>
                  </Col>
                </Row>

                <Row style={{ marginTop: 15 }} align="middle">
                  <Col span={12}>
                    <Row gutter={4}>
                      <Col>
                        <strong>Active</strong>
                      </Col>
                      <Col>
                        <Checkbox
                          checked={!status}
                          onChange={e => {
                            this.handleSingleCheckbox(e, 'status');
                          }}
                        />
                      </Col>
                    </Row>
                    <Row gutter={4} style={{ marginTop: 15 }}>
                      <Col>
                        <strong>Customer Phone Number Required</strong>
                      </Col>
                      <Col>
                        <Checkbox
                          checked={phoneRequired}
                          onChange={e => {
                            this.handleSingleCheckbox(e, 'phoneRequired');
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Message</strong>
                    </Row>
                    <Row>
                      <TextArea
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        placeholder="Message"
                        spellCheck={false}
                        showCount
                        maxLength={100}
                        onChange={e => this.handleInputChange('note', e)}
                        value={note}
                        style={{ width: 250 }}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Image Row */}
            <Divider style={{ borderColor: 'transparent' }} />
            <Row justify="center">
              <Col span={20}>
                <Row>
                  <Col span={12}>
                    <Row>
                      <strong>Current Image</strong>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Change Image</strong>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Row
                      style={{
                        width: 300,
                        height: 200,
                        border: '1px solid #e8e8e8',
                        position: 'relative',
                      }}
                      justify="center"
                    >
                      <Image
                        width={300}
                        height={200}
                        src={src}
                        preview={src !== `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`}
                      />
                      {imageName ? (
                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                          <Button
                            width={70}
                            height={35}
                            style={{ backgroundColor: 'var(--adminSecondary)' }}
                            textStyle={{ color: '#fff' }}
                            borderRadius={5}
                            onClick={this.handleCurrImageRemove}
                          >
                            Remove
                          </Button>
                        </div>
                      ) : null}
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row justify="start">
                      <ImageUploader
                        onImgUploadClick={base64 => {
                          this.handleUploadImg(base64);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}
