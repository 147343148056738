import React, { Component } from 'react';
import {
  Row,
  Col,
  Table,
  Checkbox,
  Divider,
  Input,
  Select,
  TimePicker,
  Image,
  message,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { nanoid } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  getOrderOptionMappings,
  getAccountTypes,
  saveVTImage,
  deleteVTImage,
  getGroups,
  getPanelNames,
} from '../../api';
import AdminHeader from '../AdminHeader';
import Spin from '../Spin';
import Selection from '../Selection';
import ImageUploader from '../ImageUploader';
import Button from '../Button';

const { Option } = Select;

class ViewTabAddOrEdit extends Component {
  state = {
    spinning: false,
    tab: {},
    orderOptions: [],
    accountTypes: [],
  };

  timeRangeTypes = [
    { value: '0', label: 'Available' },
    { value: '1', label: 'Not Available' },
  ];

  tabContentTypes = [
    { key: 1, value: '0', label: 'Group' },
    { key: 3, value: 'P', label: 'Panel' },
    { key: 5, value: '5', label: 'Cycle' },
  ];

  displayTypes = [
    {
      value: 0,
      label: 'No Sorting',
    },
    {
      value: 1,
      label: 'Sort Alphabetically',
    },
    {
      value: 2,
      label: 'Sort by Tags',
    },
    {
      value: 4,
      label: 'Sort by Tags-alphabetically',
    },
  ];

  tabContentColumns = [
    {
      title: () => (
        <Row justify="center">
          <FontAwesomeIcon
            icon={faCirclePlus}
            size="xl"
            color="var(--adminPrimary)"
            onClick={this.handleAddTabContentRow}
          />
        </Row>
      ),
      render: (_, record) => (
        <Row justify="center">
          <FontAwesomeIcon
            icon={faCircleMinus}
            size="xl"
            color="var(--adminSecondary)"
            onClick={() => this.handleDeleteTabContentRow(record)}
          />
        </Row>
      ),
    },
    {
      title: 'Type',
      dataIndex: '',
      render: (_, record) => (
        <Row justify="start">
          <Select
            style={{ width: 120 }}
            value={record.IsItem}
            onChange={(value, option) => this.handleTabContentTypeChange(value, option, record)}
          >
            {this.tabContentTypes.map(j => (
              <Option key={j.value} value={j.value}>
                {j.label}
              </Option>
            ))}
          </Select>
        </Row>
      ),
    },
    {
      title: 'Description',
      dataIndex: '',
      render: (_, record) => (
        <Row justify="start">
          <Select
            style={{ width: 300 }}
            value={record.ItemCode}
            showSearch={true}
            onChange={(value, option) =>
              this.handleTabContentTypeDescriptionChange(value, option, record)
            }
            onFocus={() => this.handleTabContentTypeChange(record.IsItem, _, record)}
          >
            {record && record.data
              ? record.data.map((j, index) => {
                  if (typeof j === 'object') {
                    return (
                      <Option key={j.Description} value={j.Description}>
                        {j.Description}
                      </Option>
                    );
                  } else if (typeof j === 'string') {
                    return (
                      <Option key={index} value={j}>
                        {j}
                      </Option>
                    );
                  } else return null;
                })
              : null}
          </Select>
        </Row>
      ),
    },
  ];

  weekDayOptions = [
    {
      label: 'Monday',
      value: '1',
    },
    {
      label: 'Tuesday',
      value: '2',
    },
    {
      label: 'Wednesday',
      value: '3',
    },
    {
      label: 'Thursday',
      value: '4',
    },
    {
      label: 'Friday',
      value: '5',
    },
    {
      label: 'Saturday',
      value: '6',
    },
    {
      label: 'Sunday',
      value: '0',
    },
  ];

  timeRangeTableColumns = [
    {
      title: () => (
        <Row justify="center">
          <FontAwesomeIcon
            icon={faCirclePlus}
            size="xl"
            color="var(--adminPrimary)"
            onClick={this.handleAddTimeRangeRow}
          />
        </Row>
      ),
      render: (_, record) => (
        <Row justify="center">
          <FontAwesomeIcon
            icon={faCircleMinus}
            size="xl"
            color="var(--adminSecondary)"
            onClick={() => this.handleDeleteTimeRangeRow(record)}
          />
        </Row>
      ),
    },
    {
      title: 'Type',
      render: (_, record) => (
        <Row justify="start">
          <Select
            style={{ width: 120 }}
            value={record.Type}
            onChange={(value, option) => this.handleTimeRangeTypeChange(value, option, record)}
          >
            {this.timeRangeTypes.map(j => (
              <Option key={j.value} value={j.value}>
                {j.label}
              </Option>
            ))}
          </Select>
        </Row>
      ),
    },
    {
      title: 'Start Time',
      render: (_, record) => (
        <Row style={{ minWidth: 80 }}>
          <TimePicker
            showNow={false}
            format="HH:mm"
            value={record.From ? moment(record.From, 'HH:mm') : null}
            onChange={(time, timeString) => {
              this.handleStartEndTargetTimeChange(time, timeString, record, 'From');
            }}
          />
        </Row>
      ),
    },
    {
      title: 'End Time',
      render: (_, record) => (
        <Row style={{ minWidth: 80 }}>
          <TimePicker
            showNow={false}
            format="HH:mm"
            value={record.To ? moment(record.To, 'HH:mm') : null}
            onChange={(time, timeString) => {
              this.handleStartEndTargetTimeChange(time, timeString, record, 'To');
            }}
          />
        </Row>
      ),
    },
    {
      title: 'Available Days',
      render: (_, record) => (
        <Row>
          <Checkbox.Group
            options={this.weekDayOptions}
            value={record.Code.split(',')}
            onChange={checkedValue => {
              this.handleTimeRangeAvailableDaysChange(checkedValue, record);
            }}
          />
        </Row>
      ),
    },
  ];

  init = () => {
    const { addOrEditTab } = this.props;
    if (addOrEditTab === 'Add') {
      const obj = {};
      obj.Id = 0;
      obj.ButtonText = '';
      obj.ImageName = '';
      obj.ButtonCode = '';
      obj.Buttontype = 2;
      obj.Css = {};
      obj.ImageGuid = '';
      obj.PriceCss = {};
      obj.CustomizedButtonInfoList = [];
      obj.ButtonInstruction = '';
      obj.InstructionCss = {};
      obj.AppliedToList = [];
      obj.TimeRangeList = [];
      obj.DisplayType = 0;
      obj.AvailableHouseTypes = [];
      this.setState({ tab: obj });
    }
    if (addOrEditTab === 'Edit') {
      const { tab } = this.props;
      if (tab.CustomizedButtonInfoList) {
        tab.CustomizedButtonInfoList = tab.CustomizedButtonInfoList.map(i => {
          i.key = nanoid();
          return i;
        });
      }
      if (tab.TimeRangeList) {
        tab.TimeRangeList = tab.TimeRangeList.map(i => {
          i.key = nanoid();
          return i;
        });
      }
      this.setState({ tab });
    }
  };

  getSettingOptions = async () => {
    this.setState({ spinning: true });
    await Promise.all([getOrderOptionMappings(), getAccountTypes()])
      .then(results => {
        results.forEach(res => {
          if (!res || (res && res.result !== 'OK')) throw new Error();
        });
        this.setState({
          spinning: false,
          orderOptions: results[0].data,
          accountTypes: results[1].data,
        });
      })
      .catch(error => {
        this.setState({ spinning: false });
        message.error('Error obtaining setting options!');
      });
  };

  handleSaveBtn = () => {
    const { tab } = this.state;
    this.props.onTabAddOrEditSave(tab);
  };

  handleTabDrawerCancelBtn = () => {
    this.init();
    this.props.onTabDrawerCancel();
  };

  handleInputChange = ({ target: { value } }, type) => {
    const { tab } = this.state;
    tab[type] = value;
    this.setState({ tab });
  };

  handleSelectChange = (v, _, type) => {
    const { tab } = this.state;
    tab[type] = v;
    this.setState({ tab });
  };

  handleOrderOptionsChange = arr => {
    const { tab } = this.state;
    tab.AppliedToList = arr;
    this.setState({ tab });
  };

  handleAccTypesChange = arr => {
    const { tab } = this.state;
    tab.AvailableHouseTypes = arr;
    this.setState({ tab });
  };

  handleAddTabContentRow = () => {
    const { tab } = this.state;
    const { CustomizedButtonInfoList } = tab;
    const obj = {};
    obj.key = nanoid();
    obj.GpCode = '';
    obj.IsItem = '';
    obj.ItemCode = '';
    obj.MinQty = 0;
    obj.MaxQty = 0;
    obj.ImageName = '';
    obj.ItemList = [];
    obj.Message = '';
    obj.Instruction = '';
    tab.CustomizedButtonInfoList = [...CustomizedButtonInfoList, obj];
    this.setState({ tab });
  };

  handleDeleteTabContentRow = row => {
    const { tab } = this.state;
    tab.CustomizedButtonInfoList = [...tab.CustomizedButtonInfoList.filter(i => i.key !== row.key)];
    this.setState({ tab });
  };

  getPanelOrGroup = async type => {
    try {
      this.setState({ spinning: true });
      let re;
      if (type === '0') {
        re = await getGroups('1');
      } else if (type === 'P') {
        re = await getPanelNames('1');
      } else if (type === '5') {
        re = await getPanelNames('2');
      }

      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false });
      return re.data;
    } catch (error) {
      message.error('Error obtaining panel or group!', 3);
    }
  };

  handleTabContentTypeChange = async (v, o, row) => {
    const data = await this.getPanelOrGroup(v);
    const { tab } = this.state;
    const newList = JSON.parse(JSON.stringify(tab.CustomizedButtonInfoList));
    const item = newList.find(i => i.key === row.key);
    if (!item) return;
    item.IsItem = v;
    item.ItemCode =
      v === row.IsItem
        ? row.ItemCode
        : typeof data[0] === 'string'
        ? data[0]
        : typeof data[0] === 'object'
        ? data[0].Description
        : '';
    item.data = data;
    tab.CustomizedButtonInfoList = newList;
    this.setState({ tab });
  };

  handleTabContentTypeDescriptionChange = (v, o, row) => {
    const { tab } = this.state;
    const newList = JSON.parse(JSON.stringify(tab.CustomizedButtonInfoList));
    const item = newList.find(i => i.key === row.key);
    if (!item) return;
    item.ItemCode = v;
    tab.CustomizedButtonInfoList = newList;
    this.setState({ tab });
  };

  handleAddTimeRangeRow = () => {
    const { tab } = this.state;
    const { TimeRangeList } = tab;
    const obj = {};
    obj.key = nanoid();
    obj.From = '';
    obj.To = '';
    obj.Code = '';
    obj.Type = '';
    obj.Description = '';
    tab.TimeRangeList = [...TimeRangeList, obj];
    this.setState({ tab });
  };

  handleDeleteTimeRangeRow = row => {
    const { tab } = this.state;
    tab.TimeRangeList = [...tab.TimeRangeList.filter(i => i.key !== row.key)];
    this.setState({ tab });
  };

  handleTimeRangeTypeChange = (v, _, row) => {
    const { tab } = this.state;
    const newList = JSON.parse(JSON.stringify(tab.TimeRangeList));
    const item = newList.find(i => i.key === row.key);
    if (!item) return;
    item.Type = v;
    tab.TimeRangeList = newList;
    this.setState({ tab });
  };

  handleStartEndTargetTimeChange = (_, timeString, row, type) => {
    const { tab } = this.state;
    const newList = JSON.parse(JSON.stringify(tab.TimeRangeList));
    const item = newList.find(i => i.key === row.key);
    item[type] = timeString;
    tab.TimeRangeList = newList;
    this.setState({ tab });
  };

  handleTimeRangeAvailableDaysChange = (checkedValue, row) => {
    const { tab } = this.state;
    const newList = JSON.parse(JSON.stringify(tab.TimeRangeList));
    const item = newList.find(i => i.key === row.key);
    const checkedStr = checkedValue.join(',');
    item.Code = checkedStr;
    tab.TimeRangeList = newList;
    this.setState({ tab });
  };

  uploadImg = async base64 => {
    //type: 16
    const { tab } = this.state;
    const obj = {};
    obj.Type = '16';
    obj.ImageString = base64;
    try {
      this.setState({ spinning: true });
      const re = await saveVTImage(obj);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      tab.ImageName = re.imageName;
      this.setState({ tab, spinning: false });
      message.success('Image successfully uploaded!', 3);
    } catch (error) {
      message.error('Error uploading image!', 3);
    }
  };

  handleUploadImg = async base64 => {
    const type = '16';
    const { tab } = this.state;
    const { ImageName } = tab;
    if (ImageName) {
      //Delete existing then upload
      const exObj = {};
      exObj.Code = ImageName;
      exObj.Type = type;

      try {
        this.setState({ spinning: true });
        const re = await deleteVTImage(exObj);
        if (!re || (re && re.result !== 'OK')) {
          this.setState({ spinning: false });
          throw new Error();
        }
        this.uploadImg(base64);
      } catch (error) {
        message.error('Error uploading image!', 3);
      }
    } else {
      this.uploadImg(base64);
    }
  };

  handleCurrImageRemove = () => {
    const { tab } = this.state;
    tab.ImageName = '';
    this.setState({ tab });
  };

  componentDidMount() {
    this.init();
    this.getSettingOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    const { tab } = this.props;
    if (tab !== prevProps.tab) {
      this.init();
    }
  }

  render() {
    const { spinning, tab, orderOptions, accountTypes } = this.state;
    const { ImageName } = tab;
    const { addOrEditTab, imageDir } = this.props;
    const checkGroupOrderOptions = orderOptions.map(i => {
      const obj = {};
      obj.label = i.DisplayName;
      obj.value = i.Code;
      return obj;
    });
    const checkGroupAccTypes = accountTypes.map(i => {
      const obj = {};
      obj.label = i.Description;
      obj.value = i.Code;
      return obj;
    });

    const dispayTypesOptions = this.displayTypes.map(i => (
      <Option key={i.value} value={i.value}>
        {i.label}
      </Option>
    ));

    let src = '';
    const imgFallback = `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      //dev mode
      if (ImageName) {
        src = `http://localhost:4000/${imageDir}/${ImageName}`;
      } else {
        src = imgFallback;
      }
    } else {
      //production mode
      if (ImageName) {
        src = `${process.env.PUBLIC_URL}/${imageDir}/${ImageName}`;
      } else {
        src = imgFallback;
      }
    }

    return (
      <Spin spinning={spinning}>
        <Row style={{ paddingBottom: 30 }}>
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title={addOrEditTab === 'Edit' ? 'Edit View Tab' : 'Add View Tab'}
              showLeftBtn={true}
              showRightBtn={true}
              leftBtnText="Cancel"
              rightBtnText="Save"
              onLeftBtnClick={this.handleTabDrawerCancelBtn}
              onRightBtnClick={this.handleSaveBtn}
            />
            {/* Tab Name Row */}
            <Divider style={{ borderColor: 'transparent' }} />
            <Row align="middle" gutter={8}>
              <Col>
                <h3>Tab Name</h3>
              </Col>
              <Col>
                <Input
                  size="large"
                  spellCheck={false}
                  value={tab.ButtonText}
                  onChange={e => this.handleInputChange(e, 'ButtonText')}
                  style={{ width: 250 }}
                />
              </Col>
            </Row>
            {/* Do Not Show This Tab For Row */}
            <Divider style={{ borderColor: 'transparent' }} />
            <Row>
              <Col span={24}>
                <Row>
                  <h3>Do Not Show This Tab For: </h3>
                </Row>
                <Row>
                  <Checkbox.Group
                    options={checkGroupOrderOptions}
                    value={tab && tab.AppliedToList ? tab.AppliedToList : null}
                    onChange={this.handleOrderOptionsChange}
                  />
                </Row>
              </Col>
            </Row>
            {/* Only Available For Following House Types Row */}
            <Divider style={{ borderColor: 'transparent' }} />
            <Row>
              <Col span={24}>
                <Row>
                  <h3>Only Available For Following House Types: </h3>
                </Row>
                <Row>
                  <Checkbox.Group
                    options={checkGroupAccTypes}
                    value={tab && tab.AvailableHouseTypes ? tab.AvailableHouseTypes : null}
                    onChange={this.handleAccTypesChange}
                  />
                </Row>
              </Col>
            </Row>
            {/* Display Type Row */}
            <Divider style={{ borderColor: 'transparent' }} />
            <Row>
              <Col span={24}>
                <Row>
                  <h3>Display Type </h3>
                </Row>
                <Row style={{ width: 250 }}>
                  <Selection
                    width={250}
                    size="large"
                    data={dispayTypesOptions}
                    value={tab.DisplayType}
                    onChange={(v, o) => this.handleSelectChange(v, o, 'DisplayType')}
                  />
                </Row>
              </Col>
            </Row>
            {/* Tab Content Row */}
            <Divider style={{ borderColor: 'transparent' }} />
            <Row>
              <Col span={24}>
                <Row>
                  <h3>Tab Content</h3>
                </Row>
                <Row>
                  <Col span={24}>
                    <Table
                      rowKey="key"
                      columns={this.tabContentColumns}
                      dataSource={tab.CustomizedButtonInfoList}
                      pagination={false}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Time Range Settings Row */}
            <Divider style={{ borderColor: 'transparent' }} />
            <Row>
              <Col span={24}>
                <Row>
                  <h3>Time Range Settings (Tab Availability)</h3>
                </Row>
                <Row>
                  <Col span={24}>
                    <Table
                      rowKey="key"
                      columns={this.timeRangeTableColumns}
                      dataSource={tab.TimeRangeList}
                      pagination={false}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Image upload Row */}
            <Divider style={{ borderColor: 'transparent' }} />
            <Row justify="center">
              <Col span={20}>
                <Row>
                  <Col span={12}>
                    <Row>
                      <strong>Current Image</strong>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Change Image</strong>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Row
                      style={{
                        width: 300,
                        height: 200,
                        border: '1px solid #e8e8e8',
                        position: 'relative',
                      }}
                      justify="center"
                    >
                      <Image width={300} height={200} src={src} preview={src !== imgFallback} />
                      {tab.ImageName ? (
                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                          <Button
                            width={70}
                            height={35}
                            style={{ backgroundColor: 'var(--adminSecondary)' }}
                            textStyle={{ color: '#fff' }}
                            borderRadius={5}
                            onClick={this.handleCurrImageRemove}
                          >
                            Remove
                          </Button>
                        </div>
                      ) : null}
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row justify="start">
                      <ImageUploader
                        onImgUploadClick={base64 => {
                          this.handleUploadImg(base64);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default ViewTabAddOrEdit;
