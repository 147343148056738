import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'antd';
import './index.css';

export class VTModal extends Component {
  render() {
    const { children, clientStyle, onCloseClick, showCloseBtn } = this.props;
    return (
      <Modal
        {...this.props}
        // onCancel={e => this.props.onCancel(e)}
        // onOK={e => this.props.onOK(e)}
        maskStyle={{ backgroundColor: clientStyle.backdrop }}
        bodyStyle={{ borderRadius: 20, backgroundColor: clientStyle.bgColor }}
      >
        {showCloseBtn ? (
          <div className="vt-modal-close-btn-wrap">
            <div
              className="vt-modal-close-btn-icon"
              onClick={onCloseClick}
              style={{ backgroundColor: clientStyle.primary }}
            >
              <FontAwesomeIcon icon={faX} size="2x" style={{ color: clientStyle.colorTwo }} />
            </div>
          </div>
        ) : null}

        {children}
      </Modal>
    );
  }
}

export default connect(state => ({ clientStyle: state.clientStyle }), {})(VTModal);
