import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { deleteScannedOrderItem } from '../../redux/actions';
import OrderRow from '../OrderRow';
import Button from '../Button';
import VTTextTransition from '../TextTransition';
import './index.css';

class ScanLeftList extends Component {
  ref = React.createRef();

  getSnapshotBeforeUpdate() {
    return this.ref.current.scrollHeight;
  }

  handleRemoveOrderRow = key => {
    this.props.deleteScannedOrderItem(key);
  };

  componentDidUpdate(preProps, preState, height) {
    this.ref.current.scrollTop += this.ref.current.scrollHeight - height;
  }

  render() {
    const {
      logoImageDir,
      logoImageName,
      rowEditable,
      rowEditPlus,
      order,
      kioskSetting,
      clientStyle,
    } = this.props;
    const {
      appSetting: { ScanItemMode },
    } = kioskSetting;

    let src = '';
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      //dev mode
      if (logoImageName && logoImageDir) {
        src = `http://localhost:4000/${logoImageDir}/${logoImageName}`;
      } else {
        src = '';
      }
    } else {
      //production mode
      if (logoImageName && logoImageDir) {
        src = `${process.env.PUBLIC_URL}/${logoImageDir}/${logoImageName}`;
      } else {
        src = '';
      }
    }

    const totalAmt = order
      .map(i => i.price * i.qty)
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
    const totalQty = order.map(i => i.qty).reduce((a, b) => a + b, 0);

    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };

    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };

    return (
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
        }}
      >
        <Row
          className="scan-left-list-container"
          justify="center"
          style={{
            boxShadow: '1px 2px 3px rgba(0, 0, 0, 0.5)',
            marginBottom: 10,
            backgroundColor:
              clientStyle.storeNumber === '5960' || clientStyle.storeNumber === '3932'
                ? clientStyle.secondary
                : '#fff',
          }}
        >
          <Col span={24}>
            {/* Logo /Title Row */}
            <Row align="middle" style={{ padding: '3px 15px' }}>
              <Col span={8}>
                <div className="scan-left-list-title-logo-wrapper">
                  <img src={src} alt={''} height={80} />
                </div>
              </Col>
              <Col span={16}>
                {window.innerWidth < 1200 && (
                  <Row justify="end">
                    <Button
                      width={320}
                      height={70}
                      style={{ ...rowStyle }}
                      textStyle={{ fontSize: 18 }}
                      borderRadius={5}
                      icon="check"
                      iconSize="2x"
                      onClick={() => this.props.onCompleteScanningBtn()}
                    >
                      {ScanItemMode === 1 ? 'Proceed to Checkout' : 'Add Scanned to Cart'}
                    </Button>
                  </Row>
                )}
              </Col>
            </Row>
            {/* List Row */}
            <Row
              style={{ padding: '12px 5px', maxHeight: window.innerHeight - 220, marginTop: 10 }}
              className="scan-left-list-rows"
              ref={this.ref}
            >
              <Col span={24}>
                <TransitionGroup enter={true} exit={true} appear={true}>
                  {order && order.length > 0
                    ? order.map(i => (
                        <CSSTransition key={i.key} timeout={500} classNames="order-list-item">
                          <OrderRow
                            key={i.key}
                            width="100%"
                            height={70}
                            showQty={false}
                            item={i}
                            editable={rowEditable}
                            editablePlus={rowEditPlus}
                            style={{ fontSize: 22 }}
                            onDelete={() => this.handleRemoveOrderRow(i.key)}
                            // onOrderItemChange={data => {
                            //   this.props.updateOrder(data);
                            // }}
                          />
                        </CSSTransition>
                      ))
                    : null}
                </TransitionGroup>
              </Col>
            </Row>
            {/* Tally Row */}
            <div
              className="scan-left-list-footer"
              style={{
                borderTop: `1px ${clientStyle.primary} dashed`,
                height: 100,
              }}
            >
              <Row justify="space-between" style={{ padding: 15, ...titleStyle }} align="middle">
                {/* Footer punched circles */}
                <div
                  className="scan-left-list-footer-circle-left"
                  style={{ backgroundColor: clientStyle.bgColor }}
                ></div>
                <div
                  className="scan-left-list-footer-circle-right"
                  style={{ backgroundColor: clientStyle.bgColor }}
                ></div>
                {/* Footer Content */}
                <Col>
                  <Row>
                    <span style={{ fontSize: 36, fontWeight: 'bold', marginRight: 10 }}>
                      {totalQty}
                    </span>
                    <span style={{ fontSize: 36, fontWeight: 'bold' }}>
                      {totalQty <= 1 ? 'Item' : 'Items'}
                    </span>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <span style={{ fontSize: 36, fontWeight: 'bold', marginRight: 30 }}>
                      Total:
                    </span>
                    <VTTextTransition
                      text={totalAmt ? `$${totalAmt}` : ''}
                      style={{ fontSize: 36, fontWeight: 'bold' }}
                    />
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(
  state => ({
    order: state.scannedOrder,
    clientStyle: state.clientStyle,
    kioskSetting: state.kioskSetting,
  }),
  {
    deleteScannedOrderItem,
  },
)(ScanLeftList);
