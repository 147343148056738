import React, { Component } from 'react';
import { Row, Col, Checkbox, Input, Select, message, Divider, TimePicker, Image } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import moment from 'moment';
import { nanoid } from 'nanoid';
import {
  getProfitCentres,
  getAccountTypes,
  getAvailablePayments,
  saveOrderOptionMapping,
  saveVTImage,
  deleteVTImage,
  getViewPages,
  getItemList,
} from '../../../api';
import { timeRangeTimeInterval } from '../../../config/options';
import EditableTable from '../../../components/EditableTable';
import ImageUploader from '../../../components/ImageUploader';
import AdminHeader from '../../../components/AdminHeader';
import Selection from '../../../components/Selection';
import Spin from '../../../components/Spin';
import Button from '../../../components/Button';
import './index.css';

const { Option } = Select;

export default class AdminOrderOption extends Component {
  state = {
    spinning: false,
    error: '',
    itemList: [],
    profitCenters: [],
    accountTypes: [],
    paymentTypes: [],
    viewPages: [],
    imageName: '',
    mappingType: '',
    profitCenter: '',
    displayName: '',
    surcharge: '',
    startupPage: '',
    receiptEmails: '',
    futureOrderDays: '',
    futureOrderBufferDays: '',
    inactive: '',
    enableTips: '',
    disableOrderASAP: '',
    notAvailableFor: '',
    addressInfo: '',
    accountType: [],
    availablePayments: [],
    timeRangeType: '',
    timeRangeList: [],
    dayPartList: [],
  };

  mappingType = [
    {
      label: 'Profit Center',
      value: 1,
    },
    {
      label: 'Order Table Number',
      value: 2,
    },
    {
      label: 'Order Instruction',
      value: 3,
    },
    {
      label: 'Auto Charge For Kiosk',
      value: 4,
    },
  ];

  addressInfo = [
    { label: ' ', value: 0 },
    { label: 'Confirm Address Info Before Ordering', value: 1 },
    { label: 'Display Address Info', value: 2 },
  ];

  notAvailableFor = [
    { label: ' ', value: '0' },
    { label: 'Online Order', value: '1' },
    { label: 'Kiosk', value: '2' },
  ];

  timeRangeType = [
    { label: ' ', value: 0 },
    { label: 'Time Range', value: 1 },
    { label: 'Day Part', value: 2 },
  ];

  weekDayOptions = [
    {
      label: 'Monday',
      value: '1',
    },
    {
      label: 'Tuesday',
      value: '2',
    },
    {
      label: 'Wednesday',
      value: '3',
    },
    {
      label: 'Thursday',
      value: '4',
    },
    {
      label: 'Friday',
      value: '5',
    },
    {
      label: 'Saturday',
      value: '6',
    },
    {
      label: 'Sunday',
      value: '0',
    },
  ];

  init = async () => {
    this.setState({ spinning: true });
    const { addOrEdit, orderOption } = this.props.location.state;
    let profitCenters = [],
      accountTypes = [],
      paymentTypes = [],
      viewPages = [];
    await Promise.all([
      getProfitCentres(),
      getAccountTypes(),
      getAvailablePayments(),
      getViewPages(),
    ])
      .then(values => {
        values.forEach(re => {
          if (!re || (re && re.result !== 'OK')) throw new Error();
        });
        profitCenters = values[0].data;
        accountTypes = values[1].data;
        paymentTypes = values[2].data;
        viewPages = values[3].data;
        this.setState({ spinning: false });
      })
      .catch(err => {
        this.setState({ spinning: false });
        message.error('Error obtaining setting options!', 3);
      });

    if (addOrEdit === 'Edit' && orderOption) {
      const {
        DisplayName,
        ImageName,
        EnableTips,
        Inactive,
        MappingCode,
        SurchargeType,
        OrderOptionDetail,
        Type,
      } = orderOption;
      const {
        AvailableHouseTypes,
        AvailablePaymentTypes,
        ConfirmAddress,
        EndMinutes,
        NotAvailableFor,
        OrderTimeRequired,
        ReceiptEmails,
        StartDays,
        StartupPage,
        TimeRangeType,
        TimeRangeList,
      } = OrderOptionDetail;

      let timeRangeList = [],
        dayPartList = [];
      if (TimeRangeType === 1) {
        TimeRangeList.forEach(i => {
          i.key = nanoid();
          timeRangeList.push(i);
        });
        dayPartList = [
          {
            key: nanoid(),
            AppliedTo: '',
            BlockInterval: '',
            Description: '',
            FromTime: '',
            MaxQtyAccepted: '',
            MaxQtyPerRes: '',
            MaxResAccepted: '',
            MinQtyPerRes: '',
            TargetTime: '',
            ToTime: '',
          },
        ];
      }
      if (TimeRangeType === 2) {
        TimeRangeList.forEach(i => {
          i.key = nanoid();
          dayPartList.push(i);
        });
        timeRangeList = [
          {
            key: nanoid(),
            AppliedTo: '',
            BlockInterval: '',
            Description: '',
            FromTime: '',
            MaxQtyAccepted: '',
            MaxQtyPerRes: '',
            MaxResAccepted: '',
            MinQtyPerRes: '',
            TargetTime: '',
            ToTime: '',
          },
        ];
      }

      if (TimeRangeType === 0) {
        dayPartList = [];
        timeRangeList = [];
      }

      if (Type === 4) {
        await this.getAllItems();
      }

      this.setState({
        profitCenters,
        accountTypes,
        paymentTypes,
        viewPages,
        imageName: ImageName,
        mappingType: Type,
        profitCenter: MappingCode,
        displayName: DisplayName,
        surcharge: SurchargeType,
        startupPage: StartupPage,
        receiptEmails: ReceiptEmails,
        futureOrderDays: StartDays,
        futureOrderBufferDays: EndMinutes,
        inactive: Inactive,
        enableTips: EnableTips,
        disableOrderASAP: OrderTimeRequired,
        notAvailableFor: NotAvailableFor,
        addressInfo: ConfirmAddress,
        accountType: AvailableHouseTypes,
        availablePayments: AvailablePaymentTypes,
        timeRangeType: TimeRangeType,
        timeRangeList,
        dayPartList,
      });
    }

    if (addOrEdit === 'Add' && !orderOption) {
      let dayPartList = [
        {
          key: nanoid(),
          AppliedTo: '',
          BlockInterval: '',
          Description: '',
          FromTime: '',
          MaxQtyAccepted: '',
          MaxQtyPerRes: '',
          MaxResAccepted: '',
          MinQtyPerRes: '',
          TargetTime: '',
          ToTime: '',
        },
      ];
      let timeRangeList = [
        {
          key: nanoid(),
          AppliedTo: '',
          BlockInterval: '',
          Description: '',
          FromTime: '',
          MaxQtyAccepted: '',
          MaxQtyPerRes: '',
          MaxResAccepted: '',
          MinQtyPerRes: '',
          TargetTime: '',
          ToTime: '',
        },
      ];

      this.setState({
        profitCenters,
        accountTypes,
        paymentTypes,
        viewPages,
        inactive: 0,
        enableTips: 0,
        disableOrderASAP: 0,
        timeRangeType: 0,
        timeRangeList,
        dayPartList,
      });
    }

    this.setState({ spinning: false });
  };

  handleBackBtn = () => {
    this.props.history.replace('/admin/orderoptionmappings');
  };

  handleSaveBtn = async () => {
    this.setState({ spinning: true });
    const { addOrEdit, user } = this.props.location.state;
    const { orderOption } = this.props.location.state || null;
    const { newItemIndex } = this.props.location.state || null;
    const {
      mappingType,
      profitCenter,
      displayName,
      imageName,
      surcharge,
      startupPage,
      receiptEmails,
      futureOrderDays,
      futureOrderBufferDays,
      inactive,
      enableTips,
      disableOrderASAP,
      notAvailableFor,
      addressInfo,
      accountType,
      availablePayments,
      timeRangeType,
      timeRangeList,
      dayPartList,
    } = this.state;

    if (!mappingType) {
      this.setState({ error: 'Mapping Type cannot be empty' });
      return;
    }
    if (mappingType === 1 && !profitCenter) {
      this.setState({ error: 'Please select a profit center!' });
      return;
    }
    if (!displayName) {
      this.setState({ error: 'Display Name cannot be empty!' });
      return;
    }

    const obj = {};
    if (addOrEdit === 'Edit') {
      obj.Code = orderOption.Code;
      obj.DisplayName = displayName;
      obj.EnableTips = mappingType === 4 ? '' : enableTips;
      obj.Error = orderOption.Error;
      obj.ImageName = mappingType === 4 ? '' : imageName;
      obj.Inactive = inactive;
      obj.Index = orderOption.Index;
      obj.LastUpdatedTime = orderOption.LastUpdatedTime;
      obj.MappingCode = profitCenter;
      obj.OrderOptionDetail = {
        AvailableHouseLoctaions: orderOption.OrderOptionDetail.AvailableHouseLoctaions,
        AvailableHouseTypes: accountType,
        AvailablePaymentTypes: mappingType === 4 ? [] : availablePayments,
        ConfirmAddress: mappingType === 4 ? '' : addressInfo,
        EndMinutes: mappingType === 4 ? '' : futureOrderBufferDays,
        FutureOrderDesc: orderOption.OrderOptionDetail.FutureOrderDesc,
        NotAvailableFor: mappingType === 4 ? '1' : notAvailableFor,
        OrderTimeRequired: mappingType === 4 ? '' : disableOrderASAP,
        ReceiptEmails: receiptEmails,
        StartDays: mappingType === 4 ? '' : futureOrderDays,
        StartupPage: mappingType === 4 ? '' : startupPage,
        TimeRangeList:
          timeRangeType === 1 ? [...timeRangeList] : timeRangeType === 2 ? [...dayPartList] : [],
        TimeRangeType: mappingType === 4 ? '2' : timeRangeType,
      };
      obj.StoreNumber = orderOption.StoreNumber;
      obj.SurchargeType = surcharge;
      obj.Type = mappingType;
    }
    if (addOrEdit === 'Add') {
      obj.Code = '';
      obj.DisplayName = displayName;
      obj.EnableTips = mappingType === 4 ? '' : enableTips;
      obj.Error = false;
      obj.ImageName = mappingType === 4 ? '' : imageName;
      obj.Inactive = inactive;
      obj.Index = newItemIndex;
      obj.LastUpdatedTime = null;
      obj.MappingCode = profitCenter;
      obj.OrderOptionDetail = {
        AvailableHouseLoctaions: [],
        AvailableHouseTypes: accountType,
        AvailablePaymentTypes: mappingType === 4 ? [] : availablePayments,
        ConfirmAddress: mappingType === 4 ? '' : addressInfo,
        EndMinutes: mappingType === 4 ? '' : futureOrderBufferDays,
        FutureOrderDesc: '',
        NotAvailableFor: mappingType === 4 ? '1' : notAvailableFor,
        OrderTimeRequired: mappingType === 4 ? '' : disableOrderASAP,
        ReceiptEmails: receiptEmails,
        StartDays: mappingType === 4 ? '' : futureOrderDays,
        StartupPage: mappingType === 4 ? '' : startupPage,
        TimeRangeList:
          timeRangeType === 1 ? [...timeRangeList] : timeRangeType === 2 ? [...dayPartList] : [],
        TimeRangeType: mappingType === 4 ? '2' : timeRangeType,
      };
      obj.StoreNumber = user.StoreNumber || '';
      obj.SurchargeType = surcharge;
      obj.Type = mappingType;
    }

    try {
      const re = await saveOrderOptionMapping(obj);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false });
      message.success('Order Option successfully saved!');
      this.props.history.replace('/admin/orderoptionmappings');
    } catch (error) {
      message.error('Error saving order option!', 3);
    }
  };

  getAllItems = async () => {
    try {
      this.setState({ spinning: true });
      const re = await getItemList();
      if (!re || (re && typeof re.result === 'string' && re.result.toUpperCase() !== 'OK'))
        throw new Error(re.result);
      this.setState({ itemList: re.data, spinning: false });
    } catch (error) {
      // message.error('Error obtaining items!');
      message.error(error.message);
    }
  };

  handleSelectChange = async (v, o, dataType) => {
    const { error } = this.state;
    error && this.setState({ error: '' });
    if (dataType === 'mappingType' && v === 4) {
      await this.getAllItems();
      this.setState({ notAvailableFor: '1', timeRangeType: 2 });
    }
    if (dataType === 'mappingType' && v !== 4) {
      let dayPartList = [
        {
          key: nanoid(),
          AppliedTo: '',
          BlockInterval: '',
          Description: '',
          FromTime: '',
          MaxQtyAccepted: '',
          MaxQtyPerRes: '',
          MaxResAccepted: '',
          MinQtyPerRes: '',
          TargetTime: '',
          ToTime: '',
        },
      ];
      let timeRangeList = [
        {
          key: nanoid(),
          AppliedTo: '',
          BlockInterval: '',
          Description: '',
          FromTime: '',
          MaxQtyAccepted: '',
          MaxQtyPerRes: '',
          MaxResAccepted: '',
          MinQtyPerRes: '',
          TargetTime: '',
          ToTime: '',
        },
      ];
      this.setState({ notAvailableFor: '0', timeRangeType: 0, dayPartList, timeRangeList });
    }
    this.setState({ [dataType]: v });
  };

  handleInputChange = (dataType, { target: { value } }) => {
    const { error } = this.state;
    error && this.setState({ error: '' });
    this.setState({ [dataType]: value });
  };

  handleSingleCheckbox = ({ target: { checked } }, dataType) => {
    dataType === 'inactive' && this.setState({ inactive: checked ? 0 : 1 });
    dataType !== 'inactive' && this.setState({ [dataType]: checked ? 1 : 0 });
  };

  handleAccountTypeChange = arr => {
    this.setState({ accountType: arr });
  };

  handlePaymentTypeChange = arr => {
    this.setState({ availablePayments: arr });
  };

  handleAddOrderOptionTimeRangeRow = () => {
    const { timeRangeType, timeRangeList, dayPartList } = this.state;

    const newRow = {
      key: nanoid(),
      AppliedTo: '',
      BlockInterval: '',
      Description: '',
      FromTime: '',
      MaxQtyAccepted: '',
      MaxQtyPerRes: '',
      MaxResAccepted: '',
      MinQtyPerRes: '',
      TargetTime: '',
      ToTime: '',
    };

    timeRangeType === 1 &&
      this.setState({
        timeRangeList: [...timeRangeList, newRow],
      });

    timeRangeType === 2 &&
      this.setState({
        dayPartList: [...dayPartList, newRow],
      });
  };

  handleDeleteOrderOptionTimeRangeRow = item => {
    const { timeRangeType, timeRangeList, dayPartList } = this.state;
    timeRangeType === 1 &&
      this.setState({
        timeRangeList: [...timeRangeList.filter(i => i.key !== item.key)],
      });
    timeRangeType === 2 &&
      this.setState({
        dayPartList: [...dayPartList.filter(i => i.key !== item.key)],
      });
  };

  handleStartEndTargetTimeChange = (time, timeStr, item, dataType) => {
    const { timeRangeType, timeRangeList, dayPartList } = this.state;
    let arr, obj;
    if (timeRangeType === 1) {
      arr = JSON.parse(JSON.stringify(timeRangeList));
      obj = arr.find(i => i.key === item.key);
      obj[dataType] = timeStr;
      this.setState({ timeRangeList: arr });
    }
    if (timeRangeType === 2) {
      arr = JSON.parse(JSON.stringify(dayPartList));
      obj = arr.find(i => i.key === item.key);
      obj[dataType] = timeStr;
      this.setState({ dayPartList: arr });
    }
  };

  handleTargetItemChange = (v, o, item, dataType) => {
    const { dayPartList } = this.state;
    const arr = JSON.parse(JSON.stringify(dayPartList));
    const obj = arr.find(i => i.key === item.key);
    obj[dataType] = v;
    this.setState({ dayPartList: arr });
  };

  handleTimeRangeTimeIntervalChange = (v, o, item) => {
    const { timeRangeType, timeRangeList, dayPartList } = this.state;
    let arr, obj;
    if (timeRangeType === 1) {
      arr = JSON.parse(JSON.stringify(timeRangeList));
      obj = arr.find(i => i.key === item.key);
      obj.BlockInterval = v;
      this.setState({ timeRangeList: arr });
    }
    if (timeRangeType === 2) {
      arr = JSON.parse(JSON.stringify(dayPartList));
      obj = arr.find(i => i.key === item.key);
      obj.BlockInterval = v;
      this.setState({ dayPartList: arr });
    }
  };

  handleTimeRangeAvailableDaysChange = (checkedArr, item) => {
    const { timeRangeType, timeRangeList, dayPartList } = this.state;
    let range;
    if (timeRangeType === 1) {
      range = timeRangeList.find(i => i.key === item.key);
    }
    if (timeRangeType === 2) {
      range = dayPartList.find(i => i.key === item.key);
    }
    range.AppliedTo = checkedArr.join(',');
    this.setState({ timeRangeList, dayPartList });
  };

  getUpdatedTimeRangeListData = updatedData => {
    const { timeRangeType } = this.state;
    timeRangeType === 1 && this.setState({ timeRangeList: updatedData });
    timeRangeType === 2 && this.setState({ dayPartList: updatedData });
  };

  uploadImg = async base64 => {
    //type: 10
    const obj = {};
    obj.Type = 10;
    obj.ImageString = base64;
    try {
      this.setState({ spinning: true });
      const re = await saveVTImage(obj);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ imageName: re.imageName, spinning: false });
      message.success('Image successfully uploaded!', 3);
    } catch (error) {
      message.error('Error uploading image!', 3);
    }
  };

  handleUploadImg = async base64 => {
    const type = 10;
    const { imageName } = this.state;
    if (imageName) {
      //Delete existing then upload
      const exObj = {};
      exObj.Code = imageName;
      exObj.Type = type;

      try {
        this.setState({ spinning: true });
        const re = await deleteVTImage(exObj);
        if (!re || (re && re.result !== 'OK')) {
          this.setState({ spinning: false });
          throw new Error();
        }
        this.uploadImg(base64);
      } catch (error) {
        message.error('Error uploading image!', 3);
      }
    } else {
      this.uploadImg(base64);
    }
  };

  getImage = () => {
    const { imageName } = this.state;
    const { imageDir } = this.props.location.state;
    let src = '';
    const imgFallback = `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      //dev mode
      if (imageName) {
        src = `http://localhost:4000/${imageDir}/${imageName}`;
      } else {
        src = imgFallback;
      }
    } else {
      //production mode
      if (imageName) {
        src = `${process.env.PUBLIC_URL}/${imageDir}/${imageName}`;
      } else {
        src = imgFallback;
      }
    }
    return src;
  };

  handleCurrImageRemove = () => {
    this.setState({ imageName: '' });
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const {
      spinning,
      error,
      itemList,
      profitCenters,
      accountTypes,
      paymentTypes,
      mappingType,
      viewPages,
      imageName,
      profitCenter,
      displayName,
      startupPage,
      receiptEmails,
      futureOrderDays,
      futureOrderBufferDays,
      inactive,
      enableTips,
      disableOrderASAP,
      notAvailableFor,
      addressInfo,
      accountType,
      availablePayments,
      timeRangeType,
      timeRangeList,
      dayPartList,
    } = this.state;

    const { addOrEdit } = this.props.location.state;
    const mappingTypeOptions = this.mappingType.map(i => (
      <Option key={i.value} value={i.value}>
        {i.label}
      </Option>
    ));

    const profitCentersOptions = [<Option key="emptyProfitCenterCode" value=""></Option>].concat(
      profitCenters.map(i => (
        <Option key={i.CentreCode} value={i.CentreCode}>
          {i.Description}
        </Option>
      )),
    );

    const addressInfoOptions = this.addressInfo.map(i => (
      <Option key={i.value} value={i.value}>
        {i.label}
      </Option>
    ));

    const notAvailableForOptions = this.notAvailableFor.map(i => (
      <Option key={i.value} value={i.value}>
        {i.label}
      </Option>
    ));

    const timeRangeTypeOptions = this.timeRangeType.map(i => (
      <Option key={i.value} value={i.value}>
        {i.label}
      </Option>
    ));

    const timeRangeTimeIntervalOptions = timeRangeTimeInterval.map(i => (
      <Option key={i.value} value={i.value}>
        {i.label}
      </Option>
    ));

    const paymentTypeOptions = paymentTypes.map(i => {
      const obj = {};
      obj.label = i.Description;
      obj.value = i.Code;
      return obj;
    });

    const accountTypeOptions = accountTypes.map(i => {
      const obj = {};
      obj.label = i.Description;
      obj.value = i.Code;
      return obj;
    });

    const startupPageOptions = viewPages.map(i => (
      <Option key={i.ViewName} value={i.ViewName}>
        {i.ViewName}
      </Option>
    ));

    const itemsOptions = itemList.map(i => (
      <Option
        key={i.itemCode + i.description}
        value={i.itemCode}
      >{`${i.itemCode}--${i.description}`}</Option>
    ));

    const columns = [
      {
        title: () => (
          <Row justify="center">
            <FontAwesomeIcon
              icon={faCirclePlus}
              size="xl"
              color="var(--adminPrimary)"
              onClick={this.handleAddOrderOptionTimeRangeRow}
            />
          </Row>
        ),
        render: (text, record) => (
          <Row justify="center">
            <FontAwesomeIcon
              icon={faCircleMinus}
              size="xl"
              color="var(--adminSecondary)"
              onClick={() => this.handleDeleteOrderOptionTimeRangeRow(record)}
            />
          </Row>
        ),
      },
      {
        title: timeRangeType === 1 ? 'Time Range Name' : timeRangeType === 2 ? 'Day Part Name' : '',
        dataIndex: 'Description',
        editable: true,
      },
      {
        title: 'Start Time',
        dataIndex: 'FromTime',
        render: (text, record) => (
          <Row style={{ minWidth: 80 }}>
            <TimePicker
              showNow={false}
              format="HH:mm"
              value={record.FromTime ? moment(record.FromTime, 'HH:mm') : null}
              onChange={(time, timeString) => {
                this.handleStartEndTargetTimeChange(time, timeString, record, 'FromTime');
              }}
            />
          </Row>
        ),
      },
      {
        title: 'End Time',
        dataIndex: 'ToTime',
        render: (text, record) => (
          <Row style={{ minWidth: 80 }}>
            <TimePicker
              showNow={false}
              format="HH:mm"
              value={record.ToTime ? moment(record.ToTime, 'HH:mm') : null}
              onChange={(time, timeString) => {
                this.handleStartEndTargetTimeChange(time, timeString, record, 'ToTime');
              }}
            />
          </Row>
        ),
      },
      {
        title: 'Max Order# /\n Time Slot',
        dataIndex: 'MaxQtyAccepted',
        editable: true,
      },
      ...(timeRangeType === 1
        ? [
            {
              title: 'Buffer Time (minutes)',
              dataIndex: 'MaxQtyPerRes',
              editable: true,
            },
            {
              title: 'Time Interval',
              dataIndex: 'BlockInterval',
              render: (text, record) => (
                <Selection
                  width={100}
                  data={timeRangeTimeIntervalOptions}
                  value={record.BlockInterval}
                  onChange={(v, o) => this.handleTimeRangeTimeIntervalChange(v, o, record)}
                />
              ),
            },
          ]
        : timeRangeType === 2 && mappingType === 4
        ? [
            {
              title: 'Target Item Selection',
              dataIndex: 'TargetTime',
              render: (text, record) => (
                <Row>
                  <Selection
                    width={200}
                    showSearch={true}
                    data={itemsOptions}
                    value={record.TargetTime}
                    onChange={(v, o) => this.handleTargetItemChange(v, o, record, 'TargetTime')}
                  />
                </Row>
              ),
            },
          ]
        : timeRangeType === 2 && mappingType !== 4
        ? [
            {
              title: 'Target Time',
              dataIndex: 'TargetTime',
              render: (text, record) => (
                <Row style={{ minWidth: 80 }}>
                  <TimePicker
                    showNow={false}
                    format="HH:mm"
                    value={record.TargetTime ? moment(record.TargetTime, 'HH:mm') : null}
                    onChange={(time, timeString) => {
                      this.handleStartEndTargetTimeChange(time, timeString, record, 'TargetTime');
                    }}
                  />
                </Row>
              ),
            },
          ]
        : []),
      {
        title: 'Available Days',
        dataIndex: '',
        render: (text, record) => (
          <Row>
            <Checkbox.Group
              options={this.weekDayOptions}
              value={record.AppliedTo.split(',')}
              onChange={checkedValue => {
                this.handleTimeRangeAvailableDaysChange(checkedValue, record);
              }}
            />
          </Row>
        ),
      },
    ];

    return (
      <Spin spinning={spinning}>
        <Row className="admin-order-option-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title={
                addOrEdit === 'Edit' ? 'Edit Order Option Mapping' : 'Add New Order Option Mapping'
              }
              showLeftBtn={true}
              leftBtnText="Back"
              showRightBtn={true}
              rightBtnText="Save"
              onLeftBtnClick={this.handleBackBtn}
              onRightBtnClick={this.handleSaveBtn}
            />
            {/* Error Row */}
            <Row
              style={{
                padding: 15,
                height: 30,
                fontWeight: 'bold',
                color: '#8b0000',
                fontSize: 18,
                marginBottom: 20,
              }}
              justify="center"
            >
              <Col>
                <SwitchTransition>
                  <CSSTransition key={error} timeout={500} classNames="login-account-error">
                    <Row>{error}</Row>
                  </CSSTransition>
                </SwitchTransition>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={20}>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <Row>
                      <strong>Mapping Type</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Selection
                        width={250}
                        size="large"
                        data={mappingTypeOptions}
                        value={mappingType}
                        onChange={(v, o) => this.handleSelectChange(v, o, 'mappingType')}
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    {mappingType === 1 || mappingType === 4 ? (
                      <>
                        <Row>
                          <strong>Profit Center</strong>
                        </Row>
                        <Row style={{ width: 250 }}>
                          <Selection
                            width={250}
                            size="large"
                            data={profitCentersOptions}
                            value={profitCenter}
                            onChange={(v, o) => this.handleSelectChange(v, o, 'profitCenter')}
                          />
                        </Row>
                      </>
                    ) : null}
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    <Row>
                      <strong>Display Name</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={displayName}
                        onChange={e => this.handleInputChange('displayName', e)}
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Not Available For</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Selection
                        width={250}
                        size="large"
                        disabled={mappingType === 4}
                        data={notAvailableForOptions}
                        value={notAvailableFor}
                        onChange={(v, o) => this.handleSelectChange(v, o, 'notAvailableFor')}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={12}>
                    {mappingType !== 4 ? (
                      <>
                        {' '}
                        <Row>
                          <strong>Startup Page</strong>
                        </Row>
                        <Row style={{ width: 250 }}>
                          <Selection
                            width={250}
                            size="large"
                            data={startupPageOptions}
                            value={startupPage}
                            onChange={(v, o) => this.handleSelectChange(v, o, 'startupPage')}
                          />
                        </Row>
                      </>
                    ) : null}
                  </Col>
                  <Col span={12}>
                    <Row>
                      <strong>Receipt Emails</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Input
                        size="large"
                        spellCheck={false}
                        value={receiptEmails}
                        onChange={e => this.handleInputChange('receiptEmails', e)}
                      />
                    </Row>
                  </Col>
                </Row>
                {mappingType !== 4 ? (
                  <Row style={{ marginTop: 20 }}>
                    <Col span={12}>
                      <Row>
                        <strong>Future Order Days</strong>
                      </Row>
                      <Row style={{ width: 250 }}>
                        <Input
                          size="large"
                          spellCheck={false}
                          value={futureOrderDays}
                          onChange={e => this.handleInputChange('futureOrderDays', e)}
                        />
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <strong>Future Order Buffer Days</strong>
                      </Row>
                      <Row style={{ width: 250 }}>
                        <Input
                          size="large"
                          spellCheck={false}
                          value={futureOrderBufferDays}
                          onChange={e => this.handleInputChange('futureOrderBufferDays', e)}
                        />
                      </Row>
                    </Col>
                  </Row>
                ) : null}
                {mappingType !== 4 ? (
                  <Row style={{ marginTop: 20 }}>
                    <Col span={12}>
                      <Row>
                        <strong>Address Info</strong>
                      </Row>
                      <Row style={{ width: 250 }}>
                        <Selection
                          width={250}
                          size="large"
                          data={addressInfoOptions}
                          value={addressInfo}
                          onChange={(v, o) => this.handleSelectChange(v, o, 'addressInfo')}
                        />
                      </Row>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
            <Divider style={{ borderColor: 'transparent' }} />
            {/* Single Checkboxed */}
            <Row style={{ marginTop: 15 }} justify="center">
              <Col span={20}>
                <Row>
                  <Col span={8}>
                    <Row gutter={4}>
                      <Col>
                        <strong>Active</strong>
                      </Col>
                      <Col>
                        <Checkbox
                          checked={!inactive}
                          onChange={e => {
                            this.handleSingleCheckbox(e, 'inactive');
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    {mappingType !== 4 ? (
                      <Row gutter={4}>
                        <Col>
                          <strong>Enable Tips</strong>
                        </Col>
                        <Col>
                          <Checkbox
                            checked={enableTips}
                            onChange={e => {
                              this.handleSingleCheckbox(e, 'enableTips');
                            }}
                          />
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                  <Col span={8}>
                    {mappingType !== 4 ? (
                      <Row gutter={4}>
                        <Col>
                          <strong>Disable Order ASAP</strong>
                        </Col>
                        <Col>
                          <Checkbox
                            checked={disableOrderASAP}
                            onChange={e => {
                              this.handleSingleCheckbox(e, 'disableOrderASAP');
                            }}
                          />
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Account Type */}
            <Row style={{ marginTop: 15 }} justify="center">
              <Col span={20}>
                <Row gutter={32}>
                  <Col>
                    <strong>Account Type</strong>
                  </Col>
                  <Checkbox.Group
                    options={accountTypeOptions}
                    value={accountType}
                    onChange={this.handleAccountTypeChange}
                  />
                </Row>
              </Col>
            </Row>
            {/* Available Payments */}
            {mappingType !== 4 ? (
              <Row style={{ marginTop: 15 }} justify="center">
                <Col span={20}>
                  <Row gutter={32}>
                    <Col>
                      <strong>Available Payments</strong>
                    </Col>
                    <Checkbox.Group
                      options={paymentTypeOptions}
                      value={availablePayments}
                      onChange={this.handlePaymentTypeChange}
                    />
                  </Row>
                </Col>
              </Row>
            ) : null}

            {/* Time Range Type Selection */}
            <Divider style={{ borderColor: 'transparent' }} />
            <Row style={{ marginTop: 15 }} justify="center">
              <Col span={20}>
                <Row>
                  <Col span={12}>
                    <Row>
                      <strong>Time Range Type</strong>
                    </Row>
                    <Row style={{ width: 250 }}>
                      <Selection
                        width={250}
                        size="large"
                        disabled={mappingType === 4}
                        data={timeRangeTypeOptions}
                        value={timeRangeType}
                        onChange={(v, o) => this.handleSelectChange(v, o, 'timeRangeType')}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Time Range Table */}
            <Divider style={{ borderColor: 'transparent' }} />
            {timeRangeType === 1 || timeRangeType === 2 ? (
              <Row>
                <Col span={24}>
                  <EditableTable
                    size="middle"
                    rowKey="key"
                    tableColumns={columns}
                    tableDataSource={
                      timeRangeType === 1 ? timeRangeList : timeRangeType === 2 ? dayPartList : []
                    }
                    pagination={false}
                    getUpdatedData={updatedData => this.getUpdatedTimeRangeListData(updatedData)}
                  />
                </Col>
              </Row>
            ) : null}
            <Divider style={{ borderColor: 'transparent' }} />
            {/* Image Row */}
            {mappingType !== 4 ? (
              <Row justify="center">
                <Col span={20}>
                  <Row>
                    <Col span={12}>
                      <Row>
                        <strong>Current Image</strong>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <strong>Change Image</strong>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Row
                        style={{
                          width: 300,
                          height: 200,
                          border: '1px solid #e8e8e8',
                          position: 'relative',
                        }}
                        justify="center"
                      >
                        <Image
                          width={300}
                          height={200}
                          src={this.getImage(imageName)}
                          preview={
                            this.getImage(imageName) !==
                            `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`
                          }
                        />
                        {imageName ? (
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <Button
                              width={70}
                              height={35}
                              style={{ backgroundColor: 'var(--adminSecondary)' }}
                              textStyle={{ color: '#fff' }}
                              borderRadius={5}
                              onClick={this.handleCurrImageRemove}
                            >
                              Remove
                            </Button>
                          </div>
                        ) : null}
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row justify="start">
                        <ImageUploader
                          onImgUploadClick={imgFile => {
                            this.handleUploadImg(imgFile);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </Spin>
    );
  }
}
