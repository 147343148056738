import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faX } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button';
import Modal from '../Modal';
import OrderSplitBillModalSplitByChoice from '../OrderSplitBillModalSplitByChoice';
import OrderSplitBillMain from '../OrderSplitBillMain';
import './index.css';

class OrderSplitBillSelector extends Component {
  state = {
    onDisplay: 2, //1. select split by; 2. item list or enter amount
    splitByChoice: 0, //0: by amount; 1: by items
    balance: 0,
    splitPayments: [], //{key: nanoid, amount: 0, items: []}
    currSplitPayment: {}, //{key: nanoid, amount: 0, items: []}
    errorModalVisible: false,
  };

  init = () => {
    // const { checkSum } = this.props;
    // const {
    //   OrderSummary: { Balance: balance },
    // } = checkSum;
    // this.setState({ balance });
  };

  handleSplitBillCancel = () => {
    const { splitBalance, checkSum } = this.props;
    const {
      OrderSummary: { Balance },
    } = checkSum;

    if (splitBalance === parseFloat(Balance).toFixed(2)) {
      this.cleanState();
      this.props.onModalClose();
    }

    if (splitBalance !== parseFloat(Balance).toFixed(2) && parseFloat(splitBalance) > 0) {
      this.setState({ errorModalVisible: true });
    }

    // if (splitBalance !== Balance && parseFloat(splitBalance).toFixed(2) === '0.00') {
    //   this.cleanState();
    //   this.props.onModalClose();
    // }
  };

  cleanState = () => {
    this.setState({
      onDisplay: 1,
      splitByChoice: 0,
      balance: 0,
      splitPayments: [],
      currSplitPayment: {},
    });
  };

  handleSplitByChoiceChange = choice => {
    this.setState({ splitByChoice: choice });
  };

  handleSplitByChoiceNext = () => {};

  handleUnpaidBalanceModalOK = () => {
    this.setState({ errorModalVisible: false });
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { onDisplay, splitByChoice, errorModalVisible } = this.state;
    const { splitBalance, checkSum, splitPayments } = this.props;
    return (
      <div style={{ position: 'relative' }}>
        <div className="order-split-bill-modal-close-btn">
          <div className="menu-cancel-modal-close-icon" onClick={this.handleSplitBillCancel}>
            <FontAwesomeIcon icon={faX} size="2x" style={{ color: '#666' }} />
          </div>
        </div>
        {/* <div className="order-split-bill-modal-title">
          <Row justify="center">
            <h2>Split Bill</h2>
          </Row>
        </div> */}
        <div className="order-split-bill-modal-content">
          <Row>
            <Col span={24}>
              <Row>
                <SwitchTransition mode="out-in">
                  <CSSTransition
                    key={onDisplay}
                    timeout={500}
                    classNames="order-split-bill-modal-content"
                  >
                    <>
                      {onDisplay === 1 && (
                        <OrderSplitBillModalSplitByChoice
                          splitByChoice={splitByChoice}
                          onSplitByChoiceChange={choice => {
                            this.handleSplitByChoiceChange(choice);
                          }}
                        />
                      )}
                      {onDisplay === 2 && (
                        <OrderSplitBillMain
                          splitPayments={splitPayments}
                          balance={splitBalance}
                          checkSum={checkSum}
                          onSelectPayment={(type, amt) => this.props.onSelectPayment(type, amt)}
                        />
                      )}
                    </>
                  </CSSTransition>
                </SwitchTransition>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="order-split-bill-modal-footer">
          <Row style={{ margin: '5px 10px' }}>
            <Col span={12}>
              <Row justify="start">
                {onDisplay === 0 ? (
                  <Button
                    height={50}
                    style={{ backgroundColor: '#fff' }}
                    borderRadius={5}
                    onClick={this.handleItemSizeModalCancel}
                  >
                    Previous
                  </Button>
                ) : null}
              </Row>
            </Col>
            <Col span={12}>
              <Row justify="end">
                {onDisplay === 1 && (
                  <Button
                    width={180}
                    height={50}
                    style={{ backgroundColor: 'var(--primary' }}
                    textStyle={{ color: '#fff' }}
                    borderRadius={5}
                    onClick={this.handleSplitByChoiceNext}
                  >
                    {splitByChoice === 0 ? 'Enter Amount' : 'Choose Item(s)'}
                  </Button>
                )}
              </Row>
            </Col>
          </Row>
        </div>
        <Modal
          visible={errorModalVisible}
          closable={false}
          width={520}
          maskClosable={false}
          title={null}
          footer={null}
          destroyOnClose={true}
          showCloseBtn={false}
        >
          <Row style={{ fontSize: 18, fontWeight: 'bold' }} justify="center" align="middle">
            <FontAwesomeIcon icon={faCircleExclamation} size="xl" color="#faad14" />
            <h3 style={{ marginLeft: 20 }}>You have unpaid balance</h3>
          </Row>
          <Row justify="center" style={{ marginTop: 20, height: 100 }}></Row>

          <Row justify="center" style={{ marginTop: 30, padding: '10px 30px' }}>
            <Button
              width={300}
              style={{ backgroundColor: 'var(--primary)' }}
              textStyle={{ color: '#fff', fontSize: 18 }}
              borderRadius={5}
              onClick={this.handleUnpaidBalanceModalOK}
            >
              Continue with the payments
            </Button>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default OrderSplitBillSelector;
