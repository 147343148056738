const options = [
  {
    label: 'Default',
    value: 'Segoe UI',
  },
  {
    label: 'Arial',
    value: 'Arial',
  },
  {
    label: 'Times',
    value: 'Times',
  },
  {
    label: 'Tahoma',
    value: 'Tahoma',
  },
  {
    label: 'Segoe Script',
    value: 'Segoe Script',
  },
  {
    label: 'Courier New',
    value: 'Courier New',
  },
  {
    label: 'Cambria',
    value: 'Cambria',
  },
  {
    label: 'Century Gothic',
    value: 'Century Gothic',
  },
];

export default options;
