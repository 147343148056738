import React, { Component } from 'react';
import { Row, Col, Divider, Image, Drawer, Select, message } from 'antd';
import { connect } from 'react-redux';
import { receiveAppSetting } from '../../../redux/actions';
import {
  getAppSetting,
  getVTImageFolder,
  saveAppSetting,
  saveKioskStationConfiguration,
  getKioskStationConfiguration,
  getCurrentKioskSetting,
} from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import Button from '../../../components/Button';
import ImageManager from '../../../components/ImageManager';
import Selection from '../../../components/Selection';

import './index.css';

/**
None = 0,
ViewButtonImage = 1,
ViewBackgroundImage = 2,
SliderImage = 3,
ProfitCentreImage = 4,
DashboardBackgroundImage = 5,
CompanyLogoImage = 6,
KioskOrderSetupBackgroundImage = 7,
KiosWelcomeBackgroundImage = 8,
KioskReservationBackgroundImage = 9,
OrderOptionMappingImage = 10,
ReservationMappingImage = 11,
KioskVideo = 12,
KioskCustomizedImage = 13,
VisualItem = 14,
EventImage = 15,
ItemTabImage = 16,
ListViewPopUpImage = 17
 */

const { Option } = Select;

class AdminImages extends Component {
  state = {
    spinning: false,
    showTitle: false,
    config: {},
    configName: '',
    useCustomImage: 0,
    DashboardBackgroundImage: '',
    CompanyLogoImage: '',
    KioskOrderSetupBackgroundImage: '',
    KioskReservationBackgroundImage: '',
    sliderImages: [],
    imgDrawerVisible: false,
    selectedType: '',
    currImage: '',
    imageFolders: {
      CompanyLogoImage: '',
      DashboardBackgroundImage: '',
      KioskOrderSetupBackgroundImage: '',
      KioskReservationBackgroundImage: '',
      KiosWelcomeBackgroundImage: '',
    },
  };

  defaultOrCustomOptions = [
    {
      key: 0,
      value: 0,
      label: 'Use Default Setting',
    },
    {
      key: 1,
      value: 1,
      label: 'Use Custom Setting',
    },
  ];

  getImageFolders = async () => {
    this.setState({ spinning: true });
    await Promise.all([
      getVTImageFolder('5'),
      getVTImageFolder('6'),
      getVTImageFolder('7'),
      getVTImageFolder('8'),
      getVTImageFolder('9'),
    ])
      .then(results => {
        results.forEach(res => {
          if (!res || (res && res.result !== 'OK')) throw new Error();
        });
        const imageFolders = {};
        imageFolders.DashboardBackgroundImage = results[0].data;
        imageFolders.CompanyLogoImage = results[1].data;
        imageFolders.KioskOrderSetupBackgroundImage = results[2].data;
        imageFolders.KiosWelcomeBackgroundImage = results[3].data;
        imageFolders.KioskReservationBackgroundImage = results[4].data;

        this.setState({ imageFolders, spinning: false });
      })
      .catch(error => {
        this.setState({ spinning: false });
        message.error('Error obtaining image paths!', 3);
      });
  };

  init = async () => {
    const { item } = this.props.location.state || {};
    if (item) {
      if (item.UseCustomImage === 1) {
        try {
          this.setState({ spinning: true });
          const re = await getKioskStationConfiguration(item.SettingId);
          if (!re || (re && re.result !== 'OK')) throw new Error();
          const { data } = re;
          this.setState({
            spinning: false,
            showTitle: true,
            configName: data.SettingName,
            useCustomImage: data.UseCustomImage,
            DashboardBackgroundImage: data.ImageConfiguration.DashboardBackground || '',
            CompanyLogoImage: data.ImageConfiguration.Logo || '',
            KioskOrderSetupBackgroundImage: data.ImageConfiguration.OrderOptionBackground || '',
            KioskReservationBackgroundImage: data.ImageConfiguration.ReservationBackground || '',
            sliderImages: data.ImageConfiguration.WelcomBackgrounds || [],
          });
        } catch (error) {
          this.setState({ spinning: false });
          message.error('Error obtaining kiosk settings', 3);
        }
      } else {
        this.setState({
          showTitle: true,
          configName: item.SettingName,
          useCustomImage: item.UseCustomImage,
        });
      }
    } else {
      try {
        this.setState({ spinning: true });
        const re = await getAppSetting();
        if (!re || (re && re.result !== 'OK')) throw new Error();
        this.setState({
          spinning: false,
          showTitle: false,
          configName: null,
          config: re.data,
          useCustomImage: 0,
          DashboardBackgroundImage: re.data.ImageConfigruation.DashboardBackground || '',
          CompanyLogoImage: re.data.ImageConfigruation.Logo || '',
          KioskOrderSetupBackgroundImage: re.data.ImageConfigruation.OrderOptionBackground || '',
          KioskReservationBackgroundImage: re.data.ImageConfigruation.ReservationBackground || '',
          sliderImages: re.data.ImageConfigruation.WelcomBackgrounds || [],
        });
      } catch (error) {
        this.setState({ spinning: false });
        message.error('Error obtaining kiosk settings', 3);
      }
    }
  };

  handleSaveBtn = async () => {
    const {
      config,
      useCustomImage,
      DashboardBackgroundImage,
      CompanyLogoImage,
      KioskOrderSetupBackgroundImage,
      KioskReservationBackgroundImage,
      sliderImages,
    } = this.state;
    const { item } = this.props.location.state || {};
    if (item) {
      if (useCustomImage === 1) {
        item.UseCustomImage = 1;
        item.ImageConfiguration = item.ImageConfiguration ? item.ImageConfiguration : {};
        item.ImageConfiguration.DashboardBackground = DashboardBackgroundImage;
        item.ImageConfiguration.Logo = CompanyLogoImage;
        item.ImageConfiguration.OrderOptionBackground = KioskOrderSetupBackgroundImage;
        item.ImageConfiguration.ReservationBackground = KioskReservationBackgroundImage;
        item.ImageConfiguration.WelcomBackgrounds = sliderImages;
      } else {
        item.UseCustomImage = 0;
        item.ImageConfiguration = null;
      }
      try {
        this.setState({ spinning: true });
        const re = await saveKioskStationConfiguration(item);
        if (!re || (re && re.result !== 'OK')) {
          throw new Error();
        }
        const res = await getCurrentKioskSetting();
        if (!res || (res && res.result !== 'OK')) throw new Error();
        const obj = {};
        obj.appSetting = res.appSetting ? res.appSetting : {};
        obj.imgSetting = res.imgSetting ? res.imgSetting : {};
        obj.stationName = res.stationName ? res.stationName : '';
        this.props.receiveAppSetting(obj);
        this.setState({ spinning: false });
        message.success('Config successfully saved!', 3);
        this.props.history.replace('/admin/kiosk');
      } catch (error) {
        this.setState({ spinning: false });
        message.error('Error saving config!', 3);
      }
    } else {
      config.ImageConfigruation.DashboardBackground = DashboardBackgroundImage;
      config.ImageConfigruation.Logo = CompanyLogoImage;
      config.ImageConfigruation.OrderOptionBackground = KioskOrderSetupBackgroundImage;
      config.ImageConfigruation.ReservationBackground = KioskReservationBackgroundImage;
      config.ImageConfigruation.WelcomBackgrounds = sliderImages;
      try {
        this.setState({ spinning: true });
        const re = await saveAppSetting(config);
        if (!re || (re && re.result !== 'OK')) throw new Error();
        const res = await getCurrentKioskSetting();
        if (!res || (res && res.result !== 'OK')) throw new Error();
        const obj = {};
        obj.appSetting = res.appSetting ? res.appSetting : {};
        obj.imgSetting = res.imgSetting ? res.imgSetting : {};
        obj.stationName = res.stationName ? res.stationName : '';
        this.props.receiveAppSetting(obj);
        this.setState({ spinning: false });
        message.success('Default config successfully saved!', 3);
        this.props.history.replace('/admin/kiosk');
      } catch (error) {
        this.setState({ spinning: false });
        message.error('Error saving default config!', 3);
      }
    }
  };

  handleBackBtn = () => {
    this.props.history.replace('/admin/kiosk');
  };

  handleSelectionChange = (v, _, dataType) => {
    this.setState({ [dataType]: v });
  };

  sortType = type => {
    switch (type) {
      case '8':
        return 'KiosWelcomeBackgroundImage';
      case '5':
        return 'DashboardBackgroundImage';
      case '6':
        return 'CompanyLogoImage';
      case '7':
        return 'KioskOrderSetupBackgroundImage';
      case '9':
        return 'KioskReservationBackgroundImage';
      default:
        return '';
    }
  };

  handleManageImagesBtn = type => {
    const dataType = this.sortType(type);
    const currImage = this.state[dataType];
    this.setState({ selectedType: type, currImage }, () => {
      this.setState({ imgDrawerVisible: true });
    });
  };

  getImage = (ImageUrl, type) => {
    const { imageFolders } = this.state;
    const imgFallback = `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`;
    const dataType = this.sortType(type);
    const dir = imageFolders[dataType];
    let src = '';

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      //dev mode
      if (ImageUrl) {
        src = `http://localhost:4000/${dir}/${ImageUrl}`;
      } else {
        src = imgFallback;
      }
    } else {
      //production mode
      if (ImageUrl) {
        src = `${process.env.PUBLIC_URL}/${dir}/${ImageUrl}`;
      } else {
        src = imgFallback;
      }
    }
    return src;
  };

  handleImgDrawerClose = () => {
    this.setState({ imgDrawerVisible: false });
  };

  handleImageManagerImageClick = (type, item) => {
    const dataType = this.sortType(type);
    this.state[dataType] === item.ImageName && this.setState({ [dataType]: '', currImage: '' });
    this.state[dataType] !== item.ImageName &&
      this.setState({ [dataType]: item.ImageName, currImage: item.ImageName });
  };

  handleSliderImagesChange = sliderImages => {
    this.setState({ sliderImages });
  };

  componentDidMount() {
    this.init();
    this.getImageFolders();
  }

  render() {
    const {
      spinning,
      configName,
      showTitle,
      useCustomImage,
      CompanyLogoImage,
      KioskOrderSetupBackgroundImage,
      DashboardBackgroundImage,
      KioskReservationBackgroundImage,
      sliderImages,
      imgDrawerVisible,
      selectedType,
      currImage,
    } = this.state;

    //Use item to check if it is default or custom setting
    const { item } = this.props.location.state || {};

    const defaultOrCustomOptions = this.defaultOrCustomOptions.map(i => (
      <Option key={i.key} value={i.value}>
        {i.label}
      </Option>
    ));

    return (
      <Spin spinning={spinning}>
        <Row className="admin-images-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title="Kiosk Images Configuration"
              showLeftBtn={true}
              leftBtnText="Back"
              onLeftBtnClick={this.handleBackBtn}
              showRightBtn={true}
              rightBtnText="Save"
              onRightBtnClick={this.handleSaveBtn}
            />
            {showTitle ? (
              <>
                <Row style={{ marginTop: 30 }}>
                  <Col span={24}>
                    <Row align="middle">
                      <h2 style={{ color: 'var(--adminSecondary)' }}>Configuration Name </h2>{' '}
                      <h2>{configName}</h2>
                    </Row>
                  </Col>
                </Row>
                <Row align="middle" style={{ marginTop: 30 }}>
                  <Col span={24}>
                    <Row align="middle">
                      <h2 style={{ marginRight: 20, color: 'var(--adminSecondary)' }}>
                        Setting Type
                      </h2>
                      <Selection
                        width={200}
                        data={defaultOrCustomOptions}
                        value={useCustomImage}
                        onChange={(value, option) => {
                          this.handleSelectionChange(value, option, 'useCustomImage');
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              </>
            ) : null}
            {!item || useCustomImage ? (
              <>
                {/* Logo*/}
                <Row style={{ marginTop: 15 }}>
                  <h2 style={{ color: 'var(--adminSecondary)' }}>Company/Store Logo</h2>
                </Row>
                <Row style={{ marginTop: 15 }} justify="center">
                  <Col span={18}>
                    <Row>
                      {/* Thumbnail after uploaded */}
                      <Col span={24}>
                        <Row
                          style={{
                            width: 300,
                            height: 200,
                            border: '1px solid #e8e8e8',
                            position: 'relative',
                          }}
                          justify="center"
                        >
                          <Image
                            width={300}
                            height={200}
                            src={this.getImage(CompanyLogoImage, '6')}
                            preview={
                              this.getImage(CompanyLogoImage, '6') !==
                              `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`
                            }
                          />
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <Button
                              width={70}
                              height={35}
                              style={{ backgroundColor: 'var(--adminPrimary)' }}
                              textStyle={{ color: '#fff' }}
                              borderRadius={5}
                              onClick={() => this.handleManageImagesBtn('6')}
                            >
                              Manage
                            </Button>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider style={{ borderColor: 'transparent' }} />

                {/* Order Setup Background */}
                <Row style={{ marginTop: 15 }}>
                  <h2 style={{ color: 'var(--adminSecondary)' }}>Order Setup Background</h2>
                </Row>
                <Row style={{ marginTop: 15 }} justify="center">
                  <Col span={18}>
                    <Row>
                      {/* Thumbnail after uploaded */}
                      <Col span={24}>
                        <Row
                          style={{
                            width: 300,
                            height: 200,
                            border: '1px solid #e8e8e8',
                            position: 'relative',
                          }}
                        >
                          <Image
                            width={300}
                            height={200}
                            src={this.getImage(KioskOrderSetupBackgroundImage, '7')}
                            preview={
                              this.getImage(KioskOrderSetupBackgroundImage, '7') !==
                              `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`
                            }
                          />
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <Button
                              width={70}
                              height={35}
                              style={{ backgroundColor: 'var(--adminPrimary)' }}
                              textStyle={{ color: '#fff' }}
                              borderRadius={5}
                              onClick={() => this.handleManageImagesBtn('7')}
                            >
                              Manage
                            </Button>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider style={{ borderColor: 'transparent' }} />

                {/* Dashboard Background */}
                {/* <Row style={{ marginTop: 15 }}>
                  <h2 style={{ color: 'var(--adminSecondary)' }}>Dashboard Background</h2>
                </Row>
                <Row style={{ marginTop: 15 }} justify="center">
                  <Col span={18}>
                    <Row>
                      <Col span={12}>
                        <Row
                          style={{
                            width: 300,
                            height: 200,
                            border: '1px solid #e8e8e8',
                            position: 'relative',
                          }}
                        >
                          <Image
                            width={300}
                            height={200}
                            src={this.getImage(DashboardBackgroundImage, '5')}
                            preview={
                              this.getImage(DashboardBackgroundImage, '5') !==
                              `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`
                            }
                          />
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <Button
                              width={70}
                              height={35}
                              style={{ backgroundColor: 'var(--adminPrimary)' }}
                              textStyle={{ color: '#fff' }}
                              borderRadius={5}
                              onClick={() => this.handleManageImagesBtn('5')}
                            >
                              Manage
                            </Button>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider style={{ borderColor: 'transparent' }} /> */}

                {/* Reservation Background */}
                <Row style={{ marginTop: 15 }}>
                  <h2 style={{ color: 'var(--adminSecondary)' }}>Reservation Background</h2>
                </Row>
                <Row style={{ marginTop: 15 }} justify="center">
                  <Col span={18}>
                    <Row>
                      {/* Thumbnail after uploaded */}
                      <Col span={12}>
                        <Row
                          style={{
                            width: 300,
                            height: 200,
                            border: '1px solid #e8e8e8',
                            position: 'relative',
                          }}
                        >
                          <Image
                            width={300}
                            height={200}
                            src={this.getImage(KioskReservationBackgroundImage, '9')}
                            preview={
                              this.getImage(KioskReservationBackgroundImage, '9') !==
                              `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`
                            }
                          />
                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <Button
                              width={70}
                              height={35}
                              style={{ backgroundColor: 'var(--adminPrimary)' }}
                              textStyle={{ color: '#fff' }}
                              borderRadius={5}
                              onClick={() => this.handleManageImagesBtn('9')}
                            >
                              Manage
                            </Button>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider />

                {/* Welcome Page */}
                <Row style={{ marginTop: 15 }}>
                  <h2 style={{ color: 'var(--adminSecondary)' }}>Welcome Page</h2>
                  <Button
                    width={80}
                    height={40}
                    style={{ backgroundColor: 'var(--adminPrimary)', marginLeft: 20 }}
                    textStyle={{ color: 'white' }}
                    borderRadius={5}
                    onClick={() => this.handleManageImagesBtn('8')}
                  >
                    Manage
                  </Button>
                </Row>

                <Divider style={{ borderColor: 'transparent' }} />
                {/* Images Mapping Row */}
                <Row>
                  {sliderImages.map(i => (
                    <div style={{ margin: 20 }} key={i}>
                      <Row align="middle" style={{ position: 'relative' }}>
                        <div style={{ width: 300, height: 200 }}>
                          <Image width={300} height={200} src={this.getImage(i, '8')} />
                        </div>
                      </Row>
                    </div>
                  ))}
                </Row>
              </>
            ) : null}
          </Col>
        </Row>
        <Drawer
          visible={imgDrawerVisible}
          width="100%"
          maskClosable={false}
          closable={false}
          onClose={this.handleImgDrawerClose}
          drawerStyle={{ backgroundColor: '#fff', position: 'relative' }}
          destroyOnClose={true}
          getContainer={false}
        >
          <ImageManager
            type={selectedType}
            onCloseImgDrawer={this.handleImgDrawerClose}
            currImage={currImage}
            sliderImages={sliderImages}
            onSliderImageClick={sliderImages => {
              this.handleSliderImagesChange(sliderImages);
            }}
            onImageClick={(type, item) => {
              this.handleImageManagerImageClick(type, item);
            }}
          />
        </Drawer>
      </Spin>
    );
  }
}
export default connect(state => ({}), { receiveAppSetting })(AdminImages);
