import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withIdleTimer } from 'react-idle-timer';
import { nanoid } from '@reduxjs/toolkit';
import { Row, Col, message } from 'antd';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
  resetUser,
  receiveOrderOption,
  deleteGuestName,
  receiveCustomerOrderOption,
  resetCustomerOrderOption,
  resetLoginType,
  clearOrderItems,
  clearScannedOrderItems,
  receiveAppMode,
  resetAppMode,
  deleteGuestPhone,
  deleteGuestIns,
} from '../../redux/actions';
import { getOrderOption, getVTImageFolder } from '../../api';
import Button from '../../components/Button';
import OrderOptionStepOne from '../../components/OrderOptionStepOne';
import OrderOptionStepTwo from '../../components/OrderOptionStepTwo';
import OrderOptionVenue from '../../components/OrderOptionVenue';

import './index.css';

class OrderOptions extends Component {
  //onDisplay: 1 'venue'; 2 'stepOne'; 3 'stepTwo'
  state = {
    imageDir: '',
    onDisplay: 0,
    menuBtnRotate: false,
    showOrderBtn: false,
    selectedVenue: null,
    selectedOption: {},
    orderOptions: [],
    scheduleOptionSelected: '1',
    dateChoice: null,
    dayPartChoice: null,
    timeSlotChoice: null,
    // scrollPickerChoice: null,
    userSelections: {
      venue: null,
      orderOption: null,
      date: null,
      dayPart: null,
      timeslot: null,
      pageName: '',
    },
  };

  ref = React.createRef();

  init = async () => {
    // Get OrderOption
    const { loginType, user } = this.props;
    let orderOption;
    if (loginType === 'Guest') {
      orderOption = await this.getOrderOptions();
    }
    if (loginType === 'Account' || loginType === 'Card') {
      orderOption = await this.getOrderOptions(user.CurAccountInfo);
    }

    //Check to show 'Venue' or order options
    if (orderOption && orderOption.length > 1) {
      this.setState({
        onDisplay: 1,
      });
    }
    if (orderOption && orderOption.length === 1) {
      this.setState(
        {
          orderOptions: orderOption[0].OrderOptions,
        },
        () => {
          this.setState({ onDisplay: 2 });
        },
      );
    }
  };

  getImageFolder = async () => {
    try {
      const re = await getVTImageFolder('7');
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ imageDir: '' });
      }
      this.setState({ imageDir: re.data });
    } catch (error) {}
  };

  getOrderOptions = async currAccount => {
    try {
      let re;
      if (!currAccount) {
        re = await getOrderOption();
      } else {
        re = await getOrderOption(currAccount);
      }
      if (!re || (re && re.result !== 'OK')) {
        throw new Error('Error obtaining order option!');
      }
      if (re.data.length === 0) return [];
      const data = re.data.map(i => {
        i.key = nanoid();
        i.OrderOptions.map(o => {
          o.key = nanoid();
          o.OrderDates.map(j => {
            j.key = nanoid();
            j.DayParts.map(k => {
              k.key = nanoid();
              return k;
            });
            return j;
          });
          return o;
        });
        return i;
      });
      this.props.receiveOrderOption(data);
      return data;
    } catch (error) {
      message.error('Error obtaining order option!');
    }
  };

  closeMenu = () => {
    this.setState({ menuBtnRotate: false });
  };

  handleMenuBtnClick = () => {
    this.setState({ menuBtnRotate: !this.state.menuBtnRotate });
  };

  handleOrderOptionsBack = () => {
    const { onDisplay } = this.state;
    const { orderOption } = this.props;
    if (onDisplay === 3) {
      this.setState({
        onDisplay: 2,
        menuBtnRotate: false,
        scheduleOptionSelected: '1',
        showOrderBtn: false,
      });
    }

    if (onDisplay === 2 && orderOption && orderOption.length > 1) {
      this.setState({
        onDisplay: 1,
        menuBtnRotate: false,
        scheduleOptionSelected: '1',
        showOrderBtn: false,
      });
    }

    if (onDisplay === 2 && orderOption && orderOption.length === 1) {
      this.handleOrderOptionsCancel();
    }
  };

  handleForwardArrow = () => {
    const { onDisplay } = this.state;
    this.setState({ onDisplay: onDisplay + 1 < 4 ? onDisplay + 1 : 3 });
    if (onDisplay === 2) {
      this.checkIfShowNextBtn();
    }
  };

  handleOrderOptionsCancel = () => {
    const { loginType } = this.props;
    this.props.resetCustomerOrderOption();
    if (loginType === 'Guest' || !loginType) {
      this.props.deleteGuestName();
      this.props.deleteGuestPhone();
      this.props.deleteGuestIns(0);
      this.props.clearOrderItems();
      this.props.clearScannedOrderItems();
      this.props.resetCustomerOrderOption();
      this.props.resetLoginType();
      this.props.resetAppMode();
      this.props.history.replace('/');
    }
    if (loginType === 'Account' || loginType === 'Card') {
      this.props.clearScannedOrderItems();
      this.props.resetCustomerOrderOption();
      this.props.history.replace('/admin');
    }
  };

  handleVenueClick = () => {
    this.closeMenu();
    this.setState({ onDisplay: 2 });
  };

  handleVenueOptionChange = venue => {
    const { OrderOptions } = venue;
    const { userSelections } = this.state;
    const {
      kioskSetting: {
        appSetting: { ScanItemMode },
      },
    } = this.props;
    userSelections.venue = venue;
    this.setState({ orderOptions: OrderOptions, userSelections, menuBtnRotate: false }, () => {
      if (OrderOptions.length > 0) {
        this.setState({ onDisplay: 2 });
      } else {
        this.props.receiveCustomerOrderOption(userSelections);
        if (ScanItemMode === 1) {
          this.props.history.replace('/scan');
        } else {
          this.props.history.replace('/menu');
        }
      }
    });
  };

  checkIfShowNextBtn = () => {
    const { scheduleOptionSelected, selectedOption, dateChoice, dayPartChoice, timeSlotChoice } =
      this.state;
    const { OrderDates } = selectedOption ?? null;
    if (!selectedOption.DisableOrderAsap) {
      if (scheduleOptionSelected === '1') {
        //User selected/or defaulted "Today ASAP", mark selectedOption and go to menu
        this.setState({ showOrderBtn: true });
      } else if (scheduleOptionSelected === '2') {
        //User selected "Schedule Date/Time", mark date && time and go to menu
        if (dateChoice && dayPartChoice && timeSlotChoice) {
          this.setState({ showOrderBtn: true });
        }
      }
    } else {
      //
      //User must pick date and time or daypart, mark their choices and got to menu
      if (OrderDates && OrderDates.length > 0 && dateChoice && dayPartChoice && timeSlotChoice) {
        this.setState({ showOrderBtn: true });
      }
    }
  };

  handleOrderOptionStepOneSelectionChange = option => {
    const { userSelections } = this.state;
    const {
      kioskSetting: {
        appSetting: { EnableFutureOrder, ScanItemMode },
      },
      loginType,
    } = this.props;
    const { OrderDates } = option;
    userSelections.pageName = option.PageName;

    if (EnableFutureOrder === 0 || (EnableFutureOrder === 1 && loginType === 'Guest')) {
      this.props.receiveCustomerOrderOption(userSelections);
      if (ScanItemMode === 1) {
        this.props.history.replace('/scan');
      } else {
        this.props.history.replace('/menu');
      }
      return;
    }
    if ((EnableFutureOrder === 1 && loginType === 'Account') || EnableFutureOrder === 2) {
      this.setState(
        {
          onDisplay: 3,
          selectedOption: option,
          dateChoice: OrderDates && OrderDates.length > 0 ? OrderDates[0] : {},
          dayPartChoice:
            OrderDates &&
            OrderDates.length > 0 &&
            OrderDates[0] &&
            OrderDates[0].DayParts &&
            OrderDates[0].DayParts.length > 0
              ? OrderDates[0].DayParts[0]
              : {},
          timeSlotChoice:
            OrderDates &&
            OrderDates.length > 0 &&
            OrderDates[0] &&
            OrderDates[0].DayParts &&
            OrderDates[0].DayParts.length > 0 &&
            OrderDates[0].DayParts[0].Times &&
            OrderDates[0].DayParts[0].Times.length > 0
              ? OrderDates[0].DayParts[0].Times[0]
              : {},
          userSelections: {
            ...userSelections,
            orderOption: option,
            date: OrderDates && OrderDates.length > 0 ? OrderDates[0] : {},
            dayPart:
              OrderDates &&
              OrderDates.length > 0 &&
              OrderDates[0] &&
              OrderDates[0].DayParts &&
              OrderDates[0].DayParts.length > 0
                ? OrderDates[0].DayParts[0]
                : {},
            timeslot:
              OrderDates &&
              OrderDates.length > 0 &&
              OrderDates[0] &&
              OrderDates[0].DayParts &&
              OrderDates[0].DayParts.length > 0 &&
              OrderDates[0].DayParts[0].Times &&
              OrderDates[0].DayParts[0].Times.length > 0
                ? OrderDates[0].DayParts[0].Times[0]
                : {},
          },
        },
        () => {
          this.checkIfShowNextBtn();
        },
      );
    }
  };

  handleScheduleOptionChange = key => {
    this.setState({ scheduleOptionSelected: key });
  };

  handleDateChange = date => {
    const { userSelections } = this.state;
    const { DayParts } = date;
    this.setState({
      dateChoice: date,
      dayPartChoice: DayParts && DayParts.length > 0 ? DayParts[0] : {},
      timeSlotChoice:
        DayParts && DayParts.length > 0 && DayParts[0].Times && DayParts[0].Times.length > 0
          ? DayParts[0].Times[0]
          : {},
      userSelections: {
        ...userSelections,
        date: date,
        dayPart: DayParts && DayParts.length > 0 ? DayParts[0] : {},
        timeslot:
          DayParts && DayParts.length > 0 && DayParts[0].Times && DayParts[0].Times.length > 0
            ? DayParts[0].Times[0]
            : {},
      },
    });
    this.checkIfShowNextBtn();
  };

  handleDayPartChange = dayPart => {
    const { userSelections } = this.state;
    const { Times } = dayPart;
    this.setState({
      dayPartChoice: dayPart,
      timeSlotChoice: Times && Times.length > 0 ? Times[0] : {},
      userSelections: {
        ...userSelections,
        dayPart,
        timeslot: Times && Times.length > 0 ? Times[0] : {},
      },
    });
    this.checkIfShowNextBtn();
  };

  handleTimeSlotChange = slot => {
    const { userSelections } = this.state;
    this.setState({
      timeSlotChoice: slot,
      userSelections: {
        ...userSelections,
        timeslot: slot,
      },
    });
    this.checkIfShowNextBtn();
  };

  handleOrderOptionsNextBtn = () => {
    //check choices
    const { userSelections } = this.state;
    this.props.receiveCustomerOrderOption(userSelections);
    const {
      kioskSetting: {
        appSetting: { ScanItemMode },
      },
    } = this.props;
    if (ScanItemMode === 1) {
      this.props.history.replace('/scan');
    } else {
      this.props.history.replace('/menu');
    }
  };

  componentDidMount = () => {
    this.init();
    this.getImageFolder();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isIdle()) {
      this.onIdleTimerFinish();
    } else {
      this.props.reset();
    }
  }

  render() {
    const {
      imageDir,
      onDisplay,
      showOrderBtn,
      selectedOption,
      scheduleOptionSelected,
      dateChoice,
      dayPartChoice,
      timeSlotChoice,
      orderOptions,
      userSelections,
    } = this.state;
    const { orderOption, clientStyle } = this.props;
    const screen = window.innerWidth;
    const {
      kioskSetting: {
        imgSetting: { OrderOptionBackground },
      },
    } = this.props;
    let src = '';
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      //dev mode
      if (OrderOptionBackground && imageDir) {
        src = `http://localhost:4000/${imageDir}/${OrderOptionBackground}`;
      } else {
        src = `${process.env.PUBLIC_URL}/assets/orderOption/orderoption.jpg`;
      }
    } else {
      //production mode
      if (OrderOptionBackground && imageDir) {
        src = `${process.env.PUBLIC_URL}/${imageDir}/${OrderOptionBackground}`;
      } else {
        src = `${process.env.PUBLIC_URL}/assets/orderOption/orderoption.jpg`;
      }
    }

    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };

    return (
      <div className="order-options-wrapper">
        {/* Page Background */}
        <div className="order-options-background-container">
          {clientStyle.storeNumber === '3932' || clientStyle.storeNumber === '5960' ? (
            <video width="100%" height="100%" autoPlay muted loop style={{ objectFit: 'cover' }}>
              <source
                src={`${process.env.PUBLIC_URL}/assets/videos/tin_background.webm`}
                type="video/webm"
              />
            </video>
          ) : (
            <img
              src={src}
              alt={''}
              style={{ height: '100vh', width: '100%', objectFit: 'cover' }}
            />
          )}
        </div>
        {/* Content Row */}
        <Row
          style={{
            width: screen >= 1366 ? 1200 : 950,
            height: screen === 1080 ? 1200 : screen === 1920 ? 950 : 720,
            backgroundColor:
              clientStyle.storeNumber === '3932' || clientStyle.storeNumber === '5960'
                ? 'rgba(250, 246, 232, 0.5)'
                : 'rgba(255, 255, 255, 0.7)',
            borderRadius: 16,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div className="order-options-content-wrapper">
            <div
              className="order-options-cancel-btn-wrapper"
              style={{ ...rowStyle }}
              onClick={this.handleOrderOptionsCancel}
            >
              <FontAwesomeIcon icon={faX} size="2x" />
            </div>
            {onDisplay > 1 && (
              <div
                className="order-options-back-btn-wrapper"
                style={{ ...rowStyle }}
                onClick={this.handleOrderOptionsBack}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="2x" />
              </div>
            )}
            {((onDisplay === 1 && userSelections.venue) ||
              (onDisplay === 2 && userSelections.orderOption)) && (
              <div
                className="order-options-forward-btn-wrapper"
                style={{
                  ...rowStyle,
                }}
                onClick={this.handleForwardArrow}
              >
                <FontAwesomeIcon icon={faArrowRight} size="2x" />
              </div>
            )}

            {orderOption.length === 0 ? (
              <div className="order-options-no-order-option">
                <h2 style={{ ...titleStyle }}>There is no active order option...</h2>
              </div>
            ) : (
              <Col span={24}>
                <Row>
                  <SwitchTransition mode="out-in">
                    <CSSTransition
                      key={onDisplay}
                      timeout={500}
                      classNames="order-option-content-switch"
                    >
                      <>
                        {onDisplay === 1 && (
                          <>
                            {/* <button onClick={this.handleVenueClick}>Venue</button> */}
                            <OrderOptionVenue
                              venue={userSelections.venue}
                              orderOption={orderOption}
                              onVenueClick={venue => this.handleVenueOptionChange(venue)}
                            />
                          </>
                        )}
                        {onDisplay === 2 && (
                          <OrderOptionStepOne
                            selectedOption={selectedOption}
                            orderOption={orderOptions}
                            onOrderOptionStepOneSelectionChange={option => {
                              this.handleOrderOptionStepOneSelectionChange(option);
                            }}
                          />
                        )}
                        {onDisplay === 3 && (
                          <OrderOptionStepTwo
                            screen={screen}
                            orderOption={orderOptions}
                            selectedOption={selectedOption}
                            scheduleOption={selectedOption?.DisableOrderAsap === 0}
                            scheduleOptionSelected={scheduleOptionSelected}
                            dateChoice={dateChoice}
                            dayPartChoice={dayPartChoice}
                            timeSlotChoice={timeSlotChoice}
                            // scrollPickerChoice={scrollPickerChoice}
                            onDateChange={date => this.handleDateChange(date)}
                            onScheduleOptionChange={key => this.handleScheduleOptionChange(key)}
                            onDayPartChange={key => this.handleDayPartChange(key)}
                            onTimeSlotChange={key => this.handleTimeSlotChange(key)}
                            // onScrollPickerChange={key => this.handleScrollPickerChange(key)}
                          />
                        )}
                      </>
                    </CSSTransition>
                  </SwitchTransition>
                </Row>
              </Col>
            )}
          </div>
          <div className="order-option-order-btn-wrapper">
            <CSSTransition
              in={showOrderBtn}
              timeout={1000}
              classNames="order-option-order-btn-transition"
              unmountOnExit
            >
              <div className="order-option-order-btn">
                <Button
                  width={240}
                  style={{ ...rowStyle }}
                  textStyle={{ fontSize: 20 }}
                  borderRadius={5}
                  onClick={this.handleOrderOptionsNextBtn}
                >
                  Next
                </Button>
              </div>
            </CSSTransition>
          </div>
        </Row>
      </div>
    );
  }
}

export default connect(
  state => ({
    orderOption: state.orderOption,
    user: state.user,
    loginType: state.loginType,
    kioskSetting: state.kioskSetting,
    appMode: state.appMode,
    clientStyle: state.clientStyle,
  }),
  {
    resetUser,
    deleteGuestName,
    receiveOrderOption,
    clearOrderItems,
    clearScannedOrderItems,
    receiveCustomerOrderOption,
    resetCustomerOrderOption,
    resetLoginType,
    receiveAppMode,
    resetAppMode,
    deleteGuestPhone,
    deleteGuestIns,
  },
)(withIdleTimer(OrderOptions));
