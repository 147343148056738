import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input } from 'antd';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import Keyboard from 'react-simple-keyboard';
import Button from '../../components/Button';

class LoginAccount extends Component {
  state = {
    error: '',
    input: {},
    inputName: '',
    layout: 'default',
    shift: false,
    cap: false,
  };

  ref = React.createRef();
  refPassword = React.createRef();

  handleAccountLoginCancel = () => {
    this.setState({ input: {}, error: '' });
    this.keyboard.clearInput();
    this.props.onAccountLoginCancel();
  };

  handleOK = () => {
    const { input } = this.state;
    if (!input.hasOwnProperty('username') || input.username.trim() === '') {
      this.setState({ error: 'Please enter your username' });
      this.ref.current.focus();
      return;
    }

    if (!input.hasOwnProperty('password') || input.password.trim() === '') {
      this.setState({ error: 'Please enter your password' });
      this.refPassword.current.focus();
      return;
    }
    const obj = {};
    obj.username = input.username;
    obj.password = input.password;
    this.props.onAccountLoginUserChange(obj);
    this.setState({ error: '', input: {} });
    this.keyboard.clearInput('username');
    this.keyboard.clearInput('password');
  };

  keyboardOptions = {
    ...this.commonKeyboardOptions,
    layout: {
      default: [
        '1 2 3 4 5 6 7 8 9 0 - {bksp}',
        'q w e r t y u i o p [ ] \\',
        "{lock} a s d f g h j k l ; '",
        '{shift} z x c v b n m , . /',
        '.com @ {space}',
      ],
      shift: [
        '! @ # $ % ^ &amp; * ( ) _ + {bksp}',
        'Q W E R T Y U I O P { } |',
        '{lock} A S D F G H J K L : "',
        '{shift} Z X C V B N M &lt; &gt; ?',
        '.com @ {space}',
      ],
    },
  };

  onKeyboardChange = input => {
    this.setState({ input });
  };

  onKeyPress = button => {
    if (button === '{shift}' || button === '{lock}') this.handleShift();
  };

  onKeyReleased = button => {
    const { error } = this.state;
    if (error !== '') {
      this.setState({ error: '' });
    }
    const { shift, cap } = this.state;
    if (button === '{shift}') {
      this.setState({ shift: true });
      return;
    }
    if (button === '{lock}') {
      this.setState({ cap: !this.state.cap });
      return;
    }
    if (button !== '{shift}' && button !== '{lock}' && shift && !cap) {
      this.setState({ layout: 'default' });
    }
  };

  handleShift = () => {
    const { layout } = this.state;
    this.setState({ layout: layout === 'default' ? 'shift' : 'default' });
  };

  handleUnameInputOnFocus = () => {
    this.setState({ inputName: 'username' });
  };

  handlePwdInputOnFocus = () => {
    this.setState({ inputName: 'password' });
  };

  handleUnameInputChange = e => {
    const { input } = this.state;
    input.username = e.target.value;
    this.setState({ input });
    if (e.target.value.trim().length > 0) {
      this.setState({ error: '' });
    }
    this.keyboard.setInput(e.target.value, 'username');
  };

  handlePwdInputChange = e => {
    const { input } = this.state;
    input.password = e.target.value;
    if (e.target.value.trim().length > 0) {
      this.setState({ error: '' });
    }
    this.setState({ input });
    this.keyboard.setInput(e.target.value, 'password');
  };

  //Handle physical keyboard "Enter"
  handlePEnterKey = e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      this.handleOK();
    }
  };

  componentDidMount() {
    this.ref.current.focus();
  }

  componentDidUpdate(prevProps, prevState) {
    const { getFocus } = this.props;
    if (getFocus !== prevProps.getFocus) {
      this.ref.current.focus();
    }
  }

  render() {
    const { error, input, layout, inputName } = this.state;
    const { clientStyle } = this.props;
    const descStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontTwo,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };
    return (
      <div>
        {/* Error Row */}
        <Row
          style={{ padding: 15, height: 30, fontWeight: 'bold', color: '#8b0000', fontSize: 18 }}
          justify="center"
        >
          <Col>
            <SwitchTransition>
              <CSSTransition key={error} timeout={500} classNames="login-account-error">
                <Row style={{ ...descStyle }}>{error}</Row>
              </CSSTransition>
            </SwitchTransition>
          </Col>
        </Row>
        {/* Input Group */}
        <Row style={{ marginTop: 50 }}>
          <Col span={6}></Col>
          <Col span={12}>
            <Row justify="center">
              <Col span={24}>
                <Row>
                  <Input
                    ref={this.ref}
                    autoFocus={true}
                    size="large"
                    placeholder="Please enter your username"
                    spellCheck={false}
                    value={input.username}
                    onFocus={this.handleUnameInputOnFocus}
                    onChange={this.handleUnameInputChange}
                    onKeyUp={this.handlePEnterKey}
                    style={{ ...descStyle }}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={6}></Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={6}></Col>
          <Col span={12}>
            <Row justify="center">
              <Col span={24}>
                <Row>
                  <Input
                    ref={this.refPassword}
                    size="large"
                    placeholder="Please enter your password"
                    value={input.password}
                    onFocus={this.handlePwdInputOnFocus}
                    type="password"
                    onChange={this.handlePwdInputChange}
                    onKeyUp={this.handlePEnterKey}
                    style={{ ...descStyle }}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={6}></Col>
        </Row>
        {/* Btns Row */}
        <Row justify="space-between" style={{ margin: '30px 0' }}>
          <Col span={6}></Col>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Row justify="space-between">
                  <Button
                    height={50}
                    onClick={this.handleAccountLoginCancel}
                    style={{ ...rowStyle }}
                    borderRadius={5}
                    textStyle={{ fontSize: 16 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    height={50}
                    style={{ ...rowStyle }}
                    textStyle={{ fontSize: 16 }}
                    borderRadius={5}
                    onClick={this.handleOK}
                  >
                    OK
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={6}></Col>
        </Row>
        {/* Keyboard */}
        <Row style={{ position: 'absolute', bottom: 0, width: '100%' }}>
          <Keyboard
            baseClass={'simple-keyboard-main'}
            keyboardRef={r => (this.keyboard = r)}
            layoutName={layout}
            onChangeAll={this.onKeyboardChange}
            onKeyPress={this.onKeyPress}
            onKeyReleased={this.onKeyReleased}
            inputName={inputName}
            theme={'hg-theme-default hg-layout-default  btm-border-16'}
            preventMouseDownDefault={true}
            {...this.keyboardOptions}
          />
        </Row>
      </div>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(LoginAccount);
