import React, { Component } from 'react';
import { Row, Col, Divider, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Keyboard from 'react-simple-keyboard';
import Button from '../Button';

class OrderSplitBillMain extends Component {
  state = {
    error: '',
    input: '',
    keyboardVisible: false,
    fractionSelectorVisible: false,
    denominator: 5,
  };

  refInput = React.createRef();

  keyboardOptions = {
    ...this.commonKeyboardOptions,
    layout: {
      default: ['1 2 3', '4 5 6', '7 8 9', '. 0 {enter}', '{bksp}'],
    },
    buttonTheme: [
      {
        class: 'hg-primary',
        buttons: '{enter}',
      },
    ],
    display: {
      '{bksp}': 'Del',
      '{enter}': 'OK',
    },
  };

  handleInputFocused = () => {
    this.setState({ keyboardVisible: true, input: '' });
  };

  onKeyboardChange = input => {
    let str;
    if (input.indexOf('D') >= 0) {
      str = input.slice(0, input.indexOf('D'));
    } else {
      str = input;
    }
    str = str.replace(/^0+\./g, '0.');
    str = str.match(/^0+[1-9]+/) ? str.replace(/^[0]+/g, '') : str;
    str = str.replace(/[^\d.]/g, '');
    str = str.replace(/\.{2,}/g, '.');
    str = str.replace(/^\./g, '0.');
    str = str.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3');

    this.setState({ input: str });
  };

  onKeyReleased = button => {
    const { error } = this.state;
    if (error !== '') {
      this.setState({ error: '' });
    }
    if (button === '{enter}') {
      this.handleInputBlurred();
      this.setState({ keyboardVisible: false });
    }
  };

  handleInputChange = e => {
    let str = e.target.value;
    str = str.replace(/^0+\./g, '0.'); //Remove excessive 0s before point if smaller than 1
    str = str.match(/^0+[1-9]+/) ? str.replace(/^[0]+/g, '') : str; //Remove excessive 0s before integer
    str = str.replace(/[^\d.]/g, ''); //Remove all chars other than numbers and point
    str = str.replace(/\.{2,}/g, '.'); //Only keep the first point
    str = str.replace(/^\./g, '0.'); //Add one 0 if first digit is point
    str = str.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //Max two decimal places no rounding
    if (e.target.value.trim().length > 0) {
      this.setState({ error: '' });
    }
    const input = str;
    this.setState({ input });
    this.keyboard && this.keyboard.setInput(input);
  };

  handleInputBlurred = () => {
    let { input } = this.state;
    const arr = input.split('.');
    if (arr.length === 1 && arr[0]) {
      input = input + '.00';
    }
    if (arr.length > 1) {
      if (arr[1].length < 1) {
        input = input + '00';
      }
      if (arr[1].length === 1) {
        input = input + '0';
      }
    }
    this.setState({ input });
    this.keyboard && this.keyboard.setInput(input);
  };

  handlePayRemainingBtn = () => {
    const { balance: input } = this.props;
    this.setState({ input, error: '' });
    this.keyboard && this.keyboard.setInput(input);
    this.state.fractionSelectorVisible && this.setState({ fractionSelectorVisible: false });
  };

  handlePaymentBtns = type => {
    if (this.state.fractionSelectorVisible) return;
    const { input } = this.state;
    const { balance } = this.props;
    if (parseFloat(input) > parseFloat(balance)) {
      this.setState({ error: "Amount mustn't be bigger than Remaining Balance" });
      return;
    }
    if (!input || parseFloat(input) < 0.01) {
      this.setState({ error: 'Please enter a valid amount' });
      return;
    }
    const amt = parseFloat(input).toFixed(2);
    this.props.onSelectPayment(type, amt);
    this.fillSplitAmount();
  };

  handleClickOutside = e => {
    if (this.state.keyboardVisible) {
      if (!(e.target.nodeName === 'INPUT') && !e.target.classList.contains('hg-button')) {
        this.setState({ keyboardVisible: false });
        this.handleInputBlurred();
      }
    }
  };

  handleFractionSelection = denominator => {
    this.state.fractionSelectorVisible && this.setState({ fractionSelectorVisible: false });
    this.setState({ denominator, error: '' }, () => {
      this.fillSplitAmount();
    });
  };

  fillSplitAmount = () => {
    const { denominator } = this.state;
    const { checkSum, balance } = this.props;
    const {
      OrderSummary: { Balance: checkTotal },
    } = checkSum;
    const amt = parseInt((parseFloat(checkTotal) * 100) / denominator) / 100;

    const tempTotal = parseFloat((amt * denominator).toFixed(2));
    const diff = parseFloat((checkTotal - tempTotal).toFixed(2));
    if (parseFloat(balance) > parseInt(amt * 100 + diff * 100) / 100) {
      this.setState({ input: amt.toString() }, () => {
        this.handleInputBlurred();
      });
    } else {
      this.setState({ input: parseFloat(balance).toFixed(2) });
    }

    // const remainder = (parseInt(parseFloat(checkTotal) * 100) % denominator) / 100;
    // const difference = parseInt(parseFloat(balance) * 100 - amt * 100) / 100;
    // if (difference > remainder) {
    //   this.setState({ input: amt.toString() }, () => {
    //     this.handleInputBlurred();
    //   });
    // }
    // if (difference <= remainder && difference >= 0) {
    //   //If user intervenes and picks a different fraction, remainder changes and may cause the addition to be bigger than Remaining Balance
    //   const str = ((amt * 100 + remainder * 100) / 100).toFixed(2);
    //   this.setState({ input: str });
    // }
    // if (difference <= remainder && difference < 0) {
    //   this.setState({ input: parseFloat(balance).toFixed(2) });
    // }
  };

  handleFractionMoreBtn = () => {
    !this.state.fractionSelectorVisible && this.setState({ fractionSelectorVisible: true });
    !this.state.error !== '' && this.setState({ error: '' });
    return;
  };

  handleFractionOK = () => {
    this.setState({ fractionSelectorVisible: false });
    this.fillSplitAmount();
  };

  handleFractionMinus = () => {
    const { denominator } = this.state;
    denominator > 2 && this.setState({ denominator: denominator - 1 });
    denominator <= 2 && this.setState({ denominator: 2 });
  };

  handleFractionPlus = () => {
    const { denominator } = this.state;
    denominator < 20 && this.setState({ denominator: denominator + 1 });
    denominator >= 20 && this.setState({ denominator: 20 });
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const { balance } = this.props;
    if (balance !== prevProps.balance) {
      this.fillSplitAmount();
    }
  }

  render() {
    const { checkSum, balance, splitPayments } = this.props;
    const { error, input, keyboardVisible, fractionSelectorVisible, denominator } = this.state;

    return (
      <Col span={24}>
        <Row>
          <Col span={10}>
            <Row>
              <Col span={16}>
                <h2 style={{ color: '#666' }}>Check Total: </h2>
              </Col>
              <Col span={8}>
                <Row>
                  <h2 style={{ color: '#666' }}>
                    {checkSum && checkSum.OrderSummary && checkSum.OrderSummary.Balance > 0
                      ? '$' + parseFloat(checkSum.OrderSummary.Balance).toFixed(2)
                      : ''}
                  </h2>
                </Row>
              </Col>
            </Row>
            <div className="order-split-bill-main-paid-list">
              {splitPayments && splitPayments.length > 0
                ? splitPayments.map(i => (
                    <Row align="middle" key={i.key}>
                      <Col span={16}>
                        <Row style={{ fontSize: 18, padding: 3 }} align="middle">
                          <FontAwesomeIcon icon={faCircleCheck} color="var(--secondary)" />
                          <span style={{ fontSize: 18, marginLeft: 8 }}>Payment Applied:</span>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row style={{ fontSize: 18, padding: 3 }}>
                          <span>{i.amount ? '$' + parseFloat(i.amount).toFixed(2) : ''}</span>{' '}
                        </Row>
                      </Col>
                    </Row>
                  ))
                : null}
            </div>
            <Divider style={{ marginBottom: 0 }} />
            <Row>
              <Col span={16}>
                <h2>
                  <i>Remaining Balance: </i>
                </h2>
              </Col>
              <Col span={8}>
                <Row>
                  <h2>
                    <i>{balance >= 0 ? '$' + parseFloat(balance).toFixed(2) : ''}</i>
                  </h2>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={14}>
            <Row align="middle" justify="space-between">
              <Col>
                <h3 style={{ fontSize: 22 }}>Please make a selection, or - </h3>
              </Col>
              <Col>
                <Button
                  width={160}
                  height={50}
                  style={{ backgroundColor: 'var(--primary)' }}
                  textStyle={{ color: 'white', fontSize: 18 }}
                  borderRadius={5}
                  onClick={this.handlePayRemainingBtn}
                >
                  Pay Remaining
                </Button>
              </Col>
            </Row>
            <Divider style={{ borderColor: 'transparent', margin: 10 }} />
            <Row align="middle" justify="space-between" style={{ position: 'relative' }}>
              <Col>
                <Button
                  width={50}
                  height={50}
                  borderRadius="50%"
                  style={{ backgroundColor: 'var(--primary)' }}
                  textStyle={{ color: 'white', fontSize: 18 }}
                  onClick={() => {
                    this.handleFractionSelection(2);
                  }}
                >
                  1/2
                </Button>
              </Col>
              <Col>
                <Button
                  width={50}
                  height={50}
                  borderRadius="50%"
                  style={{ backgroundColor: 'var(--primary)' }}
                  textStyle={{ color: 'white', fontSize: 18 }}
                  onClick={() => {
                    this.handleFractionSelection(3);
                  }}
                >
                  1/3
                </Button>
              </Col>
              <Col>
                <Button
                  width={50}
                  height={50}
                  borderRadius="50%"
                  style={{ backgroundColor: 'var(--primary)' }}
                  textStyle={{ color: 'white', fontSize: 18 }}
                  onClick={() => {
                    this.handleFractionSelection(4);
                  }}
                >
                  1/4
                </Button>
              </Col>
              <Col>
                <Button
                  width={50}
                  height={50}
                  borderRadius="50%"
                  style={{ backgroundColor: 'var(--primary)' }}
                  textStyle={{ color: 'white', fontSize: 18 }}
                  onClick={() => {
                    this.handleFractionSelection(5);
                  }}
                >
                  1/5
                </Button>
              </Col>
              <Col>
                <Button
                  width={50}
                  height={50}
                  borderRadius="50%"
                  style={{ backgroundColor: 'var(--primary)' }}
                  textStyle={{ color: 'white', fontSize: 18 }}
                  onClick={() => {
                    this.handleFractionSelection(6);
                  }}
                >
                  1/6
                </Button>
              </Col>
              <Col>
                <Button
                  width={50}
                  height={50}
                  borderRadius="50%"
                  style={{ backgroundColor: 'var(--primary)' }}
                  textStyle={{ color: 'white', fontSize: 18 }}
                  onClick={() => {
                    this.handleFractionSelection(7);
                  }}
                >
                  1/7
                </Button>
              </Col>
              <Col>
                <Button
                  width={50}
                  height={50}
                  borderRadius="50%"
                  style={{ backgroundColor: 'var(--primary)' }}
                  textStyle={{ color: 'white', fontSize: 15 }}
                  onClick={this.handleFractionMoreBtn}
                >
                  More
                </Button>
              </Col>
              {/* Fraction selector Row */}
              {fractionSelectorVisible ? (
                <div
                  style={{
                    position: 'absolute',
                    top: '100%',
                    left: '50%',
                    transform: 'translate(-50%, 50%)',
                    zIndex: 3,
                    background: 'rgba(255, 255, 255, 0.7)',
                    borderRadius: 12,
                    backdropFilter: 'blur(10px)',
                    WebkitBackdropFilter: 'blur(10px)',
                    width: '100%',
                    height: 80,
                    boxShadow: ' 1px 1px 6px 0px rgba(0,0,0,0.6)',
                    WebkitBoxShadow: ' 1px 1px 6px 0px rgba(0,0,0,0.6)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Row gutter={32} align="middle">
                    <Col>
                      <Button
                        width={50}
                        height={50}
                        style={{ backgroundColor: 'var(--primary)', color: 'white' }}
                        borderRadius={5}
                        onClick={this.handleFractionMinus}
                      >
                        <FontAwesomeIcon icon={faMinus} size="2xl" />
                      </Button>
                    </Col>
                    <Col>
                      <Row
                        style={{
                          width: 150,
                          height: 50,
                          border: '1px solid rgba(0, 0, 0, 0.3)',
                          padding: '0 15px',
                        }}
                      >
                        <Col span={8}>
                          <Row style={{ fontSize: 32 }} justify="center">
                            1
                          </Row>
                        </Col>
                        <Col span={8}>
                          <Row style={{ fontSize: 32 }} justify="center">
                            /
                          </Row>
                        </Col>
                        <Col span={8}>
                          <Row style={{ fontSize: 32 }} justify="center">
                            {denominator}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Button
                        width={50}
                        height={50}
                        style={{ backgroundColor: 'var(--primary)', color: 'white' }}
                        borderRadius={5}
                        onClick={this.handleFractionPlus}
                      >
                        <FontAwesomeIcon icon={faPlus} size="2xl" />
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        width={120}
                        height={50}
                        style={{ backgroundColor: 'var(--primary)' }}
                        textStyle={{ color: 'white', fontSize: 18 }}
                        borderRadius={5}
                        onClick={this.handleFractionOK}
                      >
                        OK
                      </Button>
                    </Col>
                  </Row>
                </div>
              ) : null}
            </Row>
            <Divider style={{ borderColor: 'transparent', margin: 10 }} />
            <Row
              style={{
                height: 30,
                fontWeight: 'bold',
                color: '#8b0000',
                fontSize: 18,
                marginBottom: 20,
              }}
              justify="center"
            >
              <Col>
                <SwitchTransition>
                  <CSSTransition key={error} timeout={500} classNames="login-account-error">
                    <Row>{error}</Row>
                  </CSSTransition>
                </SwitchTransition>
              </Col>
            </Row>
            <Divider style={{ borderColor: 'transparent', margin: 3 }} />

            <Row justify="center">
              <Row style={{ position: 'relative' }} justify="center" gutter={32}>
                <Col>
                  <h3 style={{ fontSize: 22 }}>Or enter an amount - </h3>
                </Col>
                <Col>
                  <Row>
                    <Input
                      ref={this.refInput}
                      // autoFocus={true}
                      size="large"
                      prefix="$"
                      spellCheck={false}
                      value={input}
                      placeholder="0.00"
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocused}
                    />
                    <div
                      style={{
                        width: '100%',
                        position: 'absolute',
                        top: '50px',
                        left: 0,
                        zIndex: 2,
                      }}
                    >
                      {keyboardVisible ? (
                        <Keyboard
                          baseClass={'simple-keyboard-main'}
                          keyboardRef={r => (this.keyboard = r)}
                          layoutName={'default'}
                          onChange={this.onKeyboardChange}
                          // onKeyPress={this.onKeyPress}
                          onKeyReleased={this.onKeyReleased}
                          theme={'hg-theme-default hg-layout-default'}
                          // preventMouseDownDefault={true}
                          {...this.keyboardOptions}
                        />
                      ) : null}
                    </div>
                  </Row>
                </Col>
              </Row>
            </Row>
            <Divider style={{ borderColor: 'transparent' }} />
            {/* Payemnt Btns Row */}
            <Row>
              <Col span={12}>
                <Row justify="center">
                  <Button
                    width={250}
                    height={70}
                    icon="credit"
                    iconSize="2xl"
                    style={{ backgroundColor: 'var(--primary)' }}
                    borderRadius={5}
                    textStyle={{ color: 'white', fontSize: 18 }}
                    onClick={() => {
                      this.handlePaymentBtns(2);
                    }}
                  >
                    Credit Card
                  </Button>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify="center">
                  <Button
                    width={250}
                    height={70}
                    icon="gift"
                    iconSize="2xl"
                    style={{ backgroundColor: 'var(--primary)' }}
                    borderRadius={5}
                    textStyle={{ color: 'white', fontSize: 18 }}
                    onClick={() => {
                      this.handlePaymentBtns(4);
                    }}
                  >
                    Gift Card
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default OrderSplitBillMain;
