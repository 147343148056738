import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withIdleTimer } from 'react-idle-timer';
import { Row, Col, Divider, message } from 'antd';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Button from '../../components/Button';
import LoginGuest from '../../components/LoginGuest';
import LoginAccount from '../../components/LoginAccount';
import LoginCard from '../../components/LoginCard';
import Spin from '../../components/Spin';
import Modal from '../../components/Modal';
import LoadingModal from '../../components/LoadingModal';
import ErrorModal from '../../components/ErrorModal';
import { userCardLogin, userLogin, getOrderSummary, addPaymentToCheck, printBill } from '../../api';
import {
  receiveUser,
  receiveLoginType,
  resetUser,
  clearOrderItems,
  clearScannedOrderItems,
  receiveGuestName,
  deleteGuestName,
  deleteGuestPhone,
  deleteGuestIns,
  resetCustomerOrderOption,
  resetLoginType,
  receiveAppMode,
  resetAppMode,
} from '../../redux/actions';
import 'react-simple-keyboard/build/css/index.css';
import './index.css';

class Login extends Component {
  state = {
    guest: false,
    account: false,
    card: false,
    boxAnimation: false,
    getFocus: false,
    spinning: false,
    cardLoginInputDisabled: true,
    loadingModalVisible: false,
    loadingModalMsg: '',
    errorModalVisible: false,
    errorModalMsg: '',
    paymentSuccessModalVisible: false,
    paymentSuccessMsg: '',
    orderNum: '',
    paymentErrorModalVisible: false,
    paymentErrorMsg: '',
    receiptPrinting: 0,
  };

  init = () => {
    const {
      kioskSetting: {
        appSetting: { KioskLoginMode },
      },
    } = this.props || null;
    if (typeof KioskLoginMode === 'number') {
      this.handleOptionBtns(KioskLoginMode);
    }
  };

  //1: Guest; 2: Account; 3: Card
  handleOptionBtns = opt => {
    if (opt === 1) {
      this.setState({
        guest: true,
        account: false,
        card: false,
        boxAnimation: true,
        getFocus: !this.state.getFocus,
      });
    }
    if (opt === 2) {
      this.setState({
        guest: false,
        account: true,
        card: false,
        boxAnimation: true,
        getFocus: !this.state.getFocus,
      });
    }
    if (opt === 3) {
      this.setState({
        guest: false,
        account: false,
        card: true,
        boxAnimation: true,
        getFocus: !this.state.getFocus,
        cardLoginInputDisabled: false,
      });
    }
  };

  handleGuestNameChange = guestName => {
    this.props.receiveGuestName(guestName);
  };

  handleGuestLoginCancel = () => {
    const {
      kioskSetting: {
        appSetting: { KioskLoginMode },
      },
    } = this.props;

    if (KioskLoginMode !== 0) {
      this.props.history.replace('/');
    } else {
      this.setState({ boxAnimation: false });
    }
  };

  handleGuestLoginOK = async () => {
    const {
      kioskSetting: {
        appSetting: { EnableOrderOptionMapping, ScanItemMode },
      },
    } = this.props;
    this.props.receiveLoginType('Guest');
    if (EnableOrderOptionMapping) {
      this.props.history.replace('/orderOptions');
    } else {
      if (ScanItemMode === 0 || ScanItemMode === 2) {
        this.props.receiveAppMode(0);
        this.props.history.replace('/menu');
      }
      if (ScanItemMode === 1) {
        this.props.receiveAppMode(1);
        this.props.history.replace('/scan');
      }
    }
  };

  handleAccountLoginCancel = () => {
    const {
      kioskSetting: {
        appSetting: { KioskLoginMode },
      },
    } = this.props;
    if (KioskLoginMode !== 0) {
      this.props.history.replace('/');
    } else {
      this.setState({ boxAnimation: false });
    }
  };

  handleAccountLogin = async obj => {
    try {
      this.setState({ spinning: true });
      const re = await userLogin(obj.username, obj.password);
      if (!re || (re && re.result !== 'OK')) throw new Error(re.result);
      this.props.receiveUser(re.data);
      this.props.receiveLoginType('Account');
      this.setState({ spinning: false });
      this.navigateToNextPage();
    } catch (error) {
      this.setState({ spinning: false, getFocus: !this.state.getFocus });
      message.error(error.message, 8);
      this.handleOptionBtns(2);
    }
  };

  navigateToNextPage = () => {
    const {
      kioskSetting: {
        appSetting: { EnableOrderOptionMapping, ScanItemMode },
      },
    } = this.props;
    try {
      if (ScanItemMode === 1) {
        if (EnableOrderOptionMapping) {
          this.props.history.replace('/orderOptions');
        } else {
          this.props.receiveAppMode(1);
          this.props.history.replace('/scan');
        }
      } else {
        this.props.receiveAppMode(0);
        this.props.history.replace('/admin');
      }
    } catch (error) {
      message.error('Error navigating to the next page!');
    }
  };

  handleCardLogin = async cardInfo => {
    try {
      this.setState({ spinning: true });
      const re = await userCardLogin(cardInfo);
      if (!re || (re && re.result !== 'OK')) throw new Error(re.result);
      this.props.receiveUser(re.data);
      this.props.receiveLoginType('Card');
      this.setState({ spinning: false });
      if (re.isAutoCharge === 1) {
        this.handleAutoChargeCheckOut(re.data, re.itemInfo);
      } else {
        this.setState({ cardLoginInputDisabled: true });
        this.navigateToNextPage();
      }
    } catch (error) {
      this.setState({ spinning: false, getFocus: !this.state.getFocus });
      message.error(error.message, 8);
      this.handleOptionBtns(3);
    }
  };

  renderTimer = ({ remainingTime }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ fontSize: 40 }}>{remainingTime}</div>
      </div>
    );
  };

  handleOrderLogOut = () => {
    const { paymentSuccessModalVisible } = this.state;
    paymentSuccessModalVisible && this.setState({ paymentSuccessModalVisible: false });
    this.setState({ receiptPrinting: 0 });
    this.props.clearOrderItems();
    //sign out user
    this.props.resetUser();
    this.props.clearScannedOrderItems();
    this.props.deleteGuestName();
    this.props.deleteGuestPhone();
    this.props.deleteGuestIns();
    this.props.resetCustomerOrderOption();
    this.props.resetLoginType();
    this.props.resetAppMode();
    // this.props.history.push('./');
  };

  handlePrintReceipt = async () => {
    const { check, receiptPrinting } = this.state;
    console.log(check);
    if (receiptPrinting !== 0) return;
    try {
      this.setState({ receiptPrinting: 1 });
      const re = await printBill(check);
      if (!re || (re && re.result !== 'OK')) throw new Error(re.result);
      this.setState({ receiptPrinting: 2 });
    } catch (error) {
      this.setState({ receiptPrinting: 0 });
      message.error(error.message || 'Error printing receipt!', 3);
    }
  };

  handleAutoChargeCheckOut = async (user, item) => {
    this.props.pause();
    try {
      this.setState({
        loadingModalVisible: true,
        loadingModalMsg: 'Your order is being processed...',
      });
      const checkObj = {};
      checkObj.CheckNumber = -1;
      checkObj.Date = '';
      checkObj.EmpCode = '';
      checkObj.Shift = 1;
      checkObj.Time = '';
      checkObj.TableNum = '';
      checkObj.CustomerInfo = {};
      checkObj.Items = [item];
      checkObj.Adjustments = [];
      checkObj.PaymentInfos = [];
      checkObj.OrderSummary = {};
      checkObj.Error = null;
      checkObj.ErrorText = '';

      const re = await getOrderSummary(checkObj);
      if (re && re.Error === true && re.ErrorText) {
        this.setState(
          {
            errorModalMsg: re.ErrorText,
          },
          () => {
            this.setState({ errorModalVisible: true });
          },
        );
      } else if (re && re.Error === false && re.ErrorText === '') {
        const paymentInfo = {};
        paymentInfo.PaymentType = 3;
        paymentInfo.Amount = re?.OrderSummary?.Balance;
        paymentInfo.Tip = 0;
        paymentInfo.AcctNo = user?.CurAccountInfo?.AccountNumber;
        paymentInfo.ExpDate = '';
        paymentInfo.TransDate = '';
        paymentInfo.Description = '';
        paymentInfo.SubCargo = '';
        const res = await addPaymentToCheck(paymentInfo, re, []);
        if (res && res.Error === false && !res.ErrorText) {
          this.setState({
            check: res,
            loadingModalVisible: false,
            paymentSuccessModalVisible: true,
            paymentSuccessMsg: 'Thank you for your order!',
            orderNum: res.CheckNumber,
          });
        }
        if (res && res.Error === true && res.ErrorText) {
          if (res.IsOrderProcessed === 1) {
            this.setState({
              loadingModalVisible: false,
              paymentErrorMsg: res.ErrorText,
              paymentErrorModalVisible: true,
            });
            return;
          }
          this.setState({
            loadingModalVisible: false,
            errorModalMsg: res.ErrorText,
            errorModalVisible: true,
          });
        }
      }
    } catch (error) {}

    this.props.reset();
  };

  handlePaymentFailModalOK = () => {
    this.setState({ errorModalVisible: false, getFocus: !this.state.getFocus });
    this.handleOptionBtns(3);
  };

  handlePaymentErrorModalOK = () => {
    this.setState({ paymentErrorModalVisible: false, paymentErrorMsg: '' });
    this.handleOrderLogOut();
  };

  handlePaymentSuccessTimerFinish = () => {
    this.setState({ paymentSuccessModalVisible: false }, () => {
      this.handleOrderLogOut();
    });
  };

  handleCardLoginCancel = () => {
    const {
      kioskSetting: {
        appSetting: { KioskLoginMode },
      },
    } = this.props;
    if (KioskLoginMode !== 0) {
      this.props.history.replace('/');
    } else {
      this.setState({ boxAnimation: false, cardLoginInputDisabled: true });
    }
  };

  componentDidMount = () => {
    this.init();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.isIdle()) {
      this.props.onIdleTimerFinish && this.props.onIdleTimerFinish();
    } else {
      this.props.reset && this.props.reset();
    }
    const { kioskSetting } = this.props;
    if (kioskSetting !== prevProps.kioskSetting) {
      this.init();
    }
  };

  render() {
    const {
      guest,
      account,
      card,
      boxAnimation,
      getFocus,
      spinning,
      cardLoginInputDisabled,
      loadingModalMsg,
      loadingModalVisible,
      errorModalVisible,
      errorModalMsg,
      paymentSuccessModalVisible,
      paymentSuccessMsg,
      orderNum,
      paymentErrorModalVisible,
      paymentErrorMsg,
      receiptPrinting,
    } = this.state;
    const { kioskSetting, clientStyle } = this.props;
    const { appSetting } = kioskSetting;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };
    return (
      <Spin spinning={spinning} spinnerColor="var(--primary)">
        <div className="login-wrapper">
          {/* Page Background */}
          <div className="login-background-container">
            {clientStyle.storeNumber === '3932' || clientStyle.storeNumber === '5960' ? (
              <video width="100%" height="100%" autoPlay muted loop style={{ objectFit: 'cover' }}>
                <source
                  src={`${process.env.PUBLIC_URL}/assets/videos/tin_background.webm`}
                  type="video/webm"
                />
              </video>
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/assets/login/loginBackground.jpg`}
                alt={''}
                style={{ height: '100vh', width: '100%', objectFit: 'cover' }}
              />
            )}
          </div>
          <div className="login-center-box">
            <div
              className="login-center-box-content"
              style={{
                animation: boxAnimation
                  ? 'flip 0.4s ease 0ms forwards'
                  : 'flipBack 0.4s ease 0ms forwards',
              }}
            >
              {appSetting && appSetting.KioskLoginMode !== 0 ? null : (
                <div
                  className="login-options-box"
                  style={{
                    backgroundColor:
                      clientStyle.storeNumber === '3932' || clientStyle.storeNumber === '5960'
                        ? 'rgba(250, 246, 232, 0.5)'
                        : 'rgba(255, 255, 255, 0.7)',
                  }}
                >
                  {appSetting &&
                  appSetting.AllowGuestOrder === 1 &&
                  ((appSetting && appSetting.KioskLoginMode === 0) ||
                    (appSetting && appSetting.KioskLoginMode === 1)) ? (
                    <Button
                      width={300}
                      height={100}
                      style={{
                        ...rowStyle,
                        marginBottom: 30,
                      }}
                      textStyle={{
                        fontSize: 20,
                      }}
                      borderRadius={6}
                      icon="guest"
                      iconSize="2x"
                      onClick={() => {
                        this.handleOptionBtns(1);
                      }}
                    >
                      Guest Login
                    </Button>
                  ) : null}
                  {(appSetting &&
                    (appSetting.KioskLoginMode === 0 || appSetting.KioskLoginMode === 2)) ||
                  (appSetting &&
                    appSetting.KioskLoginMode === 1 &&
                    appSetting.AllowGuestOrder === 0) ? (
                    <Button
                      width={300}
                      height={100}
                      style={{ ...rowStyle, marginBottom: 30 }}
                      textStyle={{ fontSize: 20 }}
                      borderRadius={6}
                      icon="user"
                      iconSize="2x"
                      onClick={() => {
                        this.handleOptionBtns(2);
                      }}
                    >
                      Account Login
                    </Button>
                  ) : null}
                  {appSetting &&
                  (appSetting.KioskLoginMode === 0 || appSetting.KioskLoginMode === 3) ? (
                    <Button
                      width={300}
                      height={100}
                      style={{ ...rowStyle, marginBottom: 30 }}
                      textStyle={{ fontSize: 20 }}
                      borderRadius={6}
                      icon="card"
                      iconSize="2x"
                      onClick={() => {
                        this.handleOptionBtns(3);
                      }}
                    >
                      Card Login
                    </Button>
                  ) : null}
                </div>
              )}

              <div
                className="login-operations-box"
                style={{
                  backgroundColor:
                    clientStyle.storeNumber === '3932' || clientStyle.storeNumber === '5960'
                      ? 'rgba(250, 246, 232, 0.5)'
                      : 'rgba(255, 255, 255, 0.7)',
                }}
              >
                {guest && (
                  <LoginGuest
                    onGuestLoginCancel={this.handleGuestLoginCancel}
                    onGuestNameChange={guestName => this.handleGuestNameChange(guestName)}
                    onGuestLoginOK={this.handleGuestLoginOK}
                    getFocus={getFocus}
                  />
                )}
                {account && (
                  <LoginAccount
                    getFocus={getFocus}
                    onAccountLoginCancel={this.handleAccountLoginCancel}
                    onAccountLoginUserChange={obj => this.handleAccountLogin(obj)}
                  />
                )}
                {card && (
                  <LoginCard
                    cardLoginInputDisabled={cardLoginInputDisabled}
                    getFocus={getFocus}
                    onCardLoginCancel={this.handleCardLoginCancel}
                    onCardLogin={cardInfo => this.handleCardLogin(cardInfo)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Auto Charge Check out Loading Modal */}
        <LoadingModal visible={loadingModalVisible} msg={loadingModalMsg} />
        {/* Payment Failure Modal */}
        <ErrorModal
          visible={errorModalVisible}
          title={'Payment or order cannot be processed'}
          msg={errorModalMsg}
          onOK={this.handlePaymentFailModalOK}
        />
        {/* Payment Error Modal */}
        <ErrorModal
          visible={paymentErrorModalVisible}
          title={'An Error has occurred'}
          showDueTo={false}
          msg={paymentErrorMsg}
          onOK={this.handlePaymentErrorModalOK}
        />
        {/* Payment Successful Modal */}
        <Modal
          visible={paymentSuccessModalVisible}
          closable={false}
          width={520}
          maskClosable={false}
          title={null}
          footer={null}
          destroyOnClose={true}
          showCloseBtn={false}
        >
          <Row justify="center">
            <FontAwesomeIcon
              icon={faCircleCheck}
              // size="xl"
              color="#70cd5f"
              style={{
                width: window.innerHeight > 768 ? 160 : 120,
                height: window.innerHeight > 768 ? 160 : 120,
                marginBottom: 10,
              }}
            />
          </Row>
          <Row justify="center">
            <div
              style={{
                marginLeft: 20,
                ...titleStyle,
                whiteSpace: 'pre-wrap',
                fontSize: 18,
                fontWeight: 'bolder',
                textAlign: 'center',
                maxWidth: 400,
              }}
            >
              {paymentSuccessMsg}
            </div>
          </Row>
          <Row
            style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, ...titleStyle }}
            justify="center"
            align="middle"
          >
            Order # {orderNum}
          </Row>
          <Row justify="center" style={{ marginTop: 10 }}>
            <div
              className="timer-wrapper"
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CountdownCircleTimer
                isPlaying
                duration={
                  appSetting?.OrderCompletedCountDown ? appSetting?.OrderCompletedCountDown : 15
                }
                colors={[clientStyle.primary]}
                // colorsTime={[15, 0]}
                onComplete={this.handlePaymentSuccessTimerFinish}
              >
                {this.renderTimer}
              </CountdownCircleTimer>
            </div>
          </Row>
          {appSetting?.AutoPrintReceipt === 0 ? (
            <>
              <Row align="middle" style={{ marginTop: 20 }}>
                <Col span={5}></Col>
                <Col span={14}>
                  <Row justify="center">
                    <Button
                      width={250}
                      style={{ ...rowStyle }}
                      textStyle={{ fontSize: 18 }}
                      borderRadius={5}
                      onClick={this.handlePrintReceipt}
                    >
                      Print Receipt
                    </Button>
                  </Row>
                </Col>
                <Col span={5}>
                  {receiptPrinting === 1 ? (
                    <FontAwesomeIcon icon={faSpinner} color="var(--primary)" size="3x" spin />
                  ) : receiptPrinting === 2 ? (
                    <FontAwesomeIcon icon={faCircleCheck} color="#70cd5f" size="3x" />
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <Divider style={{ borderColor: 'transparent' }} />
            </>
          ) : null}
          <Row justify="center" style={{ marginTop: 20 }}>
            <Button
              width={250}
              style={{ ...rowStyle }}
              textStyle={{ fontSize: 18 }}
              borderRadius={5}
              onClick={this.handleOrderLogOut}
            >
              Done
            </Button>
          </Row>
        </Modal>
      </Spin>
    );
  }
}
export default connect(
  state => ({
    orderOption: state.orderOption,
    kioskSetting: state.kioskSetting,
    guestName: state.guestName,
    clientStyle: state.clientStyle,
  }),
  {
    receiveUser,
    receiveLoginType,
    receiveGuestName,
    resetUser,
    clearOrderItems,
    clearScannedOrderItems,
    deleteGuestName,
    deleteGuestPhone,
    deleteGuestIns,
    resetCustomerOrderOption,
    resetLoginType,
    receiveAppMode,
    resetAppMode,
  },
)(withIdleTimer(Login));
