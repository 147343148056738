import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, message } from 'antd';
import { withIdleTimer } from 'react-idle-timer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import {
  resetUser,
  clearOrderItems,
  clearScannedOrderItems,
  deleteGuestName,
  resetCustomerOrderOption,
  resetLoginType,
} from '../../redux/actions';
import { getListViewPage } from '../../api';
import Spin from '../../components/Spin';
import MenuScroller from '../../components/MenuScroller';
import MenuPicker from '../../components/MenuPicker';

import './index.css';

class Preview extends Component {
  state = {
    spinning: false,
    view: {},
    tabId: null,
  };

  getView = async () => {
    const { viewName } = this.props.location.state || '';
    try {
      this.setState({ spinning: true });
      const re = await getListViewPage(viewName);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false, view: re.data });
    } catch (error) {
      message.error('Error obtaining view!', 3);
    }
  };

  handleCloseBtn = () => {
    this.props.history.replace('/admin/vieweditor');
  };

  handleScrollerSelection = id => {
    this.setState({ tabId: id });
  };

  componentDidMount = () => {
    this.getView();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isIdle()) {
      this.onIdleTimerFinish();
    } else {
      this.props.reset();
    }
  }

  render() {
    const { spinning, view, tabId } = this.state;
    const screen = window.innerWidth;
    const { Tabs } = view;
    const tabCss = view?.CustomizedCss?.find(i => i.Type === 'TabCss');
    const tagBarCss = view?.CustomizedCss?.find(i => i.Type === 'TagBarCss');
    const itemCss = view?.CustomizedCss?.find(i => i.Type === 'ItemCss');

    let tab;
    if (tabId) {
      tab = Tabs.find(i => i.Code === tabId) || {};
    }
    let tags = [];
    if (tab && tab.Tags.length > 0) {
      tags = tab.Tags || [];
    }

    return (
      <Spin spinning={spinning}>
        <div
          className="preview-menu-wrap"
          style={{
            backgroundColor: '',
            backgroundImage: `url(${''})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'relative',
          }}
        >
          <div
            className="preview-close-btn"
            style={{ position: 'absolute', top: 60, right: 10, zIndex: 2 }}
          >
            <div className="preview-close-btn-icon" onClick={this.handleCloseBtn}>
              <FontAwesomeIcon icon={faX} size="3x" style={{ color: '#999', opacity: '0.3' }} />
            </div>
          </div>
          <div className="menu-left-wrap" style={{ width: 160, backgroundColor: '' }}>
            <MenuScroller
              customCss={tabCss ? tabCss : null}
              data={Tabs ? Tabs : []}
              handleClick={id => this.handleScrollerSelection(id)}
            />
          </div>
          <div
            className="preview-right-wrap"
            style={{
              //160: MenuScroller Card width is set to 150 + margin 10
              width: screen - 160,
            }}
          >
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={screen > 1366 ? 16 : 24}>
                    <Row style={{ backgroundColor: '', height: '100vh' }}>
                      <MenuPicker
                        data={tags}
                        cardOrPill={0} //0: card; 1: pill
                        // color="black"
                        itemCss={itemCss ? itemCss : null}
                        tagBarCss={tagBarCss ? tagBarCss : null}
                        showControls={false}
                        onCardPillClick={() => null}
                        onShoppingCartClick={() => null}
                        onMenuPickerCancelBtnClick={() => null}
                      />
                    </Row>
                  </Col>
                  {screen > 1366 ? (
                    <Col span={screen > 1366 ? 8 : 24} style={{ backgroundColor: '' }}>
                      {/* <Row
                        style={{
                          padding: '1px 5px 0',
                        }}
                        justify="space-between"
                      >
                        <Col>
                          <OrderHeaderAlt
                            order={order}
                            color="white"
                            bgColor="var(--primary)"
                            fontSize={20}
                            height={60}
                            width={240}
                            transition={true}
                          />
                        </Col>
                        <Col>
                          <div
                            className="menu-control-cancel-btn"
                            style={{
                              backgroundColor: 'var(--primary)',
                              color: '#fff',
                              width: 80,
                              height: 60,
                            }}
                            onClick={this.handleMenuPickerCancelBtnClick}
                          >
                            <FontAwesomeIcon
                              icon={faX}
                              // inverse={textStyle.color === 'white'}
                              size="2xl"
                            />
                          </div>
                        </Col>
                        <Col>
                          <Button
                            width={240}
                            height={60}
                            icon="check"
                            iconSize="2xl"
                            style={{ backgroundColor: 'var(--primary)' }}
                            textStyle={{ color: 'white', fontSize: 20 }}
                            borderRadius={5}
                            onClick={this.handleCheckOutBtn}
                          >
                            Check out
                          </Button>
                        </Col>
                      </Row> */}
                      {/* <OrderItemsPane /> */}
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    );
  }
}

export default connect(state => ({}), {
  resetUser,
  clearOrderItems,
  clearScannedOrderItems,
  deleteGuestName,
  resetCustomerOrderOption,
  resetLoginType,
})(withIdleTimer(Preview));
