import React, { Component } from 'react';
import { connect } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { withIdleTimer } from 'react-idle-timer';
import { Row, Col, message } from 'antd';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import moment from 'moment';
import {
  resetUser,
  clearOrderItems,
  clearScannedOrderItems,
  deleteGuestName,
  resetCustomerOrderOption,
  resetLoginType,
  resetAppMode,
  deleteGuestPhone,
  deleteGuestIns,
} from '../../redux/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faClose,
  faCalendar,
  faClock,
  faUser,
  faLocationDot,
  faX,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  getReservationVms,
  getVTImageFolder,
  getReservationGuests,
  addReservation,
  editReservation,
} from '../../api';
import ResVenue from '../../components/ResVenue';
import ResDate from '../../components/ResDate';
import ResGuests from '../../components/ResGuests';
import ResTime from '../../components/ResTime';
import ResSubmit from '../../components/ResSubmit';
import TextTransition from '../../components/TextTransition';
import Button from '../../components/Button';

import './index.css';

class Reservation extends Component {
  //onDisplay: 1 'venue'; 2 'date'; 3 '# of guests'; 4 'time slot'; 5 'info & submit'
  state = {
    error: '',
    shakeAnimation: false,
    imageDir: '',
    cardImageDir: '',
    onDisplay: 0,
    data: [],
    dates: [],
    tables: [],
    dayParts: [],
    dateChoice: null,
    venueChoice: null,
    dayPartChoice: null,
    timeSlotChoice: null,
    guestsNum: null,
    resGuests: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      instruction: '',
      guestList: [],
    },
  };

  init = () => {
    const {
      user: {
        CurAccountInfo: { FirstName, LastName, Email, HomePhone },
      },
    } = this.props;
    const { resGuests } = this.state;
    const { reservation } = this.props.location.state;

    if (reservation) {
      resGuests.firstName = reservation.FirstName;
      resGuests.lastName = reservation.LastName;
      resGuests.phoneNumber = reservation.PrimaryPhone;
      resGuests.email = reservation.Email;
      resGuests.instruction = reservation.Comments;
      resGuests.guestList =
        reservation.ReservationGuests.length > 0
          ? reservation.ReservationGuests.map(i => {
              i.key = nanoid();
              return i;
            })
          : [];
    } else {
      resGuests.firstName = FirstName ? FirstName : '';
      resGuests.lastName = LastName ? LastName : '';
      resGuests.phoneNumber = HomePhone;
      resGuests.email = Email ? Email : '';
      resGuests.instruction = '';
      resGuests.guestList = [];
    }
    this.setState({
      resGuests,
      onDisplay: 1,
    });
  };

  getImageFolder = async () => {
    await Promise.all([getVTImageFolder('9'), getVTImageFolder('11')])
      .then(results => {
        results.forEach(res => {
          if (!res || (res && res.result !== 'OK')) throw new Error();
        });
        this.setState({
          imageDir: results[0].data,
          cardImageDir: results[1].data,
        });
      })
      .catch(error => {
        message.error('Error obtaining images!', 3);
      });
  };

  getReservationVms = async () => {
    const { reservation } = this.props.location.state;
    try {
      const re = await getReservationVms();
      if (!re || (re && re.result !== 'OK')) throw new Error();
      if (reservation) {
        const venueChoice =
          re.data.filter(
            i =>
              i.CentreCode === reservation.CentreCode &&
              i.DisplayName === reservation.ReservedLocation,
          )[0] || null;
        const dateChoice =
          venueChoice?.Dates.filter(i => i.DateShort === reservation.ArrivalDate)[0] || null;
        this.setState({ data: re.data, venueChoice, dateChoice, dates: venueChoice.Dates }, () => {
          this.handleDateChange(dateChoice, false);
        });
      } else {
        this.setState({ data: re.data });
      }
    } catch (error) {
      message.error('Error obtaining venues', 3);
    }
  };

  handleBackArrow = () => {
    const { onDisplay } = this.state;
    this.setState({ onDisplay: onDisplay - 1 >= 1 ? onDisplay - 1 : 1 });
  };

  handleResMenuClose = () => {
    const { loginType } = this.props;
    if (loginType === 'Guest' || !loginType) {
      this.props.deleteGuestName();
      this.props.deleteGuestPhone();
      this.props.deleteGuestIns();
      this.props.clearOrderItems();
      this.props.clearScannedOrderItems();
      this.props.resetCustomerOrderOption();
      this.props.resetLoginType();
      this.props.resetAppMode();
      this.props.history.replace('/');
    }
    if (loginType === 'Account' || loginType === 'Card') {
      this.props.history.replace('/admin/dashboard');
    }
  };

  handleForwardArrow = () => {
    const { onDisplay } = this.state;
    this.setState({ onDisplay: onDisplay + 1 < 6 ? onDisplay + 1 : 5 });
  };

  handleShakeAnimation = () => {
    const { shakeAnimation } = this.state;
    if (shakeAnimation) {
      clearTimeout(this.shakeAnimationTimer);
      this.shakeAnimationTimer();
    }
    if (!shakeAnimation) {
      this.setState({ shakeAnimation: true });
      this.shakeAnimationTimer();
    }
  };

  shakeAnimationTimer = () => {
    setTimeout(() => {
      this.setState({ shakeAnimation: false });
    }, 900);
  };

  handleVenueChange = venueChoice => {
    const { Dates: dates } = venueChoice;
    const { reservation } = this.props.location.state;
    if (reservation) {
      const dateChoice =
        venueChoice?.Dates.filter(i => i.DateShort === reservation.ArrivalDate)[0] || null;
      this.setState({ venueChoice, dates }, () => {
        this.handleDateChange(dateChoice, false);
        this.setState({ onDisplay: 2 });
      });
    } else {
      this.setState({ venueChoice, dates }, () => {
        this.setState({ onDisplay: 2 });
      });
    }
  };

  handleDateChange = async (date, autoNext) => {
    const { reservation } = this.props.location.state;
    const {
      venueChoice: { CentreCode },
    } = this.state;
    const { Code, DateShort } = date;
    try {
      if (reservation) {
        const re = await getReservationGuests(Code, CentreCode, DateShort, reservation.ResGuid);
        if (!re || (re && re.result !== 'OK')) throw new Error();
        const obj = re.data.filter(i => i.NumberOfGuest === reservation.GuestNumber)[0] || null;
        if (obj) {
          const { DayParts } = obj;
          const dayPartChoice =
            DayParts.filter(i => i.Times.filter(j => j.Time === reservation.ArrivalTime)[0])[0] ||
            null;
          const timeSlotChoice =
            dayPartChoice?.Times.filter(i => i.Time === reservation.ArrivalTime)[0] || null;
          this.setState(
            {
              tables: re.data,
              dateChoice: date,
              guestsNum: reservation.GuestNumber,
              dayParts: DayParts,
              dayPartChoice,
              timeSlotChoice,
            },
            () => {
              this.handleGuestsNumChange(obj, false);
              autoNext && this.setState({ onDisplay: 3 });
            },
          );
        } else {
          this.setState({ tables: re.data, dateChoice: date, guestsNum: null }, () => {
            autoNext && this.setState({ onDisplay: 3 });
          });
        }
      } else {
        const re = await getReservationGuests(Code, CentreCode, DateShort, '');
        if (!re || (re && re.result !== 'OK')) throw new Error();
        this.setState({ tables: re.data, dateChoice: date }, () => {
          this.setState({ onDisplay: 3 });
        });
      }
    } catch (error) {
      message.error('Error obtaining available guest numbers', 3);
    }
  };

  handleGuestsNumChange = (obj, autoNext) => {
    const { reservation } = this.props.location.state;
    const { NumberOfGuest: guestsNum, DayParts } = obj;
    if (reservation) {
      const dayPartChoice =
        DayParts.filter(i => i.Times.filter(j => j.Time === reservation.ArrivalTime)[0])[0] || null;
      const timeSlotChoice =
        dayPartChoice?.Times.filter(i => i.Time === reservation.ArrivalTime)[0] || null;
      this.setState({ guestsNum, dayParts: DayParts, dayPartChoice, timeSlotChoice }, () => {
        autoNext && this.setState({ onDisplay: 4 });
      });
    } else {
      this.setState(
        {
          guestsNum,
          dayParts: DayParts,
          dayPartChoice: DayParts && DayParts.length > 0 ? DayParts[0] : {},
          timeSlotChoice:
            DayParts && DayParts.length > 0 && DayParts[0].Times && DayParts[0].Times.length > 0
              ? DayParts[0].Times[0]
              : {},
        },
        () => {
          this.setState({ onDisplay: 4 });
        },
      );
    }
  };

  handleDayPartChange = dayPart => {
    const { Times } = dayPart;
    this.setState({
      dayPartChoice: dayPart,
      timeSlotChoice: Times && Times.length > 0 ? Times[0] : {},
    });
  };

  handleTimeSlotChange = timeSlot => {
    this.setState({ timeSlotChoice: timeSlot }, () => {
      this.setState({ onDisplay: 5 });
    });
  };

  handleResGuestsChange = data => {
    if (this.state.error !== '') {
      this.setState({ error: '' });
    }
    this.setState({ resGuests: data });
  };

  handleSubmit = async () => {
    const { reservation } = this.props.location.state;
    const { resGuests, dateChoice, venueChoice, timeSlotChoice, guestsNum } = this.state;
    if (!resGuests.firstName && !resGuests.lastName) {
      this.setState({ error: 'Please enter your name' });
      return;
    }
    const {
      user: {
        CurAccountInfo: { AccountNumber },
      },
    } = this.props;
    const guestList = resGuests.guestList.filter(i => i.FirstName || i.LastName);
    const obj = {};
    if (reservation) {
      obj.ResGuid = reservation.ResGuid;
    } else {
      obj.ResGuid = '';
    }
    obj.AcctNum = AccountNumber;
    obj.ArrivalDate = dateChoice.DateShort;
    obj.ArrivalTime = timeSlotChoice.Time;
    obj.GuestNumber = guestsNum;
    obj.Comments = resGuests.instruction;
    obj.Email = resGuests.email;
    obj.FirstName = resGuests.firstName;
    obj.LastName = resGuests.lastName;
    obj.PrimaryPhone = resGuests.phoneNumber;
    obj.ReservedLocation = venueChoice.DisplayName;
    obj.CentreCode = venueChoice.CentreCode;
    obj.ReservationGuests = guestList;
    obj.Status = 0;
    obj.Address = '';
    obj.CardNumber = '';
    obj.PostCode = '';
    obj.Province = '';
    obj.SecondaryPhone = '';
    obj.StoreNumber = '';
    obj.ReservationCanceled = false;
    obj.ReservationProcessed = false;
    obj.ReservationProcessedDate = '';
    obj.ProcessedGuests = 0;
    obj.Smoking = false;
    obj.ProcessedLocations = '';
    obj.ReservationType = '';
    obj.Id = 0;
    obj.City = '';
    try {
      let re;
      if (reservation) {
        re = await editReservation(obj);
      } else {
        re = await addReservation(obj);
      }

      if (!re || (re && re.result !== 'OK')) throw new Error();
      this.props.history.replace('/admin/res');
    } catch (error) {
      message.error('Error creating reservation', 3);
    }
  };

  componentDidMount = () => {
    this.init();
    this.getImageFolder();
    this.getReservationVms();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isIdle()) {
      this.onIdleTimerFinish();
    } else {
      this.props.reset();
    }
  }

  render() {
    const {
      error,
      shakeAnimation,
      data,
      cardImageDir,
      imageDir,
      onDisplay,
      dates,
      tables,
      dayParts,
      venueChoice,
      dateChoice,
      timeSlotChoice,
      guestsNum,
      dayPartChoice,
      resGuests,
    } = this.state;
    const screen = window.innerWidth;
    const {
      clientStyle,
      kioskSetting: {
        imgSetting: { ReservationBackground },
      },
    } = this.props;
    let src = '';
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      //dev mode
      if (ReservationBackground && imageDir) {
        src = `http://localhost:4000/${imageDir}/${ReservationBackground}`;
      } else {
        src = `${process.env.PUBLIC_URL}/assets/orderOption/orderoption.jpg`;
      }
    } else {
      //production mode
      if (ReservationBackground && imageDir) {
        src = `${process.env.PUBLIC_URL}/${imageDir}/${ReservationBackground}`;
      } else {
        src = `${process.env.PUBLIC_URL}/assets/orderOption/orderoption.jpg`;
      }
    }

    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const descStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontTwo,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };

    return (
      <div className="res-wrapper">
        {/* Page Background */}
        <div className="res-background-container">
          {clientStyle.storeNumber === '3932' || clientStyle.storeNumber === '5960' ? (
            <video width="100%" height="100%" autoPlay muted loop style={{ objectFit: 'cover' }}>
              <source
                src={`${process.env.PUBLIC_URL}/assets/videos/tin_background.webm`}
                type="video/webm"
              />
            </video>
          ) : (
            <img
              src={src}
              alt={''}
              style={{ height: '100vh', width: '100%', objectFit: 'cover' }}
            />
          )}
        </div>
        {/* Content Row */}
        <Row
          style={{
            width: screen >= 1366 ? 1200 : 950,
            height: screen === 1080 ? 1200 : screen === 1920 ? 950 : 720,
            backgroundColor:
              clientStyle.storeNumber === '3932' || clientStyle.storeNumber === '5960'
                ? 'rgba(250, 246, 232, 0.5)'
                : 'rgba(255, 255, 255, 0.7)',
            borderRadius: 16,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            paddingBottom: 30,
          }}
        >
          <div className="res-content-wrapper">
            <div
              className="res-cancel-btn-wrapper"
              style={{ ...rowStyle }}
              onClick={this.handleResMenuClose}
            >
              <FontAwesomeIcon icon={faX} size="2x" />
            </div>
            {onDisplay > 1 && (
              <div
                className="res-back-btn-wrapper"
                style={{ ...rowStyle }}
                onClick={this.handleBackArrow}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="2x" />
              </div>
            )}
            {((onDisplay === 1 && venueChoice) ||
              (onDisplay === 2 && dateChoice) ||
              (onDisplay === 3 && guestsNum) ||
              onDisplay === 4) && (
              <div
                className="res-forward-btn-wrapper"
                style={{
                  ...rowStyle,
                }}
                onClick={this.handleForwardArrow}
              >
                <FontAwesomeIcon icon={faArrowRight} size="2x" />
              </div>
            )}

            <Col span={24}>
              {/* Sum Row */}
              <Row style={{ padding: '5px 15px', height: 60, ...titleStyle }} align="middle">
                <Col span={1}>
                  <Row style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: 16 }} />
                  </Row>
                </Col>
                <Col span={10}>
                  <Row className="res-sum-row-text" style={{ height: 25 }}>
                    <TextTransition text={venueChoice ? venueChoice.DisplayName : 'Not Selected'} />
                  </Row>
                </Col>
                <Col span={1}>
                  <Row style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    <FontAwesomeIcon icon={faCalendar} style={{ fontSize: 16 }} />
                  </Row>
                </Col>
                <Col span={4}>
                  <Row className="res-sum-row-text" style={{ height: 25 }}>
                    {dateChoice ? (
                      <TextTransition
                        text={moment(dateChoice.DateShort).format('ddd, MMM D, YYYY')}
                      />
                    ) : (
                      'Not Selected'
                    )}
                  </Row>
                </Col>
                <Col span={1}>
                  <Row style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    <FontAwesomeIcon icon={faUser} style={{ fontSize: 16 }} />
                  </Row>
                </Col>
                <Col span={3}>
                  <Row className="res-sum-row-text" style={{ height: 25 }}>
                    {guestsNum ? <TextTransition text={guestsNum} /> : 'Not Selected'}
                  </Row>
                </Col>
                <Col span={1}>
                  <Row style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    <FontAwesomeIcon icon={faClock} style={{ fontSize: 16 }} />
                  </Row>
                </Col>
                <Col span={3}>
                  <Row className="res-sum-row-text" style={{ height: 25 }}>
                    {timeSlotChoice && timeSlotChoice.Time ? (
                      <TextTransition text={timeSlotChoice.Time} />
                    ) : (
                      'Not Selected'
                    )}
                  </Row>
                </Col>
              </Row>
              {/* Instruction Row */}
              <Row justify="center" style={{ marginBottom: 15 }} align="middle">
                <Col span={6}></Col>
                <Col span={12}>
                  <Row justify="center">
                    <div
                      style={{
                        ...titleStyle,
                        fontSize: 26,
                        fontWeight: 800,
                        animation: shakeAnimation ? 'horizontal-shaking 700ms ease-out 300ms' : '',
                      }}
                    >
                      {onDisplay === 1
                        ? 'Select A Venue'
                        : onDisplay === 2
                        ? 'Select A Date'
                        : onDisplay === 3
                        ? 'Select Number of Guests'
                        : onDisplay === 4
                        ? 'Select A Time Slot'
                        : onDisplay === 5
                        ? 'Enter The Info Below'
                        : ''}
                    </div>
                  </Row>
                </Col>
                <Col span={6}></Col>
              </Row>
              <Row
                style={{
                  // padding: 15,
                  height: 30,
                  fontWeight: 'bold',
                  color: '#8b0000',
                  fontSize: 18,
                }}
                justify="center"
              >
                <Col>
                  <SwitchTransition>
                    <CSSTransition key={error} timeout={500} classNames="login-account-error">
                      <Row style={{ ...descStyle }}>{error}</Row>
                    </CSSTransition>
                  </SwitchTransition>
                </Col>
              </Row>
              {/* Switch Content Row */}
              <Row>
                <SwitchTransition mode="out-in">
                  <CSSTransition key={onDisplay} timeout={500} classNames="res-content-switch">
                    <div
                      className="res-switch-content-wrapper"
                      style={{
                        height: window.innerHeight - 200,
                        width: '100%',
                      }}
                    >
                      {onDisplay === 1 && (
                        <ResVenue
                          vms={data}
                          imageDir={cardImageDir}
                          venueChoice={venueChoice}
                          onVenueSeletionChange={venue => this.handleVenueChange(venue)}
                        />
                      )}
                      {onDisplay === 2 && (
                        <ResDate
                          dates={dates}
                          screen={screen}
                          dateChoice={dateChoice}
                          onDateChange={date => this.handleDateChange(date, true)}
                        />
                      )}
                      {onDisplay === 3 && (
                        <ResGuests
                          tables={tables}
                          guestsNum={guestsNum}
                          onGuestsNumChange={num => this.handleGuestsNumChange(num, true)}
                        />
                      )}
                      {onDisplay === 4 && (
                        <ResTime
                          dayParts={dayParts}
                          timeSlotChoice={timeSlotChoice}
                          dateChoice={dateChoice}
                          dayPartChoice={dayPartChoice}
                          onDayPartChange={dayPart => this.handleDayPartChange(dayPart)}
                          onTimeSlotChange={timeSlot => {
                            this.handleTimeSlotChange(timeSlot);
                          }}
                        />
                      )}
                      {onDisplay === 5 && (
                        <ResSubmit
                          guestsNum={guestsNum}
                          resGuests={resGuests}
                          onResGuestsChange={data => this.handleResGuestsChange(data)}
                          // onSubmitBtnClick={this.handleSubmit}
                        />
                      )}
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </Row>
            </Col>
          </div>
          {(resGuests.firstName || resGuests.lastName) &&
            dateChoice &&
            venueChoice &&
            timeSlotChoice &&
            guestsNum && (
              <Row
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translate(-50%, 40%)',
                }}
                justify="center"
              >
                <Button
                  width={150}
                  style={{ ...rowStyle }}
                  textStyle={{ fontSize: 18 }}
                  borderRadius={5}
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </Row>
            )}
        </Row>
      </div>
    );
  }
}
export default connect(
  state => ({
    user: state.user,
    orderOption: state.orderOption,
    loginType: state.loginType,
    kioskSetting: state.kioskSetting,
    clientStyle: state.clientStyle,
  }),
  {
    resetUser,
    deleteGuestName,
    clearOrderItems,
    clearScannedOrderItems,
    resetCustomerOrderOption,
    resetLoginType,
    resetAppMode,
    deleteGuestPhone,
    deleteGuestIns,
  },
)(withIdleTimer(Reservation));
