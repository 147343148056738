import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Carousel, message } from 'antd';
import { getVTImageFolder, resetOrderSetup, getCurrentKioskSetting } from '../../api';
import { receiveLoginType, receiveAppMode, receiveAppSetting } from '../../redux/actions';
import Spin from '../../components/Spin';
import './index.css';

class Start extends Component {
  state = {
    spinning: false,
    imageDir: '',
  };

  handlePageClick = () => {
    const {
      kioskSetting: {
        appSetting: { KioskLoginMode, EnableOrderOptionMapping, ScanItemMode },
      },
    } = this.props;
    if (KioskLoginMode === 4) {
      this.props.receiveLoginType('Guest');
      if (EnableOrderOptionMapping === 0) {
        if (ScanItemMode === 0 || ScanItemMode === 2) {
          this.props.receiveAppMode(0);
          this.props.history.replace('/menu');
        } else {
          this.props.receiveAppMode(1);
          this.props.history.replace('/scan');
        }
      } else {
        this.props.history.replace('/orderOptions');
      }
    } else {
      this.props.history.replace('/login');
    }
  };

  getImage = ImageUrl => {
    const { imageDir } = this.state;
    let src = '';

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      //dev mode
      if (ImageUrl) {
        src = `http://localhost:4000/${imageDir}/${ImageUrl}`;
      } else {
        src = '';
      }
    } else {
      //production mode
      if (ImageUrl) {
        src = `${process.env.PUBLIC_URL}/${imageDir}/${ImageUrl}`;
      } else {
        src = '';
      }
    }
    return src;
  };

  async componentDidMount() {
    try {
      this.setState({ spinning: true });
      const re = await getCurrentKioskSetting();
      if (!re || (re && re.result !== 'OK')) throw new Error(re.result);
      const obj = {};
      obj.appSetting = re.appSetting ? re.appSetting : {};
      obj.imgSetting = re.imgSetting ? re.imgSetting : {};
      obj.stationName = re.stationName ? re.stationName : '';
      this.props.receiveAppSetting(obj);
      const rest = await getVTImageFolder('8');
      if (!rest || (rest && rest.result !== 'OK')) throw new Error(rest.result);
      const res = await resetOrderSetup();
      if (!res || res !== 'OK') throw new Error(res.result);
      this.setState({ spinning: false, imageDir: rest.data });
    } catch (error) {
      this.setState({ spinning: false });
      message.error('Error obtaining settings!', 3);
    }
  }

  render() {
    const { spinning, imageDir } = this.state;
    const {
      kioskSetting: {
        imgSetting: { WelcomBackgrounds },
      },
    } = this.props || [];
    const imgStyle = {
      height: '100vh',
      width: '100%',
      objectFit: 'cover',
    };

    return (
      <Spin spinning={spinning} spinnerColor="var(--primary)">
        <div
          style={{ position: 'relative', width: '100vw', height: '100vh' }}
          onClick={this.handlePageClick}
          onTouchStart={this.handlePageClick}
          onTouchEnd={this.handlePageClick}
          onTouchMove={this.handlePageClick}
        >
          <div>
            {!WelcomBackgrounds || WelcomBackgrounds.length === 0 ? (
              <img
                src={`${process.env.PUBLIC_URL}/assets/start/slider/image2.jpg`}
                alt={''}
                style={imgStyle}
              />
            ) : WelcomBackgrounds && WelcomBackgrounds.length === 1 ? (
              <img
                src={imageDir && this.getImage(WelcomBackgrounds[0])}
                alt={''}
                style={imgStyle}
              />
            ) : (
              <Carousel autoplay={true} dots={false} autoplaySpeed={8000} fade={true}>
                {WelcomBackgrounds &&
                  WelcomBackgrounds.map((i, index) => (
                    <div key={index}>
                      <img src={imageDir && this.getImage(i)} alt={''} style={imgStyle} />
                    </div>
                  ))}
              </Carousel>
            )}
          </div>
          <div className="start-btm-touch-wrapper">
            <div className="start-touch-hand-wrapper">
              <div className="start-touch-circle">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/start/slider/touch-circle.png`}
                  alt={''}
                />
              </div>
              <div style={{ textAlign: 'center' }} className="start-touch-hand">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/start/slider/touch-hand.png`}
                  alt={''}
                />
              </div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/start/slider/touch-to-start.png`}
                alt={''}
              />
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

export default connect(state => ({ kioskSetting: state.kioskSetting }), {
  receiveLoginType,
  receiveAppMode,
  receiveAppSetting,
})(Start);
