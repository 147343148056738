import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider } from 'antd';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Button from '../../components/Button';

class LoginCard extends Component {
  state = { error: '' };

  ref = React.createRef();

  handleCardLoginCancel = () => {
    this.props.onCardLoginCancel();
  };

  handleEnterKeyDown = e => {
    if (e.key === 'Enter') {
      this.props.onCardLogin(e.target.value);
      e.target.value = '';
    }
  };

  handleInputBlurred = () => {
    this.ref?.current.focus();
  };

  handleClickOutside = e => {
    if (e.target.nodeName !== 'INPUT') {
      this.ref?.current.focus();
    }
  };

  componentDidMount() {
    this.ref?.current?.focus();
    document.addEventListener('mouseup', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const { getFocus } = this.props;
    if (getFocus !== prevProps.getFocus) {
      this.ref?.current.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutside);
  }

  render() {
    const { error } = this.state;
    const {
      cardLoginInputDisabled,
      clientStyle,
      kioskSetting: {
        appSetting: { KioskLoginMode, ScanItemMode },
      },
    } = this.props;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const descStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontTwo,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };
    return (
      <div style={{ position: 'relative', height: '100%' }}>
        {/* Error Row */}
        <Row
          style={{ padding: 15, height: 30, fontWeight: 'bold', color: '#8b0000', fontSize: 18 }}
          justify="center"
        >
          <Col>
            <SwitchTransition>
              <CSSTransition key={error} timeout={500} classNames="login-account-error">
                <Row style={{ ...descStyle }}>{error}</Row>
              </CSSTransition>
            </SwitchTransition>
          </Col>
        </Row>
        {/* Instruction Row */}
        <Row
          style={{ ...titleStyle, fontSize: 24, fontWeight: 'bold', marginTop: 50 }}
          justify="center"
        >
          {KioskLoginMode === 3 && ScanItemMode === 4
            ? 'Please tap or swipe your card'
            : 'Please tap or swipe your card to login'}
        </Row>
        {/* Tap Image Row */}
        <Row style={{ marginTop: 30 }} justify="center">
          <img src={`${process.env.PUBLIC_URL}/assets/login/tapcard.png`} alt={''} height={178} />
        </Row>
        <Divider style={{ borderColor: 'transparent' }} />
        <Row justify="space-between" style={{ marginTop: 50 }}>
          <Col span={6}></Col>
          <Col span={12}>
            {KioskLoginMode === 3 && ScanItemMode === 4 ? null : (
              <Row>
                <Col span={24}>
                  <Row justify="center">
                    <Button
                      width={120}
                      height={50}
                      onClick={this.handleCardLoginCancel}
                      style={{ ...rowStyle }}
                      textStyle={{ fontSize: 16 }}
                      borderRadius={5}
                    >
                      Cancel
                    </Button>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={6}></Col>
        </Row>
        <input
          disabled={cardLoginInputDisabled}
          ref={this.ref}
          className="login-card-input"
          autoFocus={true}
          onBlur={this.handleInputBlurred}
          onKeyDown={e => {
            this.handleEnterKeyDown(e);
          }}
        />
      </div>
    );
  }
}
export default connect(
  state => ({ clientStyle: state.clientStyle, kioskSetting: state.kioskSetting }),
  {},
)(LoginCard);
