import React, { Component } from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import menuList from '../../config/menuConfig';

const SubMenu = Menu.SubMenu;

class AdminNav extends Component {
  state = {
    menuNodes: [],
  };

  hasAuth = item => {
    //User type 3, 4, 9
    const {
      user,
      loginType,
      kioskSetting: {
        appSetting: {
          EnableReservation,
          EnableOrderOptionMapping,
          DisabledOrderHistory,
          DisabledAccountStatement,
        },
      },
    } = this.props;
    const { isPublic } = item;
    //Check user type
    if (item.title === 'Settings' && user?.Type !== 3 && user?.Type !== 4 && user?.Type !== 9)
      return false;
    //Check app settings
    if (item.title === 'Password' && loginType === 'Card') return false;
    if (item.title === 'Order Option Mappings' && EnableOrderOptionMapping === 0) return false;
    if (item.title === 'Order History' && DisabledOrderHistory === 1) return false;
    if (item.title === 'Statements' && DisabledAccountStatement === 1) return false;
    if (
      EnableReservation === 0 &&
      (item.title === 'My Reservations' ||
        item.title === 'Reservation Mappings' ||
        item.title === 'Reservation Settings')
    )
      return false;

    return isPublic === true;
  };

  getMenuNodes = menuList => {
    const path = this.props.location.pathname;

    return menuList.reduce((pre, item) => {
      if (this.hasAuth(item)) {
        if (!item.children) {
          pre.push(
            <Menu.Item key={item.key}>
              <Link to={item.key}>
                {item.icon && <span>{item.icon}</span>}
                <span>{item.title}</span>
              </Link>
            </Menu.Item>,
          );
        } else {
          const cItem = item.children.find(cItem => path.indexOf(cItem.key) === 0);
          if (cItem) {
            this.openKey = item.key;
          }

          pre.push(
            <SubMenu
              key={item.key}
              title={
                <span>
                  <span>{item.icon}</span>
                  <span>{item.title}</span>
                </span>
              }
            >
              {this.getMenuNodes(item.children)}
            </SubMenu>,
          );
        }
      }
      return pre;
    }, []);
  };

  UNSAFE_componentWillMount() {
    const menuNodes = this.getMenuNodes(menuList);
    this.setState({ menuNodes });
  }

  componentDidUpdate(prevProps, prevState) {
    const { kioskSetting } = this.props;
    if (kioskSetting !== prevProps.kioskSetting) {
      const menuNodes = this.getMenuNodes(menuList);
      this.setState({ menuNodes });
    }
  }

  render() {
    const { menuNodes } = this.state;
    const { clientStyle } = this.props;
    let path = this.props.location.pathname;
    switch (path) {
      case '/admin/adduser':
        path = '/admin/user';
        break;
      case '/admin/edituser':
        path = '/admin/user';
        break;
      case '/admin/app':
        path = '/admin/kiosk';
        break;
      case '/admin/image':
        path = '/admin/kiosk';
        break;
      case '/admin/imagemanager':
        path = '/admin/kiosk';
        break;
      case '/admin/editresmapping':
        path = '/admin/resmappings';
        break;
      case '/admin/orderoption':
        path = '/admin/orderoptionmappings';
        break;
      case '/admin/reshistory':
        path = '/admin/res';
        break;
      case '/admin/ressetting':
        path = '/admin/ressettings';
        break;
      case '/admin/editview':
        path = '/admin/vieweditor';
        break;
      case '/admin/viewtab':
        path = '/admin/vieweditor';
        break;
      default:
        break;
    }
    const openKey = this.openKey;

    const titleStyle = {
      // color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      // textTransform: clientStyle.textTransform,
    };

    return (
      <Menu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={['/admin/dashboard']}
        selectedKeys={[path]}
        defaultOpenKeys={[openKey]}
        style={{ ...titleStyle }}
      >
        {menuNodes}
      </Menu>
    );
  }
}

export default connect(
  state => ({
    user: state.user,
    loginType: state.loginType,
    clientStyle: state.clientStyle,
  }),
  {},
)(withRouter(AdminNav));
