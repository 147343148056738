import React, { Component } from 'react';
import { getVTImageFolder } from '../../api';
import Card from '../Card';

import './index.css';

export default class MenuScroller extends Component {
  state = { selectedCard: null, imageDir: '' };

  ref = React.createRef();

  init = () => {
    const innerHeight = window.innerHeight;
    if (this.ref.current.scrollHeight > innerHeight) {
      this.setState({ showArrow: true });
    }

    const { selectedCard } = this.state;
    const { data } = this.props;

    if (data && data[0] && selectedCard === null) {
      this.setState({ selectedCard: data[0].Code });
      this.props.handleClick(data[0].Code);
    }
  };

  getImageFolder = async () => {
    try {
      const re = await getVTImageFolder('16');
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ imageDir: '' });
      }
      this.setState({ imageDir: re.data });
    } catch (error) {}
  };

  handleCardClick = id => {
    this.setState({ selectedCard: id });
    this.props.handleClick(id);
  };

  componentDidMount() {
    this.init();
    this.getImageFolder();
  }

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props;
    if (data !== prevProps.data) {
      this.init();
    }
  }

  render() {
    const { selectedCard, imageDir } = this.state;
    const { data, customCss } = this.props;
    let BackgroundColor, FontName, Color;
    if (customCss) {
      BackgroundColor = customCss.BackgroundColor
        ? customCss.BackgroundColor
        : 'linear-gradient(120deg, #fdfbfb, #ebedee)';
      FontName = customCss.FontName ? customCss.FontName : 'Noto-Regular';
      Color = customCss.Color ? customCss.Color : 'black';
    }

    return (
      <div
        className="menu-scroller-outer"
        style={{
          height: '100vh',
        }}
      >
        <div className="menu-scroller-container" ref={this.ref}>
          <div className="menu-scroller-items">
            {data.map(i => (
              <div key={i.Code} className="menu-scroller-card">
                <Card
                  backgroundImage={BackgroundColor}
                  titleStyle={{
                    color: Color,
                    fontFamily: FontName,
                    fontSize: 16,
                  }}
                  img={i.ImageUrl}
                  imageDir={imageDir}
                  imgHeight={80}
                  title={i.Description}
                  width={150}
                  height={150}
                  selectedCard={selectedCard}
                  id={i.Code}
                  item={i}
                  onCardClick={() => this.handleCardClick(i.Code)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
