import React, { Component } from 'react';
import { Drawer } from 'antd';

export default class VTDrawer extends Component {
  render() {
    const { children } = this.props;
    return (
      <Drawer {...this.props} onClose={e => this.props.onClose(e)}>
        {children}
      </Drawer>
    );
  }
}
