import React, { Component } from 'react';
import { Row, Col, Table, Tabs, Checkbox, Drawer, Image, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashCan,
  faPenToSquare,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { getViewPages, saveViewPage, getVTImageFolder } from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import Button from '../../../components/Button';
import ViewTabAddOrEdit from '../../../components/ViewTabAddOrEdit';
import VSTabCard from '../../../components/VSTabCard';
import VSTagBar from '../../../components/VSTagBar';
import VSItemCard from '../../../components/VSItemCard';
import './index.css';

const { TabPane } = Tabs;

class AdminEditView extends Component {
  state = {
    spinning: false,
    view: {},
    activeTab: 'tab_one',
    activeStylingTab: 'tab_card',
    tabDrawerVisible: false,
    tabSelected: {},
    addOrEditTab: '',
    imageDir: '',
  };

  init = async () => {
    const { viewName } = this.props.location.state || '';
    this.setState({ spinning: true });
    await Promise.all([getViewPages(), getVTImageFolder('16')])
      .then(results => {
        results.forEach(res => {
          if (!res || (res && res.result !== 'OK')) throw new Error();
        });
        this.setState({
          spinning: false,
          view: results[0].data.find(i => i.ViewName === viewName),
          imageDir: results[1].data,
        });
      })
      .catch(error => {
        this.setState({ spinning: false });
        message.error('Error obtaining view!', 3);
      });
  };

  saveView = async view => {
    try {
      this.setState({ spinning: true });
      const re = await saveViewPage(view);
      if (!re || (re && re.result !== 'OK')) {
        this.setState({ spinning: false });
        throw new Error();
      }
      this.setState({ spinning: false });
      this.init();
      message.success('View successfully updated!', 3);
    } catch (error) {
      message.error('Error updating view!', 3);
    }
  };

  handleBackBtn = () => {
    this.props.history.replace('/admin/vieweditor');
  };

  handleSaveBtn = () => {
    const { view } = this.state;
    this.saveView(view);
  };

  handleIsStartupChange = ({ target: { checked } }) => {
    const { view } = this.state;
    view.IsStartUp = checked;
    this.setState({ view });
  };

  handleTabClick = key => {
    this.setState({ activeTab: key });
  };

  handleStylingTabClick = key => {
    this.setState({ activeStylingTab: key });
  };

  handleIndexMove = (row, type) => {
    const { view } = this.state;
    const { Buttons } = view;
    const item = Buttons.find(i => i.ButtonCode === row.ButtonCode);
    const itemIndex = Buttons.findIndex(i => i.ButtonCode === row.ButtonCode);
    if (type === 'Down') {
      const nextItem = Buttons[itemIndex + 1];
      nextItem.Id = nextItem.Id - 1;
      item.Id = item.Id + 1;
    }
    if (type === 'Up') {
      const prevItem = Buttons[itemIndex - 1];
      prevItem.Id = prevItem.Id + 1;
      item.Id = item.Id - 1;
    }
    this.saveView(view);
  };

  handleAddTab = () => {
    this.setState({ tabSelected: {}, addOrEditTab: 'Add' }, () => {
      this.setState({ tabDrawerVisible: true });
    });
  };

  handleDeleteTab = row => {
    const { view } = this.state;
    view.Buttons = view.Buttons.filter(i => i.ButtonCode !== row.ButtonCode);
    this.saveView(view);
  };

  handleEditTab = row => {
    this.setState({ tabSelected: row, addOrEditTab: 'Edit' }, () => {
      this.setState({ tabDrawerVisible: true });
    });
  };

  handleTabDrawerCancel = () => {
    this.setState({ tabDrawerVisible: false, tabSelected: {}, addOrEditTab: '' });
  };

  handleTabAddOrEditSave = button => {
    const { view, addOrEditTab } = this.state;
    let newView = {};
    if (addOrEditTab === 'Edit') {
      const index = view.Buttons.findIndex(i => i.ButtonCode === button.ButtonCode);
      const head = index <= 0 ? [] : view.Buttons.slice(0, index);
      const tail = view.Buttons.slice(index + 1);
      newView = { ...view, Buttons: [...head, button, ...tail] };
    }
    if (addOrEditTab === 'Add') {
      newView = { ...view, Buttons: [...view.Buttons, button] };
    }
    this.saveView(newView);
    this.handleTabDrawerCancel();
  };

  handleTabCardStylingChange = style => {
    const { view } = this.state;
    const { color, colorStop1, colorStop2, direction, fontFamily } = style;
    const obj = {};
    obj.FontName = fontFamily;
    obj.FontSize = '';
    obj.Color = color;
    obj.BorderStyle = '';
    obj.BorderSize = '';
    obj.BorderColor = '';
    obj.Height = '';
    obj.Width = '';
    obj.MarginTop = '';
    obj.MarginLeft = '';
    obj.MarginRight = '';
    obj.MarginBottom = '';
    obj.PaddingTop = '';
    obj.PaddingLeft = '';
    obj.PaddingRight = '';
    obj.PaddingBottom = '';
    obj.BackgroundColor = `linear-gradient(${direction}deg, ${colorStop1}, ${colorStop2})`;
    obj.FontPosition = '';
    obj.IsHideText = '';
    obj.IsHidePrice = '';
    obj.IsHideImage = '';
    obj.Type = 'TabCss';

    view.CustomizedCss = [...view.CustomizedCss.filter(i => i.Type !== 'TabCss'), obj];
    this.setState({ view });
  };

  handleTagBarStylingChange = style => {
    const { view } = this.state;
    const { color, colorStop1, colorStop2, direction, fontFamily } = style;
    const obj = {};
    obj.FontName = fontFamily;
    obj.FontSize = '';
    obj.Color = color;
    obj.BorderStyle = '';
    obj.BorderSize = '';
    obj.BorderColor = '';
    obj.Height = '';
    obj.Width = '';
    obj.MarginTop = '';
    obj.MarginLeft = '';
    obj.MarginRight = '';
    obj.MarginBottom = '';
    obj.PaddingTop = '';
    obj.PaddingLeft = '';
    obj.PaddingRight = '';
    obj.PaddingBottom = '';
    obj.BackgroundColor = `linear-gradient(${direction}deg, ${colorStop1}, ${colorStop2})`;
    obj.FontPosition = '';
    obj.IsHideText = '';
    obj.IsHidePrice = '';
    obj.IsHideImage = '';
    obj.Type = 'TagBarCss';

    view.CustomizedCss = [...view.CustomizedCss.filter(i => i.Type !== 'TagBarCss'), obj];
    this.setState({ view });
  };

  handleItemCardStylingChange = style => {
    const { view } = this.state;
    const { color, colorStop1, colorStop2, direction, fontFamily } = style;
    const obj = {};
    obj.FontName = fontFamily;
    obj.FontSize = '';
    obj.Color = color;
    obj.BorderStyle = '';
    obj.BorderSize = '';
    obj.BorderColor = '';
    obj.Height = '';
    obj.Width = '';
    obj.MarginTop = '';
    obj.MarginLeft = '';
    obj.MarginRight = '';
    obj.MarginBottom = '';
    obj.PaddingTop = '';
    obj.PaddingLeft = '';
    obj.PaddingRight = '';
    obj.PaddingBottom = '';
    obj.BackgroundColor = `linear-gradient(${direction}deg, ${colorStop1}, ${colorStop2})`;
    obj.FontPosition = '';
    obj.IsHideText = '';
    obj.IsHidePrice = '';
    obj.IsHideImage = '';
    obj.Type = 'ItemCss';

    view.CustomizedCss = [...view.CustomizedCss.filter(i => i.Type !== 'ItemCss'), obj];
    this.setState({ view });
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const {
      spinning,
      view,
      activeTab,
      activeStylingTab,
      tabDrawerVisible,
      tabSelected,
      addOrEditTab,
      imageDir,
    } = this.state;
    const columns = [
      {
        title: 'Delete',
        render: (_, record) => (
          <FontAwesomeIcon
            icon={faTrashCan}
            size="xl"
            color="var(--adminSecondary)"
            onClick={() => this.handleDeleteTab(record)}
          />
        ),
      },
      {
        title: 'Tab Name',
        dataIndex: 'ButtonText',
      },
      {
        title: 'Index',
        dataIndex: 'Id',
        render: (_, record, index) => {
          const { Buttons } = view;
          if (index === 0) {
            return (
              <Row>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="xl"
                  color="var(--adminSecondary)"
                  onClick={() => {
                    this.handleIndexMove(record, 'Down');
                  }}
                />
              </Row>
            );
          } else if (index > 0 && index < Buttons.length - 1) {
            return (
              <>
                <Row>
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    size="xl"
                    color="var(--adminSecondary)"
                    onClick={() => {
                      this.handleIndexMove(record, 'Up');
                    }}
                  />
                </Row>
                <Row>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="xl"
                    color="var(--adminSecondary)"
                    onClick={() => {
                      this.handleIndexMove(record, 'Down');
                    }}
                  />
                </Row>
              </>
            );
          } else if (index === Buttons.length - 1) {
            return (
              <Row>
                <FontAwesomeIcon
                  icon={faChevronUp}
                  size="xl"
                  color="var(--adminSecondary)"
                  onClick={() => {
                    this.handleIndexMove(record, 'Up');
                  }}
                />
              </Row>
            );
          }
        },
      },
      {
        title: 'Image',
        dataIndex: 'ImageName',
        render: (_, record) => {
          let src = '';
          const imgFallback = `${process.env.PUBLIC_URL}/assets/admin/imageFallback.png`;
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            //dev mode
            if (record.ImageName) {
              src = `http://localhost:4000/${imageDir}/${record.ImageName}`;
            } else {
              src = imgFallback;
            }
          } else {
            //production mode
            if (record.ImageName) {
              src = `${process.env.PUBLIC_URL}/${imageDir}/${record.ImageName}`;
            } else {
              src = imgFallback;
            }
          }
          return (
            <Row style={{ width: 120, height: 80, border: '1px solid #e8e8e8' }}>
              <Image width={120} height={80} src={src} preview={src !== imgFallback} />
            </Row>
          );
        },
      },
      {
        title: 'Edit',
        render: (_, record) => (
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="xl"
            color="var(--adminSecondary)"
            onClick={() => this.handleEditTab(record)}
          />
        ),
      },
    ];

    return (
      <Spin spinning={spinning}>
        <Row className="admin-view-edit-view-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title={view.ViewName}
              showLeftBtn={true}
              showRightBtn={true}
              leftBtnText="Back"
              rightBtnText="Save"
              onLeftBtnClick={this.handleBackBtn}
              onRightBtnClick={this.handleSaveBtn}
            />
            {/* Set as Start up Row */}
            <Row justify="center">
              <Checkbox checked={view.IsStartUp} onChange={e => this.handleIsStartupChange(e)}>
                <h3>Set as startup</h3>
              </Checkbox>
            </Row>
            {/* Content Row */}
            <Row style={{ marginTop: 20 }}>
              <Col span={24}>
                <Tabs
                  size="large"
                  type="card"
                  tabPosition="top"
                  tabBarStyle={{
                    wordWrap: 'normal',
                    fontWeight: '600',
                    width: 180,
                  }}
                  tabBarGutter={8}
                  activeKey={activeTab}
                  onTabClick={key => this.handleTabClick(key)}
                >
                  <TabPane tab="Tabs" key="tab_one">
                    <Row>
                      <Col span={24}>
                        <Row style={{ marginTop: 15 }} justify="end">
                          <Button
                            width={120}
                            height={50}
                            style={{ backgroundColor: 'var(--adminPrimary)' }}
                            textStyle={{ fontSize: 16, color: 'white' }}
                            borderRadius={5}
                            onClick={this.handleAddTab}
                          >
                            Add Tab
                          </Button>
                        </Row>
                        <Row style={{ marginTop: 15 }}>
                          <Col span={24}>
                            <Table
                              size="middle"
                              rowKey="ButtonCode"
                              columns={columns}
                              dataSource={view.Buttons}
                              pagination={false}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Styling" key="tab_two">
                    <Row>
                      <Col span={24}>
                        <Tabs
                          size="large"
                          type="card"
                          tabPosition="top"
                          tabBarStyle={{
                            wordWrap: 'normal',
                            fontWeight: '600',
                          }}
                          tabBarGutter={8}
                          activeKey={activeStylingTab}
                          onTabClick={key => this.handleStylingTabClick(key)}
                        >
                          <TabPane tab="Tab Card" key="tab_card">
                            <VSTabCard
                              view={view}
                              onTabCardChange={obj => {
                                this.handleTabCardStylingChange(obj);
                              }}
                            />
                          </TabPane>
                          <TabPane tab="Tag Bar" key="tag_bar">
                            <VSTagBar
                              view={view}
                              onTagBarChange={obj => {
                                this.handleTagBarStylingChange(obj);
                              }}
                            />
                          </TabPane>
                          <TabPane tab="Item Card" key="item_card">
                            <VSItemCard
                              view={view}
                              onItemCardChange={obj => {
                                this.handleItemCardStylingChange(obj);
                              }}
                            />
                          </TabPane>
                        </Tabs>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Col>
          <Drawer
            visible={tabDrawerVisible}
            width="100%"
            maskClosable={false}
            closable={false}
            onClose={this.handlTabDrawerClose}
            drawerStyle={{ backgroundColor: '#fff', position: 'relative' }}
            destroyOnClose={true}
            getContainer={false}
          >
            <ViewTabAddOrEdit
              tab={tabSelected}
              addOrEditTab={addOrEditTab}
              imageDir={imageDir}
              onTabDrawerCancel={this.handleTabDrawerCancel}
              onTabAddOrEditSave={tab => this.handleTabAddOrEditSave(tab)}
            />
          </Drawer>
        </Row>
      </Spin>
    );
  }
}

export default AdminEditView;
