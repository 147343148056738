import React, { Component } from 'react';
import { connect } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { Row, Col, Radio, Drawer } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus, faX, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import TextTransition, { presets } from 'react-text-transition';
import VTTextTransition from '../TextTransition';
import { CSSTransition, TransitionGroup, SwitchTransition } from 'react-transition-group';
import { receivePopupOrder } from '../../redux/actions';
import { getItemInformation, getPizzaPrice } from '../../api';
import MenuItemModsSelector from '../../components/MenuItemModsSelector';
import MenuItemSizeSelector from '../MenuItemSizeSelector';
import PackageItemSelector from '../../components/PackageItemSelector';
import MenuPizzaModal from '../../components/MenuPizzaModal';
import Button from '../Button';
import PackageSelectedItemsRow from '../../components/PackageSelectedItemsRow';
import { toCamel } from '../../utils/camelCase';

import './index.css';

class PopupItemSelector extends Component {
  state = {
    animated: false,
    itemSizeModalVisible: false,
    itemModsModalVisible: false,
    pizzaItemModalVisible: false,
    packageItemDrawerVisible: false,
    tabs: [],
    checkItem: {
      code: '',
      description: '',
      price: '', //Need to convert to decimal at check out
      qty: 0,
      error: false,
      errorMessage: '',
      dependencyCargo: '',
      type: '',
      sizeCode: '',
      modifierDetails: [],
      pizzaDetails: [],
      packageDetails: [],
      matrixDetails: [],
      interStoreNumber: '',
      guestNumber: -1, //int,
      packageType: '',
    },
    tabSelected: null,
    itemSelected: null,
    list: [],
    sizeCode: '',
    pizzaTotalPrice: 0,
    sizeItemInfoList: [],
    modifierInfos: [],
    packageItemInfos: [],
    toppingItemList: [],
  };

  init = () => {
    const { popupItemInfos } = this.props || [];
    const tabs = popupItemInfos.map(i => {
      i.key = nanoid();

      const arr = i.Tags.map(j => j.Items);
      i.ItemList = arr.flat();
      return i;
    });
    this.setState({ tabs: popupItemInfos, tabSelected: tabs[0], list: [] });
  };

  handlePopupItemCancel = () => {
    this.setState({ tabSelected: null, itemSelected: null, list: [] });
    this.cleanCheckItem();
    this.props.onPopupItemCancel();
  };

  handleInstructionAnimation = () => {
    const { animated } = this.state;
    if (animated) {
      clearTimeout(this.animationTimer);
      this.animationTimer();
    }
    if (!animated) {
      this.setState({ animated: true });
      this.animationTimer();
    }
  };

  animationTimer = () => {
    setTimeout(() => {
      this.setState({ animated: false });
    }, 900);
  };

  prunePrice = i => {
    let price = '';
    if (i.Price === 0 && i.SizeItemInfos.length === 0) {
      price = '$0.00';
    } else if ((i.Price === 0 || i.Price !== 0) && i.SizeItemInfos.length === 0) {
      price = `$${i.Price.toFixed(2)}`;
    } else if ((i.Price === 0 || i.Price !== 0) && i.SizeItemInfos.length > 0) {
      const arr = i.SizeItemInfos.map(j => j.BasePrice);
      const max = Math.max(...arr);
      const min = Math.min(...arr);
      if (min === max) {
        price = `$${max.toFixed(2)}`;
      }
      if (min !== max) {
        price = `$${min.toFixed(2)}-${max.toFixed(2)}`;
      }
    }
    return price;
  };

  handlePrevBtn = index => {
    const { tabs } = this.state;
    if (index === 0) return;
    let nextIndex;
    if (index > 0) {
      nextIndex = index - 1;
      this.setState({ tabSelected: tabs[nextIndex] });
    }
  };

  handleNextBtn = index => {
    const { tabs, tabSelected } = this.state;
    const tab = tabs.find(i => i.key === tabSelected.key) || {};
    const min = tab.MinQty;
    const sum = tab.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
    if (sum < min) {
      this.handleInstructionAnimation();
      return;
    }
    if (sum >= min) {
      if (index === tabs.length - 1) return;
      let nextIndex;
      if (index < tabs.length - 1) {
        nextIndex = index + 1;
        this.setState({ tabSelected: tabs[nextIndex] });
      }
    }
  };

  cleanCheckItem = () => {
    this.setState({
      checkItem: {
        code: '',
        description: '',
        price: '',
        qty: 0,
        error: false,
        errorMessage: '',
        dependencyCargo: '',
        type: '',
        sizeCode: '',
        modifierDetails: [],
        pizzaDetails: [],
        packageDetails: [],
        matrixDetails: [],
        interStoreNumber: '',
        guestNumber: -1, //int,
        packageType: '',
      },
      sizeCode: '',
      pizzaTotalPrice: 0,
      sizeItemInfoList: [],
      modifierInfos: [],
      packageItemInfos: [],
      toppingItemList: [],
      itemSelected: {},
    });
  };

  handleMinusOne = item => {
    const { tabs, tabSelected, list } = this.state;
    const tab = tabs.find(i => i.key === tabSelected.key);
    const { ItemList } = tab;
    const tItem = ItemList.find(i => i.Code === item.Code);
    const cItem = list.find(i => i.code === item.Code);
    const otherCItems = list.filter(i => i.code !== item.Code);
    if (cItem) {
      if (cItem.qty > 1) {
        cItem.qty = cItem.qty - 1;
        tItem.Qty = tItem.Qty - 1;
        this.setState({ tabs, list });
      } else if (cItem.qty === 1) {
        cItem.qty = 0;
        tItem.Qty = 0;
        this.setState({ tabs, list: [...otherCItems] });
      }
    } else {
      return;
    }
  };

  handlePlusClick = async item => {
    const { tabs, tabSelected, checkItem, list } = this.state;
    const cItem = list.find(i => i.code === item.Code);
    const tab = tabs.find(i => i.key === tabSelected.key);
    const isExist = list.filter(i => i.code === item.Code).length > 0;
    const max = tab.MaxQty;
    let maxed = false;
    const sum = tab.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);

    if (max > 0 && sum >= max) {
      maxed = true;
      this.handleInstructionAnimation();
      return;
    }

    const re = await getItemInformation(item.Code, '');
    if (!re || (re && re.result !== 'OK')) return;
    const { data } = re;

    if (!maxed && !cItem) {
      checkItem.code = data.Code;
      checkItem.description = data.Description;
      if (data.SizeItemInfoList.length > 0) {
        checkItem.price = data.SizeItemInfoList[0].BasePrice.toFixed(2);
      }
      if (data.SizeItemInfoList.length === 0) {
        checkItem.price = data.Price.toFixed(2);
      }
      checkItem.qty = 1;
      checkItem.type = data.Type;
      checkItem.sizeCode = '';
      checkItem.modifierDetails = [];
      if (data.Type === 'P' && data.PackageItemInfos.length > 0) {
        checkItem.packageDetails = [];
        checkItem.packageType = data.PackageType.toString();
      }
      if (data.Type === 'I') {
        checkItem.packageDetails = [];
        checkItem.packageType = '';
      }
      this.setState({
        tabs,
        checkItem,
        sizeItemInfoList: data.SizeItemInfoList,
        modifierInfos: data.ModifierInfos,
        packageItemInfos: data.PackageItemInfos,
        toppingItemList: data.ToppingItemList,
        itemSelected: data,
        sizeCode: '',
        pizzaTotalPrice: 0,
      });
    }

    const { SizeItemInfoList, ModifierInfos, PackageItemInfos } = data;

    //Item is a PackageItem, qty not maxed, not in list, open package item drawer
    if (
      PackageItemInfos &&
      PackageItemInfos.length > 0 &&
      !maxed &&
      (!cItem || (cItem && cItem.qty === 0))
    ) {
      this.setState({ packageItemDrawerVisible: true });
    }

    //Item is a PackageItem, qty not maxed, already in list, go to add one
    if (PackageItemInfos && PackageItemInfos.length > 0 && !maxed && cItem) {
      this.setState({ itemSelected: data }, () => {
        this.handleAddToList();
      });
    }

    //Item has sizes, qty not maxed, not in list, open size selector
    if (
      SizeItemInfoList &&
      SizeItemInfoList.length > 0 &&
      !maxed &&
      (!cItem || (cItem && cItem.qty === 0))
    ) {
      this.setState({ itemSizeModalVisible: true });
    }

    //Item has sizes, qty not maxed, already in list, go to add one;
    if (SizeItemInfoList && SizeItemInfoList.length > 0 && !maxed && cItem && cItem.qty > 0) {
      this.setState({ itemSelected: data }, () => {
        this.handleAddToList();
      });
    }

    //Item has no sizes, but has mods, qty not maxed, not in list, open mods selector
    if (
      (!SizeItemInfoList || SizeItemInfoList.length === 0) &&
      ModifierInfos &&
      ModifierInfos.length > 0 &&
      !maxed &&
      !isExist
    ) {
      this.setState({ itemSelected: data }, () => {
        this.setState({ itemModsModalVisible: true });
      });
    }

    //Item has no sizes, but has mods, qty not maxed, already in list, go to add one
    if (
      (!SizeItemInfoList || SizeItemInfoList.length === 0) &&
      ModifierInfos &&
      ModifierInfos.length > 0 &&
      !maxed &&
      isExist
    ) {
      this.setState({ itemSelected: data }, () => {
        this.handleAddToList();
      });
    }

    //Item has no sizes nor mods, not a package item, qty not maxed, go to add one;
    if (
      (!PackageItemInfos || PackageItemInfos.length === 0) &&
      (!SizeItemInfoList || SizeItemInfoList.length === 0) &&
      (!ModifierInfos || ModifierInfos.length === 0) &&
      !maxed
    ) {
      this.setState({ itemSelected: data }, () => {
        this.handleAddToList();
      });
    }
  };

  handleAddToList = () => {
    const { tabs, tabSelected, list, itemSelected, checkItem } = this.state;
    const tab = tabs.find(i => i.key === tabSelected.key) || {};
    const { ItemList } = tab;
    const tItem = ItemList.find(i => i.Code === itemSelected.Code);
    const max = tab.MaxQty;
    const min = tab.MinQty;
    const cItem = list.find(i => i.code === itemSelected.Code);

    if (!cItem) {
      const item = JSON.parse(JSON.stringify(checkItem));
      item.key = nanoid();
      tItem.Qty = 1;
      this.setState({ list: [...list, item], tabs });
      this.checkAutoNext();
    } else {
      if ((max === 0 && min === 0) || (max === 0 && min > 0)) {
        cItem.qty = cItem.qty + 1;
        tItem.Qty = tItem.Qty + 1;
        this.setState({ tabs, list, itemSize: '' });
      } else if (max > 0) {
        const sum = tab.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
        if (sum < max) {
          cItem.qty = cItem.qty + 1;
          tItem.Qty = tItem.Qty + 1;
          this.setState({ tabs, list }, () => {
            this.checkAutoNext();
          });
        } else {
          return;
        }
      }
    }
    this.cleanCheckItem();
  };

  checkAutoNext = () => {
    const { tabs, tabSelected } = this.state;
    const tab = tabs.find(i => i.key === tabSelected.key) || {};
    const max = tab.MaxQty;
    const sum = tab.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
    const tabIndex = tabs.findIndex(i => i.key === tabSelected.key);
    if (sum === max) {
      this.handleNextBtn(tabIndex);
    }
  };

  handleItemSizeModalCancel = () => {
    this.setState({
      itemSizeModalVisible: false,
    });
    this.cleanCheckItem();
  };

  handleItemSizeModalOK = info => {
    const { modifierInfos, checkItem, itemSelected } = this.state;
    checkItem.description = info.description;
    checkItem.sizeCode = info.sizeCode;
    checkItem.price = info.price;
    this.setState({ checkItem, sizeCode: info.sizeCode }, () => {
      if (itemSelected.Type === 'I') {
        this.setState({ itemSizeModalVisible: false, pizzaItemModalVisible: true });
      } else if (modifierInfos.length > 0) {
        this.setState({ itemSizeModalVisible: false, itemModsModalVisible: true });
      } else if (modifierInfos.length === 0) {
        this.setState({ itemSizeModalVisible: false });
        this.handleAddToList();
      }
    });
  };

  flattenMods = (mods, tier) => {
    const arr = [];
    mods.forEach(i => {
      switch (tier) {
        // case 0:
        //   i.description = i.description;
        //   break;
        case 1:
          i.description = `=>${i.description}`;
          break;
        case 2:
          i.description = `==>${i.description}`;
          break;
        case 3:
          i.description = `===>${i.description}`;
          break;
        case 4:
          i.description = `====>${i.description}`;
          break;
        case 5:
          i.description = `=====>${i.description}`;
          break;
        default:
          break;
      }
      let obj = JSON.parse(JSON.stringify(i));
      obj.modifierDetails = [];
      arr.push(obj);
      if (i.modifierDetails && i.modifierDetails.length > 0) {
        const nextTier = tier + 1;
        const subArr = this.flattenMods(i.modifierDetails, nextTier);
        if (subArr.length > 0) {
          subArr.forEach(j => {
            switch (nextTier) {
              case 1:
                j.description = `=>${j.description.slice(2)}`;
                break;
              case 2:
                j.description = `==>${j.description.slice(3)}`;
                break;
              case 3:
                j.description = `===>${j.description.slice(4)}`;
                break;
              case 4:
                j.description = `====>${j.description.slice(5)}`;
                break;
              case 5:
                j.description = `=====>${j.description.slice(6)}`;
                break;
              default:
                break;
            }
            arr.push(j);
          });
        }
      }
    });
    return arr;
  };

  handleAddMods = mods => {
    this.setState({ itemModsModalVisible: false });
    const { checkItem } = this.state;
    if (mods.length > 0) {
      const modsArr = this.flattenMods(mods, 0);
      checkItem.modifierDetails = modsArr;
    } else {
      checkItem.modifierDetails = [];
    }
    this.setState({ checkItem }, () => {
      this.handleAddToList();
    });
  };

  handleModsModalRedo = () => {
    const { itemSelected } = this.state;
    this.setState({ itemModsModalVisible: false, pizzaDetails: [] }, () => {
      this.handlePlusClick(itemSelected);
    });
  };

  handleModsModalCancel = () => {
    this.setState({
      itemModsModalVisible: false,
    });
    this.cleanCheckItem();
  };

  handlePackageItemDrawerClose = () => {
    this.setState({ packageItemDrawerVisible: false });
  };

  handlePackageItemCancel = () => {
    this.setState({ packageItemDrawerVisible: false });
    this.cleanCheckItem();
  };

  flattenPackageItems = packageItems => {
    const arr = [];
    packageItems.forEach(i => {
      let obj = JSON.parse(JSON.stringify(i));
      obj.modifierDetails = [];
      arr.push(obj);
      // if (i.pizzaDetails.length > 0) {
      //   i.pizzaDetails.forEach(j => {
      //     j.description = `=>${j.description}`;
      //     arr.push(j);
      //   });
      // }
      if (i.modifierDetails.length > 0) {
        const subArr = this.flattenMods(i.modifierDetails, 1);
        subArr.forEach(k => {
          arr.push(k);
        });
      }
    });
    return arr;
  };

  handlePackageChange = packageItems => {
    const { checkItem } = this.state;
    packageItems = packageItems.map(i => {
      if (i.pizzaDetails.length > 0) {
        i.pizzaDetails = i.pizzaDetails.map(j => {
          j.description = `=>${j.description}`;
          return j;
        });
      }
      return i;
    });
    if (packageItems.length > 0) {
      const packageArr = this.flattenPackageItems(packageItems);
      checkItem.packageDetails = packageArr;
      if (checkItem.packageType === '1') {
        const amount = packageArr
          .map(p => {
            let packItemSub = parseFloat(p.price) * p.qty,
              packPSub = 0;
            if (p.pizzaDetails && p.pizzaDetails.length > 0) {
              packPSub = p.pizzaDetails
                .map(k => parseFloat(k.price) * k.qty)
                .reduce((l, m) => l + m, 0);
            }
            return packItemSub + packPSub;
          })
          .reduce((u, w) => u + w, 0);
        checkItem.price = amount;
      }
    } else {
      checkItem.packageDetails = [];
      checkItem.price = '0.00';
    }
    this.setState({ checkItem, packageItemDrawerVisible: false }, () => {
      this.handleAddToList();
    });
  };

  handlePizzaModalCancel = () => {
    this.setState({ pizzaItemModalVisible: false });
    this.cleanCheckItem();
  };

  handlePizzaModalRedo = () => {
    const { itemSelected } = this.state;
    this.setState({ pizzaItemModalVisible: false }, () => {
      this.handlePlusClick(itemSelected);
    });
  };

  //isOK: 1 - "OK" button pressed; 0 - only update price
  handleAddPizzaDetails = (pizzaDetails, isOK) => {
    const { checkItem, itemSelected } = this.state;
    checkItem.pizzaDetails = pizzaDetails;
    this.setState({ checkItem }, () => {
      if (isOK === 1) {
        this.getPizzaItemPrice();
        if (itemSelected.ModifierInfos.length > 0) {
          this.setState({ pizzaItemModalVisible: false, itemModsModalVisible: true });
        }
      }
      if (isOK === 0) {
        this.getPizzaItemPrice();
      }
    });
  };

  getPizzaItemPrice = async () => {
    const { checkItem } = this.state;
    const re = await getPizzaPrice(checkItem);
    if (re.result === 'OK') {
      const arr = toCamel(re.pizzaDetails);
      checkItem.pizzaDetails = arr;
      this.setState({ pizzaTotalPrice: re.price, checkItem });
    }
  };

  handleOKBtn = () => {
    const { tabs, tabSelected, list } = this.state;
    const tab = tabs.find(i => i.key === tabSelected.key);
    const min = tab.MinQty;
    const sum = tab.ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
    if (sum < min) {
      this.handleInstructionAnimation();
      return;
    }
    this.props.onSelectionChange();
    this.props.receivePopupOrder(list);
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const {
      animated,
      tabs,
      tabSelected,
      list,
      checkItem,
      itemSelected,
      itemSizeModalVisible,
      itemModsModalVisible,
      pizzaItemModalVisible,
      packageItemDrawerVisible,
      sizeCode,
      pizzaTotalPrice,
      sizeItemInfoList,
      modifierInfos,
      packageItemInfos,
    } = this.state;
    const { clientStyle } = this.props;
    let tabIndex, tab;
    if (tabSelected && tabSelected.key) {
      tabIndex = tabs.findIndex(i => i.key === tabSelected.key);
      tab = tabs.find(i => i.key === tabSelected.key);
    }
    let la = true,
      ra = true;
    if (tabIndex === 0) {
      la = false;
    }
    if (tabs.length === 1 || (tabs.length > 1 && tabIndex === tabs.length - 1)) {
      ra = false;
    }
    const { ItemList } = tab || [];
    const max = tabSelected && tabSelected.MaxQty;
    const min = tabSelected && tabSelected.MinQty;

    let sum = 0,
      grayOut = false;
    if (ItemList && ItemList.length > 0) {
      sum = ItemList.map(i => i.Qty).reduce((a, b) => a + b, 0);
    }
    if (max > 0) {
      grayOut = sum === max;
    }

    let ins = '';
    if (min === 0 && max === 0) {
      ins = '';
    }
    if (min > 0 && max === 0) {
      ins = `Select minimum ${min}`;
    }
    if (min === 0 && max > 0) {
      ins = `Select maximum ${max}`;
    }
    if (min > 0 && max > 0) {
      ins = `Select minimum ${min}, maximum ${max}`;
    }

    const packageSelectedTotal =
      list
        .map(i => {
          let iSub = i.qty * parseFloat(i.price),
            mSub = 0,
            pSub = 0;
          if (i.modifierDetails.length > 0) {
            mSub =
              i.modifierDetails.map(j => parseFloat(j.price) * j.qty).reduce((a, b) => a + b, 0) *
              i.qty;
          }
          if (i.pizzaDetails.length > 0) {
            pSub =
              i.pizzaDetails.map(k => parseFloat(k.price) * k.qty).reduce((e, f) => e + f, 0) *
              i.qty;
          }
          return iSub + mSub + pSub;
        })
        .reduce((c, d) => c + d, 0)
        .toFixed(2) || '0.00';

    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };
    return (
      <>
        <Row>
          <Col span={10}>
            <Row style={{ padding: '0 8px' }}>
              <Col span={24}>
                <Row justify="space-between" align="bottom">
                  <Col span={12}>
                    <Button
                      width={120}
                      height={50}
                      style={{
                        fontSize: 18,
                        ...rowStyle,
                      }}
                      borderRadius={5}
                      onClick={this.handlePopupItemCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Row justify="space-between" align="bottom">
                      <Col>
                        <Row>
                          <TextTransition
                            style={{ ...titleStyle, fontSize: 18, fontWeight: 'bold' }}
                          >{`$${packageSelectedTotal}`}</TextTransition>
                        </Row>
                      </Col>
                      <Col>
                        <Row justify="end" style={{ ...titleStyle }}>
                          <div
                            className="menu-package-item-sum-title"
                            style={{ borderBottom: `3px solid ${clientStyle.colorOne}` }}
                          >
                            Summary
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{ padding: '10px 8px 0', height: window.innerHeight - 120 }}
              className="menu-package-selector-selected-pane"
            >
              <Col span={24}>
                <TransitionGroup enter={true} exit={true} appear={true}>
                  {list.map(
                    i =>
                      i.qty > 0 && (
                        <CSSTransition
                          key={i.code}
                          timeout={500}
                          classNames="menu-package-selector-selected-item"
                        >
                          <PackageSelectedItemsRow
                            key={i.code}
                            item={i}
                            packageType={1}
                            popup={true}
                          />
                        </CSSTransition>
                      ),
                  )}
                </TransitionGroup>
              </Col>
            </Row>
          </Col>
          <Col span={14}>
            <Row style={{ padding: '0 8px' }} className="menu-item-package-drawer-selector">
              <Col span={24}>
                <Row
                  justify="space-between"
                  align="middle"
                  className="menu-item-package-drawer-sticky-header"
                  style={{ backgroundColor: clientStyle.bgColor }}
                >
                  <Col span={24}>
                    <Row align="middle">
                      <Col span={5}>
                        <Row justify="start">
                          {la ? (
                            <Button
                              width={120}
                              height={50}
                              style={{
                                fontSize: 18,
                                ...rowStyle,
                              }}
                              borderRadius={5}
                              onClick={() => {
                                this.handlePrevBtn(tabIndex);
                              }}
                            >
                              Previous
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Row>
                      </Col>
                      <Col span={14}>
                        <Row justify="center">
                          {/* <div
                            style={{
                              ...titleStyle,
                              fontWeight: 'bold',
                              fontSize: 18,
                              animation: animated ? 'horizontal-shaking 700ms ease-out 300ms' : '',
                            }}
                          >
                            {tabSelected?.Message}
                          </div> */}
                        </Row>
                      </Col>
                      <Col span={5}>
                        <Row justify="end">
                          {ra ? (
                            <Button
                              width={120}
                              height={50}
                              style={{
                                fontSize: 18,
                                ...rowStyle,
                              }}
                              borderRadius={5}
                              onClick={() => {
                                this.handleNextBtn(tabIndex);
                              }}
                              className="menu-item-mods-modal-next-button"
                            >
                              Next
                            </Button>
                          ) : (
                            <Button
                              width={120}
                              height={50}
                              style={{
                                fontSize: 18,
                                ...rowStyle,
                              }}
                              borderRadius={5}
                              onClick={() => {
                                this.handleOKBtn();
                              }}
                            >
                              OK
                            </Button>
                          )}
                        </Row>
                      </Col>
                    </Row>
                    <Row justify="center" style={{ padding: '8px 5px' }}>
                      <div
                        style={{
                          ...titleStyle,
                          fontSize: 18,
                          fontWeight: 'bold',
                          animation: animated ? 'horizontal-shaking 700ms ease-out 300ms' : '',
                        }}
                      >
                        <TextTransition inline={true} delay={300} springConfig={presets.default}>
                          {tabSelected?.Message ? tabSelected?.Message : ins}
                        </TextTransition>
                      </div>
                    </Row>
                  </Col>
                </Row>
                <SwitchTransition mode="out-in">
                  <CSSTransition
                    key={tabSelected && tabSelected.key}
                    timeout={500}
                    classNames="menu-package-items-list"
                  >
                    <div className="menu-package-items-list">
                      {ItemList &&
                        ItemList.map(i => (
                          <Row
                            key={i.Code}
                            justify="space-between"
                            align="middle"
                            className="menu-package-item-selector-row"
                          >
                            <Col span={24}>
                              <Row align="middle" style={{ ...titleStyle }}>
                                <Col span={18}>
                                  <Row
                                    justify="space-between"
                                    align="middle"
                                    style={{ marginLeft: 10 }}
                                  >
                                    <Col span={17}>
                                      <h3 style={{ padding: '0 2px', ...titleStyle }}>
                                        {i.Description ? i.Description : 'Description N/A'}
                                      </h3>
                                    </Col>
                                    <Col span={7}>
                                      <Row justify="start">
                                        <h3>{this.prunePrice(i)}</h3>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={2}>
                                  <Row
                                    className="menu-item-mods-selector-list-minus"
                                    justify="center"
                                    align="middle"
                                    onClick={() => {
                                      this.handleMinusOne(i);
                                    }}
                                  >
                                    <Col>
                                      <FontAwesomeIcon
                                        icon={faCircleMinus}
                                        size="3x"
                                        color={i.Qty === 0 ? '#999' : clientStyle.primary}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={2}>
                                  <Row justify="center" align="middle">
                                    <Col>
                                      <h2 style={{ ...titleStyle }}>
                                        <VTTextTransition text={i.Qty} />
                                      </h2>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={2}>
                                  <Row
                                    className="menu-item-mods-selector-list-minus"
                                    justify="center"
                                    align="middle"
                                    onClick={() => this.handlePlusClick(i)}
                                  >
                                    <Col>
                                      <FontAwesomeIcon
                                        icon={faCirclePlus}
                                        size="3x"
                                        color={grayOut ? '#999' : clientStyle.primary}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ))}
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Popup item size modal */}
        <MenuItemSizeSelector
          visible={itemSizeModalVisible}
          onCancel={this.handleItemSizeModalCancel}
          checkItem={checkItem}
          sizes={sizeItemInfoList}
          itemSelected={itemSelected}
          onOK={obj => this.handleItemSizeModalOK(obj)}
        />
        {/* Popup item mods modal */}
        <MenuItemModsSelector
          width={800}
          visible={itemModsModalVisible}
          des={checkItem?.description}
          itemMods={modifierInfos || []}
          addMods={mods => this.handleAddMods(mods)}
          onCancel={this.handleModsModalCancel}
          onRedo={this.handleModsModalRedo}
          sizes={sizeItemInfoList || []}
        />
        {/* Menu package item drawer */}
        <Drawer
          width="100%"
          visible={packageItemDrawerVisible}
          destroyOnClose={true}
          maskClosable={false}
          closable={false}
          drawerStyle={{ backgroundColor: '#f0f2f5', position: 'relative' }}
          maskStyle={{ backgroundColor: clientStyle.backdrop }}
          bodyStyle={{ backgroundColor: clientStyle.bgColor }}
        >
          <Row>
            <Col span={24}>
              <PackageItemSelector
                onPackageItemCancel={this.handlePackageItemCancel}
                packageItemInfos={packageItemInfos}
                packageType={checkItem.packageType}
                packagePrice={checkItem.packageType !== '1' ? itemSelected?.Price : 0}
                onSelectionChange={packageItems => {
                  this.handlePackageChange(packageItems);
                }}
              />
            </Col>
          </Row>
        </Drawer>
        <MenuPizzaModal
          visible={pizzaItemModalVisible}
          item={itemSelected}
          checkItem={checkItem}
          pizzaTotalPrice={pizzaTotalPrice}
          sizeCode={sizeCode}
          onPizzaModalCancel={this.handlePizzaModalCancel}
          onPizzaModalRedo={this.handlePizzaModalRedo}
          addPizzaDetails={(pizzaDetails, isOK) => this.handleAddPizzaDetails(pizzaDetails, isOK)}
        />
      </>
    );
  }
}

export default connect(state => ({ clientStyle: state.clientStyle }), { receivePopupOrder })(
  PopupItemSelector,
);
