import React, { Component } from 'react';
import { Row, Col, Input, message } from 'antd';
import { connect } from 'react-redux';
import Keyboard from 'react-simple-keyboard';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { updateUserPassword } from '../../../api';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import './index.css';

class AdminPassword extends Component {
  state = {
    error: '',
    visible: false,
    input: {},
    inputName: '',
    layout: 'default',
    shift: false,
    cap: false,
    spinning: false,
  };

  refNew = React.createRef();
  refConfirm = React.createRef();

  keyboardOptions = {
    ...this.commonKeyboardOptions,
    layout: {
      default: [
        '1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        'q w e r t y u i o p [ ] \\',
        "{lock} a s d f g h j k l ; '",
        '{shift} z x c v b n m , . /',
        '.com @ {space}',
      ],
      shift: [
        '! @ # $ % ^ &amp; * ( ) _ + {bksp}',
        'Q W E R T Y U I O P { } |',
        '{lock} A S D F G H J K L : "',
        '{shift} Z X C V B N M &lt; &gt; ?',
        '.com @ {space}',
      ],
    },
  };

  init = () => {
    const input = {
      newpassword: '',
      confirmnewpassword: '',
    };

    this.setState({ input });
  };

  onKeyboardChange = vInput => {
    const { input } = this.state;
    for (const key in input) {
      if (Object.hasOwnProperty.call(vInput, key)) {
        input[key] = vInput[key];
      }
    }
    this.setState({ input });
  };

  onKeyPress = button => {
    if (button === '{shift}' || button === '{lock}') this.handleShift();
  };

  onKeyReleased = button => {
    const { error } = this.state;
    if (error !== '') {
      this.setState({ error: '' });
    }
    const { shift, cap } = this.state;
    if (button === '{shift}') {
      this.setState({ shift: true });
      return;
    }
    if (button === '{lock}') {
      this.setState({ cap: !this.state.cap });
      return;
    }
    if (button !== '{shift}' && button !== '{lock}' && shift && !cap) {
      this.setState({ layout: 'default' });
    }
  };

  handleShift = () => {
    const { layout } = this.state;
    this.setState({ layout: layout === 'default' ? 'shift' : 'default' });
  };

  handleNewPwdOnFocus = () => {
    this.setState({ inputName: 'newpassword' });
    if (!this.state.visible) {
      this.setState({ visible: true });
    }
  };

  handleConformNewPwdOnFocus = () => {
    this.setState({ inputName: 'confirmnewpassword' });
    if (!this.state.visible) {
      this.setState({ visible: true });
    }
  };

  handleNewPwdChange = e => {
    const { input } = this.state;
    input.newpassword = e.target.value;
    this.setState({ input });
    if (e.target.value.trim().length > 0) {
      this.setState({ error: '' });
    }
    this.keyboard && this.keyboard.setInput(e.target.value, 'newpassword');
  };

  handleConformNewPwdChange = e => {
    const { input } = this.state;
    input.confirmnewpassword = e.target.value;
    this.setState({ input });
    if (e.target.value.trim().length > 0) {
      this.setState({ error: '' });
    }
    this.keyboard && this.keyboard.setInput(e.target.value, 'confirmnewpassword');
  };

  handleSaveBtn = async () => {
    const { input } = this.state;
    const {
      user: { UserName },
    } = this.props;

    if (!input.hasOwnProperty('newpassword') || input.newpassword.trim() === '') {
      this.setState({ error: 'Please enter your new password' });
      this.refNew.current.focus();
      return;
    }

    if (!input.hasOwnProperty('confirmnewpassword') || input.confirmnewpassword.trim() === '') {
      this.setState({ error: 'Please enter your new password again' });
      this.refConfirm.current.focus();
      return;
    }

    if (
      input.hasOwnProperty('confirmnewpassword') &&
      input.hasOwnProperty('newpassword') &&
      input.newpassword.trim() &&
      input.confirmnewpassword.trim()
    ) {
      if (input.newpassword.trim() !== input.confirmnewpassword.trim()) {
        this.setState({ error: 'Passwords entered do not match!', input: {} });
        this.refNew.current.focus();
        return;
      }
    }

    this.keyboard.clearInput();

    try {
      this.setState({ spinning: true });
      const re = await updateUserPassword(UserName, input.newpassword);
      if (re && re.result === 'OK') {
        this.setState({ spinning: false, input: {} });
        this.keyboard.clearInput();
        message.success('Password successfully updated!', 3);
      } else if (!re || re.result !== 'OK') {
        this.setState({ spinning: false, input: {} });
        this.keyboard.clearInput();
        throw new Error();
      }
    } catch (error) {
      message.error('Error updating password!');
    }
  };

  handlePEnterKey = e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      this.handleSaveBtn();
    }
  };

  handleClickOutside = e => {
    if (this.state.visible) {
      if (!(e.target.nodeName === 'INPUT') && !e.target.classList.contains('hg-button')) {
        this.setState({ visible: false });
      }
    }
  };

  componentDidMount() {
    this.init();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const { error, input, layout, inputName, spinning, visible } = this.state;
    return (
      <Spin spinning={spinning}>
        <Row className="admin-password-wrapper">
          <Col span={24}>
            <AdminHeader
              title="Change Password"
              showBack={false}
              showRightBtn={true}
              onRightBtnClick={this.handleSaveBtn}
              rightBtnWidth={180}
              rightBtnText="Change Password"
            />
            <Row
              style={{
                padding: 15,
                height: 30,
                fontWeight: 'bold',
                color: '#8b0000',
                fontSize: 18,
                marginBottom: 20,
              }}
              justify="center"
            >
              <Col>
                <SwitchTransition>
                  <CSSTransition key={error} timeout={500} classNames="login-account-error">
                    <Row>{error}</Row>
                  </CSSTransition>
                </SwitchTransition>
              </Col>
            </Row>
            {/* Inputs */}
            <Row justify="center" style={{ marginBottom: 30 }}>
              <Col span={24}>
                <Row align="middle" style={{ marginBottom: 20 }}>
                  <Col span={10}>
                    <Row
                      justify="end"
                      style={{ padding: '0 20px', fontSize: 18, fontWeight: 'bold' }}
                    >
                      New Password
                    </Row>
                  </Col>
                  <Col span={14}>
                    <Row style={{ width: 300 }}>
                      <Input.Password
                        ref={this.refNew}
                        size="large"
                        spellCheck={false}
                        value={input.newpassword}
                        onFocus={this.handleNewPwdOnFocus}
                        onChange={this.handleNewPwdChange}
                        onKeyUp={this.handlePEnterKey}
                        visibilityToggle={false}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row align="middle" style={{ marginBottom: 20 }}>
                  <Col span={10}>
                    <Row
                      justify="end"
                      style={{ padding: '0 20px', fontSize: 18, fontWeight: 'bold' }}
                    >
                      Confirm New Password
                    </Row>
                  </Col>
                  <Col span={14}>
                    <Row style={{ width: 300 }}>
                      <Input.Password
                        ref={this.refConfirm}
                        size="large"
                        spellCheck={false}
                        value={input.confirmnewpassword}
                        onFocus={this.handleConformNewPwdOnFocus}
                        onChange={this.handleConformNewPwdChange}
                        onKeyUp={this.handlePEnterKey}
                        visibilityToggle={false}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Keyboard */}
            <Row justify="center">
              {visible && (
                <Keyboard
                  baseClass={'simple-keyboard-main'}
                  keyboardRef={r => (this.keyboard = r)}
                  layoutName={layout}
                  onChangeAll={this.onKeyboardChange}
                  onKeyPress={this.onKeyPress}
                  onKeyReleased={this.onKeyReleased}
                  inputName={inputName}
                  theme={'hg-theme-default hg-layout-default'}
                  preventMouseDownDefault={true}
                  {...this.keyboardOptions}
                />
              )}
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default connect(state => ({ user: state.user }), {})(AdminPassword);
