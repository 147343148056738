import React, { Component } from 'react';
import { withIdleTimer } from 'react-idle-timer';
import { connect } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { Row, Col, Divider, Input, message } from 'antd';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import {
  clearOrderItems,
  clearScannedOrderItems,
  resetUser,
  deleteGuestName,
  resetCustomerOrderOption,
  resetLoginType,
  resetAppMode,
  receiveGuestName,
  receiveGuestPhone,
  deleteGuestPhone,
  receiveGuestIns,
  deleteGuestIns,
} from '../../redux/actions';
import {
  createOrder,
  addPaymentToCheck,
  getGiftCardInfo,
  getRewardPointsInfo,
  setOrderSetup,
  printBill,
} from '../../api';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import ErrorModal from '../../components/ErrorModal';
import OrderInstructionModal from '../../components/OrderInstructionModal';
import LoadingModal from '../../components/LoadingModal';
import OrderSplitBillSeletor from '../../components/OrderSplitBillSelector';

import './index.css';

const { TextArea } = Input;
const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

class Order extends Component {
  state = {
    insModalVisible: false,
    insModalFor: '',
    check: {},
    checkSum: null,
    pinPadInsModalVisible: false,
    cardSwipeModalVisible: false,
    multiPaymentChoiceModalVisible: false,
    paymentSuccessModalVisible: false,
    paymentFailureModalVisible: false,
    paymentErrorModalVisible: false,
    splitBillModalVisible: false,
    paymentErrorMsg: '',
    loadingModalVisible: '',
    loadingModalMsg: '',
    paymentSuccessMsg: '',
    orderNum: '',
    splitBalance: 0,
    splitPayments: [],
    multiPayments: [],
    tempPartialPayment: {},
    receiptPrinting: 0, // 0: hasn't clicked print; 1: just clicked, calling method; 2: successfully printed
  };

  refInstructionBox = React.createRef();
  listRef = React.createRef();

  checkGuestName = () => {
    const {
      guestName,
      guestPhone,
      kioskSetting: {
        appSetting: { GuestNameRequired, CollectGuestPhoneNumber },
      },
    } = this.props;
    const AccountNumber = this.props.user?.CurAccountInfo?.AccountNumber ?? '';
    if (!AccountNumber && !guestName && GuestNameRequired) {
      this.setState({ insModalFor: 'GUESTNAME' }, () => {
        this.setState({ insModalVisible: true });
      });
    } else if (CollectGuestPhoneNumber && !guestPhone) {
      this.checkGuestPhone();
    }
  };

  checkGuestPhone = () => {
    this.setState({ insModalFor: 'GUESTPHONE' }, () => {
      this.setState({ insModalVisible: true });
    });
  };

  handleGoBackToMenu = () => {
    this.props.history.replace('/menu');
  };

  handleGoBackToScan = () => {
    this.props.history.replace('/scan');
  };

  handleInstructionBoxOnFocus = () => {
    this.setState({ insModalFor: 'INSTRUCTIONS' }, () => {
      this.setState({ insModalVisible: true });
      this.refInstructionBox.current.blur();
    });
  };

  handleNameBoxOnFocus = () => {
    this.setState({ insModalFor: 'GUESTNAME' }, () => {
      this.setState({ insModalVisible: true });
    });
  };
  handlePhoneBoxOnFocus = () => {
    this.setState({ insModalFor: 'GUESTPHONE' }, () => {
      this.setState({ insModalVisible: true });
    });
  };

  handleInsModalCancel = () => {
    this.setState({ insModalVisible: false });
  };

  handleInsTextChange = (text, dataType) => {
    if (dataType === 'GUESTNAME') {
      this.props.receiveGuestName(text);
      this.setState({ insModalVisible: false }, () => {
        const {
          guestPhone,
          kioskSetting: {
            appSetting: { CollectGuestPhoneNumber },
          },
        } = this.props;
        if (CollectGuestPhoneNumber && !guestPhone) {
          this.checkGuestPhone();
        } else {
          this.updateOrderSetup();
        }
      });
    } else if (dataType === 'GUESTPHONE') {
      this.props.receiveGuestPhone(text);
      this.setState({ insModalVisible: false }, () => {
        this.updateOrderSetup();
      });
    } else {
      this.props.receiveGuestIns(text);
      this.setState({ insModalVisible: false });
    }
  };

  updateOrderSetup = async () => {
    const { customerOrderOption, guestName, guestPhone } = this.props;
    const AccountNumber = this.props.user?.CurAccountInfo?.AccountNumber ?? '';
    const obj = {};
    obj.AccountNumber = AccountNumber;
    obj.ProfitCentreCode = customerOrderOption?.orderOption?.CentreCode ?? '';
    obj.RequestedDate = customerOrderOption?.date?.DateShort ?? '';
    obj.RequestedTime = customerOrderOption?.timeslot?.Time ?? '';
    obj.GuestName = !guestName
      ? ''
      : guestName && guestName.length > 15
      ? guestName.substring(0, 15)
      : guestName;
    obj.GuestPhoneNumber = guestPhone;

    try {
      const res = await setOrderSetup(obj);
      if (!res || res !== 'OK') throw new Error(res.result);
    } catch (error) {
      message.error('Error obtaining settings!', 3);
    }
  };

  //0: None
  //1: PayAtCounter
  // 2. CreditCard
  // 3. Account
  // 4. GiftCard
  // 5. RewardPoints
  //6. DebitCard
  handlePaymentBtns = type => {
    if (type === 1) {
      this.setState(
        {
          loadingModalVisible: true,
          loadingModalMsg: 'Your order is being processed...',
        },
        () => {
          setTimeout(() => {
            this.handlePayAtCounter();
          }, 1000);
        },
      );
    }

    if (type === 2) {
      this.setState({ pinPadInsModalVisible: true }, () => {
        setTimeout(() => {
          this.handleCreditCardPayment(type);
        }, 1000);
      });
    }

    if (type === 3) {
      this.setState(
        {
          loadingModalVisible: true,
          loadingModalMsg: 'Your payment is being processed...',
        },
        () => {
          setTimeout(() => {
            this.handlePayByAccount(type);
          }, 1000);
        },
      );
    }

    if (type === 4) {
      //Check kiosksetting's integrationtype, if 1 or 4, pass type 4 instruct pinpad
      const { GiftCardIntegrationType } = this.props.kioskSetting?.appSetting ?? null;
      if (GiftCardIntegrationType === 1 || GiftCardIntegrationType === 4) {
        this.setState({ pinPadInsModalVisible: true }, () => {
          setTimeout(() => {
            this.handleCreditCardPayment(type);
          }, 1000);
        });
      } else {
        this.setState({ cardSwipeModalVisible: true });
      }
    }

    if (type === 5) {
      this.setState(
        {
          loadingModalVisible: true,
          loadingModalMsg: 'Your payment is being processed...',
        },
        () => {
          setTimeout(() => {
            this.getRewardPointsInfo(type);
          }, 1000);
        },
      );
    }
  };

  handlePayAtCounter = async () => {
    this.props.pause();
    const { checkSum } = this.props.location.state;
    const re = await createOrder(checkSum);
    if (re && re.Error === false && !re.ErrorText) {
      this.setState({
        check: re,
        loadingModalVisible: false,
        paymentSuccessModalVisible: true,
        paymentSuccessMsg: 'Please proceed to counter to pay for',
        orderNum: re.CheckNumber,
      });
    }
    if (re && re.Error === true && re.ErrorText) {
      this.setState({
        loadingModalVisible: false,
        paymentErrorMsg: re.ErrorText,
        paymentFailureModalVisible: true,
      });
    }
    this.props.reset();
  };

  getRemainingAmt = () => {
    const { checkSum, multiPayments } = this.state;
    let amount;
    if (multiPayments.length === 0) {
      amount = checkSum.OrderSummary.Balance;
    } else {
      amount = formatter.format(
        checkSum.OrderSummary.Balance - multiPayments.map(i => i.Amount).reduce((a, b) => a + b, 0),
      );
    }
    return amount;
  };

  handlePayment = async (type, amount, acctNum) => {
    this.props.pause();
    const { checkSum, multiPayments } = this.state;
    const { guestIns, clientStyle } = this.props;
    const paymentInfo = {};
    paymentInfo.PaymentType = type;
    paymentInfo.Amount = amount;
    paymentInfo.Tip = 0;
    paymentInfo.AcctNo = acctNum;
    paymentInfo.ExpDate = '';
    paymentInfo.TransDate = '';
    paymentInfo.Description = '';
    paymentInfo.SubCargo = '';
    checkSum.Instruction = guestIns;
    const re = await addPaymentToCheck(paymentInfo, checkSum, multiPayments);
    if (re && re.Error === false && !re.ErrorText) {
      this.setState({
        check: re,
        loadingModalVisible: false,
        pinPadInsModalVisible: false,
        cardSwipeModalVisible: false,
        paymentSuccessModalVisible: true,
        paymentSuccessMsg:
          clientStyle.storeNumber === '5960'
            ? 'Thank you! \nWe will text you when your order is ready'
            : 'Thank you for your order!',
        orderNum: re.CheckNumber,
        multiPayments: [],
      });
    }
    if (re && re.Error === true && re.ErrorText) {
      if (re.IsOrderProcessed === 1) {
        this.setState({
          loadingModalVisible: false,
          pinPadInsModalVisible: false,
          cardSwipeModalVisible: false,
          paymentErrorMsg: re.ErrorText,
          paymentErrorModalVisible: true,
          multiPayments: [],
        });
        return;
      }
      this.setState({
        loadingModalVisible: false,
        pinPadInsModalVisible: false,
        cardSwipeModalVisible: false,
        paymentErrorMsg: re.ErrorText,
        paymentFailureModalVisible: true,
        multiPayments: [],
      });
    }
    this.props.reset();
  };

  handleCreditCardPayment = type => {
    const amount = this.getRemainingAmt();
    const acctNum = '';
    this.handlePayment(type, amount, acctNum);
  };

  handlePayByAccount = type => {
    const { AccountNumber } = this.props.user?.CurAccountInfo ?? null;
    const amount = this.getRemainingAmt();
    this.handlePayment(type, amount, AccountNumber);
  };

  handleGiftCardModalClose = () => {
    this.setState({ cardSwipeModalVisible: false });
  };

  handleGiftCardModalInputEnterKeyDown = e => {
    if (e.key === 'Enter') {
      if (e.target.value.trim() === '') return;
      this.getGiftCardInfo(e.target.value);
      e.target.value = '';
    }
    // this.getGiftCardInfo(';603628766552002029013?');
  };

  getGiftCardInfo = async cardNum => {
    const re = await getGiftCardInfo(cardNum);
    if (!re || (re && re.result !== 'OK')) {
      this.setState({
        cardSwipeModalVisible: false,
        paymentErrorMsg: re.result,
        paymentFailureModalVisible: true,
      });
      return;
    }
    const { multiPayments } = this.state;
    const { AllowPartialPayment, Balance, CardNumber } = re.data;
    const pooled =
      multiPayments.filter(i => i.PaymentType === 4 && i.AcctNo === CardNumber).length > 0;
    if (pooled) {
      this.setState({
        cardSwipeModalVisible: false,
        paymentErrorMsg: 'Card is already in use for partial payment.',
        paymentFailureModalVisible: true,
      });
      return;
    }

    if (Balance <= 0) {
      this.setState({
        cardSwipeModalVisible: false,
        paymentErrorMsg: 'Gift card amount is 0.00.',
        paymentFailureModalVisible: true,
      });
      return;
    }
    const amount = this.getRemainingAmt();
    if (Balance >= amount) {
      this.handlePayment(4, amount, CardNumber);
    }
    if (Balance < amount) {
      if (!AllowPartialPayment) {
        this.setState({
          cardSwipeModalVisible: false,
          paymentErrorMsg: 'Gift card amount is insufficient.',
          paymentFailureModalVisible: true,
        });
        return;
      }
      const paymentInfo = {};
      paymentInfo.PaymentType = 4;
      paymentInfo.Amount = Balance;
      paymentInfo.Tip = 0;
      paymentInfo.AcctNo = CardNumber;
      paymentInfo.ExpDate = '';
      paymentInfo.TransDate = '';
      paymentInfo.Description = '';
      paymentInfo.SubCargo = '';
      this.setState(
        {
          tempPartialPayment: paymentInfo,
        },
        () => {
          this.setState({ multiPaymentChoiceModalVisible: true });
        },
      );
    }
  };

  handleAddMultiPayment = () => {
    const { tempPartialPayment, multiPayments } = this.state;
    this.setState(
      {
        multiPaymentChoiceModalVisible: false,
        cardSwipeModalVisible: false,
        multiPayments: [...multiPayments, tempPartialPayment],
      },
      () => {
        this.setState({ tempPartialPayment: {} });
      },
    );
  };

  handleCancelMultiPayment = () => {
    this.setState({
      multiPaymentChoiceModalVisible: false,
      cardSwipeModalVisible: false,
      tempPartialPayment: {},
    });
  };

  getRewardPointsInfo = async type => {
    const { AccountNumber: acctNo } = this.props.user?.CurAccountInfo ?? null;
    const re = await getRewardPointsInfo(acctNo);
    if (!re || (re && re.result !== 'OK')) {
      this.setState({
        loadingModalVisible: false,
        paymentErrorMsg: re.result,
        paymentFailureModalVisible: true,
      });
      return;
    }
    const { AccountNumber, Amount } = re.data;
    if (Amount <= 0) {
      this.setState({
        loadingModalVisible: false,
        paymentErrorMsg: 'Points amount is 0.00.',
        paymentFailureModalVisible: true,
      });
      return;
    }

    const amt = this.getRemainingAmt();
    if (Amount >= amt) {
      this.handlePayment(type, amt, AccountNumber);
    }
    if (Amount < amt) {
      const paymentInfo = {};
      paymentInfo.PaymentType = type;
      paymentInfo.Amount = Amount;
      paymentInfo.Tip = 0;
      paymentInfo.AcctNo = AccountNumber;
      paymentInfo.ExpDate = '';
      paymentInfo.TransDate = '';
      paymentInfo.Description = '';
      paymentInfo.SubCargo = '';
      this.setState(
        {
          tempPartialPayment: paymentInfo,
        },
        () => {
          this.setState({ multiPaymentChoiceModalVisible: true, loadingModalVisible: false });
        },
      );
    }
  };

  handlePaymentSuccessTimerFinish = () => {
    this.setState({ paymentSuccessModalVisible: false });
    this.props.clearOrderItems();
    //sign out user
    this.props.resetUser();
    this.props.clearScannedOrderItems();
    this.props.deleteGuestName();
    this.props.deleteGuestPhone();
    this.props.deleteGuestIns();
    this.props.resetCustomerOrderOption();
    this.props.resetLoginType();
    this.props.resetAppMode();
    this.props.history.push('./');
  };

  renderTimer = ({ remainingTime }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ fontSize: 40 }}>{remainingTime}</div>
      </div>
    );
  };

  handleOrderLogOut = () => {
    const { paymentSuccessModalVisible } = this.state;
    paymentSuccessModalVisible && this.setState({ paymentSuccessModalVisible: false });
    this.props.clearOrderItems();
    this.props.clearScannedOrderItems();
    //sign out user
    this.props.resetUser();
    this.props.deleteGuestName();
    this.props.deleteGuestPhone();
    this.props.deleteGuestIns();
    this.props.resetCustomerOrderOption();
    this.props.resetLoginType();
    this.props.resetAppMode();
    this.props.history.push('./');
  };

  handlePrintReceipt = async () => {
    const { check, receiptPrinting } = this.state;
    if (receiptPrinting !== 0) return;
    try {
      this.setState({ receiptPrinting: 1 });
      const re = await printBill(check);
      if (!re || (re && re.result !== 'OK')) throw new Error(re.result);
      this.setState({ receiptPrinting: 2 });
    } catch (error) {
      this.setState({ receiptPrinting: 0 });
      message.error(error.message || 'Error printing receipt!', 3);
    }
  };

  handlePaymentFailModalOK = () => {
    this.setState({ paymentFailureModalVisible: false }, () => {
      this.setState({ paymentErrorMsg: '' });
    });
  };

  handlePaymentErrorModalOK = () => {
    this.setState({ paymentErrorModalVisible: false }, () => {
      this.setState({ paymentErrorMsg: '' });
    });
    this.handleOrderLogOut();
  };

  handleSplitBillBtn = () => {
    const { checkSum } = this.props.location.state;
    const {
      OrderSummary: { Balance },
    } = checkSum;
    const balStr = parseFloat(Balance).toFixed(2);
    this.setState({ splitBalance: balStr }, () => {
      this.setState({ splitBillModalVisible: true });
    });
  };

  handleSplitBillCancel = () => {
    this.setState({ splitBillModalVisible: false });
  };

  handleSplitDemoPayment = (type, amt) => {
    this.setState({ pinPadInsModalVisible: true }, () => {
      setTimeout(() => {
        this.handleDemoSplitPayment(type, amt);
      }, 1000);
    });
  };

  handleDemoSplitPayment = (type, amt) => {
    const { splitPayments, splitBalance } = this.state;
    const amount = formatter.format(parseFloat(amt));
    const currbalance = formatter.format(parseFloat(splitBalance));
    const newBalance = (currbalance - amount).toFixed(2);
    const obj = {};
    obj.key = nanoid();
    obj.amount = amount;
    obj.items = [];
    if (newBalance === '0.00') {
      setTimeout(() => {
        this.setState({
          splitPayments: [],
          splitBalance: 0,
          pinPadInsModalVisible: false,
          splitBillModalVisible: false,
          paymentSuccessModalVisible: true,
          paymentSuccessMsg: 'Payment Successful!',
        });
      }, 2000);
    } else {
      this.setState({ splitPayments: [...splitPayments, obj], splitBalance: newBalance }, () => {
        setTimeout(() => {
          this.setState({
            pinPadInsModalVisible: false,
            // paymentSuccessModalVisible: true,
            // paymentSuccessMsg: 'Payment Successful!',
          });
        }, 2000);
      });
    }
  };

  componentDidMount = () => {
    const { checkSum } = this.props.location.state;
    this.setState({ checkSum });
    this.checkGuestName();
    // this.setState({ paymentSuccessModalVisible: true });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.multiPayments !== prevState.multiPayments) {
      this.listRef.current?.lastElementChild?.scrollIntoView({
        behavior: 'smooth',
      });
    }

    if (this.props.isIdle()) {
      this.onIdleTimerFinish();
    } else {
      this.props.reset();
    }
  };

  render() {
    const {
      checkSum,
      insModalVisible,
      insModalFor,
      pinPadInsModalVisible,
      cardSwipeModalVisible,
      multiPaymentChoiceModalVisible,
      paymentSuccessModalVisible,
      paymentFailureModalVisible,
      paymentErrorModalVisible,
      splitBillModalVisible,
      paymentErrorMsg,
      loadingModalVisible,
      loadingModalMsg,
      paymentSuccessMsg,
      orderNum,
      splitBalance,
      splitPayments,
      multiPayments,
      tempPartialPayment,
      receiptPrinting,
    } = this.state;
    const {
      kioskSetting: {
        appSetting: {
          EnablePayAtCounter,
          EnableCreditCardPayment,
          EnableHousePayment,
          EnableGiftCard,
          EnableLoyaltyPayment,
          GuestNameRequired,
          CollectGuestPhoneNumber,
          OrderCompletedCountDown,
          AutoPrintReceipt,
        },
      },
      menuOrder,
      scannedOrder,
      loginType,
      clientStyle,
      guestName,
      guestPhone,
      guestIns,
    } = this.props;
    const { isScanned } = this.props.location.state || null;
    const { OrderSummary, Adjustments } = checkSum || {};

    let order = [];
    if (isScanned) {
      order = scannedOrder;
    } else {
      order = menuOrder;
    }

    const multiPayBalance = formatter.format(
      OrderSummary?.Balance - multiPayments.map(i => i.Amount).reduce((a, b) => a + b, 0),
    );

    const screen = window.innerWidth;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const descStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontTwo,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };

    return (
      <>
        {screen >= 1200 ? (
          <Row
            style={{
              padding: '3px 15px 0',
              backgroundColor: clientStyle.bgColor,
              height: '100vh',
            }}
            gutter={16}
          >
            {/* Left Side */}
            <Col span={10}>
              <Row align="middle">
                <Col span={14}>
                  <Row align="middle" style={{ height: 50 }}>
                    {multiPayments.length === 0 && !isScanned ? (
                      <Button
                        width={250}
                        height={50}
                        icon="menu"
                        iconSize="2xl"
                        style={{ ...rowStyle }}
                        textStyle={{ fontSize: 18 }}
                        borderRadius={5}
                        onClick={this.handleGoBackToMenu}
                      >
                        Back to Menu
                      </Button>
                    ) : multiPayments.length === 0 && isScanned ? (
                      <Button
                        width={150}
                        height={50}
                        icon="back"
                        iconSize="2xl"
                        style={{ ...rowStyle }}
                        textStyle={{ fontSize: 18 }}
                        borderRadius={5}
                        onClick={this.handleGoBackToScan}
                      >
                        Back
                      </Button>
                    ) : null}
                  </Row>
                </Col>
                <Col span={10}>
                  <Row justify="end">
                    <h1 style={{ ...titleStyle }}>Order Summary</h1>
                  </Row>
                </Col>
              </Row>
              <Divider style={{ borderColor: 'transparent', margin: 5 }} />
              {/* Instructions Row */}
              <Row
                justify="center"
                style={{
                  marginBottom: 10,
                }}
              >
                <TextArea
                  ref={this.refInstructionBox}
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  placeholder=" Special Instructions"
                  spellCheck={false}
                  maxLength={100}
                  onFocus={this.handleInstructionBoxOnFocus}
                  value={guestIns}
                  style={{ ...descStyle }}
                />
              </Row>
              <Divider style={{ borderColor: 'transparent', margin: 5 }} />
              {/* Name, Phone Row */}
              <Row gutter={16}>
                <Col span={12}>
                  {GuestNameRequired ? (
                    <Row style={{ ...titleStyle }}>
                      Guest Name{' '}
                      <Input
                        placeholder="Guest Name"
                        spellCheck={false}
                        onFocus={this.handleNameBoxOnFocus}
                        value={guestName}
                        style={{ ...descStyle }}
                      />
                    </Row>
                  ) : null}
                </Col>
                <Col span={12}>
                  {CollectGuestPhoneNumber ? (
                    <Row style={{ ...titleStyle }}>
                      Guest Phone{' '}
                      <Input
                        placeholder="Guest Cell Phone"
                        spellCheck={false}
                        onFocus={this.handlePhoneBoxOnFocus}
                        value={guestPhone}
                        style={{ ...descStyle }}
                      />
                    </Row>
                  ) : null}
                </Col>
              </Row>
              <Divider style={{ borderColor: 'transparent', margin: 5 }} />
              {/* Order Sum List */}
              <Row
                className="order-sum-pane"
                style={{
                  height: window.innerHeight - 210,
                  overflow: 'scroll',
                  paddingBottom: 30,
                }}
              >
                <Col span={24}>
                  {order.map(i => {
                    let data = [];
                    if (i && i.type === 'P' && i.packageDetails && i.packageDetails.length > 0) {
                      let packageArr = [];
                      i.packageDetails.forEach(j => {
                        packageArr.push(j);
                        if (j.type === 'I' && j.pizzaDetails.length > 0) {
                          j.pizzaDetails.forEach(k => {
                            packageArr.push(k);
                          });
                        }
                      });

                      data = packageArr || [];
                    } else if (i && i.type === 'I') {
                      data = [...i.pizzaDetails, ...i.modifierDetails];
                    } else if (
                      i &&
                      i.type !== 'P' &&
                      i.modifierDetails &&
                      i.modifierDetails.length > 0
                    ) {
                      data = i.modifierDetails || [];
                    }
                    return (
                      <div key={i.key} style={{ ...titleStyle }}>
                        <Row
                          key={i.code}
                          className="order-sum-list-item-row"
                          align="middle"
                          style={{ ...rowStyle }}
                        >
                          <Col span={18}>
                            <Row justify="start">
                              {i.description ? i.description : 'Description N/A'}
                            </Row>
                          </Col>
                          <Col span={3}>
                            <Row justify="start">{i.qty}</Row>
                          </Col>
                          <Col span={3}>
                            <Row justify="start">
                              {i.packageType === '1' ? '' : `$${parseFloat(i.price).toFixed(2)}`}
                            </Row>
                          </Col>
                        </Row>
                        {data.length > 0 &&
                          data.map((p, index) => (
                            <Row
                              key={index + i.key + i.code + p.code}
                              className="order-sum-list-mod-row"
                              align="middle"
                              style={{ ...rowStyle, filter: 'brightness(1.2) opacity(0.9)' }}
                            >
                              <Col span={18}>
                                <Row justify="start">
                                  {p.description ? p.description : 'Description N/A'}
                                </Row>
                              </Col>
                              <Col span={3}>
                                <Row justify="start">{p.qty}</Row>
                              </Col>
                              <Col span={3}>
                                <Row justify="start">{`$${parseFloat(p.price).toFixed(2)}`}</Row>
                              </Col>
                            </Row>
                          ))}
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Col>
            {/* Right Side */}
            <Col span={14}>
              <Row className="order-total-pane" style={{ backgroundColor: clientStyle.primary }}>
                <Col span={6}></Col>
                <Col span={12}>
                  <Row
                    style={{
                      ...titleStyle,
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginBottom: 10,
                      ...rowStyle,
                    }}
                  >
                    <Col span={12}>
                      <Row style={{ padding: '0 10px' }}>Subtotal</Row>
                    </Col>
                    <Col span={12}>
                      <Row justify="end" style={{ padding: '0 10px' }}>
                        {`$${parseFloat(OrderSummary && OrderSummary.AmountBeforeTax).toFixed(
                          2,
                        )}` || '$0.00'}
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ ...titleStyle, fontSize: 16, marginBottom: 10, ...rowStyle }}>
                    <Col span={12}>
                      <Row style={{ padding: '0 10px' }}>Taxes</Row>
                    </Col>
                    <Col span={12}>
                      <Row justify="end" style={{ padding: '0 10px' }}>
                        {`$${parseFloat(OrderSummary && OrderSummary.Tax).toFixed(2)}` || '$0.00'}
                      </Row>
                    </Col>
                  </Row>

                  {/* <Divider /> */}
                  <div className="order-sum-discount-pane" style={{ maxHeight: '15vh' }}>
                    {Adjustments?.length > 0 &&
                      Adjustments.map(
                        (i, index) =>
                          i.AdjType !== 4 && (
                            <Row
                              key={index}
                              style={{
                                ...titleStyle,
                                fontSize: 16,
                                marginBottom: 10,
                                ...rowStyle,
                              }}
                            >
                              <Col span={12}>
                                <Row style={{ padding: '0 10px' }}>
                                  {i.Description || 'Description N/A'}
                                </Row>
                              </Col>
                              <Col span={12}>
                                <Row justify="end" style={{ padding: '0 10px' }}>
                                  -{`$${parseFloat(i.DiscountAmount).toFixed(2)}` || '$0.00'}
                                </Row>
                              </Col>
                            </Row>
                          ),
                      )}
                  </div>
                  {/* )} */}
                  <Divider style={{ margin: 5 }} />
                  <Row style={{ ...titleStyle, fontSize: 22, fontWeight: 'bold', ...rowStyle }}>
                    <Col span={12}>
                      <Row style={{ padding: '0 10px' }}>Total</Row>
                    </Col>
                    <Col span={12}>
                      <Row justify="end" style={{ padding: '0 10px' }}>
                        {`$${parseFloat(OrderSummary && OrderSummary.Balance).toFixed(2)}` ||
                          '$0.00'}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={6}></Col>
              </Row>
              {/* Multiple payments Row */}
              {multiPayments.length > 0 ? (
                <Row className="order-multi-payments-wrapper" style={{ maxHeight: '15vh' }}>
                  <Col span={6}></Col>
                  <Col span={12}>
                    <ul ref={this.listRef}>
                      {multiPayments.map((i, index) => (
                        <li key={index}>
                          <Row style={{ ...titleStyle, fontSize: 16, marginBottom: 10 }}>
                            <Col span={12}>
                              <Row style={{ padding: '0 10px' }}>
                                {i.PaymentType === 2
                                  ? 'Credit Card'
                                  : i.PaymentType === 3
                                  ? 'Account'
                                  : i.PaymentType === 4
                                  ? 'Gift Card'
                                  : i.PaymentType === 5
                                  ? 'Points'
                                  : i.PaymentType === 6
                                  ? 'Debit Card'
                                  : 'Unkown'}
                              </Row>
                            </Col>
                            <Col span={12}>
                              <Row justify="end" style={{ padding: '0 10px' }}>
                                -{i.Amount ? `$${i.Amount.toFixed(2)}` : '$0.00'}
                              </Row>
                            </Col>
                          </Row>
                        </li>
                      ))}
                    </ul>
                  </Col>
                  <Col span={6}></Col>
                </Row>
              ) : null}
              {multiPayments.length > 0 ? (
                <Row>
                  <Col span={6}></Col>
                  <Col span={12}>
                    <Divider style={{ margin: 5 }} />
                    <Row style={{ ...titleStyle, fontSize: 18, fontWeight: 'bold' }}>
                      <Col span={16}>
                        <Row style={{ padding: '0 10px' }}>Remaining Balance</Row>
                      </Col>
                      <Col span={8}>
                        <Row justify="end" style={{ padding: '0 10px' }}>
                          {parseFloat(multiPayBalance) >= 0 ? `$${multiPayBalance}` : '$0.00'}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={6}></Col>
                </Row>
              ) : null}
              <Divider style={{ borderColor: 'transparent', margin: 10 }} />
              {/* Buttons for Payment Methods */}
              <Row className="order-payment-btns-wrapper" style={{ maxHeight: '50vh' }}>
                <Col span={12}>
                  <Row justify="center" style={{ marginBottom: 30 }}>
                    {multiPayments.length === 0 && EnablePayAtCounter ? (
                      <Button
                        width={250}
                        height={70}
                        icon="register"
                        iconSize="2xl"
                        style={{ ...rowStyle }}
                        textStyle={{ fontSize: 18 }}
                        borderRadius={5}
                        onClick={() => {
                          this.handlePaymentBtns(1);
                        }}
                      >
                        Pay at Counter
                      </Button>
                    ) : null}
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify="center" style={{ marginBottom: 30 }}>
                    {EnableCreditCardPayment ? (
                      <Button
                        width={250}
                        height={70}
                        icon="credit"
                        iconSize="2xl"
                        style={{ ...rowStyle }}
                        textStyle={{ fontSize: 18 }}
                        borderRadius={5}
                        onClick={() => {
                          this.handlePaymentBtns(2);
                        }}
                      >
                        Credit Card
                      </Button>
                    ) : null}
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify="center" style={{ marginBottom: 30 }}>
                    {loginType !== 'Guest' && EnableHousePayment ? (
                      <Button
                        width={250}
                        height={70}
                        icon="account"
                        iconSize="2xl"
                        style={{ ...rowStyle }}
                        textStyle={{ fontSize: 18 }}
                        borderRadius={5}
                        onClick={() => {
                          this.handlePaymentBtns(3);
                        }}
                      >
                        Account
                      </Button>
                    ) : null}
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify="center" style={{ marginBottom: 30 }}>
                    {EnableGiftCard ? (
                      <Button
                        width={250}
                        height={70}
                        icon="gift"
                        iconSize="2xl"
                        style={{ ...rowStyle }}
                        textStyle={{ fontSize: 18 }}
                        borderRadius={5}
                        onClick={() => {
                          this.handlePaymentBtns(4);
                        }}
                      >
                        Gift Card
                      </Button>
                    ) : null}
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify="center" style={{ marginBottom: 30 }}>
                    {multiPayments.filter(i => i.PaymentType === 5).length === 0 &&
                    loginType !== 'Guest' &&
                    EnableLoyaltyPayment ? (
                      <Button
                        width={250}
                        height={70}
                        icon="reward"
                        iconSize="2xl"
                        style={{ ...rowStyle }}
                        textStyle={{ fontSize: 18 }}
                        borderRadius={5}
                        onClick={() => {
                          this.handlePaymentBtns(5);
                        }}
                      >
                        Reward Points
                      </Button>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row
            style={{
              padding: '3px 15px 0',
              backgroundColor: clientStyle.bgColor,
              height: '100vh',
            }}
            className="order-one-column-layout"
          >
            <Col span={24}>
              <Row style={{ height: 120, marginTop: 10 }}>
                {multiPayments.length === 0 && !isScanned ? (
                  <Button
                    width={250}
                    height={50}
                    icon="menu"
                    iconSize="2xl"
                    style={{ ...rowStyle }}
                    textStyle={{ fontSize: 18 }}
                    borderRadius={5}
                    onClick={this.handleGoBackToMenu}
                  >
                    Back to Menu
                  </Button>
                ) : multiPayments.length === 0 && isScanned ? (
                  <Button
                    width={150}
                    height={50}
                    icon="back"
                    iconSize="2xl"
                    style={{ ...rowStyle }}
                    textStyle={{ fontSize: 18 }}
                    borderRadius={5}
                    onClick={this.handleGoBackToScan}
                  >
                    Back
                  </Button>
                ) : null}
              </Row>
              {/* Order Summary Row */}
              <Row justify="center">
                <h1 style={{ ...titleStyle }}>Order Summary</h1>
              </Row>
              <Row
                className="order-total-pane"
                style={{ backgroundColor: clientStyle.primary, padding: '15px 0' }}
              >
                <Col span={6}></Col>
                <Col span={12}>
                  <Row
                    style={{
                      ...titleStyle,
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginBottom: 10,
                      ...rowStyle,
                    }}
                  >
                    <Col span={12}>
                      <Row style={{ padding: '0 10px' }}>Subtotal</Row>
                    </Col>
                    <Col span={12}>
                      <Row justify="end" style={{ padding: '0 10px' }}>
                        {`$${parseFloat(OrderSummary && OrderSummary.AmountBeforeTax).toFixed(
                          2,
                        )}` || '$0.00'}
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ ...titleStyle, fontSize: 16, marginBottom: 10, ...rowStyle }}>
                    <Col span={12}>
                      <Row style={{ padding: '0 10px' }}>Taxes</Row>
                    </Col>
                    <Col span={12}>
                      <Row justify="end" style={{ padding: '0 10px' }}>
                        {`$${parseFloat(OrderSummary && OrderSummary.Tax).toFixed(2)}` || '$0.00'}
                      </Row>
                    </Col>
                  </Row>

                  {/* <Divider /> */}
                  <div className="order-sum-discount-pane" style={{ minHeight: 100 }}>
                    {Adjustments?.length > 0 &&
                      Adjustments.map(
                        (i, index) =>
                          i.AdjType !== 4 && (
                            <Row
                              key={index}
                              style={{
                                ...titleStyle,
                                fontSize: 16,
                                marginBottom: 10,
                                ...rowStyle,
                              }}
                            >
                              <Col span={12}>
                                <Row style={{ padding: '0 10px' }}>
                                  {i.Description || 'Description N/A'}
                                </Row>
                              </Col>
                              <Col span={12}>
                                <Row justify="end" style={{ padding: '0 10px' }}>
                                  -{`$${parseFloat(i.DiscountAmount).toFixed(2)}` || '$0.00'}
                                </Row>
                              </Col>
                            </Row>
                          ),
                      )}
                  </div>
                  {/* )} */}
                  <Divider style={{ margin: 5 }} />
                  <Row style={{ ...titleStyle, fontSize: 22, fontWeight: 'bold', ...rowStyle }}>
                    <Col span={12}>
                      <Row style={{ padding: '0 10px' }}>Total</Row>
                    </Col>
                    <Col span={12}>
                      <Row justify="end" style={{ padding: '0 10px' }}>
                        {`$${parseFloat(OrderSummary && OrderSummary.Balance).toFixed(2)}` ||
                          '$0.00'}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={6}></Col>
              </Row>
              {/* Multiple payments Row */}
              <Row className="order-multi-payments-wrapper" style={{ minHeight: 150 }}>
                <Col span={6}></Col>
                <Col span={12}>
                  {multiPayments.length > 0 ? (
                    <ul ref={this.listRef}>
                      {multiPayments.map((i, index) => (
                        <li key={index}>
                          <Row style={{ ...titleStyle, fontSize: 16, marginBottom: 10 }}>
                            <Col span={12}>
                              <Row style={{ padding: '0 10px' }}>
                                {i.PaymentType === 2
                                  ? 'Credit Card'
                                  : i.PaymentType === 3
                                  ? 'Account'
                                  : i.PaymentType === 4
                                  ? 'Gift Card'
                                  : i.PaymentType === 5
                                  ? 'Points'
                                  : i.PaymentType === 6
                                  ? 'Debit Card'
                                  : 'Unkown'}
                              </Row>
                            </Col>
                            <Col span={12}>
                              <Row justify="end" style={{ padding: '0 10px' }}>
                                -{i.Amount ? `$${i.Amount.toFixed(2)}` : '$0.00'}
                              </Row>
                            </Col>
                          </Row>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </Col>
                <Col span={6}></Col>
              </Row>
              {multiPayments.length > 0 ? (
                <Row>
                  <Col span={6}></Col>
                  <Col span={12}>
                    <Divider style={{ margin: 5 }} />
                    <Row style={{ ...titleStyle, fontSize: 18, fontWeight: 'bold' }}>
                      <Col span={16}>
                        <Row style={{ padding: '0 10px' }}>Remaining Balance</Row>
                      </Col>
                      <Col span={8}>
                        <Row justify="end" style={{ padding: '0 10px' }}>
                          {parseFloat(multiPayBalance) >= 0 ? `$${multiPayBalance}` : '$0.00'}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={6}></Col>
                </Row>
              ) : null}
              {/* Payment Buttons Row */}
              <Row>
                <Col span={8}>
                  <Row justify="center" style={{ marginBottom: 30 }}>
                    {multiPayments.length === 0 && EnablePayAtCounter ? (
                      <Button
                        width={250}
                        height={70}
                        icon="register"
                        iconSize="2xl"
                        style={{ ...rowStyle }}
                        textStyle={{ fontSize: 18 }}
                        borderRadius={5}
                        onClick={() => {
                          this.handlePaymentBtns(1);
                        }}
                      >
                        Pay at Counter
                      </Button>
                    ) : null}
                  </Row>
                </Col>
                <Col span={8}>
                  <Row justify="center" style={{ marginBottom: 30 }}>
                    {EnableCreditCardPayment ? (
                      <Button
                        width={250}
                        height={70}
                        icon="credit"
                        iconSize="2xl"
                        style={{ ...rowStyle }}
                        textStyle={{ fontSize: 18 }}
                        borderRadius={5}
                        onClick={() => {
                          this.handlePaymentBtns(2);
                        }}
                      >
                        Credit Card
                      </Button>
                    ) : null}
                  </Row>
                </Col>
                <Col span={8}>
                  <Row justify="center" style={{ marginBottom: 30 }}>
                    {loginType !== 'Guest' && EnableHousePayment ? (
                      <Button
                        width={250}
                        height={70}
                        icon="account"
                        iconSize="2xl"
                        style={{ ...rowStyle }}
                        textStyle={{ fontSize: 18 }}
                        borderRadius={5}
                        onClick={() => {
                          this.handlePaymentBtns(3);
                        }}
                      >
                        Account
                      </Button>
                    ) : null}
                  </Row>
                </Col>
                <Col span={8}>
                  <Row justify="center" style={{ marginBottom: 30 }}>
                    {EnableGiftCard ? (
                      <Button
                        width={250}
                        height={70}
                        icon="gift"
                        iconSize="2xl"
                        style={{ ...rowStyle }}
                        textStyle={{ fontSize: 18 }}
                        borderRadius={5}
                        onClick={() => {
                          this.handlePaymentBtns(4);
                        }}
                      >
                        Gift Card
                      </Button>
                    ) : null}
                  </Row>
                </Col>
                <Col span={8}>
                  <Row justify="center" style={{ marginBottom: 30 }}>
                    {multiPayments.filter(i => i.PaymentType === 5).length === 0 &&
                    loginType !== 'Guest' &&
                    EnableLoyaltyPayment ? (
                      <Button
                        width={250}
                        height={70}
                        icon="reward"
                        iconSize="2xl"
                        style={{ ...rowStyle }}
                        textStyle={{ fontSize: 18 }}
                        borderRadius={5}
                        onClick={() => {
                          this.handlePaymentBtns(5);
                        }}
                      >
                        Reward Points
                      </Button>
                    ) : null}
                  </Row>
                </Col>
              </Row>
              {/* Instructions Row */}
              <Row
                justify="center"
                style={{
                  margin: '80px 0 10px',
                }}
              >
                <h1 style={{ ...titleStyle }}>Special Instructions</h1>
                <TextArea
                  ref={this.refInstructionBox}
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  placeholder=" Special Instructions"
                  spellCheck={false}
                  maxLength={100}
                  onFocus={this.handleInstructionBoxOnFocus}
                  value={guestIns}
                  style={{ ...descStyle }}
                />
              </Row>
              {/* Name, Phone Row */}
              <Row gutter={16} style={{ marginBottom: 50 }}>
                <Col span={12}>
                  {GuestNameRequired ? (
                    <Row style={{ ...titleStyle }}>
                      Guest Name{' '}
                      <Input
                        placeholder="Guest Name"
                        spellCheck={false}
                        onFocus={this.handleNameBoxOnFocus}
                        value={guestName}
                        style={{ ...descStyle }}
                      />
                    </Row>
                  ) : null}
                </Col>
                <Col span={12}>
                  {CollectGuestPhoneNumber ? (
                    <Row style={{ ...titleStyle }}>
                      Guest Phone{' '}
                      <Input
                        placeholder="Guest Cell Phone"
                        spellCheck={false}
                        onFocus={this.handlePhoneBoxOnFocus}
                        value={guestPhone}
                        style={{ ...descStyle }}
                      />
                    </Row>
                  ) : null}
                </Col>
              </Row>
              {/* Order List Row */}
              <Row justify="center">
                <h1 style={{ ...titleStyle }}>Order Items</h1>
              </Row>
              <Row
                className="order-sum-pane"
                style={{
                  // height: window.innerHeight - 150,
                  // overflow: 'scroll',
                  paddingBottom: 30,
                }}
              >
                <Col span={24}>
                  {order.map(i => {
                    let data = [];
                    if (i && i.type === 'P' && i.packageDetails && i.packageDetails.length > 0) {
                      let packageArr = [];
                      i.packageDetails.forEach(j => {
                        packageArr.push(j);
                        if (j.type === 'I' && j.pizzaDetails.length > 0) {
                          j.pizzaDetails.forEach(k => {
                            packageArr.push(k);
                          });
                        }
                      });

                      data = packageArr || [];
                    } else if (i && i.type === 'I') {
                      data = [...i.pizzaDetails, ...i.modifierDetails];
                    } else if (
                      i &&
                      i.type !== 'P' &&
                      i.modifierDetails &&
                      i.modifierDetails.length > 0
                    ) {
                      data = i.modifierDetails || [];
                    }
                    return (
                      <div key={i.key} style={{ ...titleStyle }}>
                        <Row
                          key={i.code}
                          className="order-sum-list-item-row"
                          align="middle"
                          style={{ ...rowStyle }}
                        >
                          <Col span={18}>
                            <Row justify="start">
                              {i.description ? i.description : 'Description N/A'}
                            </Row>
                          </Col>
                          <Col span={3}>
                            <Row justify="start">{i.qty}</Row>
                          </Col>
                          <Col span={3}>
                            <Row justify="start">
                              {i.packageType === '1' ? '' : `$${parseFloat(i.price).toFixed(2)}`}
                            </Row>
                          </Col>
                        </Row>
                        {data.length > 0 &&
                          data.map((p, index) => (
                            <Row
                              key={index + i.key + i.code + p.code}
                              className="order-sum-list-mod-row"
                              align="middle"
                              style={{ ...rowStyle, filter: 'brightness(1.2) opacity(0.9)' }}
                            >
                              <Col span={18}>
                                <Row justify="start">
                                  {p.description ? p.description : 'Description N/A'}
                                </Row>
                              </Col>
                              <Col span={3}>
                                <Row justify="start">{p.qty}</Row>
                              </Col>
                              <Col span={3}>
                                <Row justify="start">{`$${parseFloat(p.price).toFixed(2)}`}</Row>
                              </Col>
                            </Row>
                          ))}
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <OrderInstructionModal
          visible={insModalVisible}
          insModalFor={insModalFor}
          onInsModalCancel={this.handleInsModalCancel}
          onTextChange={(text, dataType) => {
            this.handleInsTextChange(text, dataType);
          }}
        />
        {/* Follow Pinpad Instruction Modal */}
        <Modal
          visible={pinPadInsModalVisible}
          closable={false}
          width={930}
          maskClosable={false}
          title={null}
          footer={null}
          destroyOnClose={true}
          bodyStyle={{ backgroundColor: '#fff' }}
          showCloseBtn={false}
        >
          <Row justify="center">
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/assets/order/cfpayment.jpg`}
                alt={''}
                height={500}
              />
            </div>
          </Row>
        </Modal>
        {/* Gift Card Swipe Modal */}
        <Modal
          visible={cardSwipeModalVisible}
          closable={false}
          // width={650}
          maskClosable={false}
          title={null}
          footer={null}
          destroyOnClose={true}
          onCancel={this.handleGiftCardModalClose}
          onCloseClick={this.handleGiftCardModalClose}
          showCloseBtn={true}
        >
          <div style={{ position: 'relative' }}>
            <div>
              <input
                // ref={this.loginRef}
                className="scan-barcode-input"
                autoFocus={true}
                onKeyDown={e => {
                  this.handleGiftCardModalInputEnterKeyDown(e);
                }}
                // onBlur={() => this.loginRef.current.focus()}
              />
              <Row style={{ fontSize: 18, fontWeight: 'bold' }} justify="center" align="middle">
                <FontAwesomeIcon icon={faCircleInfo} size="xl" color="#70cd5f" />
                <h3 style={{ marginLeft: 20, ...titleStyle }}>Please swipe your card</h3>
              </Row>
              <Row style={{ margin: '50px 0' }} justify="center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/order/swipecard.png`}
                  alt={''}
                  height={200}
                />
              </Row>
            </div>
          </div>
        </Modal>
        {/* MultiPayment Choice Modal */}
        <Modal
          visible={multiPaymentChoiceModalVisible}
          closable={false}
          width={520}
          maskClosable={false}
          title={null}
          footer={null}
          destroyOnClose={true}
          showCloseBtn={false}
        >
          <Row style={{ fontSize: 18, fontWeight: 'bold' }} justify="center" align="middle">
            <FontAwesomeIcon icon={faCircleExclamation} size="xl" color="#faad14" />
            <h3 style={{ marginLeft: 20, ...titleStyle }}>
              Insufficient Amount: {formatter.format(tempPartialPayment?.Amount)}{' '}
            </h3>
          </Row>
          <Divider style={{ borderColor: 'transparent' }} />
          <Row justify="center">
            <Button
              width={400}
              height={70}
              style={{ ...rowStyle }}
              textStyle={{ fontSize: 18 }}
              borderRadius={5}
              onClick={this.handleAddMultiPayment}
            >
              Use {formatter.format(tempPartialPayment?.Amount)} for a partial payment
            </Button>
          </Row>
          <Divider style={{ borderColor: 'transparent' }} />
          <Row justify="center">
            <Button
              width={400}
              height={70}
              style={{ ...rowStyle }}
              textStyle={{ fontSize: 18 }}
              borderRadius={5}
              onClick={this.handleCancelMultiPayment}
            >
              Choose a different payment method
            </Button>
          </Row>
          <Divider style={{ borderColor: 'transparent' }} />
        </Modal>
        {/* Payment scuccess modal */}
        <Modal
          visible={paymentSuccessModalVisible}
          closable={false}
          width={520}
          maskClosable={false}
          title={null}
          footer={null}
          destroyOnClose={true}
          showCloseBtn={false}
        >
          <Row justify="center">
            <FontAwesomeIcon
              icon={faCircleCheck}
              // size="xl"
              color="#70cd5f"
              style={{
                width: window.innerHeight > 768 ? 160 : 120,
                height: window.innerHeight > 768 ? 160 : 120,
                marginBottom: 10,
              }}
            />
          </Row>
          <Row justify="center">
            <div
              style={{
                marginLeft: 20,
                ...titleStyle,
                whiteSpace: 'pre-wrap',
                fontSize: 18,
                fontWeight: 'bolder',
                textAlign: 'center',
                maxWidth: 400,
              }}
            >
              {paymentSuccessMsg}
            </div>
          </Row>
          <Row
            style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, ...titleStyle }}
            justify="center"
            align="middle"
          >
            Order # {orderNum}
          </Row>
          <Row justify="center" style={{ marginTop: 10 }}>
            <h2 style={{ ...titleStyle }}>Redirecting to Start Page in</h2>
          </Row>
          <Row justify="center" style={{ marginTop: 10 }}>
            <div
              className="timer-wrapper"
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CountdownCircleTimer
                isPlaying={receiptPrinting !== 1}
                duration={OrderCompletedCountDown ? OrderCompletedCountDown : 15}
                colors={[clientStyle.primary]}
                // colorsTime={[15, 0]}
                onComplete={this.handlePaymentSuccessTimerFinish}
              >
                {this.renderTimer}
              </CountdownCircleTimer>
              <h2
                style={{ display: 'flex', justifyContent: 'center', marginTop: 5, ...titleStyle }}
              >
                seconds
              </h2>
            </div>
          </Row>
          {AutoPrintReceipt === 0 ? (
            <>
              <Row align="middle" style={{ marginTop: 20 }}>
                <Col span={5}></Col>
                <Col span={14}>
                  <Row justify="center">
                    <Button
                      width={250}
                      style={{ ...rowStyle }}
                      textStyle={{ fontSize: 18 }}
                      borderRadius={5}
                      onClick={this.handlePrintReceipt}
                    >
                      Print Receipt
                    </Button>
                  </Row>
                </Col>
                <Col span={5}>
                  {receiptPrinting === 1 ? (
                    <FontAwesomeIcon icon={faSpinner} color="var(--primary)" size="3x" spin />
                  ) : receiptPrinting === 2 ? (
                    <FontAwesomeIcon icon={faCircleCheck} color="#70cd5f" size="3x" />
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <Divider style={{ borderColor: 'transparent' }} />
            </>
          ) : null}
          <Row style={{ marginTop: 20 }}>
            <Col span={5}></Col>
            <Col span={14}>
              <Row justify="center">
                <Button
                  width={250}
                  style={{ ...rowStyle }}
                  textStyle={{ fontSize: 18 }}
                  borderRadius={5}
                  onClick={this.handleOrderLogOut}
                >
                  Done
                </Button>
              </Row>
            </Col>
            <Col span={5}></Col>
          </Row>
        </Modal>
        {/* Payment Failure Modal */}
        <ErrorModal
          visible={paymentFailureModalVisible}
          title={'Payment or order cannot be processed'}
          msg={paymentErrorMsg}
          onOK={this.handlePaymentFailModalOK}
        />
        {/* Payment Error Modal */}
        <ErrorModal
          visible={paymentErrorModalVisible}
          title={'An Error has occurred'}
          showDueTo={false}
          msg={paymentErrorMsg}
          onOK={this.handlePaymentErrorModalOK}
        />
        {/* Loading Modal */}
        <LoadingModal visible={loadingModalVisible} msg={loadingModalMsg} />
        {/* Split Bill Modal */}
        <Modal
          visible={splitBillModalVisible}
          closable={false}
          width={950}
          maskClosable={false}
          title={null}
          footer={null}
          destroyOnClose={true}
          showCloseBtn={false}
        >
          <OrderSplitBillSeletor
            checkSum={checkSum}
            splitBalance={splitBalance}
            splitPayments={splitPayments}
            onModalClose={this.handleSplitBillCancel}
            onSelectPayment={(type, amt) => {
              this.handleSplitDemoPayment(type, amt);
            }}
          />
        </Modal>
      </>
    );
  }
}
export default connect(
  state => ({
    user: state.user,
    loginType: state.loginType,
    menuOrder: state.order,
    scannedOrder: state.scannedOrder,
    kioskSetting: state.kioskSetting,
    guestName: state.guestName,
    guestPhone: state.guestPhone,
    guestIns: state.guestIns,
    customerOrderOption: state.customerOrderOption,
    clientStyle: state.clientStyle,
  }),
  {
    clearOrderItems,
    clearScannedOrderItems,
    resetUser,
    deleteGuestName,
    resetCustomerOrderOption,
    resetLoginType,
    resetAppMode,
    receiveGuestName,
    receiveGuestPhone,
    deleteGuestPhone,
    receiveGuestIns,
    deleteGuestIns,
  },
)(withIdleTimer(Order));
