import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faCircleHalfStroke,
  faCirclePlus,
  faCircleMinus,
} from '@fortawesome/free-solid-svg-icons';
import VTTextTransition from '../TextTransition';
import './index.css';

/// Whole = 1,
/// FirstHalf = 2,
/// SecondHalf = 3,
class MenuPizzaToppingRow extends Component {
  state = {
    firstHalf: false,
    whole: true,
    secondHalf: false,
  };

  handleFirstHalf = item => {
    const { firstHalf } = this.state;
    if (!firstHalf) {
      this.setState({ firstHalf: true, whole: false, secondHalf: false });
    }
    if (item.Qty === 0) {
      return;
    } else if (item.Qty > 0) {
      this.props.updatePizzaTopping(item, 2);
    }
  };

  handleWhole = item => {
    const { whole } = this.state;
    if (!whole) {
      this.setState({ firstHalf: false, whole: true, secondHalf: false });
    }
    if (item.Qty === 0) {
      return;
    } else if (item.Qty > 0) {
      this.props.updatePizzaTopping(item, 1);
    }
  };

  handleSecondHalf = item => {
    const { secondHalf } = this.state;
    if (!secondHalf) {
      this.setState({ firstHalf: false, whole: false, secondHalf: true });
    }
    if (item.Qty === 0) {
      return;
    } else if (item.Qty > 0) {
      this.props.updatePizzaTopping(item, 3);
    }
  };

  getIndex = () => {
    const { firstHalf, whole, secondHalf } = this.state;
    const { isPizza } = this.props;
    let index = 0;
    if (!isPizza) {
      index = 1;
    } else {
      if (whole && !firstHalf && !secondHalf) {
        index = 1;
      }
      if (!whole && firstHalf && !secondHalf) {
        index = 2;
      }
      if (!whole && !firstHalf && secondHalf) {
        index = 3;
      }
    }
    return index;
  };

  handlePlusOne = item => {
    const index = this.getIndex();
    item.Qty = item.Qty + 1;
    this.props.updatePizzaTopping(item, index);
  };

  handleMinusOne = item => {
    if (item.Qty === 0) return;
    const index = this.getIndex();
    item.Qty = item.Qty - 1 > 0 ? item.Qty - 1 : 0;
    this.props.updatePizzaTopping(item, index);
  };

  render() {
    const { firstHalf, whole, secondHalf } = this.state;
    const { item, isPizza, clientStyle } = this.props;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };

    return (
      <Row
        style={{ ...titleStyle, padding: '10px 5px' }}
        align="middle"
        className="menu-pizza-item-topping-row"
      >
        <Col span={10} align="middle">
          <Row style={{ paddingLeft: 3 }}>
            <h3 style={{ ...titleStyle }}>{item.Description || ''}</h3>
          </Row>
        </Col>
        <Col span={1} align="middle">
          <Row>
            <h2 style={{ ...titleStyle }}>
              <VTTextTransition text={item.Qty} />
            </h2>
          </Row>
        </Col>
        {/* Minus Btn */}
        <Col span={2}>
          <Row
            className="menu-item-mods-selector-list-minus"
            justify="center"
            align="middle"
            onClick={() => {
              this.handleMinusOne(item);
            }}
          >
            <Col>
              <FontAwesomeIcon
                icon={faCircleMinus}
                size="3x"
                color={item.Qty === 0 ? '#999' : clientStyle.primary}
              />
            </Col>
          </Row>
        </Col>
        {/* 1st Half Btn */}
        <Col span={3}>
          {isPizza && (
            <Row
              justify="center"
              align="middle"
              onClick={() => {
                this.handleFirstHalf(item);
              }}
            >
              <Col>
                <FontAwesomeIcon
                  icon={faCircleHalfStroke}
                  size="3x"
                  color={firstHalf ? clientStyle.secondary : clientStyle.primary}
                />
              </Col>
            </Row>
          )}
        </Col>
        {/* Whole Btn */}
        <Col span={3}>
          {isPizza && (
            <Row
              justify="center"
              align="middle"
              onClick={() => {
                this.handleWhole(item);
              }}
            >
              <Col>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="3x"
                  color={whole ? clientStyle.secondary : clientStyle.primary}
                />
              </Col>
            </Row>
          )}
        </Col>
        {/* 2nd Half Btn*/}
        <Col span={3}>
          {isPizza && (
            <Row
              justify="center"
              align="middle"
              onClick={() => {
                this.handleSecondHalf(item);
              }}
            >
              <Col>
                <FontAwesomeIcon
                  icon={faCircleHalfStroke}
                  size="3x"
                  color={secondHalf ? clientStyle.secondary : clientStyle.primary}
                  rotation={180}
                />
              </Col>
            </Row>
          )}
        </Col>
        {/* Plus Btn */}
        <Col span={2}>
          <Row
            className="menu-item-mods-selector-list-minus"
            justify="center"
            align="middle"
            onClick={() => this.handlePlusOne(item)}
          >
            <Col>
              <FontAwesomeIcon icon={faCirclePlus} size="3x" color={clientStyle.primary} />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(MenuPizzaToppingRow);
