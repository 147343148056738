import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

class OrderOptionStepOne extends Component {
  state = {
    options: [],
    selectedOption: null,
  };

  init = () => {
    const { selectedOption, orderOption } = this.props;
    this.setState({ selectedOption, options: orderOption });
  };

  handleOptionClick = option => {
    this.setState({ selectedOption: option });
    this.props.onOrderOptionStepOneSelectionChange(option);
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedOption } = this.props;
    if (selectedOption !== prevProps.selectedOption) {
      this.init();
    }
  }

  render() {
    const { options, selectedOption } = this.state;
    const { clientStyle } = this.props;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const descStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontTwo,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };
    const btnStyle = {
      ...titleStyle,
      margin: 20,
      backgroundColor: '#fff',
      padding: '15px 10px',
      minWidth: 200,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 16,
      WebkitBoxShadow: '4px 4px 10px 0px rgba(0,0,0,0.75)',
      boxShadow: '4px 4px 10px 0px rgba(0,0,0,0.75)',
      fontSize: 18,
      fontWeight: 'bold',
      textAlign: 'center',
    };
    const selectedBtnStyle = {
      ...titleStyle,
      ...rowStyle,
      margin: 20,
      padding: '15px 10px',
      minWidth: 200,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 16,
      WebkitBoxShadow: '4px 4px 10px 0px rgba(0,0,0,0.75)',
      boxShadow: '4px 4px 10px 0px rgba(0,0,0,0.75)',
      fontSize: 18,
      fontWeight: 'bold',
      textAlign: 'center',
    };

    return (
      <Col span={24} style={{ marginTop: 30 }} className="order-option-step-two-right-side">
        <Row justify="center" style={{ marginBottom: 30 }}>
          <div style={{ fontSize: 26, fontWeight: 800, ...titleStyle }}>PLACE AN ORDER FOR</div>
        </Row>
        <Row justify="center">
          {options.map(i => (
            <div
              key={i.key}
              style={selectedOption && selectedOption.key === i.key ? selectedBtnStyle : btnStyle}
              onClick={() => {
                this.handleOptionClick(i);
              }}
            >
              {i.Description}
            </div>
          ))}
        </Row>
      </Col>
    );
  }
}

export default connect(state => ({ clientStyle: state.clientStyle }), {})(OrderOptionStepOne);
