import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal';
import Button from '../Button';

class ErrorModal extends Component {
  render() {
    const { visible, title, msg, clientStyle, showDueTo = true } = this.props;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const descStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontTwo,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };
    return (
      <Modal
        visible={visible}
        closable={false}
        width={520}
        maskClosable={false}
        title={null}
        footer={null}
        destroyOnClose={true}
        showCloseBtn={false}
      >
        <Row style={{ fontSize: 18, fontWeight: 'bold' }} justify="center" align="middle">
          <FontAwesomeIcon icon={faCircleExclamation} size="xl" color="#faad14" />
          <h3 style={{ ...titleStyle, marginLeft: 20 }}>{title}</h3>
        </Row>
        {showDueTo ? (
          <Row justify="center" style={{ marginTop: 20 }}>
            <h3 style={{ ...titleStyle }}>due to:</h3>
          </Row>
        ) : null}
        <Row
          style={{
            padding: '10px 15px',
            marginTop: 20,
            fontSize: 18,
            maxHeight: 300,
            overflow: 'hidden',
            ...descStyle,
          }}
        >
          {msg}
        </Row>
        <Row justify="end" style={{ marginTop: 30, padding: '0 30px' }}>
          <Button
            style={{ ...rowStyle }}
            textStyle={{ fontSize: 18 }}
            borderRadius={5}
            onClick={this.props.onOK}
          >
            OK
          </Button>
        </Row>
      </Modal>
    );
  }
}

export default connect(state => ({ clientStyle: state.clientStyle }), {})(ErrorModal);
