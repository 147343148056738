import React, { Component } from 'react';
import { Row, Col, Select, Divider } from 'antd';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleArrowRight,
  faCartPlus,
  faCalendarDays,
  faBarcode,
  faTableList,
  faClockRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  resetUser,
  receiveCurrAccount,
  deleteGuestName,
  resetCustomerOrderOption,
  resetLoginType,
  clearOrderItems,
  clearScannedOrderItems,
  receiveAppMode,
  resetAppMode,
  deleteGuestPhone,
  deleteGuestIns,
} from '../../../redux/actions';
import Button from '../../../components/Button';
import Selection from '../../../components/Selection';

import './index.css';

const { Option } = Select;

class AdminDashboard extends Component {
  handleAccountChange = v => {
    const { user } = this.props;
    const { AccountList } = user || [];
    const acc = AccountList.find(i => i.AccountNumber === v) || {};
    this.props.receiveCurrAccount(acc);
  };

  handleStartAnOrder = () => {
    this.props.receiveAppMode(0);
    const {
      kioskSetting: {
        appSetting: { EnableOrderOptionMapping },
      },
    } = this.props;
    if (EnableOrderOptionMapping) {
      this.props.history.replace('/orderOptions');
    } else {
      this.props.history.replace('/menu');
    }
  };

  handleMakeNewRes = () => {
    this.props.history.replace({
      pathname: '/reservation',
      state: { editOrAdd: 'Add' },
    });
  };

  handleLogOut = () => {
    this.props.resetUser();
    this.props.clearOrderItems();
    this.props.clearScannedOrderItems();
    this.props.deleteGuestName();
    this.props.deleteGuestPhone();
    this.props.deleteGuestIns();
    this.props.resetCustomerOrderOption();
    this.props.resetLoginType();
    this.props.resetAppMode();
    this.props.history.replace('/');
  };

  handleScanModeBtn = () => {
    this.props.receiveAppMode(1);
    const {
      kioskSetting: {
        appSetting: { EnableOrderOptionMapping },
      },
    } = this.props;
    if (EnableOrderOptionMapping) {
      this.props.history.replace('/orderOptions');
    } else {
      this.props.history.replace('/scan');
    }
  };

  handleGoToAccStatement = () => {
    this.props.history.replace('/admin/statements');
  };

  handleGoToOrderHistory = () => {
    this.props.history.replace('/admin/orderhistory');
  };

  render() {
    const { user } = this.props;
    const { AccountList } = user || [];
    const { CurAccountInfo: currAccount } = user || {};
    const {
      kioskSetting: {
        appSetting: {
          DisabledOnlineOrdering,
          EnableReservation,
          EnableHousePayment,
          ScanItemMode,
          DisabledOrderHistory,
          DisabledAccountStatement,
        },
      },
    } = this.props;
    const accountsOptions =
      (AccountList &&
        AccountList.map(i => (
          <Option key={i.AccountNumber} value={i.AccountNumber}>
            {`${i.FirstName} ${i.LastName} - ${i.AccountNumber}`}
          </Option>
        ))) ||
      [];

    return (
      <Row className="admin-dashboard-wrapper">
        <Col span={24}>
          {/* Greeting/Account/Logout Row */}
          <Row gutter={16}>
            <Col span={12}>
              <Row
                style={{
                  minHeight: 130,
                  backgroundColor: 'var(--adminPrimary)',
                  color: '#fff',
                  padding: '5px 10px',
                  borderRadius: 5,
                  boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.75)',
                  WebkitBoxShadow: '0px 1px 5px 0px rgba(0,0,0,0.75)',
                }}
              >
                <Col span={24}>
                  <Row style={{ marginBottom: 10 }}>
                    <h1 style={{ color: '#fff' }}>Hi, {`${user?.FirstName} ${user?.LastName}`}</h1>
                  </Row>
                  <Divider style={{ marginTop: 0, borderColor: '#fff' }} />
                  <Row>
                    <strong>Current Account</strong>:{' '}
                    {currAccount &&
                      `${currAccount.FirstName} ${currAccount.LastName} - ${currAccount.AccountNumber}`}
                  </Row>
                  <Row>
                    <strong>Phone</strong>:{' '}
                    {currAccount ? currAccount.OfficePhone || currAccount.HomePhone : ''}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row
                style={{
                  minHeight: 130,
                  backgroundColor: 'var(--adminPrimary)',
                  color: '#fff',
                  padding: '5px 10px',
                  borderRadius: 5,
                  boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.75)',
                  WebkitBoxShadow: '0px 1px 5px 0px rgba(0,0,0,0.75)',
                }}
              >
                <Col span={24}>
                  <Row justify="end">
                    <Button
                      width={150}
                      height={40}
                      style={{
                        backgroundColor: 'var(--adminSecondary)',
                        color: '#fff',
                      }}
                      icon="logout"
                      iconSize="xl"
                      textStyle={{ fontSize: 18 }}
                      borderRadius={5}
                      onClick={this.handleLogOut}
                    >
                      Log out
                    </Button>
                  </Row>
                  {currAccount && (
                    <>
                      <Row justify="start">
                        <strong>All Accounts</strong>
                      </Row>
                      <Row justify="start" style={{ margin: '10px 0' }}>
                        <Selection
                          width={300}
                          value={currAccount ? currAccount.AccountNumber : null}
                          data={accountsOptions}
                          onChange={value => {
                            this.handleAccountChange(value);
                          }}
                        />
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Balance Row */}
          <Row style={{ marginTop: 30 }}>
            {currAccount?.PlanName ? (
              <div style={{ margin: '0 20px 20px 0', minWidth: 0 }}>
                <Row
                  style={{
                    width: 220,
                    minWidth: 0,
                    height: 100,
                    borderRadius: 10,
                    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.75)',
                    WebkitBoxShadow: '0px 1px 5px 0px rgba(0,0,0,0.75)',
                  }}
                >
                  <Col span={24}>
                    <Row
                      justify="center"
                      align="middle"
                      style={{
                        fontWeight: 'bold',
                        width: '100%',
                        height: 40,
                        backgroundColor: 'var(--adminPrimary)',
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        color: '#fff',
                      }}
                    >
                      Account Plan
                    </Row>
                    <Row
                      justify="center"
                      align="middle"
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        width: 220,
                        height: 60,
                        color: 'var(--adminSecondary)',
                        textAlign: 'center',
                        overflow: 'hidden',
                      }}
                    >
                      {currAccount?.PlanName ?? ''}
                    </Row>
                  </Col>
                </Row>
              </div>
            ) : null}
            {currAccount && EnableHousePayment ? (
              <>
                <div style={{ margin: '0 20px 20px 0' }}>
                  <Row
                    style={{
                      width: 220,
                      height: 100,
                      borderRadius: 10,
                      boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.75)',
                      WebkitBoxShadow: '0px 1px 5px 0px rgba(0,0,0,0.75)',
                    }}
                  >
                    <Col span={24}>
                      <Row
                        justify="center"
                        align="middle"
                        style={{
                          fontWeight: 'bold',
                          width: '100%',
                          height: 40,
                          backgroundColor: 'var(--adminPrimary)',
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10,
                          color: '#fff',
                        }}
                      >
                        Meal Credit Balance
                      </Row>
                      <Row
                        justify="center"
                        align="middle"
                        style={{
                          fontSize: 18,
                          fontWeight: 'bold',
                          width: '100%',
                          height: 60,
                          color: 'var(--adminSecondary)',
                        }}
                      >
                        {currAccount && parseFloat(currAccount.MealCreditBalance).toFixed(2)}
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div style={{ margin: '0 20px 20px 0' }}>
                  <Row
                    style={{
                      width: 220,
                      height: 100,
                      borderRadius: 10,
                      boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.75)',
                      WebkitBoxShadow: '0px 1px 5px 0px rgba(0,0,0,0.75)',
                    }}
                  >
                    <Col span={24}>
                      <Row
                        justify="center"
                        align="middle"
                        style={{
                          fontWeight: 'bold',
                          width: '100%',
                          height: 40,
                          backgroundColor: 'var(--adminPrimary)',
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10,
                          color: '#fff',
                        }}
                      >
                        Prepaid Balance
                      </Row>
                      <Row
                        justify="center"
                        align="middle"
                        style={{
                          fontSize: 18,
                          fontWeight: 'bold',
                          width: '100%',
                          height: 60,
                          color: 'var(--adminSecondary)',
                        }}
                      >
                        {currAccount && parseFloat(currAccount.PrepaidBalance).toFixed(2)}
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div style={{ margin: '0 20px 20px 0' }}>
                  <Row
                    style={{
                      width: 220,
                      height: 100,
                      borderRadius: 10,
                      boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.75)',
                      WebkitBoxShadow: '0px 1px 5px 0px rgba(0,0,0,0.75)',
                    }}
                  >
                    <Col span={24}>
                      <Row
                        justify="center"
                        align="middle"
                        style={{
                          fontWeight: 'bold',
                          width: '100%',
                          height: 40,
                          backgroundColor: 'var(--adminPrimary)',
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10,
                          color: '#fff',
                        }}
                      >
                        Account Balance
                      </Row>
                      <Row
                        justify="center"
                        align="middle"
                        style={{
                          fontSize: 18,
                          fontWeight: 'bold',
                          width: '100%',
                          height: 60,
                          color: 'var(--adminSecondary)',
                        }}
                      >
                        {currAccount && parseFloat(currAccount.AccountBalance).toFixed(2)}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </>
            ) : null}
          </Row>

          {/* Order/Book Row */}
          {currAccount ? (
            <Row style={{ marginTop: 10 }}>
              {/* Order Button*/}
              {DisabledOnlineOrdering === 0 ? (
                <div
                  className="admin-dashboard-order-book-btn"
                  style={{
                    width: 300,
                    height: 150,
                    margin: '0 20px 20px 0',
                    boxShadow: '6px 4px 8px 0px rgba(0,0,0,0.75)',
                    WebkitBoxShadow: '6px 4px 8px 0px rgba(0,0,0,0.75)',
                    borderRadius: 10,
                  }}
                  onClick={
                    ScanItemMode === 0 || ScanItemMode === 2
                      ? this.handleStartAnOrder
                      : this.handleScanModeBtn
                  }
                >
                  <Row
                    style={{
                      width: '100%',
                      height: 100,
                      backgroundColor: 'var(--adminPrimary)',
                      borderRadius: '9px 9px 0 0',
                    }}
                    justify="center"
                    align="middle"
                  >
                    <FontAwesomeIcon
                      icon={ScanItemMode === 0 || ScanItemMode === 2 ? faCartPlus : faBarcode}
                      size="4x"
                      color="#fff"
                    />
                  </Row>
                  <Row
                    style={{
                      width: '100%',
                      height: 50,
                      padding: '0 20px',
                      fontSize: 18,
                      fontWeight: 'bold',
                    }}
                    align="middle"
                    justify="space-between"
                  >
                    Start An Order
                    <FontAwesomeIcon
                      icon={faCircleArrowRight}
                      size="1x"
                      color="var(--adminSecondary)"
                    />
                  </Row>
                </div>
              ) : null}
              {/* Reservation Button */}
              {EnableReservation ? (
                <div
                  className="admin-dashboard-order-book-btn"
                  style={{
                    width: 300,
                    height: 150,
                    margin: '0 20px 20px 0',
                    boxShadow: '6px 4px 8px 0px rgba(0,0,0,0.75)',
                    WebkitBoxShadow: '6px 4px 8px 0px rgba(0,0,0,0.75)',
                    borderRadius: 10,
                  }}
                  onClick={this.handleMakeNewRes}
                >
                  <Row
                    style={{
                      width: '100%',
                      height: 100,
                      backgroundColor: 'var(--adminPrimary)',
                      borderRadius: '9px 9px 0 0',
                    }}
                    justify="center"
                    align="middle"
                  >
                    <FontAwesomeIcon icon={faCalendarDays} size="4x" color="#fff" />
                  </Row>
                  <Row
                    style={{
                      width: '100%',
                      height: 50,
                      padding: '0 20px',
                      fontSize: 18,
                      fontWeight: 'bold',
                    }}
                    align="middle"
                    justify="space-between"
                  >
                    Make A Reservation
                    <FontAwesomeIcon
                      icon={faCircleArrowRight}
                      size="1x"
                      color="var(--adminSecondary)"
                    />
                  </Row>
                </div>
              ) : null}
              {/* Account Statement Button */}
              {DisabledAccountStatement === 0 ? (
                <div
                  className="admin-dashboard-order-book-btn"
                  style={{
                    width: 300,
                    height: 150,
                    margin: '0 20px 20px 0',
                    boxShadow: '6px 4px 8px 0px rgba(0,0,0,0.75)',
                    WebkitBoxShadow: '6px 4px 8px 0px rgba(0,0,0,0.75)',
                    borderRadius: 10,
                  }}
                  onClick={this.handleGoToAccStatement}
                >
                  <Row
                    style={{
                      width: '100%',
                      height: 100,
                      backgroundColor: 'var(--adminPrimary)',
                      borderRadius: '9px 9px 0 0',
                    }}
                    justify="center"
                    align="middle"
                  >
                    <FontAwesomeIcon icon={faTableList} size="4x" color="#fff" />
                  </Row>
                  <Row
                    style={{
                      width: '100%',
                      height: 50,
                      padding: '0 20px',
                      fontSize: 18,
                      fontWeight: 'bold',
                    }}
                    align="middle"
                    justify="space-between"
                  >
                    View Account Statements
                    <FontAwesomeIcon
                      icon={faCircleArrowRight}
                      size="1x"
                      color="var(--adminSecondary)"
                    />
                  </Row>
                </div>
              ) : null}
              {/* Order History Button */}
              {DisabledOrderHistory === 0 ? (
                <div
                  className="admin-dashboard-order-book-btn"
                  style={{
                    width: 300,
                    height: 150,
                    margin: '0 20px 20px 0',
                    boxShadow: '6px 4px 8px 0px rgba(0,0,0,0.75)',
                    WebkitBoxShadow: '6px 4px 8px 0px rgba(0,0,0,0.75)',
                    borderRadius: 10,
                  }}
                  onClick={this.handleGoToOrderHistory}
                >
                  <Row
                    style={{
                      width: '100%',
                      height: 100,
                      backgroundColor: 'var(--adminPrimary)',
                      borderRadius: '9px 9px 0 0',
                    }}
                    justify="center"
                    align="middle"
                  >
                    <FontAwesomeIcon icon={faClockRotateLeft} size="4x" color="#fff" />
                  </Row>
                  <Row
                    style={{
                      width: '100%',
                      height: 50,
                      padding: '0 20px',
                      fontSize: 18,
                      fontWeight: 'bold',
                    }}
                    align="middle"
                    justify="space-between"
                  >
                    View Order History
                    <FontAwesomeIcon
                      icon={faCircleArrowRight}
                      size="1x"
                      color="var(--adminSecondary)"
                    />
                  </Row>
                </div>
              ) : null}
            </Row>
          ) : null}
        </Col>
      </Row>
    );
  }
}

export default connect(
  state => ({ user: state.user, kioskSetting: state.kioskSetting, appMode: state.appMode }),
  {
    resetUser,
    receiveCurrAccount,
    deleteGuestName,
    deleteGuestPhone,
    deleteGuestIns,
    resetCustomerOrderOption,
    resetLoginType,
    clearOrderItems,
    clearScannedOrderItems,
    receiveAppMode,
    resetAppMode,
  },
)(AdminDashboard);
