import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Row, Col } from 'antd';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import Button from '../Button';
import Modal from '../Modal';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

const { TextArea } = Input;

class ResInputModal extends Component {
  state = {
    layout: 'default',
    input: '',
    shift: false,
    cap: false,
    error: '',
  };

  refInstructionInput = React.createRef();

  setLayout = () => {
    const { dataField } = this.props;
    if (dataField?.type === 'phoneNumber') {
      this.setState({ layout: 'number' });
    } else {
      this.setState({ layout: 'default' });
    }
  };

  handleInputOnFocus = e => {
    const temp_value = e.target.value;
    e.target.value = '';
    e.target.value = temp_value;
    this.setState({ input: e.target.value });
  };

  handleInputOnBlur = () => {
    this.refInstructionInput.current.focus();
  };

  handleInputOnChange = e => {
    let input;
    const { dataField } = this.props;
    if (dataField?.type === 'phoneNumber') {
      input = e.target.value.replace(/\D/g, '').substring(0, 10);
    } else {
      input = e.target.value;
    }
    this.setState({ input, error: '' });
    this.keyboard.setInput(input);
  };

  handleInsModalCancel = () => {
    this.handleClearInput();
    this.props.onInsModalCancel();
  };

  keyboardOptions = {
    ...this.commonKeyboardOptions,
    layout: {
      default: [
        '1 2 3 4 5 6 7 8 9 0 - {bksp}',
        'q w e r t y u i o p [ ] \\',
        "{lock} a s d f g h j k l ; '",
        '{shift} z x c v b n m , .',
        '.com @ {space}',
      ],
      shift: [
        '! @ # $ % ^ &amp; * ( ) _ {bksp}',
        'Q W E R T Y U I O P { } |',
        '{lock} A S D F G H J K L : "',
        '{shift} Z X C V B N M &lt; &gt; ?',
        '.com @ {space}',
      ],
      number: ['1 2 3', '4 5 6', '7 8 9', '0 {bksp}'],
    },
  };

  onKeyboardChange = input => {
    const { dataField } = this.props;
    if (dataField?.type === 'phoneNumber') {
      input = input.replace(/\D/g, '').substring(0, 10);
    }
    this.setState({ input, error: '' });
    this.keyboard.setInput(input);
  };

  onKeyPress = button => {
    if (button === '{shift}' || button === '{lock}') this.handleShift();
  };

  onKeyReleased = button => {
    const { shift, cap } = this.state;
    if (button === '{shift}') {
      this.setState({ shift: true });
      return;
    }
    if (button === '{lock}') {
      this.setState({ cap: !this.state.cap });
      return;
    }
    if (button !== '{shift}' && button !== '{lock}' && shift && !cap) {
      if (this.props.dataField?.type === 'phoneNumber') {
        return;
      } else {
        this.setState({ layout: 'default' });
      }
    }
  };

  handleShift = () => {
    const { layout } = this.state;
    this.setState({ layout: layout === 'default' ? 'shift' : 'default' });
  };

  handleInputEnterKey = e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      this.handleOK();
    }
  };

  handleClearInput = () => {
    this.setState({ input: '' });
    this.keyboard.clearInput();
  };

  handleOK = () => {
    const { input } = this.state;
    const { dataField } = this.props;
    if (dataField?.type === 'phoneNumber') {
      const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      if (input && !re.test(input)) {
        this.setState({ error: 'Please enter a valid phone number' });
        return;
      }
    }
    this.props.onTextChange(input);
    this.setState({ input: '' });
    this.keyboard.clearInput();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ input: nextProps.value, layout: nextProps.keyboardLayout });
    this.keyboard && this.keyboard.setInput(nextProps.value);
  }

  componentDidMount() {
    const { input } = this.state;
    this.keyboard && this.keyboard.setInput(input);
    this.setLayout();
  }

  componentDidUpdate(prevProps, prevState) {
    const { dataField, value } = this.props;
    if (dataField?.type !== prevProps.dataField?.type) {
      this.setState({ input: value });
      this.setLayout();
    }

    const { input } = this.state;
    this.keyboard && this.keyboard.setInput(input);
  }

  render() {
    const { layout, input, error } = this.state;
    const { visible, dataField, clientStyle } = this.props;
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    const descStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontTwo,
    };
    const rowStyle = {
      backgroundColor: clientStyle.primary,
      color: clientStyle.colorTwo,
    };
    return (
      <Modal
        width={dataField?.type === 'phoneNumber' ? 450 : 950}
        visible={visible}
        maskClosable={false}
        closable={false}
        title={null}
        footer={null}
        destroyOnClose={true}
        onCancel={this.handleInsModalCancel}
        showCloseBtn={false}
      >
        <Row
          justify="center"
          align="middle"
          style={{ margin: '0 0 10px', fontSize: 18, fontWeight: 'bold' }}
        >
          <h3 style={{ ...titleStyle, marginLeft: 20, textAlign: 'center' }}>{dataField?.type}</h3>
        </Row>
        <Row
          style={{ padding: 15, height: 30, fontWeight: 'bold', color: '#8b0000', fontSize: 18 }}
          justify="center"
        >
          <Col>
            <SwitchTransition>
              <CSSTransition key={error} timeout={500} classNames="login-account-error">
                <Row style={{ ...descStyle }}>{error}</Row>
              </CSSTransition>
            </SwitchTransition>
          </Col>
        </Row>
        {(dataField?.type === 'firstName' ||
          dataField?.type === 'lastName' ||
          dataField?.type === 'FirstName' ||
          dataField?.type === 'LastName' ||
          dataField?.type === 'email') && (
          <Row justify="center">
            <Input
              ref={this.refInstructionInput}
              autoFocus={true}
              spellCheck={false}
              value={input}
              maxLength={25}
              style={{ margin: '20px 0 30px', width: 500, fontWeight: 'bolder', ...descStyle }}
              onFocus={this.handleInputOnFocus}
              onBlur={this.handleInputOnBlur}
              onChange={this.handleInputOnChange}
            />
          </Row>
        )}
        {dataField?.type === 'instruction' && (
          <TextArea
            ref={this.refInstructionInput}
            showCount
            autoFocus={true}
            autoSize={{ minRows: 3, maxRows: 3 }}
            maxLength={100}
            spellCheck={false}
            value={input}
            style={{ margin: '20px 0 30px', ...descStyle }}
            onFocus={this.handleInputOnFocus}
            onBlur={this.handleInputOnBlur}
            onChange={this.handleInputOnChange}
            onKeyDown={this.handleInputEnterKey}
          />
        )}
        {dataField?.type === 'phoneNumber' && (
          <>
            <Row justify="center">
              <input
                ref={this.refInstructionInput}
                className="login-card-input"
                autoFocus={true}
                type="tel"
                value={input}
                onFocus={this.handleInputOnFocus}
                onBlur={this.handleInputOnBlur}
                onChange={this.handleInputOnChange}
              />
            </Row>
            <Row justify="center">
              <Col>
                <Row
                  justify="center"
                  style={{
                    margin: '20px 0 30px',
                    width: 300,
                    borderBottom: `2px solid ${clientStyle.primary}`,
                    fontSize: 20,
                    fontWeight: 'bolder',
                    ...descStyle,
                  }}
                >
                  <span className="order-instruction-modal-phone-number-cell">(</span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(0, 1)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(1, 2)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(2, 3)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">)</span>
                  {/* <span className="order-instruction-modal-phone-number-cell"> </span> */}
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(3, 4)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(4, 5)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(5, 6)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">-</span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(6, 7)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(7, 8)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(8, 9)}
                  </span>
                  <span className="order-instruction-modal-phone-number-cell">
                    {input.slice(9, 10)}
                  </span>
                </Row>
              </Col>
            </Row>
          </>
        )}
        <Row justify="space-between" style={{ margin: '0 10px 30px' }}>
          <Button
            width={120}
            height={60}
            style={{ ...rowStyle }}
            textStyle={{ fontSize: 18 }}
            borderRadius={5}
            onClick={this.handleClearInput}
          >
            Clear
          </Button>
          <Button
            width={120}
            height={60}
            style={{ ...rowStyle }}
            textStyle={{ fontSize: 18 }}
            borderRadius={5}
            onClick={this.handleOK}
          >
            OK
          </Button>
        </Row>

        <Keyboard
          keyboardRef={r => (this.keyboard = r)}
          layoutName={layout}
          onChange={this.onKeyboardChange}
          onKeyPress={this.onKeyPress}
          onKeyReleased={this.onKeyReleased}
          theme={'hg-theme-default hg-layout-default'}
          preventMouseDownDefault={true}
          {...this.keyboardOptions}
        />
      </Modal>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(ResInputModal);
