import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import MenuPizzaToppingRow from '../MenuPizzaToppingRow';

class MenuPizzaToppingsGrouper extends Component {
  state = { expanded: true, groupPaneMaxHeight: null };
  menuPizzaGroupRef = React.createRef();

  init = () => {
    const height = this.menuPizzaGroupRef.current.scrollHeight;
    this.setState({ groupPaneMaxHeight: height });
  };

  handleExpand = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  handleUpdatePizzaTopping = (item, index) => {
    this.props.updatePizzaTopping(item, index);
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { expanded, groupPaneMaxHeight } = this.state;
    const { style, height = 60, data, title, priceInfo, isPizza, clientStyle } = this.props;
    // let { barBg, paneBg } = this.props || {};
    // if (!barBg) {
    //   barBg = 'linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)';
    //   barBg = 'var(--primary)';
    // }
    // if (!paneBg) {
    //   paneBg = 'linear-gradient(to top, #f3e7e9 0%, #e3eeff 50%, #e3eeff 100%)';
    //   paneBg = '';
    // }
    const titleStyle = {
      color: clientStyle.colorOne,
      fontFamily: clientStyle.fontOne,
      fontVariationSettings: clientStyle.fontVariationSettings,
      textTransform: clientStyle.textTransform,
    };
    return (
      <Row style={{ marginBottom: 10 }}>
        <Col span={24}>
          <Row
            justify="space-between"
            align="middle"
            onClick={this.handleExpand}
            className="menu-pizza-toppings-grouper-bar"
            style={{
              ...style,
              height,
              width: '100%',
              borderTop: `2px solid ${clientStyle.primary}`,
              borderLeft: `5px solid ${clientStyle.primary}`,
              padding: '0 0 0 10px',
              position: 'sticky',
              top: `100px`,
              zIndex: 2,
              backgroundColor: clientStyle.bgColor,
            }}
          >
            <Col>
              <Row style={{ ...titleStyle, fontWeight: 'bold' }}>{title}</Row>
            </Col>
            <Col>
              <Row
                justify="end"
                style={{
                  ...titleStyle,
                  fontWeight: 'bold',
                  borderBottom: `2px solid ${clientStyle.primary}`,
                }}
              >
                {priceInfo === '' ? '' : `$${priceInfo.toFixed(2)} Each Topping`}
              </Row>
            </Col>
          </Row>
          <div
            ref={this.menuPizzaGroupRef}
            className="menu-pizza-toppings-grouper-content"
            style={{
              overflow: 'hidden',
              maxHeight: expanded ? groupPaneMaxHeight : 0,
              transition: 'max-height 500ms ease-in-out',
              padding: '0 5px',
            }}
          >
            <Col span={24}>
              {isPizza && data && data.length > 0 && (
                <Row style={{ ...titleStyle }}>
                  <Col span={10}></Col>
                  <Col span={1}></Col>
                  <Col span={2}></Col>
                  <Col span={3}>
                    <Row justify="center">1st Half</Row>
                  </Col>
                  <Col span={3}>
                    <Row justify="center">Whole</Row>
                  </Col>
                  <Col span={3}>
                    <Row justify="center">2nd Half</Row>
                  </Col>
                  <Col span={2}></Col>
                </Row>
              )}
              {data &&
                data.length > 0 &&
                data.map(i => (
                  <MenuPizzaToppingRow
                    key={i.Code}
                    item={i}
                    color="var(--primary)"
                    isPizza={isPizza}
                    updatePizzaTopping={(item, index) => {
                      this.handleUpdatePizzaTopping(item, index);
                    }}
                  />
                ))}
            </Col>
          </div>
        </Col>
      </Row>
    );
  }
}
export default connect(state => ({ clientStyle: state.clientStyle }), {})(MenuPizzaToppingsGrouper);
