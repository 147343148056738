import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider, Select, Table, Input, message } from 'antd';
import { nanoid } from '@reduxjs/toolkit';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import {
  getAppSetting,
  saveAppSetting,
  getKioskStationConfigurations,
  getProfitCentres,
  getCurrentKioskSetting,
} from '../../../api';
import { receiveAppSetting } from '../../../redux/actions';
import AdminHeader from '../../../components/AdminHeader';
import Spin from '../../../components/Spin';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Selection from '../../../components/Selection';
import './index.css';

const { Option } = Select;

class AdminKioskMappings extends Component {
  count = 1;
  state = {
    spinning: false,
    error: '',
    appSetting: {},
    kiosks: [],
    visible: false,
    configs: [],
    profitCenters: [],
    kioskName: '',
    profitCenterCode: '',
    customSettingCode: '',
    selectedkiosk: null,
  };

  init = async () => {
    this.setState({ spinning: true });
    await Promise.all([getAppSetting(), getKioskStationConfigurations(), getProfitCentres()])
      .then(results => {
        results.forEach(res => {
          if (!res || (res && res.result !== 'OK')) throw new Error();
        });
        let prunedData = results[0].data.Kiosks.filter(i => i.KioskName);
        prunedData = prunedData.map(i => {
          i.key = nanoid();
          return i;
        });
        this.setState({
          spinning: false,
          appSetting: results[0].data,
          kiosks: prunedData,
          configs: results[1].data,
          profitCenters: results[2].data,
        });
      })
      .catch(error => {
        this.setState({ spinning: false });
        message.error('Error obtaining kiosk settings');
      });
  };

  handleSaveBtn = async () => {
    const { appSetting, kiosks } = this.state;
    appSetting.Kiosks = kiosks;
    try {
      this.setState({ spinning: true });
      const re = await saveAppSetting(appSetting);
      if (!re || (re && re.result !== 'OK')) throw new Error();
      const res = await getCurrentKioskSetting();
      if (!res || (res && res.result !== 'OK')) throw new Error();
      const obj = {};
      obj.appSetting = res.appSetting ? res.appSetting : {};
      obj.imgSetting = res.imgSetting ? res.imgSetting : {};
      obj.stationName = res.stationName ? res.stationName : '';
      this.props.receiveAppSetting(obj);
      this.setState({ spinning: false });
      message.success('Mappings successfully updated!', 3);
    } catch (error) {
      this.setState({ spinning: false });
      message.error('Error saving mappings!', 3);
    }
    this.init();
  };

  handleAddKioskButton = () => {
    this.setState(
      {
        kioskName: '',
        profitCenterCode: '',
        customSettingCode: '',
        error: '',
        selectedkiosk: null,
      },
      () => {
        this.setState({ visible: true });
      },
    );
  };

  handleModalOkBtn = () => {
    const { kioskName, profitCenterCode, customSettingCode, kiosks, selectedkiosk } = this.state;
    if (!kioskName || kioskName.trim() === '') {
      this.setState({ error: 'Kiosk name is required!' });
      return;
    }
    if (
      !selectedkiosk &&
      kiosks.filter(i => i.KioskName.trim().toLowerCase() === kioskName.trim().toLowerCase())
        .length > 0
    ) {
      this.setState({ error: 'Kiosk name already exists!' });
      return;
    }

    if (
      selectedkiosk &&
      kiosks
        .filter(i => i.key !== selectedkiosk.key)
        .filter(i => i.KioskName.trim().toLowerCase() === kioskName.trim().toLowerCase()).length > 0
    ) {
      this.setState({ error: 'Kiosk name already exists!' });
      return;
    }

    if (selectedkiosk) {
      const item = kiosks.find(i => i.key === selectedkiosk.key);
      const itemIndex = kiosks.findIndex(i => i.key === selectedkiosk.key);
      const head = itemIndex <= 0 ? [] : kiosks.slice(0, itemIndex);
      const tail = kiosks.slice(itemIndex + 1);
      const newItem = JSON.parse(JSON.stringify(item));
      newItem.KioskName = kioskName.toUpperCase();
      newItem.CustomizedSettingId = customSettingCode;
      newItem.ProfitCentreCode =
        profitCenterCode === undefined || profitCenterCode === null ? '' : profitCenterCode;
      this.setState({
        kiosks: [...head, newItem, ...tail],
        visible: false,
        kioskName: '',
        profitCenterCode: '',
        customSettingCode: '',
        selectedkiosk: null,
      });
    } else {
      const obj = {};
      obj.key = nanoid();
      obj.CustomizedSettingId = customSettingCode;
      obj.EnableAdmin = null;
      obj.KioskId = null;
      obj.KioskName = kioskName;
      obj.PaymentLaneId = null;
      obj.PrinterNo = null;
      obj.ProfitCentreCode =
        profitCenterCode === undefined || profitCenterCode === null ? '' : profitCenterCode;

      this.setState({
        kiosks: [...kiosks, obj],
        visible: false,
        kioskName: '',
        profitCenterCode: '',
        customSettingCode: '',
        selectedkiosk: null,
      });
    }
  };

  handleModalCancel = () => {
    this.setState({ visible: false });
    if (this.state.error !== '') {
      this.setState({ error: '' });
    }
  };

  handleEditRow = row => {
    this.setState(
      {
        kioskName: row.KioskName,
        profitCenterCode: row.ProfitCentreCode,
        customSettingCode: row.CustomizedSettingId,
        error: '',
        selectedkiosk: row,
      },
      () => {
        this.setState({ visible: true });
      },
    );
  };

  handleRemoveRow = row => {
    const { kiosks } = this.state;
    const newData = kiosks.filter(i => i.key !== row.key);
    this.setState({ kiosks: [...newData] });
  };

  handleModalKioskNameChange = ({ target: { value } }) => {
    this.setState({ kioskName: value });
    if (this.state.error !== '') {
      this.setState({ error: '' });
    }
  };

  handleModalSelectChange = (v, _, dataType) => {
    this.setState({ [dataType]: v });
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const {
      spinning,
      error,
      kiosks,
      visible,
      configs,
      profitCenters,
      kioskName,
      profitCenterCode,
      customSettingCode,
      selectedkiosk,
    } = this.state;
    const columns = [
      {
        title: 'Delete',
        render: (text, record) => (
          <Row>
            <Col>
              <FontAwesomeIcon
                icon={faTrashCan}
                size="xl"
                color="var(--adminSecondary)"
                onClick={() => this.handleRemoveRow(record)}
                style={{ marginLeft: 20 }}
              />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Kiosk Name',
        dataIndex: 'KioskName',
      },
      {
        title: 'Profit Center Code',
        dataIndex: 'ProfitCentreCode',
        render: (text, record) => {
          const pCenter = profitCenters.find(i => i.CentreCode === record.ProfitCentreCode);
          return <Row justify="start">{pCenter ? pCenter.Description : ''}</Row>;
        },
      },
      {
        title: 'Customized Setting',
        dataIndex: 'CustomizedSettingId',
        render: (text, record) => {
          let txt = '';
          if (record.CustomizedSettingId === '') {
            txt = 'Default';
          } else {
            const cSetting = configs.find(i => i.SettingId === record.CustomizedSettingId);
            if (cSetting) {
              txt = cSetting.SettingName;
            } else {
              txt = 'Default';
            }
          }
          return <Row justify="start">{txt}</Row>;
        },
      },
      {
        title: 'Edit',
        render: (text, record) => (
          <Row>
            <Col>
              <FontAwesomeIcon
                icon={faPenToSquare}
                size="xl"
                color="var(--adminPrimary)"
                onClick={() => this.handleEditRow(record)}
              />
            </Col>
          </Row>
        ),
      },
    ];
    const profitCenterOptions = profitCenters.map(i => (
      <Option key={i.CentreCode} value={i.CentreCode}>
        {i.Description}
      </Option>
    ));
    const customSettingOptions = [
      <Option key="Default" value="">
        Default
      </Option>,
      ...configs.map(i => (
        <Option key={i.SettingId} value={i.SettingId}>
          {i.SettingName}
        </Option>
      )),
    ];

    return (
      <Spin spinning={spinning}>
        <Row className="admin-kiosk-mappings-wrapper">
          <Col span={24}>
            {/* Header row */}
            <AdminHeader
              title="Kiosk Configuration Mappings"
              showLeftBtn={false}
              showRightBtn={true}
              rightBtnText="Save"
              onRightBtnClick={this.handleSaveBtn}
            />
            <Divider style={{ borderColor: 'transparent' }} />
            <Row>
              <Button
                width={120}
                height={50}
                style={{ backgroundColor: 'var(--adminPrimary)' }}
                textStyle={{ fontSize: 18, color: 'white' }}
                borderRadius={5}
                onClick={this.handleAddKioskButton}
              >
                Add
              </Button>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col span={24}>
                <Table
                  size="middle"
                  rowKey="key"
                  columns={columns}
                  dataSource={kiosks}
                  pagination={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          visible={visible}
          width={520}
          title={null}
          footer={null}
          closable={false}
          maskClosable={false}
          destroyOnClose={true}
          showCloseBtn={false}
        >
          <Row>
            <Col span={24}>
              {/* Title Row */}
              <Row justify="center">
                <h2>{selectedkiosk ? 'Edit Kiosk' : 'Add Kiosk'}</h2>
              </Row>
              {/* Error Row */}
              <Row
                style={{
                  padding: 15,
                  height: 30,
                  fontWeight: 'bold',
                  color: '#8b0000',
                  fontSize: 16,
                  marginBottom: 20,
                }}
                justify="center"
              >
                <Col>
                  <SwitchTransition>
                    <CSSTransition key={error} timeout={500} classNames="login-account-error">
                      <Row>{error}</Row>
                    </CSSTransition>
                  </SwitchTransition>
                </Col>
              </Row>
              {/* Kiosk Name Row */}
              <Row justify="center">
                <Col>
                  <Row>
                    <h3>Kiosk Name</h3>
                  </Row>
                  <Row style={{ width: 250 }}>
                    <Input
                      spellCheck={false}
                      value={kioskName.toUpperCase()}
                      onChange={e => {
                        this.handleModalKioskNameChange(e);
                      }}
                    />
                  </Row>
                </Col>
              </Row>
              <Divider style={{ borderColor: 'transparent' }} />
              {/* ProdiftCenter Row */}
              <Row justify="center">
                <Col>
                  <Row>
                    <h3>Profit Center</h3>
                  </Row>
                  <Row style={{ width: 250 }}>
                    <Selection
                      width={250}
                      showSearch={true}
                      allowClear={true}
                      data={profitCenterOptions}
                      style={{ width: 250 }}
                      value={profitCenterCode}
                      onChange={(v, o) => {
                        this.handleModalSelectChange(v, o, 'profitCenterCode');
                      }}
                    />
                  </Row>
                </Col>
              </Row>
              <Divider style={{ borderColor: 'transparent' }} />
              {/* CutomSetting Row */}
              <Row justify="center">
                <Col>
                  <Row>
                    <h3>Cutomized Setting</h3>
                  </Row>
                  <Row style={{ width: 250 }}>
                    <Selection
                      width={250}
                      style={{ width: 250 }}
                      data={customSettingOptions}
                      value={customSettingCode}
                      onChange={(v, o) => {
                        this.handleModalSelectChange(v, o, 'customSettingCode');
                      }}
                    />
                  </Row>
                </Col>
              </Row>
              <Divider style={{ borderColor: 'transparent' }} />
              {/* Footer Row */}
              <Row style={{ padding: '0, 30px' }} justify="space-between">
                <Button
                  width={120}
                  style={{ backgroundColor: 'var(--adminPrimary)' }}
                  textStyle={{ fontSize: 18, color: 'white' }}
                  borderRadius={5}
                  onClick={this.handleModalCancel}
                >
                  Cancel
                </Button>
                <Button
                  width={120}
                  style={{ backgroundColor: 'var(--adminPrimary)' }}
                  textStyle={{ fontSize: 18, color: 'white' }}
                  borderRadius={5}
                  onClick={this.handleModalOkBtn}
                >
                  OK
                </Button>
              </Row>
            </Col>
          </Row>
        </Modal>
      </Spin>
    );
  }
}
export default connect(state => ({}), { receiveAppSetting })(AdminKioskMappings);
